import React from 'react';
import { useFormikContext } from 'formik';
import { StyledButton } from '../Button';
import { displayName } from '../../util';

interface Props {
  className?: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  width?: number;
  children?: React.ReactNode;
  additionalDisabledCondition?: boolean;
  id?: string;
}

const SubmitButton: React.FC<Props> = ({
  className,
  width,
  leftIcon,
  rightIcon,
  children,
  additionalDisabledCondition,
  id,
}) => {
  const { submitForm, isSubmitting } = useFormikContext();

  const isDisabled =
    additionalDisabledCondition !== undefined ? isSubmitting || additionalDisabledCondition : isSubmitting;

  return (
    <StyledButton
      id={id}
      className={className}
      variant="contained"
      disableElevation={true}
      width={width}
      startIcon={leftIcon}
      endIcon={rightIcon}
      onClick={submitForm}
      disabled={isDisabled}
    >
      {children}
    </StyledButton>
  );
};

displayName(SubmitButton, 'SubmitButton');

export default SubmitButton;
