import React, { useCallback, useMemo, useState } from 'react';
import { SelectChangeEvent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import DefaultSelect from '../Select';
import { ActionButton } from '../Button';
import TextInput from '../TextInput';
import { toMenuItem } from './util';
import { displayName } from '../../util';
import {
  statisticsFilterDates,
  StatisticsFilterValues,
  useInstances,
  useStatisticsCategories,
  useStatisticsFilter,
} from '../../globalState';
import SimpleCard from 'components/SimpleCard';

const StatisticsFilter: React.FC = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useStatisticsFilter();
  const [localFilter, setLocalFilter] = useState<StatisticsFilterValues>(filter);
  const instances = useInstances();
  const categories = useStatisticsCategories();

  const handleApply = useCallback(() => setFilter(localFilter), [localFilter, setFilter]);
  const dateOptions = useMemo(() => statisticsFilterDates().map(toMenuItem), []);
  const categoryOptions = useMemo(() => categories.map(toMenuItem), [categories]);
  const instanceOptions = useMemo(() => instances.map(toMenuItem), [instances]);
  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const { name, value } = e.target;
      setLocalFilter(f => ({ ...f, [name]: value }));
    },
    [setLocalFilter]
  );

  return (
    <SimpleCard>
      <Stack direction="column" gap={4} padding={3}>
        <Text weight="700">{t('admin.sidebar.statisticsFilter.title')}</Text>
        <Stack direction="column" gap={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.from')}</Text>
            <DefaultSelect name="from" value={localFilter.from} onChange={handleSelectChange} width={11}>
              {dateOptions}
            </DefaultSelect>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.until')}</Text>
            <DefaultSelect name="to" value={localFilter.to} onChange={handleSelectChange} width={11}>
              {dateOptions}
            </DefaultSelect>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.category')}</Text>
            <DefaultSelect name="category" value={localFilter.category} onChange={handleSelectChange} width={11}>
              {categoryOptions}
            </DefaultSelect>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.instance')}</Text>
            <DefaultSelect name="instance" value={localFilter.instance} onChange={handleSelectChange} width={11}>
              {instanceOptions}
            </DefaultSelect>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.user')}</Text>
            <TextInput value={localFilter.user} onChange={user => setLocalFilter(f => ({ ...f, user }))} width={11} />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text>{t('admin.sidebar.statisticsFilter.filter.description')}</Text>
            <TextInput
              value={localFilter.description}
              onChange={description => setLocalFilter(f => ({ ...f, description }))}
              width={11}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="end">
          <ActionButton onClick={handleApply}>{t('dialog.apply')}</ActionButton>
        </Stack>
      </Stack>
    </SimpleCard>
  );
};

displayName(StatisticsFilter, 'StatisticsFilter');

export default StatisticsFilter;
