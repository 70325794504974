import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card, FormControlLabel, MenuItem, Radio, Stack, styled, Tooltip, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { keyBy } from 'lodash';
import WizardStepLayout from '../WizardStepLayout';
import FormItem from '../FormItem';
import FormikTextInputField from '../formik/FormikTextInputField';
import Text from '../Text';
import FormikCheckbox from '../formik/FormikCheckbox';
import FormikRadioGroup from '../formik/FormikRadioGroup';
import { CustomReportData, ReportRecipient, SendEmailButton } from './CustomReportWizard';
import ReportRecipientTable from './ReportRecipientTable';
import { ActionButton, UploadButton } from '../Button';
import { SUPPORTED_LANGUAGES, useAnalysisFilterFields, useApi, useVisibleTableQueryObject } from '../../globalState';
import { displayName, useFetch } from '../../util';
import ReportRecipientEditor from './ReportRecipientEditor';
import { ReportRecipientDTO } from '../../dto';
import { downloadXLSX, uploadXLSX } from '../../xlsutil';
import { useTranslation } from 'react-i18next';
import FormikSelect from '../formik/FormikSelect';
import FormikTextInput from '../formik/FormikTextInput';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey['200']};
  padding: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
}

const CustomReportWizardStep2: React.FC<Props> = ({ disabledSendEmail, triggerSendEmail }) => {
  const theme = useTheme();
  const api = useApi();
  const visibleQueryObject = useVisibleTableQueryObject();
  const filterFields = useAnalysisFilterFields()
    .fields.slice()
    .sort((a, b) => a.label.localeCompare(b.label));

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CustomReportData>();
  const { dispatchType, encrypt } = values;
  const [recipients, setRecipients] = useState<ReportRecipient[]>(
    values.recipients?.map((r, i) => ({
      ...r,
      id: `${i + 1}`,
      status: r.status ?? t('sidebar.customReport.step2.reportRecipient.new'),
    })) ?? []
  );
  const [editingRecipient, setEditingRecipient] = useState<{ isEditing: boolean; recipient?: ReportRecipient }>({
    isEditing: false,
  });
  const [distinctValues, fetchDistinctValues, loading] = useFetch(api.getDistinctValues, { values: [] });

  const filterFieldsByName = useMemo(() => keyBy(filterFields, 'name'), [filterFields]);
  const updateRecipients = useCallback(
    (updated: ReportRecipient[]) => {
      setRecipients(updated);
      setFieldValue(
        'recipients',
        updated.map(r => ({ value: r.value, email: r.email })),
        true
      );
    },
    [setFieldValue]
  );
  const addRecipient = useCallback(
    (value: string, email: string) => {
      if (editingRecipient.recipient) {
        const recipient = recipients.find(r => r.id === editingRecipient.recipient?.id) as ReportRecipient;
        recipient.value = value;
        recipient.email = email;
      } else {
        updateRecipients(
          recipients.concat({
            id: `${recipients.length + 1}`,
            value,
            email,
            status: t('sidebar.customReport.step2.reportRecipient.new'),
          })
        );
      }
    },
    [editingRecipient.recipient, recipients, t, updateRecipients]
  );
  const batchField = filterFieldsByName[values.batchField];
  useEffect(() => {
    async function getDistinctionValues() {
      await fetchDistinctValues(visibleQueryObject, { entityName: batchField.entityName, name: batchField.name });
    }
    getDistinctionValues().catch(console.error);
  }, [fetchDistinctValues, batchField, visibleQueryObject]);
  const handleDownload = useCallback(() => {
    downloadXLSX(
      ['value', 'email'],
      recipients.map(r => ({ value: r.value, email: r.email })),
      `${values.reportName}.xlsx`
    );
  }, [recipients, values.reportName]);
  const handleUpload = async (file: File) => {
    const recipients = await uploadXLSX<ReportRecipientDTO>(file, ['value', 'email']);
    updateRecipients(
      recipients.map((r, i) => ({
        id: `${i + 1}`,
        value: r.value,
        email: r.email,
        status: 'New',
      }))
    );
  };

  return (
    <WizardStepLayout title={t('sidebar.customReport.step2.title')}>
      <Stack direction="column" gap={4}>
        <Stack direction="row" paddingX={4}>
          <FormikRadioGroup name="dispatchType">
            <FormControlLabel
              control={<Radio />}
              value="SINGLE"
              label={t('sidebar.customReport.step2.dispatchType.single')}
            />
            <FormControlLabel
              control={<Radio />}
              value="BATCH"
              label={t('sidebar.customReport.step2.dispatchType.batch')}
            />
          </FormikRadioGroup>
        </Stack>
        {dispatchType === 'SINGLE' && (
          <Stack direction={'row'} gap={3}>
            <FormikTextInput
              label={t('sidebar.customReport.step2.emailLabel')}
              fieldName={'emailAddresses'}
              textAlignEnd={true}
              boldLabel={true}
              textWidth={10}
              inputWidth={12}
              gap={4}
            />
            <Tooltip title={t('sidebar.customReport.step1.tooltip')}>
              <InfoOutlinedIcon sx={{ fill: theme.palette.info.main, fontSize: '32px' }} />
            </Tooltip>
          </Stack>
        )}
        {dispatchType === 'BATCH' && (
          <Container elevation={0}>
            <Stack direction="column" gap={4}>
              <FormItem label={t('sidebar.customReport.step2.batchFieldLabel')} alignItems={'baseline'}>
                <FormikSelect name="batchField" width={12}>
                  {filterFields.map((f, i) => (
                    <MenuItem key={i} value={f.name}>
                      {f.label}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </FormItem>
              <Stack height="150px">
                <ReportRecipientTable
                  fieldLabel={filterFieldsByName[values.batchField].label}
                  distinctValues={distinctValues.values}
                  recipients={recipients}
                  onEdit={recipient => setEditingRecipient({ isEditing: true, recipient })}
                  onRemove={({ id }) =>
                    updateRecipients(recipients.filter(r => r.id !== id).map((r, i) => ({ ...r, id: `${i + 1}` })))
                  }
                />
              </Stack>
              <Stack direction="row" gap={4}>
                <ActionButton
                  startIcon={<AddIcon />}
                  width={10}
                  onClick={() => setEditingRecipient({ isEditing: true })}
                  disabled={loading}
                  variant={'outlined'}
                >
                  {t('dialog.add')}
                </ActionButton>
                <UploadButton
                  mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  startIcon={<FileUploadOutlinedIcon />}
                  width={10}
                  onUpload={handleUpload}
                >
                  {t('dialog.upload')}
                </UploadButton>
                <ActionButton
                  startIcon={<FileDownloadOutlinedIcon />}
                  width={10}
                  onClick={handleDownload}
                  variant={'outlined'}
                >
                  {t('dialog.download')}
                </ActionButton>
              </Stack>
            </Stack>
          </Container>
        )}
        <FormItem label={t('sidebar.customReport.step2.periodicityLabel')}>
          <Text>{t('sidebar.customReport.step2.periodicityText')}</Text>
        </FormItem>
        <FormItem label={t('sidebar.customReport.step2.documentTypeLabel')}>
          <FormikSelect width={12} name="documentType">
            <MenuItem value="XLSX">XLSX</MenuItem>
            <MenuItem value="CSV">CSV</MenuItem>
            <MenuItem value="XLS">XLS</MenuItem>
            <MenuItem value="PDF">PDF</MenuItem>
            <MenuItem value="JSON">JSON</MenuItem>
          </FormikSelect>
        </FormItem>
        <FormItem label={t('sidebar.customReport.step2.languageLabel')}>
          <FormikSelect name="reportLanguage" width={12}>
            {SUPPORTED_LANGUAGES.map((l, i) => (
              <MenuItem key={i} value={l.value}>
                {l.label}
              </MenuItem>
            ))}
          </FormikSelect>
        </FormItem>
        <FormItem>
          <FormikCheckbox label={t('sidebar.customReport.step2.encryptText')} name="encrypt" value="encrypt" />
        </FormItem>
        {encrypt.includes('encrypt') && (
          <FormItem label={t('sidebar.customReport.step2.zipPassword')}>
            <FormikTextInputField type="text" fieldName="zipPassword" width={12} />
          </FormItem>
        )}
        <FormItem>
          <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
        </FormItem>
      </Stack>
      {editingRecipient.isEditing && (
        <ReportRecipientEditor
          field={filterFieldsByName[values.batchField]}
          distinctValues={distinctValues.values}
          initialValues={editingRecipient.recipient}
          onClose={() => setEditingRecipient({ isEditing: false })}
          onAdd={addRecipient}
        />
      )}
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep2, 'CustomReportWizardStep2');

export default CustomReportWizardStep2;
