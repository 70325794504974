import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import DefaultSelect, { StyledSelectProps } from '../Select';
import { displayName } from '../../util';
import { ValidationError } from '../Error';
import ErrorText from './ErrorText';

type FormikSelectProps = StyledSelectProps & {
  name: string;
};

const FormikSelect: React.FC<FormikSelectProps> = props => {
  const { values, handleChange } = useFormikContext();
  const [, meta] = useField(props.name);
  const validationError = meta.error as unknown as ValidationError;

  return (
    <Stack>
      <DefaultSelect {...props} value={(values as Record<string, unknown>)[props.name]} onChange={handleChange}>
        {props.children}
      </DefaultSelect>
      {!!meta.error && validationError.message && <ErrorText text={validationError.message} />}
    </Stack>
  );
};

displayName(FormikSelect, 'FormikSelect');

export default FormikSelect;
