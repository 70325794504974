import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { AdminCustomersDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { clientLanguageState } from './telcobill';

const blacklistQuery = selector<AdminCustomersDTO>({
  key: 'blacklist',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getBlacklistCustomers();
    return dataOrThrow(response);
  },
});

export const useBlacklist = () => useRecoilValue(blacklistQuery);

export const useRefreshBlacklist = () => useRecoilRefresher_UNSTABLE(blacklistQuery);
