import React, { Suspense } from 'react';
import ContentCard from '../ContentCard';
import Spinner from '../Spinner';
import ApplicationStatisticsHeader from './ApplicationStatisticsHeader';
import ApplicationStatisticsChart from './ApplicationStatisticsChart';
import { displayName } from '../../util';

const ApplicationStatistics: React.FC = () => {
  return (
    <ContentCard flexGrow={1} header={<ApplicationStatisticsHeader />}>
      <Suspense fallback={<Spinner />}>
        <ApplicationStatisticsChart />
      </Suspense>
    </ContentCard>
  );
};

displayName(ApplicationStatistics, 'ApplicationStatistics');

export default ApplicationStatistics;
