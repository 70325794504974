import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EditorState, RichUtils, convertToRaw, Modifier, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { displayName } from '../util';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
  height?: number;
  placeholder?: string;
  initialValue?: string;
  onChange?: (html: string) => void;
  readOnly?: boolean;
}

const EditorContainer = styled('div', {
  shouldForwardProp: prop => (prop as string) !== 'height',
})<{
  height?: number;
}>`
  .DraftEditor-root {
    padding: ${({ theme }) => theme.spacing(3)};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .rdw-editor-main {
    overflow: visible;
  }
  .rdw-editor-wrapper {
    border: 1px solid #979797;
    border-radius: 4px;
    ${({ theme, height }) => (height ? `height: ${theme.spacing(height)};` : '')}
    overflow-y: auto;
  }
  .DraftEditor-root {
    height: unset;
  }
`;

const ReadOnlyEditorContainer = styled('div')`
  .DraftEditor-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
`;

const fontSizes = ['fontsize-14', 'fontsize-24', 'fontsize-40'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UntypedEditor = Editor as any;

const createInitialState = (html?: string): EditorState => {
  if (!html) {
    return EditorState.createEmpty();
  }
  const { contentBlocks, entityMap } = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

const RichTextEditor: React.FC<Props> = ({ height, placeholder, initialValue, onChange, readOnly }) => {
  const [editorState, setEditorState] = useState(() => createInitialState(initialValue));
  const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  useEffect(() => {
    if (onChange) {
      onChange(html);
    }
  }, [onChange, html]);
  const handleInlineStyleChange = (value: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, value));
  };
  const handleBlockStyleChange = (value: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, value));
  };
  const inlineStyle = editorState.getCurrentInlineStyle();
  const inlineStyleButtonState = useMemo(() => inlineStyle.toArray(), [inlineStyle]);
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  const blockTypeButtonState = useMemo(() => (blockType ? [blockType] : []), [blockType]);
  const fontSizeArray = inlineStyle.toArray().filter(s => s?.startsWith('fontsize-') || false);
  const fontSize = fontSizeArray.length > 0 ? fontSizeArray[0] : 'fontsize-14';
  const handleFontSizeChange = (value: string) => {
    let nextContentState = fontSizes.reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style);
    }, editorState.getCurrentContent());
    nextContentState = Modifier.applyInlineStyle(nextContentState, selection, value);
    const nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
    setEditorState(nextEditorState);
  };
  return (
    <Stack direction="column" gap={4}>
      {!readOnly && (
        <Stack direction="row" gap={4} height={theme => theme.spacing(5)}>
          <ToggleButtonGroup value={inlineStyleButtonState}>
            <ToggleButton
              value="BOLD"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleInlineStyleChange('BOLD')}
            >
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="ITALIC"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleInlineStyleChange('ITALIC')}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup value={blockTypeButtonState}>
            <ToggleButton
              value="unordered-list-item"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleBlockStyleChange('unordered-list-item')}
            >
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton
              value="ordered-list-item"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleBlockStyleChange('ordered-list-item')}
            >
              <FormatListNumberedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup value={fontSize}>
            <ToggleButton
              value="fontsize-14"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleFontSizeChange('fontsize-14')}
            >
              14
            </ToggleButton>
            <ToggleButton
              value="fontsize-24"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleFontSizeChange('fontsize-24')}
            >
              24
            </ToggleButton>
            <ToggleButton
              value="fontsize-40"
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleFontSizeChange('fontsize-40')}
            >
              40
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
      {readOnly ? (
        <ReadOnlyEditorContainer>
          <UntypedEditor
            toolbarHidden={true}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            placeholder={placeholder}
            readOnly={true}
          />
        </ReadOnlyEditorContainer>
      ) : (
        <EditorContainer height={height}>
          <UntypedEditor
            toolbarHidden={true}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            placeholder={placeholder}
          />
        </EditorContainer>
      )}
    </Stack>
  );
};

displayName(RichTextEditor, 'RichTextEditor');

export default RichTextEditor;
