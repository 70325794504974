import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '../Button';
import { InvoiceDTO } from '../../dto';
import { displayName, formatDate, throwApiError } from '../../util';
import { DEFAULT_USER_START_PAGE, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { toError, useSetEncounteredError } from '../Error';
import NonInteractiveTooltip from '../Tooltip';
import { DownloadSpinner } from '../Spinner';
import SupportDataGrid from './SupportDataGrid';
import { ApiError } from '../../api';

interface Props {
  invoices: InvoiceDTO[];
  error: ApiError | undefined;
}

const getDate = ({ row }: GridValueGetterParams<InvoiceDTO, unknown>) =>
  `${formatDate(row.dateFrom)} - ${formatDate(row.dateTo)}`;

const SupportInvoices: React.FC<Props> = ({ invoices, error }) => {
  const api = useApi();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [loginRow, setLoginRow] = useState('-1');

  const columns: GridColDef<InvoiceDTO & { id: string }>[] = useMemo(() => {
    const loginAsUser = async (invoice: InvoiceDTO, rowId: string) => {
      setSuspended(true);
      setLoginRow(rowId);
      try {
        const response = await api.loginAs('INVOICE', invoice.invoiceNo);
        if (response.data?.status === 'OK') {
          window.location.href = DEFAULT_USER_START_PAGE.replace(':lng', language);
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(t('admin.support.error.login'), toError(err), {
          api: 'loginAs',
          loginAsType: 'INVOICE',
          invoiceNo: invoice.invoiceNo,
        });
      } finally {
        setSuspended(false);
      }
    };
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 55,
        renderCell: ({ row }) => (
          <Stack direction={'row'}>
            <NonInteractiveTooltip title={t('admin.support.tooltip.login')}>
              <IconButton onClick={() => loginAsUser(row, row.id)} disabled={suspended}>
                <ChevronRightIcon />
              </IconButton>
            </NonInteractiveTooltip>
            <DownloadSpinner size={20} suspended={suspended && loginRow === row.id} />
          </Stack>
        ),
      },
      {
        field: 'invoiceNo',
        headerName: t('admin.support.invoice.header.invoiceNo'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'accountNo',
        headerName: t('admin.support.invoice.header.accountNo'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'date',
        headerName: t('admin.support.invoice.header.date'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 200,
        valueGetter: getDate,
      },
    ];
  }, [api, language, loginRow, setEncounteredError, suspended, t]);
  const rows = useMemo(() => {
    return invoices.map((invoice, i) => ({ ...invoice, id: `${i + 1}` }));
  }, [invoices]);

  return (
    <SupportDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      error={error}
      title={t('admin.support.invoice.title')}
    />
  );
};

displayName(SupportInvoices, 'SupportInvoices');

export default SupportInvoices;
