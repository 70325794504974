import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SidebarLayout from '../../SidebarLayout';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { IconButton } from '../../components/Button';
import { useApi } from '../../globalState';
import { displayName, useSuspenseFetch } from '../../util';
import SupportAccounts from '../../components/support/SupportAccounts';
import SupportCustomers from '../../components/support/SupportCustomers';
import SupportInvoices from '../../components/support/SupportInvoices';
import SupportReports from '../../components/support/SupportReports';
import SupportSubscriptions from '../../components/support/SupportSubscriptions';
import SupportUsers from '../../components/support/SupportUsers';
import { SupportEntityType } from '../../dto';
import SimpleCard from 'components/SimpleCard';
import useDocumentTitle from '../../useDocumentTitle';
import ErrorText from '../../components/formik/ErrorText';

const emptySearchErrors: Record<SupportEntityType, string | undefined> = {
  CUSTOMER: undefined,
  USER: undefined,
  ACCOUNT: undefined,
  SUBSCRIPTION: undefined,
  INVOICE: undefined,
  REPORT: undefined,
};

const Support: React.FC = () => {
  const dispatcherRef = React.useRef<DispatcherApi>(null);
  const { t } = useTranslation();
  const [searchErrors, setSearchErrors] = useState<Record<SupportEntityType, string | undefined>>(emptySearchErrors);

  const handleSearch = useCallback((type: SupportEntityType, search: string) => {
    if (dispatcherRef.current) {
      const searchError = dispatcherRef.current.search(type, search);
      const newSearchErrors = { ...emptySearchErrors };
      newSearchErrors[type] = searchError;
      setSearchErrors(newSearchErrors);
    }
  }, []);

  useDocumentTitle(t('application.shortName') + ' - ' + t('admin.support.title'));
  return (
    <SidebarLayout
      title={t('admin.support.title')}
      sidebarContent={<SearchPanel onSearch={handleSearch} searchErrors={searchErrors} />}
    >
      <Dispatcher ref={dispatcherRef} />
    </SidebarLayout>
  );
};

displayName(Support, 'Support');

export default Support;
interface SearchItemProps {
  type: SupportEntityType;
  label: string;
  onSearch: (type: SupportEntityType, search: string) => void;
  searchError?: string;
}

const SearchItem: React.FC<SearchItemProps> = ({ type, label, onSearch, searchError }) => {
  const [search, setSearch] = useState('');

  return (
    <Stack direction="column">
      <Text>{label}</Text>
      <Stack direction="row" justifyContent="space-between">
        <Stack width={'100%'} marginRight={'8px'}>
          <TextInput width={'100%'} value={search} onChange={setSearch} />
        </Stack>
        <IconButton customSize={5} onClick={() => onSearch(type, search)}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
      {searchError !== undefined && (
        <Stack>
          <ErrorText text={searchError} />
        </Stack>
      )}
    </Stack>
  );
};

interface SearchPanelProps {
  onSearch: (type: SupportEntityType, search: string) => void;
  searchErrors: Record<SupportEntityType, string | undefined>;
}

const SearchPanel: React.FC<SearchPanelProps> = ({ onSearch, searchErrors }) => {
  const { t } = useTranslation();

  return (
    <SimpleCard>
      <Stack direction="column" paddingX={3} paddingTop={2} paddingBottom={3} gap={3}>
        <Text weight="700">{t('admin.sidebar.search.title')}</Text>
        <SearchItem
          type="CUSTOMER"
          label={t('admin.sidebar.search.type.customer')}
          onSearch={onSearch}
          searchError={searchErrors['CUSTOMER']}
        />
        <SearchItem
          type="USER"
          label={t('admin.sidebar.search.type.user')}
          onSearch={onSearch}
          searchError={searchErrors['USER']}
        />
        <SearchItem
          type="ACCOUNT"
          label={t('admin.sidebar.search.type.account')}
          onSearch={onSearch}
          searchError={searchErrors['ACCOUNT']}
        />
        <SearchItem
          type="SUBSCRIPTION"
          label={t('admin.sidebar.search.type.subscription')}
          onSearch={onSearch}
          searchError={searchErrors['SUBSCRIPTION']}
        />
        <SearchItem
          type="INVOICE"
          label={t('admin.sidebar.search.type.invoice')}
          onSearch={onSearch}
          searchError={searchErrors['INVOICE']}
        />
        <SearchItem
          type="REPORT"
          label={t('admin.sidebar.search.type.report')}
          onSearch={onSearch}
          searchError={searchErrors['REPORT']}
        />
      </Stack>
    </SimpleCard>
  );
};

interface Query {
  type: SupportEntityType;
  search: string;
}

interface DispatcherApi {
  search: (type: SupportEntityType, search: string) => string | undefined;
  refresh: () => void;
}

const Dispatcher = React.forwardRef<DispatcherApi>((_, ref) => {
  const api = useApi();
  const [query, setQuery] = useState<Query>();
  const [customers, fetchCustomers, customerError] = useSuspenseFetch(api.searchCustomers, { customers: [] });
  const [users, fetchUsers, usersError] = useSuspenseFetch(api.searchUsers, { users: [] });
  const [accounts, fetchAccounts, accountsError] = useSuspenseFetch(api.searchAccounts, { accounts: [] });
  const [subscriptions, fetchSubscriptions, subscriptionsError] = useSuspenseFetch(api.searchSubscriptions, {
    subscriptions: [],
  });
  const [invoices, fetchInvoices, invoicesError] = useSuspenseFetch(api.searchInvoices, { invoices: [] });
  const [reports, fetchReports, reportsError] = useSuspenseFetch(api.searchReports, { reports: [] });
  const { t } = useTranslation();

  const doFetch = useCallback(
    (type: SupportEntityType, search: string) => {
      switch (type) {
        case 'CUSTOMER':
          fetchCustomers(search);
          break;
        case 'USER':
          fetchUsers(search);
          break;
        case 'ACCOUNT':
          fetchAccounts(search);
          break;
        case 'SUBSCRIPTION':
          fetchSubscriptions(search);
          break;
        case 'INVOICE':
          fetchInvoices(search);
          break;
        case 'REPORT':
          fetchReports(search);
          break;
      }
    },
    [fetchAccounts, fetchCustomers, fetchInvoices, fetchReports, fetchSubscriptions, fetchUsers]
  );
  const search = useCallback(
    (type: SupportEntityType, search: string): string | undefined => {
      if (type === 'SUBSCRIPTION' && search.length < 4) {
        return t('admin.sidebar.search.error.searchMinimumLength');
      } else {
        doFetch(type, search);
        setQuery({ type, search });
        return undefined;
      }
    },
    [doFetch, t]
  );
  const refresh = useCallback(() => {
    if (query) {
      doFetch(query.type, query.search);
    }
  }, [doFetch, query]);
  useEffect(() => {
    (ref as React.MutableRefObject<DispatcherApi>).current = { search, refresh };
  }, [ref, refresh, search]);

  return (
    <>
      {query === undefined && <NoSearch />}
      {query?.type === 'CUSTOMER' && <SupportCustomers customers={customers.customers} error={customerError} />}
      {query?.type === 'USER' && <SupportUsers users={users.users} refresh={refresh} error={usersError} />}
      {query?.type === 'ACCOUNT' && <SupportAccounts accounts={accounts.accounts} error={accountsError} />}
      {query?.type === 'SUBSCRIPTION' && (
        <SupportSubscriptions subscriptions={subscriptions.subscriptions} error={subscriptionsError} />
      )}
      {query?.type === 'INVOICE' && <SupportInvoices invoices={invoices.invoices} error={invoicesError} />}
      {query?.type === 'REPORT' && <SupportReports reports={reports.reports} error={reportsError} />}
    </>
  );
});

const NoSearch: React.FC = () => {
  const { t } = useTranslation();

  return <Typography variant={'body1'}>{t('admin.support.noSearch')}</Typography>;
};
