import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import { displayName } from '../util';

const StyledCard = styled(Card, {
  shouldForwardProp: prop => !['flexBasis', 'flexGrow', 'marginTop', 'marginBottom'].includes(prop.toString()),
})<{ flexBasis?: string; flexGrow?: number; marginTop?: number; marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey['200']};
  ${({ flexBasis }) => (flexBasis ? `flex-basis: ${flexBasis};` : '')}
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : '')}
  ${({ theme, marginTop }) => (marginTop ? `margin-top: ${theme.spacing(marginTop)};` : '')}
  ${({ theme, marginBottom }) => (marginBottom ? `margin-bottom: ${theme.spacing(marginBottom)};` : '')}
`;

const Body = styled('div', {
  shouldForwardProp: prop => !['minHeight', 'padding', 'hasFooter'].includes(prop.toString()),
})<{
  minHeight?: number;
  padding: number;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme, padding }) => theme.spacing(padding)};
  background: white;
  ${({ theme, minHeight }) => (minHeight ? `min-height: ${theme.spacing(minHeight)};` : '')}
`;

interface Props {
  title?: string;
  header?: React.ReactElement;
  customPagination?: React.ReactElement;
  footer?: React.ReactElement;
  minHeight?: number;
  padding?: number;
  marginTop?: number;
  marginBottom?: number;
  flexBasis?: string;
  flexGrow?: number;
  children?: React.ReactNode;
}

const ContentCard: React.FC<Props> = ({
  title,
  header,
  customPagination,
  footer,
  minHeight,
  padding = 4,
  marginTop,
  marginBottom,
  flexBasis,
  flexGrow,
  children,
}) => {
  return (
    <StyledCard
      elevation={0}
      flexBasis={flexBasis}
      flexGrow={flexGrow}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Stack direction="column">
        {title && customPagination ? (
          <Stack direction="row" paddingX={4} paddingY="12px" justifyContent={'space-between'}>
            <Typography fontWeight={700}>{title}</Typography>
            {customPagination}
          </Stack>
        ) : title ? (
          <Stack direction="row" paddingX={4} paddingY="12px">
            <Typography fontWeight={700}>{title}</Typography>
          </Stack>
        ) : null}
        {header}
      </Stack>
      <Body minHeight={minHeight} padding={padding}>
        {children}
      </Body>
      {footer}
    </StyledCard>
  );
};

displayName(ContentCard, 'ContentCard');

export default ContentCard;
