import React, { Suspense, useCallback, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Stack } from '@mui/material';
import { useAnalysisTableGroup, useSetCurrentPageToZero } from '../../globalState';
import GroupSelect from '../GroupSelect';
import { IconButton } from '../Button';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { RoundGreyStack } from '../Container';
import {
  GroupIndexType,
  useModelsUpdateAfterGroupingCallback,
  useSetVisibleGroupingsAfterReportSelectionCallback,
  useVisibleGroupings,
} from 'globalState/analysis';

interface Props {
  children?: React.ReactNode;
}

const AnalysisTableHeader: React.FC<Props> = ({ children }) => {
  const styleUpdateAfterGroupingCallback = useModelsUpdateAfterGroupingCallback();
  const setVisibleGroupingsAfterReportSelectionCallback = useSetVisibleGroupingsAfterReportSelectionCallback();
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const [group1, setGroup1] = useAnalysisTableGroup('1');
  const [group2, setGroup2] = useAnalysisTableGroup('2');
  const [group3, setGroup3] = useAnalysisTableGroup('3');
  const [numberVisibleGroupings, setNumberVisibleGroupings] = useVisibleGroupings();
  const { t } = useTranslation();

  useEffect(() => {
    const initiallySetVisibleGroupings = async (visibleGroupings: number) => {
      setNumberVisibleGroupings(visibleGroupings);
    };
    setVisibleGroupingsAfterReportSelectionCallback({ callback: initiallySetVisibleGroupings });
  }, [setNumberVisibleGroupings, setVisibleGroupingsAfterReportSelectionCallback]);

  const handleAdd = useCallback(() => {
    if (numberVisibleGroupings === 1 && group1 !== '--') {
      setNumberVisibleGroupings(2);
    } else if (numberVisibleGroupings === 2 && group2 !== '--') {
      setNumberVisibleGroupings(3);
    }
  }, [group1, group2, numberVisibleGroupings, setNumberVisibleGroupings]);

  const handleRemove = useCallback(
    (index: GroupIndexType) => {
      setAnalysisPageToZero();
      if (index === '2') {
        setNumberVisibleGroupings(1);
        setGroup3('--', group3);
        setGroup2('--', group2);
      } else if (index === '3') {
        setNumberVisibleGroupings(2);
        setGroup3('--', group3);
      }
    },
    [group2, group3, setAnalysisPageToZero, setGroup2, setGroup3, setNumberVisibleGroupings]
  );

  return (
    <RoundGreyStack direction="row" alignItems="center" padding={3}>
      <Stack direction="column" gap={3}>
        <Stack direction="row" alignItems="center" gap={4}>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              onClick={handleAdd}
              disabled={
                (numberVisibleGroupings === 1 && group1 === '--') ||
                (numberVisibleGroupings === 2 && group2 === '--') ||
                numberVisibleGroupings >= 3
              }
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Stack>
          <Suspense fallback={<div />}>
            <GroupSelect
              label={t('analysis.header.multipleGroupings', { groupingNumber: 1 })}
              value={group1}
              onChange={group1Field => {
                setAnalysisPageToZero();
                setGroup1(group1Field, group1);
                if (group1Field !== '--') {
                  styleUpdateAfterGroupingCallback.callback([group1Field, group2, group3]).catch(console.error);
                }
              }}
              groupIndex={0}
            />
          </Suspense>
        </Stack>
        {numberVisibleGroupings >= 2 && (
          <Stack direction="row" alignItems="center" gap={4}>
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton onClick={() => handleRemove('2')} disabled={numberVisibleGroupings !== 2}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Stack>
            <Suspense fallback={<div />}>
              <GroupSelect
                label={t('analysis.header.multipleGroupings', { groupingNumber: 2 })}
                value={group2}
                onChange={group2Field => {
                  setAnalysisPageToZero();
                  setGroup2(group2Field, group2);
                  if (group2Field !== '--') {
                    styleUpdateAfterGroupingCallback.callback([group1, group2Field, group3]).catch(console.error);
                  }
                }}
                groupIndex={1}
              />
            </Suspense>
          </Stack>
        )}
        {numberVisibleGroupings === 3 && (
          <Stack direction="row" alignItems="center" gap={4}>
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton onClick={() => handleRemove('3')}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Stack>
            <Suspense fallback={<div />}>
              <GroupSelect
                label={t('analysis.header.multipleGroupings', { groupingNumber: 3 })}
                value={group3}
                onChange={group3Field => {
                  setAnalysisPageToZero();
                  setGroup3(group3Field, group3);
                  if (group3Field !== '--') {
                    styleUpdateAfterGroupingCallback.callback([group1, group2, group3Field]).catch(console.error);
                  }
                }}
                groupIndex={2}
              />
            </Suspense>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" flexGrow={1} justifyContent="end" alignItems={'center'}>
        {children}
      </Stack>
    </RoundGreyStack>
  );
};

displayName(AnalysisTableHeader, 'AnalysisTableHeader');

export default AnalysisTableHeader;
