import { styled } from '@mui/material/styles';
import { Card, CardProps } from '@mui/material';
import React from 'react';

const StyledCard = styled(Card)``;

const SimpleCard: React.FC<CardProps> = props => {
  return <StyledCard variant="elevation" elevation={0} {...props} />;
};

export default SimpleCard;
