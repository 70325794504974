import React, { useCallback, useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';
import DraggableListItem from './DraggableListItem';
import { useDrop } from 'react-dnd';
import { Stack } from '@mui/material';

interface Props<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onChange?: (movedId: string, items: T[]) => void;
  width?: string;
}

export interface ListItemBase {
  id: string;
}

const DndList = <T extends ListItemBase>({
  items,
  renderItem,
  onChange = noop,
  width,
}: Props<T>): React.ReactElement | null => {
  const [movedId, setMovedId] = useState('');
  const [localItems, setLocalItems] = useState(items);
  const ref = useRef<HTMLDivElement>(null);
  const findItem = useCallback(
    (id: string) => {
      const item = localItems.filter(it => it.id === id)[0] as ListItemBase;
      return {
        item,
        index: localItems.indexOf(item as never),
      };
    },
    [localItems]
  );
  const handleMove = useCallback(
    (id: string, atIndex: number) => {
      const { item, index } = findItem(id);
      const newItems: ListItemBase[] = [...localItems];
      newItems.splice(index, 1);
      newItems.splice(atIndex, 0, item);
      setLocalItems(newItems as T[]);
      setMovedId(id);
    },
    [findItem, localItems, setLocalItems]
  );
  const handleCommit = useCallback(() => {
    onChange(movedId, localItems);
  }, [onChange, movedId, localItems]);
  const [, drop] = useDrop(() => ({ accept: 'ListItem' }));
  useEffect(() => {
    drop(ref);
  }, [drop]);
  useEffect(() => setLocalItems(items), [setLocalItems, items]);
  return (
    <Stack ref={ref} direction="column" width={width}>
      {localItems.map(item => (
        <DraggableListItem key={item.id} id={item.id} findItem={findItem} onMove={handleMove} onCommit={handleCommit}>
          {renderItem(item)}
        </DraggableListItem>
      ))}
    </Stack>
  );
};

export default DndList;
