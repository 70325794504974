import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import Label from './Label';
import DefaultSelect from './Select';
import { displayName, useEntity } from '../util';
import { useReactiveAnalysisGroupFields } from '../globalState/analysis';

const StyledLabel = styled(Label)`
  font-weight: 500;
  width: ${({ theme }) => theme.spacing(10)};
`;

interface Props {
  label?: string;
  value: string;
  width?: number;
  mandatory?: boolean;
  onChange: (value: string) => void;
  groupIndex: number;
}

const GroupSelect: React.FC<Props> = ({ label, value, width = 12, mandatory = false, onChange, groupIndex }) => {
  const entity = useEntity();
  const groupFields = useReactiveAnalysisGroupFields(entity, groupIndex);

  const sortedGroupFields = useMemo(
    () => groupFields.fields.slice().sort((a, b) => a.label.localeCompare(b.label)),
    [groupFields.fields]
  );

  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}
      <DefaultSelect width={width} value={value} onChange={e => onChange(e.target.value as string)}>
        {!mandatory && <MenuItem value="--">--</MenuItem>}
        {sortedGroupFields.map((f, i) => (
          <MenuItem key={i} value={f.name}>
            {f.label}
          </MenuItem>
        ))}
      </DefaultSelect>
    </>
  );
};

displayName(GroupSelect, 'GroupSelect');

export default GroupSelect;
