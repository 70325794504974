import React from 'react';
import { keyBy } from 'lodash';
import { Box, CardActions, CardContent, MenuItem, Typography } from '@mui/material';
import { useAccount, useAccounts, useSetCurrentPageToZero } from '../../globalState';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import DefaultSelect from '../Select';
import SimpleCard from 'components/SimpleCard';

const InvoiceFilter: React.FC = () => {
  const accounts = useAccounts();
  const accountByFullName = keyBy(accounts.data?.accounts, 'fullName');
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const { t } = useTranslation();
  const [account, setAccount] = useAccount();

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontWeight: 700 }}>{t('sidebar.filter.invoiceFilter.title')}</Typography>
      </CardContent>
      <CardActions>
        <DefaultSelect
          id="account"
          width={12}
          value={account.fullName}
          onChange={e => {
            setCurrentPageToZero();
            setAccount(accountByFullName[e.target.value as never]);
          }}
          fill={true}
        >
          {accounts.data?.accounts
            .slice()
            .sort((a, b) => a.fullName.localeCompare(b.fullName))
            .map((a, i) => (
              <MenuItem key={i} value={a.fullName}>
                {a.fullName}
              </MenuItem>
            ))}
        </DefaultSelect>
      </CardActions>
    </React.Fragment>
  );

  return (
    <Box sx={{ minWidth: 275 }}>
      <SimpleCard>{card}</SimpleCard>
    </Box>
  );
};

displayName(InvoiceFilter, 'InvoiceFilter');

export default InvoiceFilter;
