import React, { ReactNode, useMemo, useState } from 'react';
import DataGrid from './DataGrid';
import {
  GridCallbackDetails,
  GridColDef,
  gridFilteredSortedRowIdsSelector,
  GridState,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import ContentCard from './ContentCard';
import { displayName } from '../util';
import TextInput from './TextInput';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import DefaultSelect from './Select';
import { useTranslation } from 'react-i18next';
import { ClientSidePagination } from './pagination/ClientSidePagination';

export interface ObjectWithId {
  id: number;
}

interface Props {
  title: string;
  columns: GridColDef[];
  items: ObjectWithId[];
  selection: number[];
  onSelectionChange: (selection: number[]) => void;
}

const TransferListTable: React.FC<Props> = ({ title, columns, items, selection, onSelectionChange }) => {
  const apiRef = useGridApiRef();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const maxPageSize = 100;
  const initialState = useMemo(
    () => ({
      pagination: {
        paginationModel: {
          pageSize: maxPageSize,
        },
      },
    }),
    []
  );
  const onPaginationChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    apiRef.current.setPage(newPage - 1);
    setCurrentPage(newPage - 1);
  };

  const dispatchStateChange = (state: GridState, event: MuiEvent, details: GridCallbackDetails) => {
    const visibleRows = gridFilteredSortedRowIdsSelector(apiRef).length;
    const newPageCount = Math.ceil(visibleRows / state.pagination.paginationModel.pageSize);
    apiRef.current.setPage(currentPage);
    setPageCount(newPageCount);
  };
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }
  const handleFilterChange = (columnId: string, filterTerm: string) => {
    if (apiRef.current) {
      apiRef.current.upsertFilterItem({ field: columnId, value: filterTerm, operator: 'contains' });
      const visibleRows = gridFilteredSortedRowIdsSelector(apiRef).length;
      const newPageCount = Math.ceil(visibleRows / maxPageSize);
      apiRef.current.setPage(0);
      setCurrentPage(0);
      setPageCount(newPageCount);
    }
  };

  return (
    <ContentCard
      title={title}
      minHeight={12}
      flexBasis="10px"
      flexGrow={1}
      padding={0}
      customPagination={
        <ClientSidePagination
          currentPage={currentPage}
          pageCount={pageCount}
          onChange={onPaginationChange}
          alignRight={true}
        />
      }
      header={
        <FilterElement
          columns={columns.map(it => ({
            field: it.field,
            name: it.headerName ?? '',
          }))}
          handleFilterChange={handleFilterChange}
        />
      }
    >
      <DataGrid
        apiRef={apiRef}
        onStateChange={dispatchStateChange}
        initialState={initialState}
        pagination={true}
        columns={columns}
        rows={items}
        hideFooter={true}
        columnHeaderHeight={36}
        rowHeight={30}
        disableRowSelectionOnClick={true}
        checkboxSelection={true}
        rowSelectionModel={selection as never}
        onRowSelectionModelChange={onSelectionChange as never}
        disableColumnFilter
      />
    </ContentCard>
  );
};

displayName(TransferListTable, 'TransferListTable');

export default TransferListTable;

interface ColumnFilterInfo {
  field: string;
  name: string;
}

interface FilterElementProps {
  columns: ColumnFilterInfo[];
  handleFilterChange: (columnId: string, filterTerm: string) => void;
}

const FilterElement: React.FC<FilterElementProps> = ({ columns, handleFilterChange }) => {
  const { t } = useTranslation();
  const [selectedColumn, setSelectedColumn] = useState(columns[0].field);
  const handleOnChange = (searchTerm: string) => {
    handleFilterChange(selectedColumn, searchTerm);
  };
  const handleSelectChange = (e: SelectChangeEvent<unknown>, child?: ReactNode) => {
    setSelectedColumn(e.target.value as string);
  };

  return (
    <Stack direction={'row'} paddingX={4} paddingBottom="12px" gap={1}>
      <DefaultSelect value={selectedColumn} onChange={handleSelectChange}>
        {columns.map((col, i) => (
          <MenuItem key={i} value={col.field}>
            {col.name}
          </MenuItem>
        ))}
      </DefaultSelect>
      <TextInput onChange={handleOnChange} placeholder={t('filterElement.filterPlaceholder')} />
    </Stack>
  );
};
