import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FilterPanel from './FilterPanel';
import Text from '../Text';
import DateInput from '../DateInput';
import moment from 'moment';
import { displayName } from '../../util';
import { AnalysisFilterComponentProps } from './AnalysisFilterComponent';
import {
  TimeRange,
  useNewestTimeRange,
  useSetCurrentPageToZero,
  useSetTimeRangeFilterUpdateCallback,
} from '../../globalState';

const isValidDate = (d: string) => moment(d, 'YYYY-MM-DD', true).isValid();

const TimeRangeFilter: React.FC<AnalysisFilterComponentProps> = () => {
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const setTimeRangeFilterUpdateCallback = useSetTimeRangeFilterUpdateCallback();
  const { t } = useTranslation();
  const [range, setRange] = useNewestTimeRange();
  const [from, setFrom] = useState(range.from);
  const [to, setTo] = useState(range.to);

  const timeRangeFilterUpdateCallback = useCallback((timeRange: TimeRange) => {
    setFrom(timeRange.from);
    setTo(timeRange.to);
  }, []);
  useEffect(() => {
    setTimeRangeFilterUpdateCallback({ callback: timeRangeFilterUpdateCallback });
  }, [setTimeRangeFilterUpdateCallback, timeRangeFilterUpdateCallback]);
  const handleApply = useCallback(
    (from: string, to: string) => {
      if (isValidDate(from) && isValidDate(to)) {
        setRange({ from, to });
      }
    },
    [setRange]
  );
  const handleFromChange = useCallback(
    (newFrom: string) => {
      if (newFrom !== from) {
        setAnalysisPageToZero();
      }
      setFrom(newFrom);
      handleApply(newFrom, to);
    },
    [from, handleApply, setAnalysisPageToZero, to]
  );
  const handleToChange = useCallback(
    (newTo: string) => {
      if (newTo !== to) {
        setAnalysisPageToZero();
      }
      setTo(newTo);
      handleApply(from, newTo);
    },
    [from, handleApply, setAnalysisPageToZero, to]
  );

  return (
    <FilterPanel name="time-range" title={t('sidebar.filter.timeRange.title')}>
      <Stack direction="column" gap={3} sx={{ width: '100%', paddingRight: '30px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
          <Text>{t('sidebar.filter.timeRange.from')}</Text>
          <DateInput value={from} onChange={handleFromChange} />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
          <Text>{t('sidebar.filter.timeRange.to')}</Text>
          <DateInput value={to} onChange={handleToChange} />
        </Stack>
      </Stack>
    </FilterPanel>
  );
};

displayName(TimeRangeFilter, 'TimeRangeFilter');

export default TimeRangeFilter;
