import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ApiError } from '../api';
import { useCreateError, useLogError } from './Error';
import { useTranslation } from 'react-i18next';
import { displayName } from '../util';
import { CentralizedDiv } from './Container';

const ErrorContainer = styled(CentralizedDiv)`
  color: ${({ theme }) => theme.palette.error.main};
`;

interface Props {
  error: ApiError;
}

export const ErrorOverlay: React.FC<Props> = ({ error }) => {
  const logError = useLogError();
  const createError = useCreateError();
  const { t } = useTranslation();

  useEffect(() => {
    const handleErrorLog = async () => {
      await logError(createError(error));
    };
    handleErrorLog().catch(console.error);
  }, [logError, error, createError]);

  return (
    <ErrorContainer>
      <Typography variant="h6">{t('overlay.error')}</Typography>
      {error.errors && (
        <ul>
          {error.errors.map((err, i) => (
            <li key={i}>
              <Typography>{err.message}</Typography>
            </li>
          ))}
        </ul>
      )}
    </ErrorContainer>
  );
};

displayName(ErrorOverlay, 'ErrorOverlay');

export const NoResultsOverlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CentralizedDiv>
      <Typography variant="h6">{t('overlay.noResults')}</Typography>
    </CentralizedDiv>
  );
};

displayName(NoResultsOverlay, 'NoResultsOverlay');
