import React from 'react';
import { styled } from '@mui/material/styles';
import { useDragLayer } from 'react-dnd';
import ListItem from './ListItem';
import { displayName } from '../../util';

interface Props {
  width: number;
  findItem: (id: string) => { item: { label: string } };
}

const Layer = styled('div')`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const CustomDragLayer: React.FC<Props> = ({ width, findItem }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  if (!isDragging) {
    return null;
  }
  const label = findItem(item.id).item?.label;
  if (!label) {
    return null;
  }
  const transform =
    initialOffset && currentOffset ? `translate(${currentOffset.x}px, ${currentOffset.y}px)` : undefined;
  return (
    <Layer>
      {transform && (
        <div style={{ transform, WebkitTransform: transform, width: `${width}px` }}>
          {itemType === 'ListItem' && <ListItem label={label} />}
        </div>
      )}
    </Layer>
  );
};

displayName(CustomDragLayer, 'CustomDragLayer');

export default CustomDragLayer;
