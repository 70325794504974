import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import ContentCard from '../ContentCard';
import Text from '../Text';
import DefaultSelect from '../Select';
import DataGrid from '../DataGrid';
import { ActionButton, IconButton } from '../Button';
import { TagDTO, TagGroupDTO } from '../../dto';
import { fromPairs } from 'lodash';
import TagValuesDialog from './TagValuesDialog';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { checkForApiError, displayName, throwApiError } from '../../util';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import {
  useApi,
  useRefreshTagGroups,
  useRefreshTags,
  useSetSelectedTagGroupId,
  useTagGroups,
  useTags,
} from '../../globalState';

const TagValues: React.FC = () => {
  const api = useApi();
  const refreshTagGroups = useRefreshTagGroups();
  const refreshTags = useRefreshTags();
  const { fields } = useTagGroups();
  const tags = useTags();
  const setSelectedTagGroupIdState = useSetSelectedTagGroupId();
  const { t } = useTranslation();
  const setEncounteredError = useSetEncounteredError();
  const [editing, setEditing] = useState<{ open: boolean; tag?: TagDTO }>({ open: false });
  const [deleting, setDeleting] = useState<TagDTO>();
  const [field, setField] = useState(fields.length > 0 ? fields[0] : undefined);

  useEffect(() => {
    const initialField = fields.find(f => f.name === field?.name) ?? field;
    setField(initialField);
    setSelectedTagGroupIdState(initialField?.id);
  }, [field, fields, setSelectedTagGroupIdState]);
  const handleFieldChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const fieldName = e.target.value as string;
      const newField = fields.find(f => f.name === fieldName);
      setField(newField);
      setSelectedTagGroupIdState(newField?.id);
    },
    [fields, setSelectedTagGroupIdState]
  );
  const handleAdd = useCallback(() => setEditing({ open: true }), []);
  const handleCancel = useCallback(() => setEditing({ open: false }), []);
  const handleSave = useCallback(
    async (baseValues: Record<string, string>, value: string) => {
      const tagGroupId = (field as TagGroupDTO).id;
      try {
        if (editing.tag) {
          const editResponse = await api.updateTagValue(tagGroupId, editing.tag.id, value);
          if (editResponse.error !== undefined && editResponse.error !== null) {
            throwApiError(editResponse.error);
          }
        } else {
          const createResponse = await api.addTags(tagGroupId, { tags: [{ baseValues, value }] });
          if (createResponse.error !== undefined && createResponse.error !== null) {
            throwApiError(createResponse.error);
          }
        }
        refreshTagGroups();
        refreshTags();
      } catch (error) {
        setEncounteredError(
          t('tags.values.error', { action: editing.tag ? t('action.edit') : t('action.create') }),
          toError(error),
          {
            api: editing.tag ? 'updateTagValue' : 'addTags',
            tagGroupId: tagGroupId,
            tagId: editing.tag ? editing.tag.id : 'addTags',
          }
        );
      } finally {
        setEditing({ open: false });
      }
    },
    [api, editing.tag, field, refreshTagGroups, refreshTags, setEncounteredError, t]
  );
  const handleDeleteCancel = useCallback(() => setDeleting(undefined), []);
  const handleDeleteConfirm = useCallback(async () => {
    try {
      const tagGroupId = (field as TagGroupDTO).id;
      const tagId = (deleting as TagDTO).id;
      const response = await api.deleteTag(tagGroupId, tagId);
      checkForApiError(response);
      refreshTagGroups();
      refreshTags();
    } catch (error) {
      setEncounteredError(t('tags.values.error', { action: t('action.delete') }), error, {
        api: 'deleteTag',
        tagGroupId: (field as TagGroupDTO).id,
        tagId: (deleting as TagDTO).id,
      });
    } finally {
      setDeleting(undefined);
    }
  }, [api, deleting, field, refreshTagGroups, refreshTags, setEncounteredError, t]);
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={3}>
            <Tooltip title={t('tags.values.table.tooltip.edit')}>
              <IconButton onClick={() => setEditing({ open: true, tag: row.value })}>
                <EditOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tags.values.table.tooltip.delete')}>
              <IconButton onClick={() => setDeleting(row.value)}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      } as GridColDef,
    ]
      .concat(
        field?.baseFields.map(f => ({
          field: f.name,
          headerName: f.label,
          flex: 1,
          sortable: false,
          resizable: false,
          disableColumnMenu: true,
        })) ?? []
      )
      .concat(
        field
          ? {
              field: field.name,
              headerName: field.name,
              flex: 1,
              sortable: false,
              resizable: false,
              disableColumnMenu: true,
            }
          : []
      );
  }, [field, t]);
  const rows = useMemo(() => {
    if (!field || !tags || tags.length === 0 || field.name !== tags[0].tagGroupName) {
      return [];
    }
    return tags.map((t, i) =>
      Object.assign(
        {
          id: i + '',
          [field.name]: t.value,
          value: t,
        },
        fromPairs(field.baseFields.map((f, j) => [f.name, t.baseValues[j].value]))
      )
    );
  }, [field, tags]);

  return (
    <ContentCard
      title={t('tags.values.header.title')}
      minHeight={12}
      padding={0}
      flexGrow={1}
      header={
        <Stack direction="row" alignItems="center" gap={4} marginX={4} marginY={3}>
          <Text weight="500">{t('tags.values.header.selection')}</Text>
          <DefaultSelect width={12} value={field?.name} onChange={handleFieldChange}>
            {fields.map((f, i) => (
              <MenuItem key={i} value={f.name}>
                {f.name}
              </MenuItem>
            ))}
          </DefaultSelect>
        </Stack>
      }
    >
      <Stack direction="row" gap={3} marginTop={4}>
        <ActionButton variant="outlined" startIcon={<AddIcon />} onClick={handleAdd}>
          {t('tags.values.buttons.add')}
        </ActionButton>
      </Stack>
      <DataGrid
        columns={columns}
        rows={rows}
        hideFooter={true}
        columnHeaderHeight={36}
        rowHeight={30}
        disableRowSelectionOnClick={true}
        disableColumnReorder={true}
        customMarginTop={4}
      />
      {field && editing.open && (
        <TagValuesDialog
          tagGroupId={field.id}
          onCancel={handleCancel}
          onSave={handleSave}
          fieldName={field.name}
          baseMappings={field.baseFields.map((b, i) => ({ field: b, value: editing.tag?.baseValues?.[i] }))}
          alreadyCreatedTags={tags ? tags.map(it => it.baseValues) : []}
          value={editing.tag?.value}
          isNew={!editing.tag}
        />
      )}
      {deleting && (
        <ConfirmationDialog
          title={t('tags.values.dialog.deleting.title')}
          text={t('tags.values.dialog.deleting.text')}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </ContentCard>
  );
};

displayName(TagValues, 'TagValues');

export default TagValues;
