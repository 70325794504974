import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { displayName, useEntity } from '../../util';
import {
  FilterState,
  SerializedFilterState,
  useAnalysisFilterFields,
  useFilterIds,
  useRestoreFilters,
  useUpdateFilters,
} from '../../globalState';
import { FieldDTO } from '../../dto';
import { keyBy } from 'lodash';

const deserializeFilter = (field: FieldDTO, serialized: SerializedFilterState): FilterState => {
  if (field.filterType === 'textField') {
    return { field, text: serialized.value };
  } else if (field.filterType === 'textFieldStartsWith') {
    return serialized.value.endsWith('*')
      ? { field, text: serialized.value.substring(0, serialized.value.length - 1), startsWith: true }
      : { field, text: serialized.value, startsWith: false };
  } else if (field.filterType === 'choice' || field.filterType === 'multiSelectFilter') {
    return { field, choice: serialized.value.split('!') };
  } else if (field.filterType === 'range') {
    const [from, to] = serialized.value.split('-');
    return { field, rangeFrom: Number(from), rangeTo: Number(to) };
  }
  throw new Error('Unknown filter type ' + field.filterType);
};

const FilterUpdater: React.FC = () => {
  const updateFilters = useUpdateFilters();
  const restoreFilters = useRestoreFilters();
  const entity = useEntity();
  const filterFields = useAnalysisFilterFields();
  const filterFieldsByName = useMemo(() => keyBy(filterFields.fields, 'name'), [filterFields]);
  const { search } = useLocation();
  const [, , , isInitialized] = useFilterIds();

  useEffect(() => {
    if (entity === 'Level3' || entity === 'Level5') {
      if (isInitialized) {
        restoreFilters();
      } else if (search.length > 1) {
        const filters = search
          .substring(1)
          .split('&')
          .map(p => {
            const [field, value] = p.split('=');
            return { field, value };
          })
          .map(f => deserializeFilter(filterFieldsByName[f.field], f));
        updateFilters(filters);
      }
    }
  }, [entity, filterFieldsByName, isInitialized, restoreFilters, search, updateFilters]);

  return null;
};

displayName(FilterUpdater, 'FilterUpdater');

export default FilterUpdater;
