import { getI18n } from 'react-i18next';
import { AdditionalErrorInformation } from './components/Error';

export interface OverviewTableItemDTO {
  label: string;
  value: string;
}

export interface ChartItemDTO {
  category: string;
  grouping?: string;
  value: number;
}

export interface AnalysisChartDTO {
  items: ChartItemDTO[];
}

export interface GenerateExportFileDTO {
  generatedDirectoryName: string;
}

export interface OverviewTrendChartDTO {
  items: ChartItemDTO[];
}

export interface OverviewReportDTO {
  entityName: string;
  report: CustomReportDTO;
}

export interface OverviewDTO {
  invoiceNumbers: string[];
  summaryItems: OverviewTableItemDTO[];
  keyFigures: string[];
  trendChart: OverviewTrendChartDTO;
  reports: OverviewReportDTO[];
}

export interface CostCenterDTO {
  costCenterId: number;
  parentId?: number;
  name: string;
  description?: string;
  number: string;
  customerId?: number;
  responsibleId?: string;
  responsibleFirstName?: string;
  responsibleLastName?: string;
  responsibleMail?: string;
  responsiblePhone?: string;
  virtual: boolean;
  costCenters: CostCenterDTO[];
}

export interface CreateCostCenterDTO {
  parentId: number;
  name: string;
  description?: string;
  number: string;
  responsibleFirstName?: string;
  responsibleLastName?: string;
  responsibleId?: string;
  responsiblePhone?: string;
  responsibleMail?: string;
}

export interface UpdateCostCenterDTO {
  name: string;
  description?: string;
  number: string;
  responsibleFirstName?: string;
  responsibleLastName?: string;
  responsibleId?: string;
  responsiblePhone?: string;
  responsibleMail?: string;
}

export interface AttachCostCenterDTO {
  ccId: number;
  parentId: number;
  index?: number;
}

export interface InvoicePeriodDTO {
  id: string;
  invoiceNos: string[];
  startDate: string;
  endDate: string;
}

export interface InvoicePeriodsDTO {
  invoicePeriods: InvoicePeriodDTO[];
}

export interface YearsDTO {
  years: string[];
}

export interface AccountDTO {
  accountId: number;
  name: string;
  fullName: string;
  description?: string;
}

export interface AccountsDTO {
  accounts: AccountDTO[];
}

export interface UserDTO {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  mail: string;
  customer: number;
  language: string;
}

export interface UsersDTO {
  users: UserDTO[];
}

export interface DrilldownFieldDTO {
  name: string;
  entityName: string;
  type: string;
  visible: boolean;
  group: boolean;
  groupOnPayload: boolean;
  sort: boolean;
  total?: string;
}

export interface DrilldownFieldMappingDTO {
  from: string;
  to: string;
}

export interface DrilldownPathDTO {
  level: string;
  select: string;
  mapping: DrilldownFieldMappingDTO[];
}

export interface DrilldownActionDTO {
  type: 'FILE_DOWNLOAD' | 'DATA_DOWNLOAD' | 'DISPUTE_MAIL';
  entity?: string;
  fileType?: string;
  name?: string;
  title?: string;
}

export interface DrilldownLevelDTO {
  top: boolean;
  name: string;
  title: string;
  entityName: string;
  total: boolean;
  drilldownOnTotal: boolean;
  fields: DrilldownFieldDTO[];
  paths: DrilldownPathDTO[];
  actions: DrilldownActionDTO[];
}

export interface DrilldownLevelsDTO {
  levels: DrilldownLevelDTO[];
}

export type FilterType = 'textField' | 'textFieldStartsWith' | 'range' | 'choice' | 'multiSelectFilter' | 'hidden';

export type FilterValueType = 'string' | 'int' | 'float' | 'date' | 'intList' | 'stringList';

export type FormattingType =
  | 'LABEL'
  | 'MONEY'
  | 'MONEY_4'
  | 'MONEY_4_2'
  | 'MONEY_5'
  | 'MONEY_5_2'
  | 'MONEY_DOUBLE'
  | 'TIME'
  | 'SECOND_OF_DAY'
  | 'MINUTE_OF_DAY'
  | 'MINUTE_OF_HOUR'
  | 'HOUR_OF_DAY'
  | 'DATE'
  | 'DATE_TIME'
  | 'MONTH'
  | 'MONTH_YEAR'
  | 'WEEKDAY'
  | 'QUARTER_YEAR'
  | 'UNITS'
  | 'QUANTITY'
  | 'DATA'
  | 'DATA_3';

export interface SelectItemDTO {
  value: string;
  label: string;
}

export interface FieldDTO {
  name: string;
  entityName: string;
  visible: boolean;
  position: number;
  downloadPosition?: number;
  type: string;
  aggregated: boolean;
  rawType: string;
  formattingType: FormattingType;
  align: 'left' | 'right';
  filterType: FilterType | undefined;
  label: string;
  unitLabel?: string;
  filterDefaultValue?: string;
  filterValueType?: FilterValueType;
  filterMinValue?: string;
  filterMaxValue?: string;
  filterChoice?: SelectItemDTO[];
  conversionFactor?: number;
  fieldValues?: SelectItemDTO[];
  zeroValue?: string;
}

export interface FieldsDTO {
  fields: FieldDTO[];
}

export interface QueryFieldDTO {
  entityName: string;
  name: string;
}

export type ConditionElementType = 'combined' | 'field' | 'range' | 'map';
export type ConditionComparatorType =
  | 'EQUAL_COMPARATOR'
  | 'NOT_EQUAL_COMPARATOR'
  | 'GREATER_THAN_COMPARATOR'
  | 'GREATER_OR_EQUAL_THAN_COMPARATOR'
  | 'LOWER_THAN_COMPARATOR'
  | 'LOWER_OR_EQUAL_THAN_COMPARATOR'
  | 'RANGE_COMPARATOR'
  | 'LIKE_COMPARATOR'
  | 'MAP_COMPARATOR';
export type LogicalOperatorType = 'AND_OPERATOR' | 'OR_OPERATOR';
export type SortOperatorType = 'ASC_SORT' | 'DESC_SORT';

export interface ConditionElementDTO {
  type: ConditionElementType;
  field?: QueryFieldDTO;
  value?: unknown;
  values?: unknown[];
  fromValue?: unknown;
  toValue?: unknown;
  conditionComparator?: ConditionComparatorType;
  conditionElements?: ConditionElementDTO[];
  logicalOperator?: LogicalOperatorType;
}

export interface SortElementDTO {
  field: QueryFieldDTO;
  sortOperator: SortOperatorType;
}

export interface DetailsDTO {
  details: Record<string, unknown>;
  entity: string;
}

export interface QueryObjectDTO {
  drilldownLevel?: string;
  drilldownPayload?: Record<string, unknown>;
  entityElements: string[];
  selectElements: QueryFieldDTO[];
  conditionElement: ConditionElementDTO;
  sortElements?: SortElementDTO[];
  groupElements?: QueryFieldDTO[];
  metaInformation?: QueryMetaInformationDTO;
}

export interface QueryMetaInformationDTO {
  beginIndex: number;
  endIndex: number;
}

export interface HistoryQuery {
  queryObject: QueryObjectDTO;
  report: CustomReportDTO;
}

export type HistoryElement = DetailsDTO | HistoryQuery;

export function isHistoryQuery(historyElement: HistoryElement): historyElement is HistoryQuery {
  return (historyElement as HistoryQuery).queryObject !== undefined;
}

export interface QueryResultDTO {
  fields: FieldDTO[];
  rows: Record<string, unknown>[];
  summary: Record<string, unknown>;
  totalNumberRecords: number;
}

export interface CustomerDTO {
  id: number;
  name: string;
  description?: string;
}

export interface CustomersDTO {
  customers: CustomerDTO[];
}

export interface IdDTO {
  id: number;
}

export interface StatusDTO {
  status: string;
}

export interface LogoutDTO {
  status: string;
  redirect?: string;
}

export interface ErrorMessageDTO {
  errorTypeId: number;
  i18nKey: string[];
  i18nValue: string[];
  from: number;
  to: number;
}

export interface SubscriptionAssignmentDTO {
  addedAssignmentsCostCenterIds: number[];
  addedAssignmentsSubscriptionIds: [number[]];
  addedAssignmentsSubscriptions: [string[]];
  removedAssignmentsCostCenterIds: number[];
  removedAssignmentsSubscriptionIds: [number[]];
  removedAssignmentsSubscriptions: [string[]];
  errorMessageList: ErrorMessageDTO[];
}

export interface SubscriptionAssignmentResult {
  addedSubscriptionAssignments: Map<number, string[]>;
  removedSubscriptionAssignments: Map<number, string[]>;
  invalidAssignmentsErrorMessages: string[];
}

export interface LoginResponseDTO {
  status: string;
  admin: boolean;
  sysAdmin: boolean;
}

export interface IdsDTO {
  ids: number[];
}

export interface JobDTO {
  jobName: string;
  jobGroup: string;
  enabled: boolean;
  running: boolean;
  lastExecution?: string;
  nextExecution?: string;
}

export interface JobsDTO {
  jobs: JobDTO[];
}

export interface ApplicationDataDTO {
  series: ChartItemDTO[];
}

export interface UserDataItemDTO {
  time: string;
  user: string;
  description: string;
  category: string;
  instance?: string;
}

export interface UserDataDTO {
  items: UserDataItemDTO[];
}

export type ReportDispatchType = 'NONE' | 'SINGLE' | 'BATCH';
export type ReportDocumentType = 'XLSX' | 'CSV' | 'XLS' | 'PDF' | 'JSON';

export interface CustomReportDTO {
  id: number;
  name: string;
  batchField?: string;
  costCenterId: number;
  customText?: string;
  dispatchType: ReportDispatchType;
  documentType?: ReportDocumentType;
  emailAddresses?: string;
  password?: string;
  language?: string;
  recipients?: ReportRecipientDTO[];
  queryObject: QueryObjectDTO;
  user?: string;
  errorMessage: string | null;
}

export interface CustomReportsDTO {
  reports: CustomReportDTO[];
}

export interface CreateReportDTO {
  id?: number;
  name: string;
  batchField?: string;
  costCenterId: number;
  customText?: string;
  dispatchType: ReportDispatchType;
  documentType?: ReportDocumentType;
  emailAddresses?: string;
  password?: string;
  language?: string;
  recipients?: ReportRecipientDTO[];
  queryObject: QueryObjectDTO;
}

export interface SystemReportDTO {
  id: number;
  name: string;
  batchFieldName?: string;
  documentType: string;
  queryObject: QueryObjectDTO;
}

export interface SystemReportsDTO {
  reports: SystemReportDTO[];
}

export interface MailTemplateDTO {
  id: number;
  subject: string;
  content: string;
}

export interface CostCenterAccountDTO {
  id: number;
  name: string;
  isAssigned: boolean;
}

export interface CostCenterAccountsDTO {
  assignedAccounts: CostCenterAccountDTO[];
  availableAccounts: CostCenterAccountDTO[];
}

export interface CostCenterSubscriptionDTO {
  id: number;
  subscription: string;
  account: string;
  name?: string;
}

export interface CostCenterSubscriptionsDTO {
  assignedSubscriptions: CostCenterSubscriptionDTO[];
  availableSubscriptions: CostCenterSubscriptionDTO[];
}

export interface CostCenterUserDTO {
  id: number;
  firstName: string;
  lastName: string;
  level: string;
}

export interface CostCenterUsersDTO {
  assignedUsers: CostCenterUserDTO[];
  availableUsers: CostCenterUserDTO[];
}

export interface FormattedValueDTO {
  raw: unknown;
  formatted: string;
}

export interface FormattedValuesDTO {
  values: FormattedValueDTO[];
}

export type TableExportFormatType = 'pdf' | 'csv' | 'xls' | 'xlsx';

export type ChartExportFormatType = 'jpg' | 'png';

export type TagFileType = 'CSV' | 'XLS' | 'XLSX';

export interface InvoiceDTO {
  invoiceNo: string;
  accountNo: string;
  dateFrom: string;
  dateTo: string;
}

export interface InvoicesDTO {
  invoices: InvoiceDTO[];
}

export type GroupMode = 'NOT_GROUPING' | 'TABLE_GROUPING';

export interface UpdateFieldStyleDTO {
  name: string;
  visible: boolean;
}

export interface UpdateFieldStylesDTO {
  fields: UpdateFieldStyleDTO[];
  groupMode: GroupMode;
}

export interface BaseValueDTO {
  value: string;
  rawValue: string;
}

export interface BaseValuesDTO {
  baseValues: BaseValueDTO[];
  fieldTypeName: string;
}

export interface TagDTO {
  id: number;
  tagGroupName: string;
  baseValues: BaseValueDTO[];
  value: string;
}

export interface BaseFieldDTO {
  name: string;
  label: string;
  entities: string[];
}

export interface TagGroupDTO {
  id: number;
  name: string;
  baseFields: BaseFieldDTO[];
  lastImport?: string;
  lastUpdate?: string;
  readOnly: boolean;
  uploadAllowed: boolean;
}

export interface TagsDTO {
  tags: TagDTO[];
}

export interface TagGroupsDTO {
  fields: TagGroupDTO[];
}

export interface EntityDTO {
  name: string;
  label: string;
}

export interface EntitiesDTO {
  entities: EntityDTO[];
}

export interface BaseFieldsDTO {
  baseFields: BaseFieldDTO[];
}

export interface CalculateBaseFieldsDTO {
  tagGroupId?: number;
  selectedBaseFields: string[];
}

export interface CreateTagGroupDTO {
  name: string;
  baseFields: string[];
}

export interface CreateTagDTO {
  value: string;
  baseValues: Record<string, string>;
}

export interface CreateTagsDTO {
  tags: CreateTagDTO[];
}

export interface SelectItemsDTO {
  items: SelectItemDTO[];
}

export interface AdministratorDTO {
  id: number;
  firstName: string;
  lastName: string;
  language: string;
  mail: string;
  name: string;
  systemAdministrator: boolean;
  assignedCustomers: string;
  assignedBlackListCustomers: string;
  accessToAllCustomers: boolean;
  accessToAllBlackListedCustomers: boolean;
}

export interface AdministratorsDTO {
  administrators: AdministratorDTO[];
}

export interface AdminCustomersDTO {
  available: CustomerDTO[];
  assigned: CustomerDTO[];
}

export interface CreateAdministratorDTO {
  login: string;
  mail?: string;
  password: string;
  firstName: string;
  lastName: string;
  systemAdministrator: boolean;
  accessToAllCustomers: boolean;
  accessToAllBlackListedCustomers: boolean;
  assignedCustomers: number[];
  assignedBlackListedCustomers: number[];
}

export interface TranslatedTextDTO {
  lang: string;
  text: string;
}

export interface StatusMessageDTO {
  active: boolean;
  from: string;
  to: string;
  statusMessage: TranslatedTextDTO[];
}

export interface StatusMessageTextDTO {
  text?: string;
}

export interface SupportCustomerDTO {
  id: number;
  name: string;
  description?: string;
  customerLevel: string;
  privilegeLevel: string;
  active: boolean;
  activeLabel: string;
}

export interface SupportCustomersDTO {
  customers: SupportCustomerDTO[];
}

export interface SupportAccountDTO {
  id: number;
  name: string;
  description?: string;
  customer: string;
  active: boolean;
  activeLabel: string;
  inactiveDate?: string;
}

export interface SupportAccountsDTO {
  accounts: SupportAccountDTO[];
}

export interface SupportSubscriptionDTO {
  id: number;
  subscription: string;
  name: string;
  product?: string;
  account: string;
  active: boolean;
  activeLabel: string;
  lastActive?: string;
}

export interface SupportSubscriptionsDTO {
  subscriptions: SupportSubscriptionDTO[];
}

export interface SupportReportDTO {
  id: number;
  name: string;
  entity: string;
  dispatchType: string;
  user: string;
  costCenter: string;
  periodicity?: string;
  email?: string;
  canSend: boolean;
  lastSent?: string;
  billPeriod?: string;
}

export interface SupportReportsDTO {
  reports: SupportReportDTO[];
}

export interface SupportUserDTO {
  id: number;
  name: string;
  active: boolean;
  activeLabel: string;
  firstName?: string;
  lastName?: string;
  mail: string;
  masterUser: boolean;
  level: string;
  levelLabel: string;
  customer: string;
  costCenters?: string;
  lastLogin?: string;
}

export interface SupportUsersDTO {
  users: SupportUserDTO[];
}

export interface CreateUserDTO {
  name: string;
  firstName?: string;
  lastName?: string;
  mail: string;
  level: string;
}

export interface UpdateUserDTO {
  firstName?: string;
  lastName?: string;
  mail: string;
  level: string;
}

export type SupportEntityType = 'CUSTOMER' | 'USER' | 'ACCOUNT' | 'SUBSCRIPTION' | 'INVOICE' | 'REPORT';

export interface GetDistinctValuesDTO {
  queryObject: QueryObjectDTO;
  filterField: QueryFieldDTO;
}

export interface FilterValueDTO {
  value: unknown;
  label: string;
}

export interface FilterValuesDTO {
  values: FilterValueDTO[];
}

export interface ReportRecipientDTO extends Record<string, unknown> {
  value: string;
  email: string;
  status?: string;
}

export type UserViewType =
  | 'OVERVIEW'
  | 'BILL_DRILLDOWN'
  | 'DATA_ANALYSIS'
  | 'COST_CENTER_CONFIGURATION'
  | 'TAG_CONFIGURATION';

export type AnalysisComponentType = 'REPORT' | 'COSTCENTER_TREE' | 'TIMEFRAME_LEVEL3' | 'TIMEFRAME_LEVEL5' | 'FILTER';

export interface ViewDTO {
  name: string;
  label: string;
  type: UserViewType;
  enabled: boolean;
  entity?: string;
  components: AnalysisComponentType[];
}

export interface ViewsDTO {
  views: ViewDTO[];
}

export type AdminViewType = 'SUPPORT' | 'STATISTICS' | 'ADMINISTRATORS' | 'JOBS' | 'MAINTENANCE';

export interface AdminViewsDTO {
  adminViews: AdminViewType[];
}

export type AdditionalProfileMenuItemType = 'CHANGE_PASSWORD';

export interface AdditionalProfileMenuItemsDTO {
  additionalProfileMenuItems: AdditionalProfileMenuItemType[];
}

export interface TriggerCRMSyncDTO {
  loginName: string;
  referer?: string;
}

export interface LogErrorDTO {
  name: string;
  message: string;
  stack?: string;
}

export interface EnhancedErrorDTO {
  error: LogErrorDTO;
  occurrence: Date;
  additionalErrorInformation?: AdditionalErrorInformation;
  context?: Record<string, unknown>;
}

export function unpackSubscriptionAssignmentDTO(inputDTO: SubscriptionAssignmentDTO): SubscriptionAssignmentResult {
  const unpackedAddedAssignments: Map<number, string[]> = unpackList(
    inputDTO.addedAssignmentsCostCenterIds,
    inputDTO.addedAssignmentsSubscriptions
  );
  const unpackedRemovedAssignments: Map<number, string[]> = unpackList(
    inputDTO.removedAssignmentsCostCenterIds,
    inputDTO.removedAssignmentsSubscriptions
  );
  return {
    addedSubscriptionAssignments: unpackedAddedAssignments,
    removedSubscriptionAssignments: unpackedRemovedAssignments,
    invalidAssignmentsErrorMessages: inputDTO.errorMessageList.map(extractErrorMessage),
  };
}

function unpackList(keyList: number[], valueList: [string[]]): Map<number, string[]> {
  const resultMap: Map<number, string[]> = new Map();

  for (let i = 0; i < keyList.length; i++) {
    const key = keyList[i];
    const value = valueList[i];
    resultMap.set(key, value);
  }

  return resultMap;
}

function extractErrorMessage(errorMessage: ErrorMessageDTO): string {
  const i18n = getI18n();

  const beginningI18nKey =
    errorMessage.from === errorMessage.to ? 'errorMessages.beginning.single' : 'errorMessages.beginning.multiple';
  const beginningString =
    errorMessage.from === errorMessage.to
      ? i18n.t(beginningI18nKey, { lineNumber: errorMessage.to })
      : i18n.t(beginningI18nKey, { from: errorMessage.from, to: errorMessage.to });

  const bodyI18nKey = `errorMessages.body.${errorMessage.errorTypeId}`;
  const map = errorMessage.i18nKey.map((k, i) => [k, errorMessage.i18nValue[i]]);
  const object = Object.fromEntries(map);
  const bodyString = i18n.t(bodyI18nKey, object);

  return beginningString + bodyString;
}
