import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';
import { useNewestTimeRange } from '../../globalState';
import { formatDate } from '../../util';

const TimeRangeHeaderItem: React.FC = () => {
  const { t } = useTranslation();
  const [range] = useNewestTimeRange();
  return (
    <HeaderItem
      label={t('callAnalysis.header.timeRange')}
      value={`${formatDate(range.from)} - ${formatDate(range.to)}`}
    />
  );
};

export default TimeRangeHeaderItem;
