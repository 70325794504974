import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '../Button';
import { SupportCustomerDTO } from '../../dto';
import { displayName, throwApiError } from '../../util';
import { DEFAULT_USER_START_PAGE, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { toError, useSetEncounteredError } from '../Error';
import NonInteractiveTooltip from '../Tooltip';
import { DownloadSpinner } from '../Spinner';
import SupportDataGrid from './SupportDataGrid';
import { ApiError } from '../../api';

interface Props {
  customers: SupportCustomerDTO[];
  error: ApiError | undefined;
}

const SupportCustomers: React.FC<Props> = ({ customers, error }) => {
  const api = useApi();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [loginRow, setLoginRow] = useState('-1');

  const columns: GridColDef<SupportCustomerDTO & { rowId: string }>[] = useMemo(() => {
    const loginAsUser = async (customer: SupportCustomerDTO, rowId: string) => {
      setSuspended(true);
      setLoginRow(rowId);
      try {
        const response = await api.loginAs('CUSTOMER', '' + customer.id);
        if (response.data?.status === 'OK') {
          window.location.href = DEFAULT_USER_START_PAGE.replace(':lng', language);
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(t('admin.support.error.login'), toError(err), { loginCustomerId: customer.id });
      } finally {
        setSuspended(false);
      }
    };
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 55,
        renderCell: ({ row }) => (
          <Stack direction={'row'}>
            <NonInteractiveTooltip title={t('admin.support.tooltip.login')}>
              <IconButton onClick={() => loginAsUser(row, row.rowId)} disabled={suspended || !row.active}>
                <ChevronRightIcon />
              </IconButton>
            </NonInteractiveTooltip>
            <DownloadSpinner size={20} suspended={suspended && loginRow === row.rowId} />
          </Stack>
        ),
      },
      {
        field: 'name',
        headerName: t('admin.support.customer.header.name'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 200,
      },
      {
        field: 'description',
        headerName: t('admin.support.customer.header.description'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 200,
      },
      {
        field: 'customerLevel',
        headerName: t('admin.support.customer.header.customerLevel'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'privilegeLevel',
        headerName: t('admin.support.customer.header.privilegeLevel'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'activeLabel',
        headerName: t('admin.support.customer.header.active'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
    ];
  }, [api, language, loginRow, setEncounteredError, suspended, t]);
  const rows = useMemo(() => {
    return customers.map((customer, i) => ({ ...customer, rowId: `${i + 1}` }));
  }, [customers]);

  return (
    <SupportDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      error={error}
      title={t('admin.support.customer.title')}
    />
  );
};

displayName(SupportCustomers, 'SupportCustomers');

export default SupportCustomers;
