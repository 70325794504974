import React, { Dispatch } from 'react';
import { Pagination } from '@mui/material';
import { displayName } from '../../util';
import { ANALYSIS_TABLE_PAGE_SIZE } from '../../globalState';

interface ServerSidePaginationProps {
  currentPage: number;
  setCurrentPage: Dispatch<number>;
  totalNumberRows: number;
  alignRight?: boolean;
}

export const ServerSidePagination: React.FC<ServerSidePaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalNumberRows,
  alignRight,
}) => {
  const onChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage - 1);
  };

  return (
    <Pagination
      variant="outlined"
      color="primary"
      size="small"
      count={Math.ceil(totalNumberRows / ANALYSIS_TABLE_PAGE_SIZE)}
      page={currentPage + 1}
      onChange={onChange}
      sx={{
        '& > ul': {
          justifyContent: alignRight === true ? 'right' : undefined,
        },
      }}
    />
  );
};

displayName(ServerSidePagination, 'ServerSidePagination');

export default ServerSidePagination;
