import React from 'react';
import TransferList, { Change, ColumnDef } from '../TransferList';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import { useApi, useCostCenterUsers, useRefreshAllCostCenters } from '../../globalState';
import { CostCenterUserDTO } from '../../dto';
import { checkForApiError, displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import { Stack } from '@mui/material';
import { ActionButton } from '../Button';
import AddIcon from '@mui/icons-material/Add';
import { NEW_USER_LINK } from '../../globalState/variables';

const CostCenterUsers: React.FC = () => {
  const api = useApi();
  const { t } = useTranslation();
  const { selectedCostCenter } = useCostCenterContext();
  const setEncounteredError = useSetEncounteredError();
  const { availableUsers, assignedUsers } = useCostCenterUsers(selectedCostCenter.costCenterId);
  const refreshAllCostCenters = useRefreshAllCostCenters();

  const columns: ColumnDef[] = [
    {
      field: 'lastName',
      headerName: t('costCenters.definition.responsible.lastName'),
    },
    {
      field: 'firstName',
      headerName: t('costCenters.definition.responsible.name'),
    },
    {
      field: 'level',
      headerName: t('costCenters.definition.responsible.level'),
    },
  ];
  const updateCostCenter = async ({ added, removed }: Change<CostCenterUserDTO>) => {
    try {
      if (added.length > 0) {
        const response = await api.assignUsersToCostCenter(
          selectedCostCenter.costCenterId,
          added.map(u => u.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      } else if (removed.length > 0) {
        const response = await api.removeUsersFromCostCenter(
          selectedCostCenter.costCenterId,
          removed.map(u => u.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      }
    } catch (err) {
      setEncounteredError(
        added.length > 0 ? t('costCenters.users.error.add') : t('costCenters.users.error.remove'),
        toError(err),
        { action: added.length > 0 ? 'assignUsersToCostCenter' : 'removeUsersFromCostCenter' }
      );
    }
  };

  return (
    <>
      <Stack direction="row" gap={3}>
        <ActionButton variant="outlined" startIcon={<AddIcon />} onClick={() => window.open(NEW_USER_LINK)}>
          {t('costCenters.users.addUser')}
        </ActionButton>
      </Stack>
      <TransferList
        marginTop={4}
        columns={columns}
        availableTitle={t('costCenters.users.availableTitle')}
        availableItems={availableUsers}
        assignedTitle={t('costCenters.assignedTitle', { selectedCostCenterName: selectedCostCenter.name })}
        assignedItems={assignedUsers}
        onChange={updateCostCenter}
      />
    </>
  );
};

displayName(CostCenterUsers, 'CostCenterUsers');

export default CostCenterUsers;
