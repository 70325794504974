import React, { useMemo } from 'react';
import { MenuItem, Stack } from '@mui/material';
import GroupSelect from '../GroupSelect';
import Text from '../Text';
import DefaultSelect from '../Select';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { useAnalysisChartCategoryGroup, useGetChartSeriesType } from '../../globalState/analysis';
import {
  ChartSeries,
  ChartType,
  useAnalysisChartGroup,
  useChartAggregateFields,
  useChartSeries,
} from '../../globalState';

const AnalysisChartHeader: React.FC = () => {
  const [group, setGroup] = useAnalysisChartGroup();
  const { series, setValue } = useChartSeries();
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={3} padding={3}>
      <Stack direction="row" alignItems="center" marginBottom={3}>
        <GroupSelect
          label={t('analysis.header.grouping')}
          value={group}
          onChange={setGroup}
          mandatory={true}
          groupIndex={0}
        />
      </Stack>
      <Stack direction="row" alignItems="center" gap={4}>
        <Stack direction="row" alignItems="center" width={theme => theme.spacing(12)}>
          <Text weight="500">{t('analysis.chart.series')}</Text>
        </Stack>
        {(series[0].type === 'barVertical' || series[0].type === 'barHorizontal') && (
          <Stack direction="row" alignItems="center" width={theme => theme.spacing(12)}>
            <Text weight="500">{t('analysis.header.grouping')}</Text>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" width={theme => theme.spacing(12)}>
          <Text weight="500">{t('analysis.chart.depiction')}</Text>
        </Stack>
      </Stack>
      {series.map((s, i) => (
        <Series key={i} select={s.select} type={s.type} onChange={(field, value) => setValue(i, field, value)} />
      ))}
    </Stack>
  );
};

displayName(AnalysisChartHeader, 'AnalysisChartHeader');

export default AnalysisChartHeader;

interface SeriesProps {
  select: string;
  type: string;
  onChange: (field: keyof ChartSeries | 'type', value: string) => void;
}

const Series: React.FC<SeriesProps> = ({ select, type, onChange }) => {
  const aggregateFields = useChartAggregateFields();
  const { t } = useTranslation();
  const [categoryGroup, setCategoryGroup] = useAnalysisChartCategoryGroup();
  const currentlySelectedType = useGetChartSeriesType();

  const sortedAggregateFields = useMemo(
    () => aggregateFields.fields.slice().sort((a, b) => a.label.localeCompare(b.label)),
    [aggregateFields]
  );
  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <DefaultSelect width={12} value={select} onChange={e => onChange('select', e.target.value as string)}>
        {sortedAggregateFields.map((field, i) => (
          <MenuItem key={i} value={field.name}>
            {field.label}
          </MenuItem>
        ))}
      </DefaultSelect>
      <>
        {currentlySelectedType === 'barVertical' || currentlySelectedType === 'barHorizontal' ? (
          <GroupSelect value={categoryGroup} onChange={value => setCategoryGroup(value)} groupIndex={0} />
        ) : (
          setCategoryGroup('--')
        )}
      </>
      <DefaultSelect width={12} value={type} onChange={e => onChange('type', e.target.value as ChartType)}>
        <MenuItem value="area">{t('analysis.chart.type.area')}</MenuItem>
        <MenuItem value="barVertical">{t('analysis.chart.type.verticalBarchart')}</MenuItem>
        <MenuItem value="line">{t('analysis.chart.type.line')}</MenuItem>
        <MenuItem value="pie">{t('analysis.chart.type.pie')}</MenuItem>
        <MenuItem value="barHorizontal">{t('analysis.chart.type.horizontalBarchart')}</MenuItem>
      </DefaultSelect>
    </Stack>
  );
};
