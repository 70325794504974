import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { AnalysisComponentType } from '../../dto';
import { displayName, useEntity, useView } from '../../util';
import Reports from '../../components/report/Reports';
import CostCenterFilter from '../../components/filter/CostCenterFilter';
import AdditionalFilters from '../../components/filter/AdditionalFilters';
import TimeRangeFilter from '../../components/filter/TimeRangeFilter';
import InvoicePeriodRangeFilter from '../../components/filter/InvoicePeriodRangeFilter';
import { AnalysisFilterComponentProps } from 'components/filter/AnalysisFilterComponent';
import AnalysisHeader from '../../components/analysis/AnalysisHeader';
import InvoicePeriodRangeHeaderItem from '../../components/analysis/InvoicePeriodRangeHeaderItem';
import TimeRangeHeaderItem from '../../components/analysis/TimeRangeHeaderItem';
import SidebarLayout from '../../SidebarLayout';
import { useLanguage } from '../../i18n';
import { useAnalysisTab } from '../../globalState';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../useDocumentTitle';

const sidebarComponents: Record<AnalysisComponentType, React.FC<AnalysisFilterComponentProps>> = {
  REPORT: Reports,
  COSTCENTER_TREE: CostCenterFilter,
  TIMEFRAME_LEVEL3: InvoicePeriodRangeFilter,
  TIMEFRAME_LEVEL5: TimeRangeFilter,
  FILTER: AdditionalFilters,
};

const headerComponents: Partial<Record<AnalysisComponentType, React.FC>> = {
  TIMEFRAME_LEVEL3: InvoicePeriodRangeHeaderItem,
  TIMEFRAME_LEVEL5: TimeRangeHeaderItem,
};

const Analysis: React.FC = () => {
  const navigate = useNavigate();
  const language = useLanguage();
  const entity = useEntity();
  const view = useView();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [analysisTab] = useAnalysisTab(entity);

  useDocumentTitle(t('application.shortName') + ' - ' + view.label);
  useEffect(() => {
    if (!pathname.includes('/table') && !pathname.includes('/pivot') && !pathname.endsWith('/chart')) {
      navigate(`/${language}/${view.path}/${analysisTab}`, { replace: true });
    }
  }, [navigate, pathname, analysisTab, language, view.path]);

  return (
    <SidebarLayout
      title={view.label}
      sidebarContent={
        <>
          {view.components.map((c, i) => {
            const Component = sidebarComponents[c];
            return <Component key={i} entity={view.entity as string} />;
          })}
        </>
      }
    >
      <AnalysisHeader>
        {view.components
          .filter(c => !!headerComponents[c])
          .map((c, i) => {
            const Component = headerComponents[c] as React.FC;
            return <Component key={i} />;
          })}
      </AnalysisHeader>
      <Outlet />
    </SidebarLayout>
  );
};

displayName(Analysis, 'Analysis');

export default Analysis;
