import React, { Suspense } from 'react';
import { styled } from '@mui/material/styles';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import Spinner from './Spinner';
import { displayName } from '../util';

interface Props {
  children?: React.ReactNode;
}

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;

const AccordionDetails: React.FC<Props> = ({ children }) => {
  return (
    <StyledAccordionDetails>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </StyledAccordionDetails>
  );
};

displayName(AccordionDetails, 'AccordionDetails');

export default AccordionDetails;
