import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import TransferListTable, { ObjectWithId } from './TransferListTable';
import { ActionButton } from './Button';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { displayName } from '../util';

export interface ColumnDef {
  field: string;
  headerName: string;
}

export interface Change<T> {
  assigned: T[];
  added: T[];
  removed: T[];
}

interface Props<T extends ObjectWithId> {
  className?: string;
  columns: ColumnDef[];
  availableTitle: string;
  availableItems: T[];
  assignedTitle: string;
  assignedItems: T[];
  onChange: (change: Change<T>) => void;
  marginTop?: number;
}

const TransferList = <T extends ObjectWithId>({
  className,
  columns,
  availableTitle,
  availableItems,
  assignedTitle,
  assignedItems,
  onChange,
  marginTop,
}: Props<T>): React.ReactElement | null => {
  const [availableSelection, setAvailableSelection] = useState<number[]>([]);
  const [assignedSelection, setAssignedSelection] = useState<number[]>([]);
  const clearSelection = useCallback(() => {
    setAvailableSelection([]);
    setAssignedSelection([]);
  }, []);
  const handleRemoveAll = useCallback(() => {
    onChange({ assigned: [], added: [], removed: assignedItems });
    clearSelection();
  }, [assignedItems, clearSelection, onChange]);
  const handleRemove = useCallback(() => {
    onChange({
      assigned: assignedItems.filter(item => !assignedSelection.includes(item.id)),
      added: [],
      removed: assignedItems.filter(item => assignedSelection.includes(item.id)),
    });
    clearSelection();
  }, [onChange, assignedItems, clearSelection, assignedSelection]);
  const handleAdd = useCallback(() => {
    const assignedIds = assignedItems.map(item => item.id);
    const added = availableItems.filter(item => availableSelection.includes(item.id) && !assignedIds.includes(item.id));
    onChange({
      assigned: assignedItems.concat(added),
      added,
      removed: [],
    });
    clearSelection();
  }, [assignedItems, availableSelection, onChange, availableItems, clearSelection]);
  const handleAddAll = useCallback(() => {
    const assignedIds = assignedItems.map(item => item.id);
    const availableUnassigned = availableItems.filter(item => !assignedIds.includes(item.id));
    onChange({ assigned: assignedItems.concat(availableUnassigned), added: availableUnassigned, removed: [] });
    clearSelection();
  }, [assignedItems, availableItems, onChange, clearSelection]);
  const finalColumns = useMemo(() => {
    return columns.map(col => ({
      ...col,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      flex: 1,
      filterable: true,
    }));
  }, [columns]);

  return (
    <Stack className={className} direction="row" gap={7} marginTop={marginTop}>
      <TransferListTable
        title={assignedTitle}
        columns={finalColumns}
        items={assignedItems}
        selection={assignedSelection}
        onSelectionChange={setAssignedSelection}
      />
      <div>
        <Stack direction="column" justifyContent="center" gap={3} position={'sticky'} top={'26.5vh'}>
          <ActionButton variant="outlined" onClick={handleRemoveAll} disabled={assignedItems.length === 0}>
            <KeyboardDoubleArrowRightIcon />
          </ActionButton>
          <ActionButton variant="outlined" onClick={handleRemove} disabled={assignedSelection.length === 0}>
            <ChevronRightIcon />
          </ActionButton>
          <ActionButton variant="outlined" onClick={handleAdd} disabled={availableSelection.length === 0}>
            <ChevronLeftIcon />
          </ActionButton>
          <ActionButton variant="outlined" onClick={handleAddAll} disabled={availableItems.length === 0}>
            <KeyboardDoubleArrowLeftIcon />
          </ActionButton>
        </Stack>
      </div>
      <TransferListTable
        title={availableTitle}
        columns={finalColumns}
        items={availableItems}
        selection={availableSelection}
        onSelectionChange={setAvailableSelection}
      />
    </Stack>
  );
};

displayName(TransferList, 'TransferList');

export default TransferList;
