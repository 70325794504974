import React, { useCallback, useState } from 'react';
import { MenuItem } from '@mui/material';
import DefaultSelect from '../Select';
import { ActionButton } from '../Button';
import { ChartExportFormatType, isHistoryQuery, TableExportFormatType } from '../../dto';
import { displayName, useEntity } from '../../util';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';
import { DownloadSpinner, SuspendedSpinner } from '../Spinner';
import { useSetEncounteredError } from '../Error';
import { styled } from '@mui/material/styles';
import {
  findXElements,
  getEntity,
  useAnalysisTable,
  useAnalysisTableDownload,
  useApplyQueryConditionals,
  useApplyReport,
  useChartDownloadCallback,
  useGetQueryHistory,
  useQueryHistoryStateCallback,
  useSetAnalysisTableDetails,
} from '../../globalState';

const StyledSpinner = styled(SuspendedSpinner)`
  margin-top: 0;
`;

interface Props {
  analysisTab: string;
}

const AnalysisTableDownload: React.FC<Props> = ({ analysisTab }) => {
  const tableDownload = useAnalysisTableDownload();
  const chartDownload = useChartDownloadCallback();
  const entity = useEntity();
  const queryHistory = useGetQueryHistory();
  const applyReport = useApplyReport();
  const applyQueryConditionals = useApplyQueryConditionals();
  const setQueryHistoryState = useQueryHistoryStateCallback();
  const setDetails = useSetAnalysisTableDetails(entity);
  const setEncounteredError = useSetEncounteredError();
  const analysisTableResponse = useAnalysisTable();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [tableFormat, setTableFormat] = useState<TableExportFormatType>('xlsx');
  const [chartFormat, setChartFormat] = useState<ChartExportFormatType>('png');

  const handleDownloadWaitingState = useCallback((isWaiting: boolean) => {
    setSuspended(isWaiting);
  }, []);
  const handleTableDownload = () => {
    function handleDownloadFailure(error?: Error) {
      setEncounteredError(
        error?.name === 'ThresholdExceededException' ? error.message : t('analysis.header.error.table'),
        error ?? new Error(t('analysis.header.error.table')),
        {
          api: 'tableDownload',
          tableFormat: tableFormat,
        }
      );
    }
    tableDownload(tableFormat, handleDownloadWaitingState, handleDownloadFailure).catch(console.error);
  };

  const showBackButton = queryHistory.filter(it => getEntity(it) === entity).length > 1;

  const goBackInHistory = useCallback(() => {
    const popQueryHistory = async () => {
      const toRemoveIndex = findXElements(queryHistory, 1, entity);
      const toApplyIndex = findXElements(queryHistory, 2, entity);
      const lastHistoryElement = queryHistory[toApplyIndex];
      if (lastHistoryElement) {
        if (isHistoryQuery(lastHistoryElement)) {
          await applyReport(lastHistoryElement.report, lastHistoryElement.queryObject);
          await applyQueryConditionals(lastHistoryElement.queryObject);
          setDetails(undefined);
        } else {
          setDetails(new Map(Object.entries(lastHistoryElement.details)));
        }
        const newQueryHistory = queryHistory.filter((entry, i) => i !== toRemoveIndex);
        setQueryHistoryState.callback(newQueryHistory);
      }
    };
    popQueryHistory().catch(console.error);
  }, [applyQueryConditionals, applyReport, entity, queryHistory, setDetails, setQueryHistoryState]);

  const handleChartDownload = () => {
    chartDownload.callback(chartFormat, handleDownloadWaitingState).catch(console.error);
  };

  if (analysisTab === 'table') {
    return (
      <>
        {analysisTableResponse.loading ? (
          <StyledSpinner suspended={analysisTableResponse.loading} />
        ) : (
          <HeaderItem label={t('analysis.header.export')}>
            <DefaultSelect
              width={8}
              value={tableFormat}
              onChange={e => setTableFormat(e.target.value as TableExportFormatType)}
            >
              <MenuItem value="xlsx">XLSX</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
            </DefaultSelect>
            <DownloadSpinner size={20} suspended={suspended} />
            <ActionButton variant="outlined" onClick={handleTableDownload} disabled={suspended}>
              {t('dialog.download')}
            </ActionButton>
            {showBackButton && (
              <ActionButton variant="outlined" onClick={goBackInHistory}>
                {t('dialog.back')}
              </ActionButton>
            )}
          </HeaderItem>
        )}
      </>
    );
  } else if (analysisTab === 'chart') {
    return (
      <HeaderItem label={t('analysis.header.export')}>
        <DefaultSelect
          width={8}
          value={chartFormat}
          onChange={e => {
            setChartFormat(e.target.value as ChartExportFormatType);
          }}
        >
          <MenuItem value="jpg">JPG</MenuItem>
          <MenuItem value="png">PNG</MenuItem>
        </DefaultSelect>
        <DownloadSpinner size={20} suspended={suspended} />
        <ActionButton variant="outlined" onClick={handleChartDownload} disabled={suspended}>
          {t('dialog.download')}
        </ActionButton>
      </HeaderItem>
    );
  } else {
    return null;
  }
};

displayName(AnalysisTableDownload, 'AnalysisTableDownload');

export default AnalysisTableDownload;
