import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import { displayName } from '../../util';

const StyledErrorText = styled(FormHelperText)`
  line-height: 1 !important;
  font-size: 11px !important;
  position: static !important;
  white-space: nowrap !important;
`;

interface Props {
  text: string;
  isWarning?: boolean;
}
const ErrorText: React.FC<Props> = ({ text, isWarning }) => {
  const theme = useTheme();

  const isError = isWarning === undefined || !isWarning;
  const textColor = isError ? undefined : theme.palette.warning.main;

  return (
    <StyledErrorText error={isError} sx={{ color: textColor }}>
      {text}
    </StyledErrorText>
  );
};

displayName(ErrorText, 'ErrorText');

export default ErrorText;
