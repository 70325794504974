import { styled, Theme } from '@mui/material/styles';
import { DataGridPro, DataGridProProps, deDE, enUS, frFR, GridValidRowModel, itIT } from '@mui/x-data-grid-pro';
import { useLanguage } from '../i18n';
import React from 'react';
import { MUIStyledCommonProps } from '@mui/system';

const StyledDataGrid = styled(DataGridPro)`
  background: white;
  border: 0;
  border-radius: 0;
  .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey['300']};
    box-sizing: border-box;
  }
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-columnHeaderDraggableContainer:focus,
  .MuiDataGrid-columnHeaderDraggableContainer:focus-within,
  .MuiDataGrid-columnHeaderTitleContainer:focus,
  .MuiDataGrid-columnHeaderTitleContainer:focus-within {
    outline: none;
  }
  .MuiDataGrid-row.Mui-even,
  .MuiDataGrid-row.Mui-even:hover {
    background: white;
  }
  .MuiDataGrid-row.Mui-odd,
  .MuiDataGrid-row.Mui-odd:hover {
    background: ${({ theme }) => theme.palette.grey['100']};
  }
  .MuiDataGrid-cell {
    font-size: 14px;
    border-width: 1px;
  }
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-pinnedColumns,
  .MuiDataGrid-pinnedColumnHeaders {
    box-shadow: none;
    & .MuiDataGrid-columnSeparator {
      display: none;
    }
  }
`;

const useDataGridLocaleText = () => {
  const lng = useLanguage();
  if (lng === 'de') {
    return deDE.components.MuiDataGrid.defaultProps.localeText;
  }
  if (lng === 'fr') {
    return frFR.components.MuiDataGrid.defaultProps.localeText;
  }
  if (lng === 'it') {
    return itIT.components.MuiDataGrid.defaultProps.localeText;
  }
  return enUS.components.MuiDataGrid.defaultProps.localeText;
};

interface CustomDataGridProps {
  customMarginTop?: number;
  disableColumnResize?: boolean;
}

const DefaultDataGrid = React.forwardRef<
  HTMLDivElement,
  CustomDataGridProps &
    DataGridProProps<GridValidRowModel> &
    React.RefAttributes<HTMLDivElement> &
    MUIStyledCommonProps<Theme>
>((props, ref) => {
  const { customMarginTop, disableColumnResize, ...rest } = props;

  const localeText = useDataGridLocaleText();
  const finalDisableColumnResize = disableColumnResize ?? true;

  return (
    <StyledDataGrid
      ref={ref}
      localeText={localeText}
      disableColumnReorder={true}
      disableColumnResize={finalDisableColumnResize}
      {...rest}
      sx={{ marginTop: customMarginTop ?? 2 }}
    />
  );
});

export default DefaultDataGrid;
