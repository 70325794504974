import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Stack, Typography, useTheme } from '@mui/material';
import ExpandedIcon from '@mui/icons-material/ExpandMore';
import CollapsedIcon from '@mui/icons-material/ChevronRight';
import { ColorlessTreeItemContainer } from '../treeView/ChildrenLabel';

interface Props {
  errorMessages: string[];
}

const ErrorMessageTree: React.FC<Props> = ({ errorMessages }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Stack direction="column" width="100%">
      <ColorlessTreeItemContainer direction="row" alignItems="center" gap="4px" onClick={() => setExpanded(!expanded)}>
        <Stack width="26px">
          {!expanded && <ExpandedIcon />}
          {expanded && <CollapsedIcon />}
        </Stack>
        <Typography sx={{ fontWeight: 'bold', color: theme.palette.error.main }}>
          {t('costCenters.subscriptions.assignmentDialog.errorTree.title')}
        </Typography>
      </ColorlessTreeItemContainer>
      <Stack marginLeft={4}>
        <Collapse in={expanded} unmountOnExit={true}>
          <>
            {errorMessages.map(errorMessage => (
              <Typography>- {errorMessage}</Typography>
            ))}
          </>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default ErrorMessageTree;
