import { atomFamily, useRecoilValue } from 'recoil';
import { Api, client } from '../api';
import { clientLanguageState } from './telcobill';

export const apiClient = atomFamily<Api, string>({
  key: 'apiClient',
  default: lang => client({ headers: { 'Accept-Language': lang } }),
});

export const useApi = (): Api => {
  const lang = useRecoilValue(clientLanguageState);
  return useRecoilValue(apiClient(lang));
};
