import React from 'react';
import { useField } from 'formik';
import { Stack, Typography } from '@mui/material';
import DraggableList from '../list/DraggableList';
import { displayName } from '../../util';
import { ValidationError } from '../Error';

interface Props {
  name: string;
  emptyMessage?: string;
  width?: number | string;
}

const FormikList: React.FC<Props> = ({ name, emptyMessage, width }) => {
  const [field, meta, helpers] = useField(name);
  const validationError = meta.error as unknown as ValidationError;
  const errorMessage = meta.error !== undefined ? validationError.message : '\u00a0';

  return (
    <Stack direction="column">
      <DraggableList
        items={field.value}
        onChange={value => helpers.setValue(value, true)}
        emptyMessage={emptyMessage}
        width={width}
      />
      <Typography lineHeight="16px" paddingLeft={4} fontSize="11px" color={theme => theme.palette.error.main}>
        {errorMessage}
      </Typography>
    </Stack>
  );
};

displayName(FormikList, 'FormikList');

export default FormikList;
