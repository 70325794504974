import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';
import { useNewestInvoicePeriodRange } from '../../globalState';
import { formatDate } from '../../util';

const InvoicePeriodRangeHeaderItem: React.FC = () => {
  const { t } = useTranslation();
  const [range] = useNewestInvoicePeriodRange();
  return (
    <HeaderItem
      label={t('billAnalysis.header.invoicePeriod')}
      value={`${formatDate(range.from.startDate)} - ${formatDate(range.to.endDate)}`}
    />
  );
};

export default InvoicePeriodRangeHeaderItem;
