import React, { useMemo, useState } from 'react';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '../Button';
import { SupportSubscriptionDTO } from '../../dto';
import { displayName, throwApiError } from '../../util';
import { DEFAULT_USER_START_PAGE, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import NonInteractiveTooltip from '../Tooltip';
import { DownloadSpinner } from '../Spinner';
import SupportDataGrid from './SupportDataGrid';
import { ApiError } from '../../api';

interface Props {
  subscriptions: SupportSubscriptionDTO[];
  error: ApiError | undefined;
}

const SupportSubscriptions: React.FC<Props> = ({ subscriptions, error }) => {
  const api = useApi();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [loginRow, setLoginRow] = useState('-1');

  const columns: GridColDef<SupportSubscriptionDTO & { rowId: string }>[] = useMemo(() => {
    const loginAsUser = async (subscription: SupportSubscriptionDTO, rowId: string) => {
      setSuspended(true);
      setLoginRow(rowId);
      try {
        const response = await api.loginAs('SUBSCRIPTION', '' + subscription.id);
        if (response.data?.status === 'OK') {
          window.location.href = DEFAULT_USER_START_PAGE.replace(':lng', language);
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(t('admin.support.error.login'), toError(err), {
          subscriptionId: subscription.subscription,
        });
      } finally {
        setSuspended(false);
      }
    };
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 55,
        renderCell: ({ row }) => (
          <Stack direction={'row'}>
            <NonInteractiveTooltip title={t('admin.support.tooltip.login')}>
              <IconButton onClick={() => loginAsUser(row, row.rowId)} disabled={suspended || !row.active}>
                <ChevronRightIcon />
              </IconButton>
            </NonInteractiveTooltip>
            <DownloadSpinner size={20} suspended={suspended && loginRow === row.rowId} />
          </Stack>
        ),
      },
      {
        field: 'subscription',
        headerName: t('admin.support.subscription.header.subscription'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'name',
        headerName: t('admin.support.subscription.header.name'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 240,
      },
      {
        field: 'product',
        headerName: t('admin.support.subscription.header.product'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 200,
      },
      {
        field: 'account',
        headerName: t('admin.support.subscription.header.account'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'activeLabel',
        headerName: t('admin.support.subscription.header.active'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'lastActive',
        headerName: t('admin.support.subscription.header.lastActive'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
    ];
  }, [api, language, loginRow, setEncounteredError, suspended, t]);
  const rows = useMemo(() => {
    return subscriptions.map((subscription, i) => ({ ...subscription, rowId: `${i + 1}` }));
  }, [subscriptions]);

  return (
    <SupportDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      error={error}
      title={t('admin.support.subscription.title')}
    />
  );
};

displayName(SupportSubscriptions, 'SupportSubscriptions');

export default SupportSubscriptions;
