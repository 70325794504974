import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';
import { useAccount, useNewestInvoicePeriod, useUser } from '../../globalState';
import { displayName, formatInvoicePeriod } from '../../util';
import SimpleCard from 'components/SimpleCard';

interface Props {
  invoiceNumbers: string[];
}

const OverviewHeader: React.FC<Props> = ({ invoiceNumbers }) => {
  const { t } = useTranslation();
  const { firstName, lastName } = useUser();
  const [account] = useAccount();
  const [period] = useNewestInvoicePeriod();

  return (
    <SimpleCard>
      <Stack direction="row" alignItems="center" height={theme => theme.spacing(7)} gap={7} paddingX={4}>
        <HeaderItem label={t('overview.header.invoiceNumber')} value={invoiceNumbers.join(', ')} />
        <HeaderItem label={t('overview.header.invoicePeriod')} value={formatInvoicePeriod(period)} />
        <HeaderItem label={t('overview.header.account')} value={account.fullName} />
        <HeaderItem label={t('overview.header.user')} value={`${firstName} ${lastName}`} />
      </Stack>
    </SimpleCard>
  );
};

displayName(OverviewHeader, 'OverviewHeader');

export default OverviewHeader;
