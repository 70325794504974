import React, { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import ContentCard from '../ContentCard';
import DataGrid from '../DataGrid';
import { displayName } from '../../util';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { ErrorOverlay } from '../Overlay';
import { ApiError } from '../../api';
import { ClientSidePagination } from '../pagination/ClientSidePagination';
import { GridCallbackDetails, GridState, MuiEvent } from '@mui/x-data-grid-pro';

interface Props {
  title: string;
  apiRef: React.MutableRefObject<GridApiPro>;
  columns: GridColDef[];
  rows: GridRowsProp;
  error: ApiError | undefined;
}

const SupportDataGrid: React.FC<Props> = ({ title, apiRef, columns, rows, error }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const maxPageSize = 100;
  const initialState = useMemo(
    () => ({
      pagination: {
        paginationModel: {
          pageSize: maxPageSize,
        },
      },
    }),
    []
  );
  const onPaginationChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    apiRef.current.setPage(newPage - 1);
    setCurrentPage(newPage - 1);
  };
  const dispatchStateChange = (state: GridState, event: MuiEvent, details: GridCallbackDetails) => {
    const newPageCount = Math.round(state.rows.totalRowCount / state.pagination.paginationModel.pageSize);
    apiRef.current.setPage(currentPage);
    setPageCount(newPageCount + 1);
  };
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }

  return (
    <ContentCard
      title={title}
      flexGrow={1}
      padding={0}
      customPagination={
        <ClientSidePagination currentPage={currentPage} pageCount={pageCount} onChange={onPaginationChange} />
      }
    >
      {error ? (
        <ErrorOverlay error={error} />
      ) : (
        <Stack flexGrow={1}>
          <DataGrid
            apiRef={apiRef}
            onStateChange={dispatchStateChange}
            initialState={initialState}
            pagination={true}
            columns={columns as never}
            rows={rows}
            hideFooter={true}
            columnHeaderHeight={36}
            rowHeight={30}
            disableRowSelectionOnClick={true}
          />
        </Stack>
      )}
    </ContentCard>
  );
};

displayName(SupportDataGrid, 'SupportDataGrid');

export default SupportDataGrid;
