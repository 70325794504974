import React from 'react';
import { Stack } from '@mui/material';
import Text from './Text';
import { displayName } from '../util';

interface Props {
  label?: string;
  children?: React.ReactNode;
  alignItems?: string;
}

const FormItem: React.FC<Props> = ({ label, children, alignItems }) => {
  return (
    <Stack direction="row" alignItems={alignItems ?? 'center'} gap={4} minHeight={theme => theme.spacing(5)}>
      <Stack direction="row" width={theme => theme.spacing(10)} justifyContent="end">
        <Text weight="700">{label}</Text>
      </Stack>
      {children}
    </Stack>
  );
};

displayName(FormItem, 'FormItem');

export default FormItem;
