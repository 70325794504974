import React, { useCallback, useState } from 'react';
import { Stack, ToggleButtonGroup } from '@mui/material';
import ToggleButton from '../../components/ToggleButton';
import SidebarLayout from '../../SidebarLayout';
import ApplicationStatistics from '../../components/statistics/ApplicationStatistics';
import UserStatistics from '../../components/statistics/UserStatistics';
import StatisticsFilter from '../../components/statistics/StatisticsFilter';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import SimpleCard from 'components/SimpleCard';
import useDocumentTitle from '../../useDocumentTitle';

type ViewType = 'application' | 'user' | 'administrator';

const Statistics: React.FC = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<ViewType>('application');

  useDocumentTitle(t('application.shortName') + ' - ' + t('admin.statistics.title'));
  const handleViewChange = useCallback((_: unknown, view: ViewType) => {
    if (!!view) {
      setView(view);
    }
  }, []);

  return (
    <SidebarLayout title={t('admin.statistics.title')} sidebarContent={<StatisticsFilter />}>
      <Stack direction="column" gap={4} flexGrow={1}>
        <SimpleCard>
          <Stack padding={3}>
            <ToggleButtonGroup color="primary" exclusive={true} value={view} onChange={handleViewChange}>
              <ToggleButton value="application">{t('admin.statistics.header.toggle.application')}</ToggleButton>
              <ToggleButton value="user">{t('admin.statistics.header.toggle.user')}</ToggleButton>
              <ToggleButton value="administrator">{t('admin.statistics.header.toggle.administrator')}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </SimpleCard>
        {view === 'application' && <ApplicationStatistics />}
        {view === 'user' && <UserStatistics type="user" />}
        {view === 'administrator' && <UserStatistics type="admin" />}
      </Stack>
    </SidebarLayout>
  );
};

displayName(Statistics, 'Statistics');

export default Statistics;
