import React from 'react';
import DefaultSelect from '../Select';
import { FilterState, useFieldValues } from '../../globalState';
import { MenuItem, SelectChangeEvent, useTheme } from '@mui/material';
import { displayName } from '../../util';

interface Props {
  filterState: FilterState;
  onChange: (state: FilterState) => void;
}

const ChoiceFilter: React.FC<Props> = ({ filterState, onChange }) => {
  const fieldValues = useFieldValues('');
  const theme = useTheme();

  const selectItems =
    filterState.field.filterChoice ?? fieldValues.values.map(v => ({ value: v.raw + '', label: v.formatted }));
  const value = filterState.choice ?? ['--'];
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const newValue = e.target.value;
    onChange({
      ...filterState,
      choice: [newValue as string],
    });
  };

  return (
    <DefaultSelect
      width={12}
      multiple={false}
      value={value.length > 0 ? value[0] : undefined}
      onChange={handleChange}
      customHeight={theme.custom.additionalFilters.customHeight}
    >
      <MenuItem value="--">--</MenuItem>
      {selectItems.map((v, i) => (
        <MenuItem key={i} value={v.value}>
          {v.label}
        </MenuItem>
      ))}
    </DefaultSelect>
  );
};

displayName(ChoiceFilter, 'ChoiceFilter');

export default ChoiceFilter;
