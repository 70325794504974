import React, { useMemo } from 'react';
import { Addition, ChangeType, instanceOfSubscriptionChange, useTreeContext } from './treeutil';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack, Typography, useTheme } from '@mui/material';
import { displayName } from '../../util';
import { styled } from '@mui/material/styles';

export const ColorlessTreeItemContainer = styled(Stack)`
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${'rgba(0, 0, 0, 0.04)'};
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    padding: 0 4px;
  }
`;

interface SubscriptionChangeLabelProps {
  subscription: string;
  changeType: ChangeType;
}

const SubscriptionChangeLabel: React.FC<SubscriptionChangeLabelProps> = ({ subscription, changeType }) => {
  const theme = useTheme();
  const { filter } = useTreeContext();

  const highlight = useMemo(() => {
    const trimmedFilter = filter.trim().toLocaleLowerCase();
    if (!trimmedFilter) {
      return;
    }
    const match = subscription.toLocaleLowerCase().match(new RegExp(trimmedFilter));
    return typeof match?.index === 'number' ? { index: match.index, length: trimmedFilter.length } : undefined;
  }, [filter, subscription]);
  const styling = changeType === 'added' ? { color: theme.palette.success.main } : { color: theme.palette.error.main };
  const icon = changeType === 'added' ? <AddIcon sx={styling} /> : <RemoveIcon sx={styling} />;

  if (!highlight) {
    return (
      <ColorlessTreeItemContainer direction="row" alignItems="center" gap="4px">
        {icon}
        <Typography sx={styling}>{subscription}</Typography>
      </ColorlessTreeItemContainer>
    );
  }
  const { index, length } = highlight;
  return (
    <Stack direction="row">
      {icon}
      <Typography whiteSpace="pre">{subscription.substring(0, index)}</Typography>
      <Stack bgcolor="#FFFF00">
        <Typography whiteSpace="pre">{subscription.substring(index, index + length)}</Typography>
      </Stack>
      <Typography whiteSpace="pre">{subscription.substring(index + length)}</Typography>
    </Stack>
  );
};

displayName(SubscriptionChangeLabel, 'SubscriptionChangeLabel');

interface AdditionLabelProps {
  addition: Addition;
}
const AdditionLabel: React.FC<AdditionLabelProps> = ({ addition }) => {
  const isSubscriptionChange = instanceOfSubscriptionChange(addition);
  if (isSubscriptionChange) {
    return <SubscriptionChangeLabel changeType={addition.changeType} subscription={addition.id} key={addition.id} />;
  }
  return <></>;
};

displayName(AdditionLabel, 'AdditionLabel');

interface ChildrenLabelProps {
  additions?: Addition[];
  children?: React.ReactNode;
}

const ChildrenLabels: React.FC<ChildrenLabelProps> = ({ additions, children }) => {
  return (
    <>
      {additions !== undefined && additions.map((addition, i) => <AdditionLabel addition={addition} key={i} />)}
      {children}
    </>
  );
};

displayName(ChildrenLabels, 'ChildrenLabels');

export { ChildrenLabels };
