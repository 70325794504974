import React from 'react';
import { displayName } from '../../../util';
import { GridPanelWrapper, useGridRootProps, GridColumnsPanelProps } from '@mui/x-data-grid-pro';
import { GridColumnsManagement } from './GridsColumnsManagement';

export const CustomColumnsPanel: React.FC<GridColumnsPanelProps> = props => {
  const rootProps = useGridRootProps();
  return (
    <GridPanelWrapper {...props}>
      <GridColumnsManagement {...rootProps.slotProps?.columnsPanel} />
    </GridPanelWrapper>
  );
};

displayName(CustomColumnsPanel, 'CustomColumnsPanel');
