import React, { useCallback, useEffect } from 'react';
import { EncounteredError, ErrorInformationBody, useLogError, useResetEncounteredError } from './Error';
import { customToaster } from './Toast';
import { useTranslation } from 'react-i18next';
import InformationDialog from './dialog/InformationDialog';
import { IS_PRODUCTION } from '../env';

interface Props {
  encounteredError: EncounteredError;
}

export const ErrorInformation: React.FC<Props> = ({ encounteredError }) => {
  return IS_PRODUCTION ? (
    <ErrorToast encounteredError={encounteredError} />
  ) : (
    <ErrorInformationDialog encounteredError={encounteredError} />
  );
};

const ErrorToast: React.FC<Props> = ({ encounteredError }) => {
  const logError = useLogError();
  const resetEncounteredError = useResetEncounteredError();
  const { t } = useTranslation();

  useEffect(() => {
    const handleError = async () => {
      if (encounteredError.error && encounteredError.logError) {
        await logError(encounteredError.error);
      }
      const message = `${encounteredError.message} ${t('toast.tryAgain')}`;
      customToaster.error(message, true);
      resetEncounteredError();
    };
    handleError().catch(console.error);
  }, [encounteredError, logError, resetEncounteredError, t]);

  return <></>;
};

const ErrorInformationDialog: React.FC<Props> = ({ encounteredError }) => {
  const resetEncounteredError = useResetEncounteredError();
  const { t } = useTranslation();

  const handleErrorDialogClose = useCallback(() => {
    resetEncounteredError();
  }, [resetEncounteredError]);

  return (
    <InformationDialog
      title={''}
      onClick={handleErrorDialogClose}
      buttonLabel={t('dialog.cancel')}
      body={<ErrorInformationBody message={encounteredError.message} enhancedError={encounteredError.error} />}
    />
  );
};
