import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { range } from 'lodash';
import moment from 'moment';
import { ApiResponse } from '../api';
import { ChartItemDTO, SelectItemDTO, UserDataDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow, useLoadableApiValue } from './util';
import { clientLanguageState } from './telcobill';

export interface StatisticsFilterValues {
  from: string;
  to: string;
  category: string;
  instance: string;
  user?: string;
  description?: string;
}

export interface ChartConfig {
  series: string;
  grouping: string;
  aggregation: string;
}

export const statisticsFilterDates = () =>
  range(29, -1, -1)
    .map(n => moment().subtract(n, 'days'))
    .map(date => ({ label: date.format('DD.MM.YYYY'), value: date.format('YYYY-MM-DD') }));

const statisticsFilterState = atom<StatisticsFilterValues>({
  key: 'statisticsFilter',
  default: {
    from: statisticsFilterDates()[22].value,
    to: statisticsFilterDates()[29].value,
    category: 'all',
    instance: 'all',
  },
});

const defaultChartConfig = selector<ChartConfig>({
  key: 'appStatisticsDefaultChartConfig',
  get: ({ get }) => {
    const series = get(applicationStatisticsSeriesQuery);
    const grouping = get(applicationStatisticsGroupingQuery);
    return {
      series: series[series.length - 1].value,
      grouping: grouping[1].value,
      aggregation: 'Avg',
    };
  },
});

const chartConfigState = atom<ChartConfig>({
  key: 'appStatisticsChartConfig',
  default: defaultChartConfig,
});

const userStatisticsQuery = selector<ApiResponse<UserDataDTO>>({
  key: 'userStatistics',
  get: ({ get }) => {
    const lang = get(clientLanguageState);
    return get(apiClient(lang)).getUserStatistics(get(statisticsFilterState));
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const adminStatisticsQuery = selector<ApiResponse<UserDataDTO>>({
  key: 'adminStatistics',
  get: ({ get }) => {
    const lang = get(clientLanguageState);
    return get(apiClient(lang)).getAdminStatistics(get(statisticsFilterState));
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const statisticsCategoriesQuery = selector<SelectItemDTO[]>({
  key: 'statisticsCategories',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getStatisticsCategories();
    return dataOrThrow(response).items;
  },
});

const instancesQuery = selector<SelectItemDTO[]>({
  key: 'instances',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getInstances();
    return dataOrThrow(response).items;
  },
});

const applicationStatisticsSeriesQuery = selector<SelectItemDTO[]>({
  key: 'applicationStatisticsSeries',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getApplicationStatisticsSeries();
    return dataOrThrow(response).items;
  },
});

const applicationStatisticsGroupingQuery = selector<SelectItemDTO[]>({
  key: 'applicationStatisticsGrouping',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getApplicationStatisticsGrouping();
    return dataOrThrow(response).items;
  },
});

const applicationStatisticsQuery = selector<ChartItemDTO[]>({
  key: 'applicationStatistics',
  get: async ({ get }) => {
    const filter = get(statisticsFilterState);
    const chartConfig = get(chartConfigState);
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getApplicationStatistics({
      from: filter.from,
      to: filter.to,
      instance: filter.instance,
      series: chartConfig.series,
      group: chartConfig.grouping,
      aggr: chartConfig.aggregation,
    });
    return dataOrThrow(response).series;
  },
});

export const useUserStatistics = (type: 'user' | 'admin') =>
  useLoadableApiValue(type === 'user' ? userStatisticsQuery : adminStatisticsQuery);

export const useStatisticsFilter = () => useRecoilState(statisticsFilterState);

export const useInstances = () => useRecoilValue(instancesQuery);

export const useStatisticsCategories = () => useRecoilValue(statisticsCategoriesQuery);

export const useApplicationStatisticsSeries = () => useRecoilValue(applicationStatisticsSeriesQuery);

export const useApplicationStatisticsGrouping = () => useRecoilValue(applicationStatisticsGroupingQuery);

export const useApplicationStatisticsChartConfig = () => useRecoilState(chartConfigState);

export const useApplicationStatisticsChart = () => useRecoilValue(applicationStatisticsQuery);
