import React from 'react';
import { checkForApiError, displayName } from '../../util';
import { CostCenterAccountDTO } from '../../dto';
import { useApi, useCostCenterAccounts, useRefreshAllCostCenters } from '../../globalState';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import TransferList, { Change, ColumnDef } from '../TransferList';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';

const CostCenterAccounts: React.FC = () => {
  const { t } = useTranslation();
  const api = useApi();
  const { selectedCostCenter } = useCostCenterContext();
  const { assignedAccounts, availableAccounts } = useCostCenterAccounts(selectedCostCenter.costCenterId);
  const refreshAllCostCenters = useRefreshAllCostCenters();
  const setEncounteredError = useSetEncounteredError();

  const columns: ColumnDef[] = [
    {
      field: 'name',
      headerName: t('costCenters.accounts.headerName'),
    },
  ];
  const updateCostCenter = async ({ added, removed }: Change<CostCenterAccountDTO>) => {
    try {
      if (added.length > 0) {
        const response = await api.assignAccountsToCostCenter(
          selectedCostCenter.costCenterId,
          added.map(a => a.id)
        );
        checkForApiError(response);
      } else if (removed.length > 0) {
        const response = await api.removeAccountsFromCostCenter(
          selectedCostCenter.costCenterId,
          removed.map(a => a.id)
        );
        checkForApiError(response);
      }
      refreshAllCostCenters();
    } catch (err) {
      setEncounteredError(
        added.length > 0 ? t('costCenters.accounts.error.add') : t('costCenters.accounts.error.remove'),
        toError(err),
        {
          api: added.length > 0 ? 'assignAccountsToCostCenter' : 'removeAccountsFromCostCenter',
          costCenterId: selectedCostCenter.costCenterId,
        }
      );
    }
  };

  return (
    <TransferList
      columns={columns}
      availableTitle={t('costCenters.accounts.availableTitle')}
      availableItems={availableAccounts}
      assignedTitle={t('costCenters.assignedTitle', {
        selectedCostCenterName: selectedCostCenter.name,
      })}
      assignedItems={assignedAccounts}
      onChange={updateCostCenter}
    />
  );
};

displayName(CostCenterAccounts, 'CostCenterAccounts');

export default CostCenterAccounts;
