import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '../Button';
import { SupportReportDTO } from '../../dto';
import { displayName, throwApiError } from '../../util';
import { DEFAULT_USER_START_PAGE, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { toError, useSetEncounteredError } from '../Error';
import NonInteractiveTooltip from '../Tooltip';
import { DownloadSpinner } from '../Spinner';
import SupportDataGrid from './SupportDataGrid';
import { ApiError } from '../../api';

interface Props {
  reports: SupportReportDTO[];
  error: ApiError | undefined;
}

const SupportReports: React.FC<Props> = ({ reports, error }) => {
  const api = useApi();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [loginRow, setLoginRow] = useState('-1');

  const columns: GridColDef<SupportReportDTO & { rowId: string }>[] = useMemo(() => {
    const loginAsUser = async (report: SupportReportDTO, rowId: string) => {
      setSuspended(true);
      setLoginRow(rowId);
      try {
        const response = await api.loginAs('REPORT', '' + report.id);
        if (response.data?.status === 'OK') {
          window.location.href = DEFAULT_USER_START_PAGE.replace(':lng', language);
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(t('admin.support.error.login'), toError(err), {
          api: 'loginAs',
          loginAsType: 'REPORT',
          reportId: report.id,
        });
      } finally {
        setSuspended(false);
      }
    };
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 55,
        renderCell: ({ row }) => (
          <Stack direction={'row'}>
            <NonInteractiveTooltip title={t('admin.support.tooltip.login')}>
              <IconButton onClick={() => loginAsUser(row, row.rowId)} disabled={suspended}>
                <ChevronRightIcon />
              </IconButton>
            </NonInteractiveTooltip>
            <DownloadSpinner size={20} suspended={suspended && loginRow === row.rowId} />
          </Stack>
        ),
      },
      {
        field: 'name',
        headerName: t('admin.support.report.header.name'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'entity',
        headerName: t('admin.support.report.header.entity'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'dispatchType',
        headerName: t('admin.support.report.header.dispatchType'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'user',
        headerName: t('admin.support.report.header.user'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'costCenter',
        headerName: t('admin.support.report.header.costCenter'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'periodicity',
        headerName: t('admin.support.report.header.periodicity'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'email',
        headerName: t('admin.support.report.header.email'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'lastSent',
        headerName: t('admin.support.report.header.lastSent'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
      {
        field: 'billPeriod',
        headerName: t('admin.support.report.header.billPeriod'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
    ];
  }, [api, language, loginRow, setEncounteredError, suspended, t]);
  const rows = useMemo(() => {
    return reports.map((report, i) => ({ ...report, rowId: `${i + 1}` }));
  }, [reports]);

  return (
    <SupportDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      error={error}
      title={t('admin.support.report.title')}
    />
  );
};

displayName(SupportReports, 'SupportReports');

export default SupportReports;
