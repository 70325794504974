import React from 'react';
import { styled } from '@mui/material/styles';
import { Select as MuiSelect, SelectChangeEvent, SelectProps, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { displayName } from '../util';
import { SELECT_TIMEOUT } from '../globalState';
import { noop } from 'lodash';

export const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: prop => !['width', 'fill', 'customHeight'].includes(prop.toString()),
})<{ width?: number; fill?: boolean; customHeight?: number | string }>`
  ${({ theme, width }) => (width ? `width: ${theme.spacing(width)};` : '')}
  ${({ fill }) => (fill ? `flex-grow: 1;` : '')}
  ${({ theme, customHeight }) =>
    customHeight === undefined
      ? ''
      : `height: ${typeof customHeight === 'string' ? customHeight : theme.spacing(customHeight)};`}
`;

export type StyledSelectProps = SelectProps & {
  width?: number;
  fill?: boolean;
  customHeight?: number | string;
};

const DefaultSelect: React.FC<StyledSelectProps> = props => {
  const theme = useTheme();

  return (
    <StyledSelect
      IconComponent={ExpandMoreIcon}
      {...props}
      onChange={(e: SelectChangeEvent<unknown>, object?: React.ReactNode) =>
        setTimeout(() => (props.onChange ? props.onChange(e, object) : noop()), SELECT_TIMEOUT)
      }
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiMenuItem-root:hover': {
              backgroundColor: theme.palette.hoverGrey.main,
              color: theme.palette.hover.main,
            },
          },
        },
      }}
    />
  );
};

displayName(DefaultSelect, 'DefaultSelect');

export default DefaultSelect;
