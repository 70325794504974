import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { Formik, useField, useFormikContext } from 'formik';
import Dialog from '../Dialog';
import FormikCheckbox from '../formik/FormikCheckbox';
import SubmitButton from '../formik/SubmitButton';
import TransferList, { Change } from '../TransferList';
import Accordion from '../Accordion';
import AccordionItem from '../AccordionItem';
import { AdministratorDTO, CreateAdministratorDTO, CustomerDTO } from '../../dto';
import { useApi, useRefreshAdministrators } from '../../globalState';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError, ValidationError } from '../Error';
import { CloseButton } from '../Button';
import FormikTextInput from 'components/formik/FormikTextInput';
import { SuspendedSpinner } from '../Spinner';
import { UList, UListItem } from '../List';
import {
  checkForApiError,
  displayName,
  isValidEmailAddress,
  usePasswordValidation,
  useSuspenseFetch,
} from '../../util';

interface FormData {
  login: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  mail: string;
  systemAdministrator: string[];
  accessAllCustomers: string[];
  accessAllBlackListCustomers: string[];
  assignedCustomers: CustomerDTO[];
  assignedBlacklistCustomers: CustomerDTO[];
}

interface TransferListProps {
  adminId?: number;
}

const CustomerAssignment: React.FC<TransferListProps> = ({ adminId }) => {
  const api = useApi();
  const { t } = useTranslation();
  const [, , { setValue }] = useField<CustomerDTO[]>('assignedCustomers');
  const [adminCustomers, fetchAdminCustomers] = useSuspenseFetch(api.getAdminCustomers, {
    assigned: [],
    available: [],
  });
  const [assigned, setAssigned] = useState<CustomerDTO[]>([]);
  const [available, setAvailable] = useState<CustomerDTO[]>([]);

  useEffect(() => fetchAdminCustomers(adminId), [fetchAdminCustomers, adminId]);
  useEffect(() => {
    setAssigned(adminCustomers.assigned);
    setAvailable(adminCustomers.available);
    setValue(adminCustomers.assigned, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCustomers]);
  const handleChange = useCallback(
    ({ assigned, added, removed }: Change<CustomerDTO>) => {
      setAssigned(assigned);
      setValue(assigned);
      if (added.length > 0) {
        const addedIds = added.map(c => c.id);
        setAvailable(available.filter(c => !addedIds.includes(c.id)));
      } else {
        setAvailable(available.concat(removed));
      }
    },
    [available, setValue]
  );

  return (
    <TransferList
      columns={[
        { field: 'name', headerName: t('admin.administrators.administrators.editDialog.customerAssignment.customer') },
      ]}
      availableTitle={t('admin.administrators.administrators.editDialog.customerAssignment.availableTitle')}
      availableItems={available}
      assignedTitle={t('admin.administrators.administrators.editDialog.customerAssignment.assignedTitle')}
      assignedItems={assigned}
      onChange={handleChange}
    />
  );
};

const BlacklistCustomerAssignment: React.FC<TransferListProps> = ({ adminId }) => {
  const api = useApi();
  const { t } = useTranslation();
  const [, , { setValue }] = useField<CustomerDTO[]>('assignedCustomers');
  const [adminCustomers, fetchAdminCustomers] = useSuspenseFetch(api.getAdminBlacklistCustomers, {
    assigned: [],
    available: [],
  });
  const [assigned, setAssigned] = useState<CustomerDTO[]>([]);
  const [available, setAvailable] = useState<CustomerDTO[]>([]);

  useEffect(() => fetchAdminCustomers(adminId), [fetchAdminCustomers, adminId]);
  useEffect(() => {
    setAssigned(adminCustomers.assigned);
    setAvailable(adminCustomers.available);
    setValue(adminCustomers.assigned, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCustomers]);
  const handleChange = useCallback(
    ({ assigned, added, removed }: Change<CustomerDTO>) => {
      setAssigned(assigned);
      setValue(assigned);
      if (added.length > 0) {
        const addedIds = added.map(c => c.id);
        setAvailable(available.filter(c => !addedIds.includes(c.id)));
      } else {
        setAvailable(available.concat(removed));
      }
    },
    [available, setValue]
  );

  return (
    <TransferList
      columns={[
        {
          field: 'name',
          headerName: t('admin.administrators.administrators.editDialog.assignBlackListCustomers.customer'),
        },
      ]}
      availableTitle={t('admin.administrators.administrators.editDialog.assignBlackListCustomers.availableTitle')}
      availableItems={available}
      assignedTitle={t('admin.administrators.administrators.editDialog.assignBlackListCustomers.assignedTitle')}
      assignedItems={assigned}
      onChange={handleChange}
    />
  );
};

interface CustomerAssigmentItemsProps {
  adminId?: number;
}

const CustomerAssigmentItems: React.FC<CustomerAssigmentItemsProps> = ({ adminId }) => {
  const { values } = useFormikContext<FormData>();
  const { t } = useTranslation();

  return (
    <>
      {values.accessAllCustomers.length === 0 && (
        <AccordionItem
          value="assignCustomers"
          title={t('admin.administrators.administrators.editDialog.customerAssignment.title')}
        >
          <CustomerAssignment adminId={adminId} />
        </AccordionItem>
      )}
      {values.accessAllBlackListCustomers.length === 0 && (
        <AccordionItem
          value="assignBlackListCustomers"
          title={t('admin.administrators.administrators.editDialog.assignBlackListCustomers.title')}
        >
          <BlacklistCustomerAssignment adminId={adminId} />
        </AccordionItem>
      )}
    </>
  );
};

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

interface Props {
  admin?: AdministratorDTO;
  onClose: () => void;
}

const AdministratorDialog: React.FC<Props> = ({ admin, onClose }) => {
  const api = useApi();
  const refreshAdministrators = useRefreshAdministrators();
  const setEncounteredError = useSetEncounteredError();
  const validatePassword = usePasswordValidation();
  const theme = useTheme();
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);
  const [showErrorHint, setShowErrorHint] = useState(false);

  const initialValues: FormData = useMemo(() => {
    return admin
      ? {
          login: admin.name,
          password: '',
          password2: '',
          firstName: admin.firstName,
          lastName: admin.lastName,
          mail: admin.mail,
          systemAdministrator: admin.systemAdministrator ? ['systemAdministrator'] : [],
          accessAllCustomers: admin.accessToAllCustomers ? ['accessAllCustomers'] : [],
          accessAllBlackListCustomers: admin.accessToAllBlackListedCustomers ? ['accessAllBlackListCustomers'] : [],
          assignedCustomers: [],
          assignedBlacklistCustomers: [],
        }
      : {
          login: '',
          password: '',
          password2: '',
          firstName: '',
          lastName: '',
          mail: '',
          systemAdministrator: [],
          accessAllCustomers: ['accessAllCustomers'],
          accessAllBlackListCustomers: [],
          assignedCustomers: [],
          assignedBlacklistCustomers: [],
        };
  }, [admin]);
  const validate = useCallback(
    (values: FormData): ValidationErrors => {
      const errors: ValidationErrors = {};
      if (!values.login || values.login.trim().length === 0) {
        errors.login = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      }
      if (!values.mail || values.mail.trim().length === 0) {
        errors.mail = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      } else if (!isValidEmailAddress(values.mail.trim())) {
        errors.mail = { message: t('admin.administrators.administrators.editDialog.validation.invalidEmail') };
      }
      if (!values.firstName || values.firstName.trim().length === 0) {
        errors.firstName = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      }
      if (!values.lastName || values.lastName.trim().length === 0) {
        errors.lastName = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      }
      if (!values.password || values.password.length === 0) {
        errors.password = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      } else if (values.password.length < 8) {
        errors.password = { message: t('admin.administrators.administrators.editDialog.validation.tooShortPassword') };
      } else {
        const weakPasswordMessage = validatePassword(values.password);
        if (weakPasswordMessage) {
          errors.password = { message: weakPasswordMessage };
        }
      }
      if (!values.password2 || values.password2.length === 0) {
        errors.password2 = { message: t('admin.administrators.administrators.editDialog.validation.emptyField') };
      }
      if (values.password !== values.password2) {
        errors.password2 = { message: t('admin.administrators.administrators.editDialog.validation.unequalPasswords') };
      }
      if (Object.keys(errors).length !== 0) {
        setShowErrorHint(true);
      }
      return errors;
    },
    [t, validatePassword]
  );
  const handleSubmit = useCallback(
    async (values: FormData) => {
      setSuspended(true);
      const dto: CreateAdministratorDTO = {
        login: values.login,
        mail: values.mail,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        systemAdministrator: values.systemAdministrator.length > 0,
        accessToAllCustomers: values.accessAllCustomers.length > 0,
        accessToAllBlackListedCustomers: values.accessAllBlackListCustomers.length > 0,
        assignedCustomers: values.assignedCustomers.map(c => c.id),
        assignedBlackListedCustomers: values.assignedBlacklistCustomers.map(c => c.id),
      };
      try {
        if (admin) {
          const response = await api.updateAdministrator(admin.id, dto);
          checkForApiError(response);
        } else {
          const response = await api.createAdministrator(dto);
          checkForApiError(response);
        }
        onClose();
        refreshAdministrators();
      } catch (err) {
        setEncounteredError(
          t('admin.administrators.administrators.error', {
            action: admin ? t('action.edit') : t('action.create'),
          }),
          toError(err),
          { api: admin ? 'updateAdministrator' : 'createAdministrator', login: dto.login }
        );
      } finally {
        setSuspended(false);
      }
    },
    [admin, api, onClose, refreshAdministrators, setEncounteredError, t]
  );
  const [accordionItem, setAccordionItem] = useState('details');

  return (
    <Dialog
      width="800px"
      height="800px"
      header={<Typography variant="h2">{t('admin.administrators.administrators.editDialog.title')}</Typography>}
    >
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        <Stack direction="column" marginTop={5} flexGrow={1} justifyContent="space-between">
          <Accordion value={accordionItem} onChange={setAccordionItem}>
            <AccordionItem value="details" title={t('admin.administrators.administrators.editDialog.details.title')}>
              <Stack>
                <Typography>{t('passwordRequirements.text')}</Typography>
                <UList>
                  <UListItem text={t('passwordRequirements.list.1')} />
                  <UListItem text={t('passwordRequirements.list.2')} />
                  <UListItem text={t('passwordRequirements.list.3')} />
                  <UListItem text={t('passwordRequirements.list.4')} />
                </UList>
              </Stack>
              <Stack direction="row" justifyContent="space-between" marginTop={3}>
                <Stack direction="column" flexBasis="10px" flexGrow={1} gap={3}>
                  <FormikTextInput
                    label={t('admin.administrators.administrators.editDialog.details.login')}
                    fieldName={'login'}
                    inputWidth={11}
                  />
                  <FormikTextInput
                    type={'password'}
                    label={t('admin.administrators.administrators.editDialog.details.passwort')}
                    fieldName={'password'}
                    inputWidth={11}
                  />
                  <FormikTextInput
                    label={t('admin.administrators.administrators.editDialog.details.name')}
                    fieldName={'firstName'}
                    inputWidth={11}
                  />
                </Stack>
                <Stack flexGrow={1} flexShrink={999} />
                <Stack direction="column" flexBasis="10px" flexGrow={1} gap={3}>
                  <FormikTextInput
                    label={t('admin.administrators.administrators.editDialog.details.mail')}
                    fieldName={'mail'}
                    inputWidth={11}
                  />
                  <FormikTextInput
                    type={'password'}
                    label={t('admin.administrators.administrators.editDialog.details.confirmPasswort')}
                    fieldName={'password2'}
                    inputWidth={11}
                  />
                  <FormikTextInput
                    label={t('admin.administrators.administrators.editDialog.details.lastName')}
                    fieldName={'lastName'}
                    inputWidth={11}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap={4} marginTop={4}>
                <FormikCheckbox
                  name="systemAdministrator"
                  value="systemAdministrator"
                  label={t('admin.administrators.administrators.editDialog.details.systemAdministrator')}
                />
                <FormikCheckbox
                  name="accessAllCustomers"
                  value="accessAllCustomers"
                  label={t('admin.administrators.administrators.editDialog.details.accessAllCustomers')}
                />
                <FormikCheckbox
                  name="accessAllBlackListCustomers"
                  value="accessAllBlackListCustomers"
                  label={t('admin.administrators.administrators.editDialog.details.accessAllBlackListCustomers')}
                />
              </Stack>
            </AccordionItem>
            <CustomerAssigmentItems adminId={admin?.id} />
          </Accordion>
          <SuspendedSpinner suspended={suspended} />
          <Stack marginTop={4}>
            {showErrorHint && accordionItem !== 'details' && (
              <Typography
                variant={'body1'}
                sx={{ color: theme.palette.error.main, textAlign: 'right' }}
                marginBottom={4}
              >
                {t('admin.administrators.administrators.editDialog.errorHint')}
              </Typography>
            )}
            <Stack direction="row" justifyContent="space-between" marginBottom={4}>
              <CloseButton onClose={onClose} />
              <SubmitButton>{t('dialog.save')}</SubmitButton>
            </Stack>
          </Stack>
        </Stack>
      </Formik>
    </Dialog>
  );
};

displayName(AdministratorDialog, 'AdministratorDialog');

export default AdministratorDialog;
