import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import DataGrid from '../DataGrid';
import { useDrilldown, useSetCurrentPageToZero } from '../../globalState';
import RunAnalysisButton from '../RunAnalysisButton';
import { displayName } from '../../util';

interface Props {
  columns: GridColDef[];
  summary?: Record<string, unknown>;
}

const SummaryGrid = styled(DataGrid)`
  background: none !important;
  padding: 0;
  .MuiDataGrid-columnHeaders {
    border: 0;
  }
  .MuiDataGrid-cell {
    font-weight: bold;
  }
  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row.Mui-selected:hover,
  .MuiDataGrid-row:hover {
    background: none !important;
  }
  .MuiDataGrid-cell {
    border: 0;
  }
  & > * > * {
    border-width: 0 !important;
    border-radius: 0 !important;
  }
`;

const NoRowsOverlay = () => null;

const DrilldownSummary: React.FC<Props> = ({ columns, summary }) => {
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const { goDown } = useDrilldown();

  const actionColumn: GridColDef = useMemo(() => {
    return {
      field: '__DRILLDOWN__',
      renderCell: ({ row }: { row: Record<string, unknown> }) => {
        return row['__drilldown'] ? (
          <RunAnalysisButton
            onClick={() => {
              setCurrentPageToZero();
              goDown(row['__drilldown'] as string);
            }}
          />
        ) : null;
      },
    };
  }, [goDown, setCurrentPageToZero]);
  const totalColumn: GridColDef = useMemo(() => {
    return {
      field: '__TOTAL__',
      renderCell: () => <>Total</>,
    };
  }, []);
  const summaryColumns: GridColDef[] = useMemo(() => {
    return columns.map((col, i) => {
      if (i === 0) {
        return { ...actionColumn, ...col };
      } else if (i === 1) {
        return { ...totalColumn, ...col };
      } else {
        return col;
      }
    });
  }, [actionColumn, columns, totalColumn]);
  const rows = useMemo(() => (summary ? [{ ...summary, id: 1 }] : []), [summary]);
  return (
    <SummaryGrid
      slots={{ noRowsOverlay: NoRowsOverlay }}
      columns={summaryColumns}
      rows={rows}
      hideFooter={true}
      columnHeaderHeight={0}
      rowHeight={38}
    />
  );
};

displayName(DrilldownSummary, 'DrilldownSummary');

export default DrilldownSummary;
