import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';
import { displayName } from '../util';

const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
  span.MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.grey['200']};
  text-transform: none;
  margin-right: 2px;
  &.Mui-selected {
    background: ${({ theme }) => theme.palette.primary.main};
    color: white;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.hover.main};
    color: white;
  }
  &:active {
    background: ${({ theme }) => theme.palette.pressed.main};
    color: white;
  }
`;

export interface TabItem {
  label: string;
  path: string;
}

interface Props {
  tabs: TabItem[];
  path: string;
  onChange: (path: string) => void;
}

const NavigationTabs: React.FC<Props> = ({ tabs, path, onChange }) => {
  return (
    <StyledTabs value={path} onChange={(_, path: string) => onChange(path)}>
      {tabs.map((tab, i) => (
        <StyledTab key={i} label={tab.label} value={tab.path} />
      ))}
    </StyledTabs>
  );
};

displayName(NavigationTabs, 'NavigationTabs');

export default NavigationTabs;
