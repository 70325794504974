import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Stack, Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ReportRecipient } from './CustomReportWizard';
import DataGrid from '../DataGrid';
import { displayName } from '../../util';
import { FilterValueDTO } from '../../dto';
import { IconButton } from 'components/Button';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  fieldLabel: string;
  distinctValues: FilterValueDTO[];
  recipients: ReportRecipient[];
  onEdit: (recipient: ReportRecipient) => void;
  onRemove: (recipient: ReportRecipient) => void;
}

const ReportRecipientTable: React.FC<Props> = ({ fieldLabel, distinctValues, recipients, onEdit, onRemove }) => {
  const { t } = useTranslation();

  const columns = useMemo((): GridColDef[] => {
    const formattedValues = distinctValues.map(v => v.label);
    return [
      {
        field: '__WARNING__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 40,
        renderCell: ({ row }: { row: ReportRecipient }) =>
          formattedValues.includes(row.value) ? null : (
            <Tooltip title={t('sidebar.customReport.step2.reportRecipient.warning', { value: row.value })}>
              <WarningAmberIcon color="warning" />
            </Tooltip>
          ),
      },
      {
        field: 'value',
        headerName: fieldLabel,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('sidebar.customReport.step2.reportRecipient.email'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('sidebar.customReport.step2.reportRecipient.status'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 70,
        renderCell: ({ row }: { row: ReportRecipient }) => (
          <Stack direction="row" gap={3}>
            <IconButton onClick={() => onEdit(row)}>
              <EditOutlined fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onRemove(row)}>
              <DeleteOutlined fontSize="small" />
            </IconButton>
          </Stack>
        ),
      },
    ];
  }, [distinctValues, fieldLabel, onEdit, onRemove, t]);

  return (
    <DataGrid
      columns={columns}
      rows={recipients}
      columnHeaderHeight={36}
      rowHeight={30}
      hideFooter={true}
      disableRowSelectionOnClick={true}
      disableColumnReorder={true}
      disableColumnResize={true}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
    />
  );
};

displayName(ReportRecipientTable, 'ReportRecipientTable');

export default ReportRecipientTable;
