import React from 'react';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Text from './Text';
import { displayName } from '../util';

interface Props {
  title: string;
}

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  svg {
    fill: ${({ theme }) => theme.palette.text.primary};
  }
  padding: 0 0 0 ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(5)};
  min-height: unset;
  div.MuiAccordionSummary-expandIconWrapper {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
  div.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  div.MuiAccordionSummary-content {
    margin: ${({ theme }) => theme.spacing(2)} 0;
  }
`;

const AccordionSummary: React.FC<Props> = ({ title }) => {
  return (
    <StyledAccordionSummary expandIcon={<ChevronRightIcon />}>
      <Text weight="700">{title}</Text>
    </StyledAccordionSummary>
  );
};

displayName(AccordionSummary, 'AccordionSummary');

export default AccordionSummary;
