import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { ListProps } from '@mui/material/List/List';

export const UList: React.FC<ListProps> = props => {
  return <List sx={{ padding: 0, listStyleType: 'disc', pl: 4 }} {...props} />;
};

interface UListItemProps {
  text: string;
}

export const UListItem: React.FC<UListItemProps> = ({ text }) => {
  return (
    <ListItem style={{ display: 'list-item' }} disableGutters sx={{ padding: 0 }}>
      <ListItemText sx={{ margin: 0 }} primary={text} />
    </ListItem>
  );
};
