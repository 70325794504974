import React, { useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Text from '../Text';
import { ActionButton } from '../Button';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditCostCenterDialog, { FormData } from './EditCostCenterDialog';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { pick } from 'lodash';
import ContentCard from '../ContentCard';
import { displayName, throwApiError } from '../../util';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CostCenterDTO } from '../../dto';
import { ApiResponse } from '../../api';
import { toError, useSetEncounteredError } from '../Error';
import {
  dataOrThrow,
  useApi,
  useCostCenters,
  useGetCostCenter,
  useRefreshCostCenters,
  useSetExpandedTreeItems,
} from '../../globalState';

interface FieldProps {
  label: string;
  value?: string;
}

const Field: React.FC<FieldProps> = ({ label, value = '' }) => {
  return (
    <Stack direction="row">
      <Text weight="500" width={12}>
        {label}
      </Text>
      <Text wrapText={true} width={12}>
        {value}
      </Text>
    </Stack>
  );
};

const editableProps = [
  'name',
  'description',
  'number',
  'responsibleId',
  'responsibleFirstName',
  'responsibleLastName',
  'responsibleMail',
  'responsiblePhone',
];

const CostCenterDefinition: React.FC = () => {
  const root = useCostCenters();
  const { t } = useTranslation();
  const { selectedCostCenter, setSelectedCostCenter } = useCostCenterContext();
  const setEncounteredError = useSetEncounteredError();
  const getCostCenter = useGetCostCenter();
  const api = useApi();
  const refreshCostCenters = useRefreshCostCenters();
  const [adding, setAdding] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const setExpanded = useSetExpandedTreeItems('costCenter');

  const makeExpanded = useCallback(
    (costCenter: CostCenterDTO) => {
      async function expandCostCenter() {
        let currentCostCenter: CostCenterDTO = costCenter;
        while (currentCostCenter) {
          // eslint-disable-next-line no-loop-func
          setExpanded(expanded =>
            expanded.includes(currentCostCenter.costCenterId + '')
              ? expanded
              : expanded.concat(currentCostCenter.costCenterId + '')
          );
          if (currentCostCenter.parentId) {
            const apiResponse: ApiResponse<CostCenterDTO> = await api.getCostCenter(currentCostCenter.parentId);
            currentCostCenter = dataOrThrow(apiResponse);
          } else {
            break;
          }
        }
      }
      expandCostCenter().catch(console.error);
    },
    [api, setExpanded]
  );

  const handleAdd = () => {
    setAdding(true);
  };
  const handleEdit = () => {
    setEditing(true);
  };
  const createCostCenter = async (costCenter: FormData) => {
    try {
      const response = await api.createCostCenter({
        ...costCenter,
        parentId:
          duplicating && selectedCostCenter.parentId ? selectedCostCenter.parentId : selectedCostCenter.costCenterId,
      });
      if (!response.error) {
        refreshCostCenters();
        if (response.data?.id) {
          const createdCostCenter = await getCostCenter(response.data?.id);
          setSelectedCostCenter(createdCostCenter);
          makeExpanded(createdCostCenter);
        }
      } else {
        throwApiError(response.error);
      }
    } catch (err) {
      setEncounteredError(
        t('costCenters.definition.error', {
          action: duplicating ? t(`action.duplicate`) : t(`action.create`),
        }),
        toError(err),
        {
          api: 'createCostCenter',
          action: duplicating ? `duplicate` : `create`,
          parentId:
            duplicating && selectedCostCenter.parentId ? selectedCostCenter.parentId : selectedCostCenter.costCenterId,
        }
      );
    } finally {
      setAdding(false);
      setDuplicating(false);
    }
  };
  const updateCostCenter = async (costCenter: FormData) => {
    try {
      const response = await api.updateCostCenter(selectedCostCenter.costCenterId, costCenter);
      if (response.error === null) {
        refreshCostCenters();
        const updatedSelectedCostCenter = await getCostCenter(selectedCostCenter.costCenterId);
        setSelectedCostCenter(updatedSelectedCostCenter);
        makeExpanded(updatedSelectedCostCenter);
        setEditing(false);
      } else {
        throwApiError(response.error);
      }
    } catch (err) {
      setEncounteredError(t('costCenters.definition.error', { action: t(`action.edit`) }), toError(err), {
        api: 'updateCostCenter',
        costCenterId: selectedCostCenter.costCenterId,
      });
    } finally {
      setEditing(false);
    }
  };
  const handleDuplicate = () => {
    setDuplicating(true);
  };
  const handleDelete = () => {
    setConfirming(true);
  };
  const deleteCostCenter = async () => {
    try {
      const toBeDeletedCostCenter = await getCostCenter(selectedCostCenter.costCenterId);
      const parentId = toBeDeletedCostCenter.parentId;
      const response = await api.deleteCostCenter(selectedCostCenter.costCenterId);
      if (!response.error) {
        refreshCostCenters();
        if (parentId) {
          const parentCustomerCenter = await getCostCenter(parentId);
          setSelectedCostCenter(parentCustomerCenter);
          makeExpanded(parentCustomerCenter);
        }
      } else {
        throwApiError(response.error);
      }
    } catch (err) {
      setEncounteredError(t('costCenters.definition.error', { action: t(`action.delete`) }), toError(err), {
        api: 'deleteCostCenter',
        costCenterId: selectedCostCenter.costCenterId,
      });
    } finally {
      setConfirming(false);
    }
  };
  const handleDeleteCancel = () => {
    setConfirming(false);
  };

  return (
    <>
      <Stack direction="row" gap={3}>
        <ActionButton
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={selectedCostCenter.costCenterId < 0}
        >
          {t('costCenters.definition.new')}
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<EditOutlined />}
          onClick={handleEdit}
          disabled={selectedCostCenter.costCenterId < 0}
        >
          {t('costCenters.definition.editing')}
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<ContentCopyIcon />}
          onClick={handleDuplicate}
          disabled={selectedCostCenter.costCenterId < 0}
        >
          {t('costCenters.definition.duplicate')}
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<DeleteOutlined />}
          onClick={handleDelete}
          disabled={selectedCostCenter.costCenterId < 0 || selectedCostCenter.costCenterId === root.costCenterId}
        >
          {t('costCenters.definition.delete')}
        </ActionButton>
      </Stack>
      <ContentCard title={t('costCenters.definition.title')} marginTop={4}>
        <Stack direction="row">
          <Stack direction="column" gap={3} marginRight={3}>
            <Text weight="700">{t('costCenters.definition.entity.title')}</Text>
            <Field label={t('costCenters.definition.entity.name')} value={selectedCostCenter.name} />
            <Field label={t('costCenters.definition.entity.number')} value={selectedCostCenter.number} />
          </Stack>
          <Stack direction="column" gap={3}>
            <Text weight="700">{t('costCenters.definition.responsible.title')}</Text>
            <Field
              label={t('costCenters.definition.responsible.name')}
              value={selectedCostCenter.responsibleFirstName}
            />
            <Field
              label={t('costCenters.definition.responsible.lastName')}
              value={selectedCostCenter.responsibleLastName}
            />
            <Field label={t('costCenters.definition.responsible.id')} value={selectedCostCenter.responsibleId} />
            <Field
              label={t('costCenters.definition.responsible.telephone')}
              value={selectedCostCenter.responsiblePhone}
            />
            <Field label={t('costCenters.definition.responsible.email')} value={selectedCostCenter.responsibleMail} />
          </Stack>
        </Stack>
        <Stack direction="row" marginTop={4}>
          <Text weight="500" width={12}>
            {t('costCenters.definition.comment')}
          </Text>
          <Typography width="600px">{selectedCostCenter.description || ''}</Typography>
        </Stack>
        {adding && (
          <EditCostCenterDialog
            title={t('costCenters.definition.editingDialog.newTitle')}
            costCenter={{} as never}
            onClose={() => setAdding(false)}
            onSave={createCostCenter}
          />
        )}
        {duplicating && (
          <EditCostCenterDialog
            title={t('costCenters.definition.editingDialog.newTitle')}
            costCenter={
              {
                ...pick(selectedCostCenter, editableProps),
                name: selectedCostCenter.name + t('costCenters.definition.duplicatingAddition'),
              } as never
            }
            onClose={() => setDuplicating(false)}
            onSave={createCostCenter}
          />
        )}
        {editing && (
          <EditCostCenterDialog
            title={t('costCenters.definition.editing')}
            costCenter={pick(selectedCostCenter, editableProps) as never}
            onClose={() => setEditing(false)}
            onSave={updateCostCenter}
          />
        )}
        {confirming && (
          <ConfirmationDialog
            onConfirm={deleteCostCenter}
            onCancel={handleDeleteCancel}
            title={t('costCenters.definition.delete')}
            text={t('costCenters.definition.editingDialog.deleteText')}
          />
        )}
      </ContentCard>
    </>
  );
};

displayName(CostCenterDefinition, 'CostCenterDefinition');

export default CostCenterDefinition;
