import React from 'react';
import { DatePicker as MuiDatePicker, DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { Moment } from 'moment';
import { displayName } from '../util';

interface Props {
  className?: string;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  error?: boolean;
}

const DatePicker: React.FC<Props> = ({ className, value, onChange, error }) => {
  return (
    <MuiDatePicker
      className={className}
      inputFormat="DD.MM.YYYY"
      mask="__.__.____"
      value={value}
      onChange={onChange}
      renderInput={params => <TextField {...params} error={error} />}
    />
  );
};

displayName(DatePicker, 'DatePicker');

export default DatePicker;

export const DateTimePicker: React.FC<Props> = ({ className, value, onChange, error }) => {
  return (
    <MuiDateTimePicker
      className={className}
      inputFormat="DD.MM.YYYY HH:mm"
      mask="__.__.____ __:__"
      value={value}
      onChange={onChange}
      renderInput={params => <TextField {...params} error={error} />}
    />
  );
};

displayName(DateTimePicker, 'DateTimePicker');
