import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import {
  Autocomplete,
  CircularProgress,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  Stack,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
  useTheme,
} from '@mui/material';
import { displayName, useFetch } from '../../util';
import { useApi, useSetBaseValueFieldTypeName } from '../../globalState';
import { BaseValueDTO } from '../../dto';
import { JSX } from 'react/jsx-runtime';
import { ValidationError } from '../Error';
import ErrorText from 'components/formik/ErrorText';

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => (prop as string) !== 'width',
})<{
  width?: number;
}>`
  ${({ theme, width }) =>
    width === undefined ? '' : `width: ${typeof width === 'number' ? theme.spacing(width) : width};`}
  .MuiInputBase-root {
    padding: 0;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 28px;
    font-size: 11px;
    white-space: nowrap;
  }
`;

interface Props {
  tagGroupId: number;
  baseField: string;
  name: string;
  allBaseValuesMap: Map<string, BaseValueDTO[]>;
  setAllBaseValuesMap: (value: Map<string, BaseValueDTO[]>) => void;
  width?: number;
  warningText?: string;
}

const BaseValuesCombo: React.FC<Props> = ({
  tagGroupId,
  baseField,
  name,
  allBaseValuesMap,
  setAllBaseValuesMap,
  width,
  warningText,
}) => {
  const api = useApi();
  const theme = useTheme();
  const setBaseValueFieldTypeName = useSetBaseValueFieldTypeName(baseField);
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<BaseValueDTO>({ rawValue: '', value: '' });
  const [{ baseValues, fieldTypeName }, fetchBaseValues, loading] = useFetch(api.getBaseValues, {
    baseValues: [],
    fieldTypeName: '',
  });
  const [fieldInput, fieldMeta, fieldHelper] = useField<string>(name);
  const validationError = fieldMeta.error as unknown as ValidationError;

  useEffect(() => {
    async function getBaseValues() {
      await fetchBaseValues(tagGroupId, baseField);
    }
    getBaseValues().catch(console.error);
  }, [baseField, fetchBaseValues, tagGroupId]);

  useEffect(() => {
    if (!loading) {
      setAllBaseValuesMap(new Map(allBaseValuesMap.set(baseField, baseValues)));
      setBaseValueFieldTypeName(fieldTypeName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setInternalValue(
      baseValues.find(v => v.rawValue === fieldInput.value) ?? {
        value: fieldInput.value ?? '',
        rawValue: fieldInput.value ?? '',
      }
    );
  }, [baseValues, fieldInput.value]);
  const textFieldColor = !!fieldMeta.error ? 'error' : warningText !== undefined ? 'warning' : undefined;
  const inputBorderColor = !!fieldMeta.error
    ? theme.palette.error.main
    : warningText
      ? theme.palette.warning.main
      : undefined;
  const errorText =
    !!fieldMeta.error && validationError.message !== undefined
      ? validationError.message
      : warningText !== undefined
        ? warningText
        : undefined;
  const isWarning =
    (validationError === undefined || validationError.message === undefined) && warningText !== undefined;

  return (
    <StyledAutocomplete
      freeSolo={true}
      autoSelect={true}
      value={internalValue}
      onChange={(e, value) => {
        if (e?.type && e.type === 'click' && typeof value === 'object') {
          setInternalValue((value as BaseValueDTO) ?? { value: '', rawValue: '' });
        }
      }}
      onInputChange={(e, value) => {
        if (e?.type === 'change') {
          setInternalValue(baseValues.find(v => v.value === value) ?? { value, rawValue: value });
        }
      }}
      onBlur={() => {
        fieldHelper.setValue(internalValue.rawValue, true);
        setInternalValue(Object.assign({}, internalValue));
      }}
      options={baseValues}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      isOptionEqualToValue={(option, value) => (option as BaseValueDTO).rawValue === (value as BaseValueDTO).rawValue}
      getOptionLabel={option => (typeof option === 'string' ? option : (option as BaseValueDTO).value)}
      width={width}
      renderInput={(
        params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined } & Omit<
            OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps,
            'variant'
          >
      ) => (
        <Stack direction={'column'}>
          <TextField
            {...params}
            error={!!fieldMeta.error}
            color={textFieldColor}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps?.endAdornment}
                </>
              ),
            }}
            sx={{
              '& > * > fieldset ': {
                borderColor: `${inputBorderColor} !important`,
              },
            }}
          />
          <Stack>{errorText !== undefined && <ErrorText text={errorText} isWarning={isWarning} />}</Stack>
        </Stack>
      )}
    />
  );
};

displayName(BaseValuesCombo, 'BaseValuesCombo');

export default BaseValuesCombo;
