import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { AdministratorDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { ApiResponse } from '../api';
import { clientLanguageState } from './telcobill';

const administratorQuery = selector<ApiResponse<AdministratorDTO>>({
  key: 'administrator',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getAdministrator();
  },
});

export const useAdministrator = () => dataOrThrow(useRecoilValue(administratorQuery));

const administratorsQuery = selector<AdministratorDTO[]>({
  key: 'administrators',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getAdministrators();
    return dataOrThrow(response).administrators;
  },
});

export const useAdministrators = () => useRecoilValue(administratorsQuery);

export const useRefreshAdministrators = () => useRecoilRefresher_UNSTABLE(administratorsQuery);
