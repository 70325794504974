import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { fromPairs, toPairs } from 'lodash';
import ContentCard from '../../components/ContentCard';
import SidebarLayout from '../../SidebarLayout';
import MaintenanceDates from '../../components/maintenance/MaintenanceDates';
import { SUPPORTED_LANGUAGES, useApi, useRefreshStatusMessage, useStatusMessage } from '../../globalState';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { checkForApiError, displayName } from '../../util';
import { toError, useSetEncounteredError } from '../../components/Error';
import useDocumentTitle from '../../useDocumentTitle';

const Maintenance: React.FC = () => {
  const refreshStatusMessage = useRefreshStatusMessage();
  const api = useApi();
  const setEncounteredError = useSetEncounteredError();
  const { active, from, to, statusMessage } = useStatusMessage();
  const { t } = useTranslation();
  const [messages, setMessages] = useState(fromPairs(statusMessage.map(m => [m.lang, m.text])));

  useDocumentTitle(t('application.shortName') + ' - ' + t('admin.maintenance.title'));
  const handleSave = useCallback(
    async (values: { active: boolean; from: Moment; to: Moment }) => {
      try {
        const response = await api.setStatusMessage({
          active: values.active,
          from: values.from.toISOString(),
          to: values.to.toISOString(),
          statusMessage: toPairs(messages).map(pair => ({ lang: pair[0], text: pair[1] })),
        });
        checkForApiError(response);
        refreshStatusMessage();
      } catch (err) {
        setEncounteredError(t('admin.maintenance.error', { action: t('action.create') }), toError(err), {
          api: 'setStatusMessage',
        });
      }
    },
    [api, messages, refreshStatusMessage, setEncounteredError, t]
  );

  return (
    <SidebarLayout
      title={t('admin.maintenance.title')}
      sidebarContent={<MaintenanceDates active={active} from={moment(from)} to={moment(to)} onSave={handleSave} />}
    >
      <ContentCard>
        <Typography>{t('admin.maintenance.notification')}</Typography>
        <Stack direction="column" gap={3} marginTop={5}>
          {SUPPORTED_LANGUAGES.map(l => (
            <Stack key={l.value} direction="row">
              <Text width={10}>{l.label}</Text>
              <TextInput
                multiline={true}
                width="600px"
                rows={2}
                value={messages[l.value]}
                onChange={value => setMessages(m => ({ ...m, [l.value]: value }))}
              />
            </Stack>
          ))}
        </Stack>
      </ContentCard>
    </SidebarLayout>
  );
};

displayName(Maintenance, 'Maintenance');

export default Maintenance;
