import React, { useMemo } from 'react';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import ContentCard from '../ContentCard';
import DataGrid from '../DataGrid';
import { UserDataItemDTO } from '../../dto';
import { useUserStatistics } from '../../globalState';
import { displayName, formatTimestamp } from '../../util';
import { useTranslation } from 'react-i18next';

interface Props {
  type: 'user' | 'admin';
}

interface RowType extends UserDataItemDTO, GridValidRowModel {
  id: string;
}

const UserStatistics: React.FC<Props> = ({ type }) => {
  const { data: userStatistics, loading } = useUserStatistics(type);
  const { t } = useTranslation();

  const rows = useMemo(
    () => userStatistics?.items.map((item, i) => ({ id: `${i + 1}`, ...item })) ?? [],
    [userStatistics?.items]
  );
  const columns: GridColDef<RowType>[] = useMemo(() => {
    return [
      {
        field: 'time',
        headerName: t('admin.statistics.user.header.time'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: ({ value }) => formatTimestamp(value),
      },
      {
        field: 'user',
        headerName: t('admin.statistics.user.header.user'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('admin.statistics.user.header.description'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'category',
        headerName: t('admin.statistics.user.header.category'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'instance',
        headerName: t('admin.statistics.user.header.instance'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
    ];
  }, [t]);

  return (
    <ContentCard flexGrow={1} padding={0}>
      <DataGrid
        columns={columns as never}
        rows={rows}
        loading={loading}
        hideFooter={true}
        columnHeaderHeight={36}
        rowHeight={30}
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        disableRowSelectionOnClick={true}
      />
    </ContentCard>
  );
};

displayName(UserStatistics, 'UserStatistics');

export default UserStatistics;
