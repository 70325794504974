import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Stack } from '@mui/material';
import AnalysisChartHeader from './AnalysisChartHeader';
import BarChart from '../chart/BarChart';
import LineChart from '../chart/LineChart';
import PieChart from '../chart/PieChart';
import { useAnalysisChart, useAnalysisGroupFields, useChartAggregateFields, useChartSeries } from '../../globalState';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useUpdateTabState } from './AnalysisToggle';
import { displayName } from '../../util';
import { useGetAnalysisChartGroup, useGetAnalysisChartGroupCategory } from '../../globalState/analysis';
import { useTranslation } from 'react-i18next';

const Container = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.grey['200']};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ChartContainer = styled(Stack)`
  background: white;
  height: 10px;
`;

const AnalysisChart: React.FC = () => {
  const data = useAnalysisChart();
  const aggregateFields = useChartAggregateFields();
  const { series } = useChartSeries();
  const groupFields = useAnalysisGroupFields();
  const grouping = useGetAnalysisChartGroup();
  const categoryGrouping = useGetAnalysisChartGroupCategory();
  const { t } = useTranslation();
  useUpdateTabState('chart');

  const aggregateFieldsByName = useMemo(() => keyBy(aggregateFields.fields, 'name'), [aggregateFields.fields]);
  const valueFormatter = useMemo(() => {
    const field = aggregateFieldsByName[series[0].select];
    return field.formattingType === 'TIME' ? (value: number) => moment.utc(value).format('HH:mm:ss') : undefined;
  }, [aggregateFieldsByName, series]);
  const valueAxisLabel =
    aggregateFieldsByName[series[0].select].unitLabel ?? aggregateFieldsByName[series[0].select].label;
  const labels = useMemo(() => ({ valueAxis: valueAxisLabel }), [valueAxisLabel]);
  const formatters = useMemo(() => ({ valueAxis: valueFormatter }), [valueFormatter]);
  const dataWithLabels = useMemo(
    () => data.items.map(item => ({ ...item, labels, formatters })),
    [data.items, labels, formatters]
  );
  const groupNameToLabel = new Map<string, string>();
  groupFields.fields.map(it => groupNameToLabel.set(it.name, it.label));
  let addition = '';
  if (groupNameToLabel.has(grouping)) {
    addition += t('analysis.chart.title.groupedBy', { grouping: groupNameToLabel.get(grouping) });
  }
  if (categoryGrouping !== '--' && groupNameToLabel.has(categoryGrouping)) {
    addition += t('analysis.chart.title.categoryGrouping', {
      categoryGrouping: groupNameToLabel.get(categoryGrouping),
    });
  }
  const title = valueAxisLabel + addition;

  return (
    <Container elevation={0}>
      <AnalysisChartHeader />
      <Stack flexGrow={1}>
        <ChartContainer direction="column" justifyContent="center" alignItems="center" flexGrow={1}>
          {series[0].type === 'barVertical' && (
            <BarChart
              layout="vertical"
              data={dataWithLabels}
              valueAxisLabel={valueAxisLabel}
              tableMargin={{ top: 16, right: 32, bottom: 100, left: 96 }}
              valueFormatter={valueFormatter}
              title={title}
              groupNameToLabel={groupNameToLabel}
              categoryGrouping={categoryGrouping}
            />
          )}
          {series[0].type === 'barHorizontal' && (
            <BarChart
              layout="horizontal"
              data={dataWithLabels}
              valueAxisLabel={valueAxisLabel}
              tableMargin={{ top: 16, right: 32, bottom: 100, left: 96 }}
              valueFormatter={valueFormatter}
              title={title}
              groupNameToLabel={groupNameToLabel}
              categoryGrouping={categoryGrouping}
            />
          )}
          {series[0].type === 'line' && (
            <LineChart
              fill={false}
              data={dataWithLabels}
              valueAxisLabel={valueAxisLabel}
              valueFormatter={valueFormatter}
              title={title}
            />
          )}
          {series[0].type === 'area' && (
            <LineChart
              fill={true}
              data={dataWithLabels}
              valueAxisLabel={valueAxisLabel}
              valueFormatter={valueFormatter}
              title={title}
            />
          )}
          {series[0].type === 'pie' && (
            <PieChart
              data={dataWithLabels}
              valueAxisLabel={valueAxisLabel}
              valueFormatter={valueFormatter}
              title={title}
            />
          )}
        </ChartContainer>
      </Stack>
    </Container>
  );
};

displayName(AnalysisChart, 'AnalysisChart');

export default AnalysisChart;
