import React, { useCallback } from 'react';
import { Formik } from 'formik';
import Dialog from '../Dialog';
import { Stack, Typography } from '@mui/material';
import SubmitButton from '../formik/SubmitButton';
import Text from '../Text';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { CloseButton } from '../Button';
import FormikTextInput from '../formik/FormikTextInput';
import { ValidationError } from '../Error';

export interface FormData {
  name: string;
  description?: string;
  number: string;
  responsibleId?: string;
  responsibleFirstName?: string;
  responsibleLastName?: string;
  responsibleMail?: string;
  responsiblePhone?: string;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

interface Props {
  title: string;
  costCenter: FormData;
  onClose: () => void;
  onSave: (costCenter: FormData) => void;
}

const EditCostCenterDialog: React.FC<Props> = ({ title, costCenter, onClose, onSave }) => {
  const { t } = useTranslation();

  const validate = useCallback(
    (values: FormData): ValidationErrors => {
      const errors: ValidationErrors = {};
      if (!values.name || values.name.trim().length === 0) {
        errors.name = { message: t('costCenters.definition.validation.emptyField') };
      }
      if (!values.number || values.number.trim().length === 0) {
        errors.number = { message: t('costCenters.definition.validation.emptyField') };
      }
      return errors;
    },
    [t]
  );

  return (
    <Dialog header={<Typography variant="h2">{title}</Typography>}>
      <Formik initialValues={costCenter} onSubmit={onSave} validate={validate}>
        <Stack direction="column" marginTop={3}>
          <Stack direction="row" gap={7} justifyContent={'space-between'}>
            <Stack direction="column" gap={3}>
              <Text weight="700">{t('costCenters.definition.entity.title')}</Text>
              <FormikTextInput
                label={t('costCenters.definition.entity.name')}
                fieldName="name"
                textWidth={12}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('costCenters.definition.entity.number')}
                fieldName="number"
                textWidth={12}
                inputWidth={12}
              />
            </Stack>
            <Stack direction="column" gap={3}>
              <Text weight="700">{t('costCenters.definition.responsible.title')}</Text>
              <FormikTextInput
                label={t('costCenters.definition.responsible.name')}
                fieldName="responsibleFirstName"
                textWidth={12}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('costCenters.definition.responsible.lastName')}
                fieldName="responsibleLastName"
                textWidth={12}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('costCenters.definition.responsible.id')}
                fieldName="responsibleId"
                textWidth={12}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('costCenters.definition.responsible.telephone')}
                fieldName="responsiblePhone"
                textWidth={12}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('costCenters.definition.responsible.email')}
                fieldName="responsibleMail"
                textWidth={12}
                inputWidth={12}
              />
            </Stack>
          </Stack>
          <Stack direction="row" marginTop={3}>
            <FormikTextInput
              label={t('costCenters.definition.comment')}
              fieldName="description"
              textWidth={12}
              inputWidth={12}
              multiline={true}
              rows={4}
              fullWidth={true}
            />
          </Stack>
          <Stack direction="row" marginTop={3} justifyContent="space-between">
            <CloseButton onClose={onClose} />
            <SubmitButton>{t('dialog.save')}</SubmitButton>
          </Stack>
        </Stack>
      </Formik>
    </Dialog>
  );
};

displayName(EditCostCenterDialog, 'EditCostCenterDialog');

export default EditCostCenterDialog;
