import React, { Suspense } from 'react';
import { Stack } from '@mui/material';
import AnalysisToggle from './AnalysisToggle';
import AnalysisTableDownload from './AnalysisTableDownload';
import HeaderItem from '../HeaderItem';
import { AnalysisViewType, useAnalysisCostCenter, useAnalysisTab } from '../../globalState';
import { displayName, useView } from '../../util';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLanguage } from '../../i18n';
import SimpleCard from '../SimpleCard';

interface ItemsProps {
  children?: React.ReactNode;
}

const AnalysisHeaderItems: React.FC<ItemsProps> = ({ children }) => {
  const { t } = useTranslation();
  const [costCenter] = useAnalysisCostCenter();
  const view = useView();
  const navigate = useNavigate();
  const language = useLanguage();
  const [analysisTab, setAnalysisTab] = useAnalysisTab(view.entity as string);

  const handleToggleChanged = (_: React.MouseEvent, value: string) => {
    if (!!value) {
      setAnalysisTab(value as AnalysisViewType);
      navigate(view.path.replace(':lng', language).replace(':type', value));
    }
  };

  return (
    <>
      <HeaderItem label={t('analysis.header.costCenterDescription')} value={costCenter.name} />
      {children}
      <Stack flexGrow={1} />
      <AnalysisToggle analysisTab={analysisTab} handleToggleChanged={handleToggleChanged} />
      <AnalysisTableDownload analysisTab={analysisTab} />
    </>
  );
};

interface Props {
  children?: React.ReactNode;
}

const AnalysisHeader: React.FC<Props> = ({ children }) => {
  return (
    <SimpleCard>
      <Stack direction="row" alignItems="center" height={theme => theme.spacing(7)} gap={7} paddingX={4}>
        <Suspense fallback={<div />}>
          <AnalysisHeaderItems>{children}</AnalysisHeaderItems>
        </Suspense>
      </Stack>
    </SimpleCard>
  );
};

displayName(AnalysisHeader, 'AnalysisHeader');

export default AnalysisHeader;
