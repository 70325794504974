import { HistoryElement, isHistoryQuery } from '../dto';
import { isEqual, noop } from 'lodash';
import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export function findXElements(totalHistory: HistoryElement[], amountToFind: number, entity: string) {
  let index = totalHistory.length - 1;
  let count = 1;
  while (index >= 0) {
    const currentElement = totalHistory[index];
    const foundEntity = !isHistoryQuery(currentElement)
      ? currentElement.entity
      : currentElement.queryObject.entityElements[0];
    if (entity === foundEntity) {
      if (count === amountToFind) {
        return index;
      } else {
        count += 1;
        index -= 1;
      }
    } else {
      index -= 1;
    }
  }
  return index;
}

export function getEntity(historyElement: HistoryElement) {
  //currently, no QueryObjectDTO is created with multiple entityElements which is why the first on selected automatically
  return !isHistoryQuery(historyElement) ? historyElement.entity : historyElement.queryObject.entityElements[0];
}

export function cleanUpQueryHistory(totalQueryHistory: HistoryElement[]): HistoryElement[] {
  let duplicateFreeHistory: HistoryElement[] = [];
  const entities = Array.from(new Set(totalQueryHistory.map(it => getEntity(it))));
  for (const foundEntity of entities) {
    const historyElementsOfEntity = totalQueryHistory.filter(it => getEntity(it) === foundEntity);
    const duplicateFreeQueryHistory = removeDuplicateQueryHistoryElements(historyElementsOfEntity);
    duplicateFreeHistory = [...duplicateFreeHistory, ...duplicateFreeQueryHistory];
  }
  return duplicateFreeHistory;
}

//if you change the visibility of a column, that refreshes the query and thus adds one element to the history queue
function removeDuplicateQueryHistoryElements(totalQueryHistory: HistoryElement[]): HistoryElement[] {
  const result: HistoryElement[] = [];

  if (totalQueryHistory.length === 1) {
    result.push(totalQueryHistory[0]);
    return result;
  } else if (totalQueryHistory.length === 2) {
    const currentElement = totalQueryHistory[0];
    const nextElement = totalQueryHistory[1];

    if (!isHistoryQuery(currentElement) || !isHistoryQuery(nextElement)) {
      result.push(currentElement);
      result.push(nextElement);
    } else {
      if (isEqual(currentElement, nextElement)) {
        result.push(currentElement);
      } else {
        result.push(currentElement);
        result.push(nextElement);
      }
    }
    return result;
  }

  let i = 0;
  while (i <= totalQueryHistory.length - 2) {
    const currentElement = totalQueryHistory[i];
    if (!isHistoryQuery(currentElement)) {
      result.push(currentElement);
      i++;
    } else {
      const nextElement = totalQueryHistory[i + 1];
      if (!isHistoryQuery(nextElement)) {
        result.push(currentElement);
        result.push(nextElement);
        i = i + 2;
      } else {
        if (isEqual(currentElement, nextElement)) {
          result.push(currentElement);
          i = i + 2;
        } else {
          result.push(currentElement);
          i = i + 1;
        }
      }
    }
  }
  if (i <= totalQueryHistory.length - 1) {
    result.push(totalQueryHistory[totalQueryHistory.length - 1]);
  }
  return result;
}

export const queryHistory = atom<HistoryElement[]>({
  key: 'queryHistory',
  default: [],
});

export const useGetQueryHistory = () => useRecoilValue(queryHistory);

export const useSetQueryHistory = () => useSetRecoilState(queryHistory);

interface QueryHistoryStateUpdateCallback {
  callback: (newHistory: HistoryElement[]) => void;
}

export const queryHistoryStateUpdateCallback = atom<QueryHistoryStateUpdateCallback>({
  key: 'queryHistoryStateUpdateCallback',
  default: { callback: (newHistory: HistoryElement[]) => noop },
});

export const useSetQueryHistoryStateCallback = () => useSetRecoilState(queryHistoryStateUpdateCallback);

export const useQueryHistoryStateCallback = () => useRecoilValue(queryHistoryStateUpdateCallback);

export const analysisTableDetails = atomFamily<Map<string, unknown> | undefined, string>({
  key: 'queryHistory',
  default: undefined,
});

export const useAnalysisTableDetails = (entity: string) => useRecoilState(analysisTableDetails(entity));

export const useSetAnalysisTableDetails = (entity: string) => useSetRecoilState(analysisTableDetails(entity));
