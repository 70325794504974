import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Text from '../Text';
import { useDetailFields } from '../../globalState';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';

const Body = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(6)};
  background: white;
  flex-grow: 1;
`;

const Row = styled('div', { shouldForwardProp: prop => (prop as string) !== 'index' })<{ index: number }>`
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  background: ${({ theme, index }) => (index % 2 === 0 ? 'white' : theme.palette.grey['100'])};
  padding: 0 ${({ theme }) => theme.spacing(3)};
`;

interface Props {
  row: Map<string, unknown>;
}

const AnalysisDetails: React.FC<Props> = ({ row }) => {
  const fields = useDetailFields();
  const { t } = useTranslation();

  return (
    <Stack direction="column" flexGrow={1} marginBottom={4}>
      <Stack direction="row" padding={3} justifyContent="space-between" alignItems="center">
        <Text weight="500">{t('analysis.table.details.title')}</Text>
      </Stack>
      <Body>
        <Stack direction="column" marginTop={4}>
          {fields.fields
            .filter((_, i) => i % 2 === 0)
            .map((field, i) => (
              <Row key={i} index={i}>
                <Text weight="500" width={12}>
                  {field.label}
                </Text>
                <Text width={12}>{(row.get(field.name) as string | undefined) ?? ''}</Text>
              </Row>
            ))}
        </Stack>
        <Stack direction="column" marginTop={4}>
          {fields.fields
            .filter((_, i) => i % 2 === 1)
            .map((field, i) => (
              <Row key={i} index={i}>
                <Text weight="500" width={12}>
                  {field.label}
                </Text>
                <Text width={12}>{(row.get(field.name) as string | undefined) ?? ''}</Text>
              </Row>
            ))}
        </Stack>
      </Body>
    </Stack>
  );
};

displayName(AnalysisDetails, 'AnalysisDetails');

export default AnalysisDetails;
