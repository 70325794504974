import React, { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import Text from '../Text';
import { ActionButton } from '../Button';
import { DateTimePicker } from '../DatePicker';
import Checkbox from '../Checkbox';
import { displayName } from '../../util';
import SimpleCard from 'components/SimpleCard';

interface Props {
  active: boolean;
  from: Moment;
  to: Moment;
  onSave: (value: { active: boolean; from: Moment; to: Moment }) => void;
}

const MaintenanceDates: React.FC<Props> = ({ active, from, to, onSave }) => {
  const { t } = useTranslation();
  const [localActive, setLocalActive] = useState(active);
  const [localFrom, setLocalFrom] = useState<Moment | null>(from);
  const [localTo, setLocalTo] = useState<Moment | null>(to);

  const handleApply = useCallback(() => {
    if (localFrom !== null && localTo !== null) {
      onSave({ active: localActive, from: localFrom, to: localTo });
    }
  }, [localActive, localFrom, localTo, onSave]);

  return (
    <SimpleCard>
      <Stack direction="column" gap={4} padding={3}>
        <Text weight="700">{t('admin.sidebar.maintenanceDates.title')}</Text>
        <Stack direction="column" gap={3}>
          <Stack direction="row" alignItems="center">
            <Text width={9}>{t('admin.sidebar.maintenanceDates.activeCheckbox')}</Text>
            <Checkbox
              checked={localActive}
              onChange={(_, checked: boolean | ((prevState: boolean) => boolean)) => setLocalActive(checked)}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text width={9}>{t('admin.sidebar.maintenanceDates.startDate')}</Text>
            <DateTimePicker value={localFrom} onChange={setLocalFrom} error={localFrom === null} />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text width={9}>{t('admin.sidebar.maintenanceDates.endDate')}</Text>
            <DateTimePicker value={localTo} onChange={setLocalTo} error={localTo === null} />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="end">
          <ActionButton onClick={handleApply}>{t('dialog.apply')}</ActionButton>
        </Stack>
      </Stack>
    </SimpleCard>
  );
};

displayName(MaintenanceDates, 'MaintenanceDates');

export default MaintenanceDates;
