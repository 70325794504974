import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListSubheader, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Formik, useField } from 'formik';
import { fromPairs, groupBy, toPairs } from 'lodash';
import { BaseFieldDTO, EntityDTO } from '../../dto';
import Dialog from '../Dialog';
import SubmitButton from '../formik/SubmitButton';
import Text from '../Text';
import DefaultSelect from '../Select';
import { useApi, useEntities } from '../../globalState';
import { displayName, useFetch } from '../../util';
import Spinner, { SuspendedSpinner } from '../Spinner';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../Error';
import { CloseButton } from '../Button';
import FormikTextInput from '../formik/FormikTextInput';
import FormikList from '../formik/FormikList';

interface TagDefinition {
  name: string;
  baseFields: BaseFieldDTO[];
}

interface BaseField {
  id: string;
  label: string;
  value: BaseFieldDTO;
}

export interface FormData {
  name: string;
  baseFields: BaseField[];
}

const dtoToBaseField = (dto: BaseFieldDTO): BaseField => ({ id: dto.name, label: dto.label, value: dto });

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

interface SelectItem {
  label: string;
  value: string;
}

interface SelectItemCategory {
  category: string;
  items: SelectItem[];
}

const createCategories = (baseFields: BaseFieldDTO[], entities: EntityDTO[]): SelectItemCategory[] => {
  const entityNameToLabel = fromPairs(entities.map(e => [e.name, e.label]));
  const fieldsByCategory = groupBy(
    baseFields.map(field => ({
      category: field.entities
        .map(name => entityNameToLabel[name])
        .sort((a, b) => a.localeCompare(b))
        .join(', '),
      field,
    })),
    'category'
  );
  return toPairs(fieldsByCategory)
    .map(p => ({
      category: p[0],
      items: p[1]
        .map(f => ({ label: f.field.label, value: f.field.name }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    }))
    .sort((a, b) => a.category.localeCompare(b.category));
};

const ignoreOrder = (list: BaseField[]): string =>
  list
    .map(f => f.id)
    .sort((a, b) => a.localeCompare(b))
    .join();

interface DialogContentProps {
  suspended: boolean;
  onClose: () => void;
  tagGroupId?: number;
  tagName?: string;
}

const DialogContent: React.FC<DialogContentProps> = ({ suspended, onClose, tagGroupId, tagName }) => {
  const api = useApi();
  const entities = useEntities();
  const { t } = useTranslation();
  const [{ baseFields }, fetchBaseFields, loading] = useFetch(api.calculateValidBaseFields, {
    baseFields: [],
  });
  const [selectedBaseFields, , selectedBaseFieldsHelper] = useField<FormData['baseFields']>('baseFields');
  const [encounteredError, setEncounteredError] = useState(false);

  const availableEntities = useMemo(() => {
    const entityNames =
      selectedBaseFields.value.length === 0
        ? []
        : selectedBaseFields.value.slice().sort((a, b) => a.value.entities.length - b.value.entities.length)[0].value
            .entities;
    const entityNameToLabel = fromPairs(entities.map(e => [e.name, e.label]));
    return entityNames
      .map(e => entityNameToLabel[e])
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }, [selectedBaseFields.value, entities]);
  const availableBaseFields = useMemo(
    () =>
      createCategories(
        baseFields.filter(it => (tagName === undefined ? true : it.name !== tagName)),
        entities
      ),
    [baseFields, entities, tagName]
  );
  useEffect(() => {
    async function getBaseFields() {
      try {
        await fetchBaseFields({
          tagGroupId,
          selectedBaseFields: selectedBaseFields.value.map(f => f.value.name),
        });
        setEncounteredError(false);
      } catch (e) {
        setEncounteredError(true);
      }
    }
    getBaseFields().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, fetchBaseFields, ignoreOrder(selectedBaseFields.value), tagGroupId]);
  const handleBaseFieldSelected = (e: SelectChangeEvent<unknown>) => {
    const baseFieldDto = baseFields.find(f => f.name === e.target.value);
    if (baseFieldDto) {
      selectedBaseFieldsHelper.setValue(selectedBaseFields.value.concat(dtoToBaseField(baseFieldDto)), true);
    }
  };

  return (
    <Stack direction="column" marginTop={3} gap={5}>
      <FormikTextInput
        label={t('tags.definition.dialog.editing.name')}
        fieldName="name"
        inputWidth={12}
        textWidth={12}
        gap={0}
      />
      <Stack direction="row">
        <Text width={12}>{t('tags.definition.dialog.editing.baseField')}</Text>
        <Stack direction="column" gap={4}>
          <Stack direction="column">
            <FormikList
              name="baseFields"
              emptyMessage={t('tags.definition.dialog.editing.emptyMessage')}
              width="100%"
            />
            {selectedBaseFields.value.length > 1 && (
              <Typography>{t('tags.definition.dialog.editing.dndTip')}</Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap={4}>
            <Text width={11}>{t('tags.definition.dialog.editing.addField')}</Text>
            {encounteredError ? (
              <Typography variant={'body1'} color={'red'}>
                {t('tags.definition.dialog.editing.error.baseField')}
              </Typography>
            ) : (
              <DefaultSelect width={12} value="--" onChange={handleBaseFieldSelected} disabled={loading}>
                <MenuItem value="--">{t('tags.definition.dialog.editing.defaultMenuItem')}</MenuItem>
                {availableBaseFields.flatMap((c, i) =>
                  [<ListSubheader key={i}>{c.category}</ListSubheader>].concat(
                    c.items.map(item => (
                      <MenuItem key={item.value + i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))
                  )
                )}
              </DefaultSelect>
            )}
            {loading && <Spinner size={4} />}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Text width={12}>{t('tags.definition.dialog.editing.availableEntities')}</Text>
        <Text>{availableEntities}</Text>
      </Stack>
      <SuspendedSpinner suspended={suspended} />
      <Stack direction="row" justifyContent="space-between">
        <CloseButton onClose={onClose} disabled={suspended} />
        <SubmitButton additionalDisabledCondition={encounteredError}>{t('dialog.save')}</SubmitButton>
      </Stack>
    </Stack>
  );
};

interface Props {
  tagGroupId?: number;
  initialValue?: TagDefinition;
  onClose: () => void;
  onSave: (data: FormData) => Promise<void>;
}

const TagDefinitionDialog: React.FC<Props> = ({ tagGroupId, initialValue, onClose, onSave }) => {
  const { t } = useTranslation();
  const [suspended, setSuspended] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      setSuspended(true);
      await onSave(data);
      setSuspended(false);
    },
    [onSave]
  );

  const validate = (values: FormData): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.name || values.name.trim().length === 0) {
      errors.name = { message: t('tags.definition.dialog.editing.validation.emptyName') };
    }
    if (values.baseFields.length === 0) {
      errors.baseFields = { message: t('tags.definition.dialog.editing.validation.emptyBaseFields') };
    }
    return errors;
  };

  return (
    <Dialog width="800px" header={<Typography variant="h2">{t('tags.definition.dialog.editing.title')}</Typography>}>
      <Formik
        initialValues={{
          name: initialValue?.name ?? '',
          baseFields: initialValue?.baseFields.map(dtoToBaseField) ?? [],
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <DialogContent suspended={suspended} tagGroupId={tagGroupId} onClose={onClose} tagName={initialValue?.name} />
      </Formik>
    </Dialog>
  );
};

displayName(TagDefinitionDialog, 'TagDefinitionDialog');

export default TagDefinitionDialog;
