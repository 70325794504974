import React from 'react';
import { Typography } from '@mui/material';
import { displayName } from '../util';
import { styled } from '@mui/material/styles';

interface Props {
  className?: string;
  weight?: '400' | '500' | '700';
  width?: number | string;
  children?: React.ReactNode;
  wrapText?: boolean;
}

const Text: React.FC<Props> = ({ className, weight = '400', width, children, wrapText }) => {
  return (
    <Typography
      className={className}
      variant="body1"
      fontSize="14px"
      lineHeight="22px"
      fontWeight={weight}
      whiteSpace={wrapText === true ? 'wrap' : 'noWrap'}
      width={width ? theme => theme.spacing(width) : undefined}
    >
      {children}
    </Typography>
  );
};

displayName(Text, 'Text');

export default Text;

export const NoWrapEllipsisText = styled(Text)`
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
