import { styled } from '@mui/material/styles';
import { ToggleButton } from '@mui/material';

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.palette.primary.main};
  border-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverGrey.main};
    color: ${({ theme }) => theme.palette.hover.main};
    border-color: ${({ theme }) => theme.palette.hover.main};
  }
`;

export default StyledToggleButton;
