import { AdminViewType, UserViewType } from '../dto';
//validation
export const MAX_CUSTOM_REPORT_NAME_LENGTH = 50;
export const MAX_ERROR_CUSTOM_REPORT_NAME_LENGTH = 20;
export const MIN_PASSWORD_LENGTH = 8;
//select hack
export const SELECT_TIMEOUT = 500;
//heartbeat
export const HEARTBEAT_INTERVAL_MILLISECONDS = 5000;
//expiration
export const LOGOUT_ADVANCED_WARNING_MILLISECONDS = 60000 * 5;
export const MAX_FAILED_HEARTBEATS = 3;
//toast
export const TOAST_DURATION = 5000;
//admin job
export const JOB_REFRESH_INTERVAL = 5000;
//default pages
export const DEFAULT_USER_START_PAGE = '/:lng/tb/user/overview';
export const DEFAULT_ADMIN_START_PAGE = '/:lng/tb/admin/support';
export const DEFAULT_LOGOUT_PAGE = '/:lng/logout';
export const DEFAULT_DEV_LOGIN_PAGE = '/:lng/login';
export const PREMIUM_USER_PAGES: UserViewType[] = ['DATA_ANALYSIS', 'COST_CENTER_CONFIGURATION', 'TAG_CONFIGURATION'];
export const SYS_ADMIN_PAGES: AdminViewType[] = ['ADMINISTRATORS', 'JOBS', 'MAINTENANCE'];
//colors
export const CHART_COLOR_SCHEME: string[] = [
  '#961c13',
  '#f2a29d',
  '#c7355f',
  '#f4d4dd',
  '#587467',
  '#b6c8c0',
  '#9d6764',
  '#d9c3c2',
  '#3d3935',
  '#baeaa',
  '#5f5955',
  '#e6e3df',
  '#86807c',
  '#f7f6f5',
];
//links
export const NEW_USER_LINK = 'https://sbp.sunrise.ch/manage-permissions/user/manage-portal-users/create';
//languages
export const DEFAULT_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES = [
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'Italiano',
    value: 'it',
  },
  {
    label: 'English',
    value: 'en',
  },
];
//distance
export const FIXED_RIGHT_DISTANCE = 15;
//width
export const SIDEBAR_WIDTH = 304;
//page sizes
export const ANALYSIS_TABLE_PAGE_SIZE = 100;
export const DRILLDOWN_TABLE_PAGE_SIZE = 20;
