import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { StyledTextField } from '../TextInput';
import { displayName } from '../../util';
import { Stack, useTheme } from '@mui/material';
import ErrorText from './ErrorText';
import { ValidationError } from '../Error';
import Text from '../Text';

interface Props {
  label: string;
  fieldName: string;
  fieldCypressId?: string;
  type?: 'text' | 'password';
  inputWidth?: number | string;
  textWidth?: number | string;
  fullWidth?: boolean;
  height?: number | string;
  grow?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  autocomplete?: boolean;
  autoFocus?: boolean;
  boldLabel?: boolean;
  textAlignEnd?: boolean;
  gap?: number;
}

const FormikTextInput: React.FC<Props> = ({
  label,
  type = 'text',
  fieldName,
  fieldCypressId,
  inputWidth,
  textWidth,
  fullWidth,
  height,
  multiline,
  rows,
  grow = false,
  disabled,
  autocomplete = false,
  autoFocus = false,
  boldLabel,
  textAlignEnd,
  gap = 3,
}) => {
  const ctx = useFormikContext();
  const ref = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const [field, meta, helpers] = useField(fieldName);

  useEffect(() => {
    ctx.registerField(fieldName, {
      validate: (value: unknown) => {
        if (ref.current && value !== ref.current.value) {
          helpers.setValue(ref.current.value, false);
        }
      },
    });
    return () => ctx.unregisterField(fieldName);
  }, [ctx, helpers, fieldName]);

  const validationError = meta.error as unknown as ValidationError;
  const totalWidth = fullWidth ? '100%' : undefined;
  const finalTextWidth = textWidth ? theme.spacing(textWidth) : theme.spacing(10);
  const justifyContent = textAlignEnd ? 'end' : undefined;

  return (
    <Stack direction="column" width={totalWidth}>
      <Stack direction="row" gap={gap}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={justifyContent}
          width={finalTextWidth}
          sx={{ wordBreak: 'break-word' }}
        >
          <Text weight={boldLabel ? '700' : '400'} wrapText={true}>
            {label}
          </Text>
        </Stack>
        <Stack flexGrow={1} data-cy={fieldCypressId} justifyContent={'center'}>
          <StyledTextField
            inputRef={ref}
            defaultValue={field.value}
            type={type}
            onBlur={(e: { target: { value: string | number } }) => helpers.setValue(e.target.value, true)}
            onChange={(e: { target: { value: string | number } }) => helpers.setValue(e.target.value, true)}
            width={fullWidth ? '100%' : inputWidth}
            height={height}
            grow={grow}
            multiline={multiline}
            rows={rows}
            error={!!meta.error}
            disabled={disabled}
            autoComplete={autocomplete ? 'on' : 'off'}
            autoFocus={autoFocus}
          />
        </Stack>
      </Stack>
      <Stack direction="row" gap={gap}>
        <Stack direction="row" alignItems="center" width={finalTextWidth} minWidth={finalTextWidth} />
        <Stack>{!!meta.error && validationError.message && <ErrorText text={validationError.message} />}</Stack>
      </Stack>
    </Stack>
  );
};

displayName(FormikTextInput, 'FormikTextInput');

export default FormikTextInput;
