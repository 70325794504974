import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Stack } from '@mui/material';
import SidebarLayout from '../../SidebarLayout';
import { ErrorTypography } from '../../components/Typography';
import InvoiceFilter from '../../components/filter/InvoiceFilter';
import InvoicePeriodFilter from '../../components/filter/InvoicePeriodFilter';
import OverviewHeader from '../../components/overview/OverviewHeader';
import OverviewTable, { OverviewErrorMessage } from '../../components/overview/OverviewTable';
import BarChart from '../../components/chart/BarChart';
import Spinner from '../../components/Spinner';
import ContentCard from '../../components/ContentCard';
import { useLanguage } from '../../i18n';
import { displayName, useView } from '../../util';
import { ErrorOverlay, NoResultsOverlay } from '../../components/Overlay';
import useDocumentTitle from '../../useDocumentTitle';
import { TelcobillView, useApplyOverviewReport, useOverview, useStatusMessageText, useViews } from '../../globalState';
import { OverviewReportDTO } from '../../dto';

const ChartContainer = styled(Stack)`
  background: white;
  height: 10px;
`;

const Overview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useLanguage();
  const applyOverviewReport = useApplyOverviewReport();
  const response = useOverview();
  const view = useView();
  const views = useViews();
  const statusMessageText = useStatusMessageText();

  useDocumentTitle(t('application.shortName') + ' - ' + view.label);
  const menuItemAction = (overviewReport: OverviewReportDTO) => {
    const asyncAction = async (overviewReport: OverviewReportDTO) => {
      const view = views.find(view => view.entity === overviewReport.entityName) as TelcobillView;
      navigate(view.path.replace(':lng', language).replace(':type', 'table'));
      await applyOverviewReport(overviewReport);
    };
    asyncAction(overviewReport).catch(console.error);
  };

  return (
    <SidebarLayout
      title={view.label}
      sidebarContent={
        <>
          <InvoiceFilter />
          <InvoicePeriodFilter />
        </>
      }
    >
      {response.loading ? (
        <Spinner />
      ) : response.data ? (
        <>
          <OverviewHeader invoiceNumbers={response.data.invoiceNumbers} />
          <Stack direction="column" gap={4} marginTop={4}>
            {statusMessageText.text && (
              <Stack direction="row" gap={4}>
                <ContentCard title={t('overview.statusMessage')} flexGrow={1}>
                  <ErrorTypography variant={'h2'} sx={{ marginLeft: '10px' }}>
                    {statusMessageText.text}
                  </ErrorTypography>
                </ContentCard>
              </Stack>
            )}
            <Stack direction="row" gap={4}>
              <ContentCard title={t('overview.summary.title')} flexBasis="0" flexGrow={1} padding={0}>
                {response.data.summaryItems.length > 0 ? (
                  <OverviewTable items={response.data.summaryItems} />
                ) : (
                  <OverviewErrorMessage errorMessage={t('overview.summary.noResults')} />
                )}
              </ContentCard>
              <ContentCard title={t('overview.chart.title')} flexBasis="0" flexGrow={1} padding={0}>
                <ChartContainer direction="column" justifyContent="center" alignItems="center" flexGrow={1}>
                  <BarChart
                    data={response.data.trendChart.items.map(item => ({
                      category: item.category,
                      value: item.value / 100,
                      grouping: undefined,
                      labels: { valueAxis: t('overview.chart.tooltipValue') },
                    }))}
                    tableMargin={{ top: 16, right: 32, bottom: 32, left: 96 }}
                    valueAxisLabel={t('overview.chart.valueAxis')}
                    layout="vertical"
                  />
                </ChartContainer>
              </ContentCard>
            </Stack>
            <Stack direction="row" gap={4}>
              <ContentCard title={t('overview.reports.title')} flexBasis="0" flexGrow={1} padding={0}>
                {response.data.reports.length > 0 ? (
                  <OverviewTable
                    items={response.data.reports.map(r => ({
                      label: r.report.name,
                      action: () => menuItemAction(r),
                    }))}
                  />
                ) : (
                  <OverviewErrorMessage errorMessage={t('overview.reports.noResults')} />
                )}
              </ContentCard>
              <ContentCard title={t('overview.keyFigures.title')} flexBasis="0" flexGrow={1} padding={0}>
                {response.data.keyFigures.length > 0 ? (
                  <OverviewTable items={response.data.keyFigures.map(s => ({ label: s }))} />
                ) : (
                  <OverviewErrorMessage errorMessage={t('overview.keyFigures.noResults')} />
                )}
              </ContentCard>
            </Stack>
          </Stack>
        </>
      ) : response.error ? (
        <ErrorOverlay error={response.error} />
      ) : (
        <NoResultsOverlay />
      )}
    </SidebarLayout>
  );
};

displayName(Overview, 'Overview');

export default Overview;
