import React, { useCallback, useState } from 'react';
import { noop } from 'lodash';
import { Stack } from '@mui/material';
import TreeItem from './TreeItem';
import { TreeContextProvider, TreeNode } from './treeutil';
import { IconButton } from '../Button';
import { DeleteOutlined, UnfoldLessOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import TextInput from '../TextInput';
import { TreeType, useExpandedTreeItems, useSetExpandedTreeItems } from '../../globalState';

const flatten = (root: TreeNode): TreeNode[] => [root].concat(root.children.flatMap(child => flatten(child)));

interface TreeItemsProps {
  items: TreeNode[];
}

const TreeItems: React.FC<TreeItemsProps> = ({ items }) => {
  return (
    <>
      {items.map(item => (
        <TreeItem key={item.id} node={item}>
          {item.children.length > 0 && <TreeItems items={item.children} />}
        </TreeItem>
      ))}
    </>
  );
};

interface Props<T> {
  root: TreeNode<T>;
  treeKey: TreeType;
  selectedNode: TreeNode<T>;
  onSelectionChange: (selected: TreeNode<T>) => void;
  enableDnd?: boolean;
  onDrop?: (source: T, target: T) => void;
  trie: Map<string, Set<string>>;
}

const TreeView = <T,>({
  root,
  treeKey,
  selectedNode,
  onSelectionChange,
  enableDnd = false,
  onDrop = noop,
  trie,
}: Props<T>) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [filterNoResult, setFilterNoResult] = useState(false);
  const setExpanded = useSetExpandedTreeItems(treeKey);
  const expanded = useExpandedTreeItems(treeKey);

  const toggleExpanded = useCallback(
    (nodeId: string) => {
      setExpanded(expanded =>
        expanded.includes(nodeId) ? expanded.filter(id => id !== nodeId) : expanded.concat(nodeId)
      );
    },
    [setExpanded]
  );
  const handleFilterChange = useCallback(
    (value: string) => {
      setFilter(value);
      const foundIds = trie.get(value);
      const newExpandedIds: string[] = foundIds === undefined ? [value] : Array.from(foundIds).concat(value);
      setExpanded(newExpandedIds);
      if (value === '') {
        setFilterNoResult(false);
      } else {
        setFilterNoResult(foundIds === undefined || foundIds.size === 0);
      }
    },
    [setExpanded, trie]
  );

  return (
    <>
      <Stack direction="row">
        <TextInput placeholder="Filter" grow={true} value={filter} onChange={handleFilterChange} />
        <IconButton
          disabled={filter.length === 0}
          onClick={() => {
            setFilter('');
            setFilterNoResult(false);
            setExpanded([]);
          }}
        >
          <DeleteOutlined fontSize="small" sx={{ paddingLeft: '8px' }} />
        </IconButton>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Stack direction="row">
          <IconButton title={t('tooltip.expandAll')} onClick={() => setExpanded(flatten(root).map(node => node.id))}>
            <UnfoldMoreOutlined fontSize="small" />
          </IconButton>
          <IconButton title={t('tooltip.collapseAll')} onClick={() => setExpanded([])}>
            <UnfoldLessOutlined fontSize="small" />
          </IconButton>
        </Stack>
        <TreeContextProvider
          value={{
            selectedNode,
            onSelectionChange,
            expanded,
            toggleExpanded,
            enableDnd,
            onDrop,
            filter,
            filterNoResult,
          }}
        >
          <TreeItems items={[root]} />
        </TreeContextProvider>
      </Stack>
    </>
  );
};

export default TreeView;
