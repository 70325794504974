import React from 'react';
import { styled } from '@mui/material/styles';
import { range } from 'lodash';
import { Stack } from '@mui/material';
import Text, { NoWrapEllipsisText } from '../Text';
import RunAnalysisButton from '../RunAnalysisButton';
import { displayName } from '../../util';

export const Table = styled(Stack)`
  div.Mui-row {
    height: ${({ theme }) => theme.table.row.height};
  }
  div.Mui-even {
    background: ${({ theme }) => theme.table.row.even};
  }
  div.Mui-odd {
    background: ${({ theme }) => theme.table.row.odd};
  }
`;

const padItems = (items: OverviewTableItem[]): OverviewTableItem[] => {
  if (items.length >= 8) {
    return items;
  }
  return items.concat(range(9 - items.length).map(() => ({ label: '' })));
};

interface OverviewTableItem {
  label: string;
  value?: string;
  action?: () => void;
}
interface OverviewTableProps {
  items: OverviewTableItem[];
}

const OverviewTable: React.FC<OverviewTableProps> = ({ items }) => {
  return (
    <Table direction="column">
      {padItems(items).map((item, i) => (
        <Stack
          key={i}
          className={'Mui-row ' + (i % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: 1, borderColor: 'grey.100', pl: 4, pr: 4 }}
        >
          <NoWrapEllipsisText>{item.label}</NoWrapEllipsisText>
          {item.value && <NoWrapEllipsisText>{item.value}</NoWrapEllipsisText>}
          {item.action && <RunAnalysisButton onClick={item.action} />}
        </Stack>
      ))}
    </Table>
  );
};

displayName(OverviewTable, 'OverviewTable');

export default OverviewTable;

const padStringItems = (item: string): string[] => {
  return [item].concat(range(7).map(() => ''));
};

interface TextTableProps {
  errorMessage: string;
}
export const OverviewErrorMessage: React.FC<TextTableProps> = ({ errorMessage }) => {
  return (
    <Table direction="column">
      {padStringItems(errorMessage).map((item, i) => (
        <Stack
          key={i}
          className={'Mui-row'}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: 4, pr: 4 }}
        >
          <Text>{item}</Text>
        </Stack>
      ))}
    </Table>
  );
};

displayName(OverviewErrorMessage, 'TextTable');
