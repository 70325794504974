import React from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup } from '@mui/material';
import { displayName } from '../../util';

interface Props {
  name: string;
  children?: React.ReactNode;
}

const FormikRadioGroup: React.FC<Props> = ({ name, children }) => {
  const { values, handleChange } = useFormikContext<Record<string, unknown>>();
  return (
    <RadioGroup row={true} name={name} value={values[name]} onChange={handleChange}>
      {children}
    </RadioGroup>
  );
};

displayName(FormikRadioGroup, 'FormikRadioGroup');

export default FormikRadioGroup;
