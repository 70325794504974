import React from 'react';
import { Card, Stack, styled, Typography } from '@mui/material';
import WizardStepLayout from '../WizardStepLayout';
import { useLatestInvoicePeriod, useMailTemplate, useUser } from '../../globalState';
import { displayName, formatDate } from '../../util';
import { useFormikContext } from 'formik';
import { CustomReportData, SendEmailButton } from './CustomReportWizard';
import RichTextEditor from '../RichTextEditor';
import { useTranslation } from 'react-i18next';

const Container = styled(Card)`
  background: ${({ theme }) => theme.palette.grey['200']};
  padding: ${({ theme }) => theme.spacing(3)};
`;

interface Props {
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
}

const CustomReportWizardStep4: React.FC<Props> = ({ disabledSendEmail, triggerSendEmail }) => {
  const { t } = useTranslation();
  const { content } = useMailTemplate('REPORT_MAIL_TEMPLATE');
  const { startDate, endDate } = useLatestInvoicePeriod();
  const { firstName, lastName } = useUser();
  const { values } = useFormikContext<CustomReportData>();
  const processedContent = content
    .replace('#{FROM_DATE}', formatDate(startDate))
    .replace('#{TO_DATE}', formatDate(endDate))
    .replace('#{USER_NAME}', `${firstName} ${lastName}`)
    .replace('#{REPORT_ERROR}', '');
  return (
    <WizardStepLayout title={t('sidebar.customReport.step4.title')}>
      <Container elevation={0}>
        <Stack direction="column" gap={2}>
          {processedContent.split('\n').map((p, i) => (
            <Typography key={i}>{p}</Typography>
          ))}
          <RichTextEditor readOnly={true} initialValue={values.customText} />
        </Stack>
      </Container>
      <Stack marginTop={4} alignItems={'start'}>
        <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep4, 'CustomReportWizardStep4');

export default CustomReportWizardStep4;
