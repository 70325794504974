/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { DataGridProProcessedProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { useTranslation } from 'react-i18next';
import { checkColumnOrderModelSame, checkColumnVisibilityModelsSame, displayName } from '../../../util';
import DndList from './dnd/DndList';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useCreateError, useLogError } from '../../Error';
import { fromPairs } from 'lodash';
import {
  useAllAnalysisTableFields,
  useColumnPanelOrderModel,
  useColumnPanelUpdateAnalysisTableFieldState,
  useColumnPanelVisibilityModel,
  useFieldStylesUpdateCallback,
  useGetAnalysisTableGroupings,
  useSetAnalysisTableChangeState,
} from '../../../globalState';
import {
  getDataGridUtilityClass,
  GridColDef,
  gridColumnDefinitionsSelector,
  GridColumnVisibilityModel,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';

export interface GridColumnsManagementProps {
  /*
   * Changes how the options in the columns selector should be ordered.
   * If not specified, the order is derived from the `columns` prop.
   */
  sort?: 'asc' | 'desc';
  searchPredicate?: (column: GridColDef, searchValue: string) => boolean;
  /**
   * If `true`, the column search field will be focused automatically.
   * If `false`, the first column switch input will be focused automatically.
   * This helps to avoid input keyboard panel to popup automatically on touch devices.
   * @default true
   */
  autoFocusSearchField?: boolean;
  /**
   * Returns the list of togglable columns.
   * If used, only those columns will be displayed in the panel
   * which are passed as the return value of the function.
   * @param {GridColDef[]} columns The `ColDef` list of all columns.
   * @returns {GridColDef['field'][]} The list of togglable columns' field names.
   */
  getTogglableColumns?: (columns: GridColDef[]) => GridColDef['field'][];
}

type OwnerState = DataGridProProcessedProps;

interface IdInterFace {
  id: string;
}

type ExpandedGridColDef = GridColDef & IdInterFace;

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['columnsManagement'],
    header: ['columnsManagementHeader'],
    footer: ['columnsManagementFooter'],
    row: ['columnsManagementRow'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

const collator = new Intl.Collator();

function wrapGridColDef(input: GridColDef, inputId: number, hideable: boolean): ExpandedGridColDef {
  return {
    id: inputId.toString(),
    ...input,
    hideable: hideable,
  };
}

export function GridColumnsManagement(props: GridColumnsManagementProps) {
  const apiRef = useGridApiContext();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const allFields = useAllAnalysisTableFields();
  const currentGroupings = useGetAnalysisTableGroupings();
  const updateAnalysisTableFieldState = useColumnPanelUpdateAnalysisTableFieldState();
  const setAnalysisTableChangeState = useSetAnalysisTableChangeState();
  const rootProps = useGridRootProps();
  const classes = useUtilityClasses(rootProps);
  const createError = useCreateError();
  const logError = useLogError();
  const handleAnalysisTableChangeCallback = useFieldStylesUpdateCallback();
  const [searchValue, setSearchValue] = React.useState('');
  const [suspended, setSuspended] = React.useState(false);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = useColumnPanelVisibilityModel();
  const [currentColumnOrderModel, setCurrentColumnOrderModel] = useColumnPanelOrderModel();
  const [initialColumnVisibilityModel, setInitialColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [initialColumnOrderModel, setInitialColumnOrderModel] = useState<string[]>([]);
  const { t } = useTranslation();

  const { sort, searchPredicate = defaultSearchPredicate, autoFocusSearchField = true, getTogglableColumns } = props;
  const hasNotChanged = React.useMemo(() => {
    const visibilitySame = checkColumnVisibilityModelsSame(currentColumnVisibilityModel, initialColumnVisibilityModel);
    const columnSame = checkColumnOrderModelSame(currentColumnOrderModel, initialColumnOrderModel);
    return visibilitySame && columnSame;
  }, [currentColumnOrderModel, currentColumnVisibilityModel, initialColumnOrderModel, initialColumnVisibilityModel]);
  useEffect(() => {
    setAnalysisTableChangeState(!hasNotChanged);
  }, [hasNotChanged, setAnalysisTableChangeState]);

  useEffect(() => {
    const initializedColumnVisibilityModel = fromPairs(allFields.map(f => [f.name, f.visible]));
    setCurrentColumnVisibilityModel(initializedColumnVisibilityModel);
    setInitialColumnVisibilityModel(initializedColumnVisibilityModel);
    const initializedColumnOrderModel = allFields.map(f => f.name);
    setCurrentColumnOrderModel(initializedColumnOrderModel);
    setInitialColumnOrderModel(initializedColumnOrderModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedColumns = React.useMemo(() => {
    switch (sort) {
      case 'asc':
        return [...columns].sort((a, b) => collator.compare(a.headerName || a.field, b.headerName || b.field));

      case 'desc':
        return [...columns].sort((a, b) => -collator.compare(a.headerName || a.field, b.headerName || b.field));

      default:
        return columns;
    }
  }, [columns, sort]);

  const toggleColumn = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { name: field } = event.target as HTMLInputElement;
      const newColumnVisibilityModel: GridColumnVisibilityModel = { ...currentColumnVisibilityModel };
      const newVisibility = !currentColumnVisibilityModel[field];
      newColumnVisibilityModel[field] = newVisibility;
      setCurrentColumnVisibilityModel(newColumnVisibilityModel);
      apiRef.current.setColumnVisibility(field, newVisibility);
    },
    [apiRef, currentColumnVisibilityModel, setCurrentColumnVisibilityModel]
  );

  const currentColumns = React.useMemo(() => {
    const togglableColumns = getTogglableColumns ? getTogglableColumns(sortedColumns) : null;

    const togglableSortedColumns = togglableColumns
      ? sortedColumns.filter(({ field }) => togglableColumns.includes(field))
      : sortedColumns;

    if (!searchValue) {
      return togglableSortedColumns;
    }

    return togglableSortedColumns.filter(column => searchPredicate(column, searchValue.toLowerCase()));
  }, [sortedColumns, searchValue, searchPredicate, getTogglableColumns]);

  const groupingSet = React.useMemo(() => {
    return new Set(currentGroupings);
  }, [currentGroupings]);
  const nonDndItems: ExpandedGridColDef[] = React.useMemo(() => {
    return currentColumns
      .filter(it => it.field === '__DRILLDOWN__' || groupingSet.has(it.field))
      .map((it, i) => wrapGridColDef(it, i, false));
  }, [currentColumns, groupingSet]);
  const dndItems: ExpandedGridColDef[] = React.useMemo(() => {
    return currentColumns
      .filter(it => it.field !== '__DRILLDOWN__' && !groupingSet.has(it.field))
      .map((it, i) => wrapGridColDef(it, i, true));
  }, [currentColumns, groupingSet]);

  const handleSearchValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const firstSwitchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (autoFocusSearchField) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      searchInputRef.current!.focus();
    } else if (firstSwitchRef.current && typeof firstSwitchRef.current.focus === 'function') {
      firstSwitchRef.current.focus();
    }
  }, [autoFocusSearchField]);

  const renderItem = useCallback(
    (column: ExpandedGridColDef, isDraggable?: boolean) => {
      let firstHideableColumnFound = false;
      const isFirstHideableColumn = (column: GridColDef) => {
        if (!firstHideableColumnFound && column.hideable !== false) {
          firstHideableColumnFound = true;
          return true;
        }
        return false;
      };

      return (
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ cursor: 'pointer' }}
          width={'100%'}
          key={column.field}
        >
          <FormControlLabel
            className={classes.row}
            control={
              <rootProps.slots.baseSwitch
                disabled={column.hideable === false}
                checked={currentColumnVisibilityModel[column.field] !== false}
                onClick={toggleColumn}
                name={column.field}
                sx={{ p: 0.5 }}
                size={'small'}
                inputRef={isFirstHideableColumn(column) ? firstSwitchRef : undefined}
                {...rootProps.slotProps?.baseSwitch}
              />
            }
            sx={{ marginLeft: '-5px', marginBottom: '1px' }}
            label={column.headerName || column.field}
          />
          {!(isDraggable === false) && <DragHandleIcon />}
        </Stack>
      );
    },
    [classes.row, currentColumnVisibilityModel, rootProps, toggleColumn]
  );
  const reorderItem = useCallback(
    async (movedId: string, items: ExpandedGridColDef[]) => {
      let movedField = '';
      let newIndex = -1;
      let fieldToLeft = '';

      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        if (item.id === movedId) {
          movedField = item.field;
          newIndex = index;
          if (index !== 0) {
            const itemToLeft = items[index - 1];
            fieldToLeft = itemToLeft.field;
          }
          break;
        }
      }
      if (newIndex !== -1) {
        //do not forget to account for the fixed columns that should not be reordered
        apiRef.current.setColumnIndex(movedField, newIndex + 1);

        const cleanedColumnOrderModel = currentColumnOrderModel.filter(it => it !== movedField);
        let newColumnOrder: string[] = [];
        if (fieldToLeft === '') {
          newColumnOrder = [movedField, ...cleanedColumnOrderModel];
        } else {
          const indexToLeft = cleanedColumnOrderModel.indexOf(fieldToLeft);
          if (indexToLeft === -1) {
            await logError(
              createError(
                'GridsColumnsManagement: The element to the left of the to be inserted element does not exist'
              )
            );
          } else {
            newColumnOrder = [
              ...cleanedColumnOrderModel.slice(0, indexToLeft + 1),
              movedField,
              ...cleanedColumnOrderModel.slice(indexToLeft + 1, cleanedColumnOrderModel.length),
            ];
          }
        }
        setCurrentColumnOrderModel(newColumnOrder);
      }
    },
    [apiRef, createError, currentColumnOrderModel, logError, setCurrentColumnOrderModel]
  );
  const handleReset = useCallback(() => {
    setCurrentColumnVisibilityModel(initialColumnVisibilityModel);
    setCurrentColumnOrderModel(initialColumnOrderModel);
    for (let i = 0; i < initialColumnOrderModel.length; i++) {
      const fieldToMove = initialColumnOrderModel[i];
      apiRef.current.setColumnIndex(fieldToMove, i + 1);
    }
  }, [
    apiRef,
    initialColumnOrderModel,
    initialColumnVisibilityModel,
    setCurrentColumnOrderModel,
    setCurrentColumnVisibilityModel,
  ]);
  const handleApply = useCallback(() => {
    if (apiRef.current) {
      apiRef.current.hidePreferences();
    }
    setSuspended(true);
    updateAnalysisTableFieldState();
    handleAnalysisTableChangeCallback.callback().then();
    setSuspended(false);
  }, [apiRef, handleAnalysisTableChangeCallback, updateAnalysisTableFieldState]);

  return (
    <React.Fragment>
      <GridColumnsManagementHeader className={classes.header} ownerState={rootProps} sx={{ padding: '8px 0' }}>
        <rootProps.slots.baseTextField
          placeholder={t('analysis.table.columnsPanel.searchPlaceholder')}
          inputRef={searchInputRef}
          value={searchValue}
          onChange={handleSearchValueChange}
          variant="standard"
          size="small"
          fullWidth
          {...rootProps.slotProps?.baseTextField}
        />
      </GridColumnsManagementHeader>
      <GridColumnsManagementBody
        className={classes.root}
        ownerState={rootProps}
        sx={{ paddingTop: '8px', paddingBottom: '16px' }}
      >
        {nonDndItems.map(it => renderItem(it, false))}
        <DndList items={dndItems} renderItem={renderItem} onChange={reorderItem} width={'100%'} />
        {currentColumns.length === 0 && (
          <GridColumnsManagementEmptyText ownerState={rootProps}>
            {t('analysis.table.columnsPanel.noResult')}
          </GridColumnsManagementEmptyText>
        )}
      </GridColumnsManagementBody>
      {currentColumns.length > 0 ? (
        <GridColumnsManagementFooter ownerState={rootProps} className={classes.footer} sx={{ padding: '8px' }}>
          <rootProps.slots.baseButton
            onClick={handleReset}
            disabled={hasNotChanged || suspended}
            {...rootProps.slotProps?.baseButton}
          >
            {t('analysis.table.columnsPanel.reset')}
          </rootProps.slots.baseButton>
          <rootProps.slots.baseButton onClick={handleApply} disabled={suspended} {...rootProps.slotProps?.baseButton}>
            {t('analysis.table.columnsPanel.apply')}
          </rootProps.slots.baseButton>
          <Box
            sx={{
              display: suspended ? 'flex' : 'none',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <CircularProgress size={20} />
          </Box>
        </GridColumnsManagementFooter>
      ) : null}
    </React.Fragment>
  );
}

displayName(GridColumnsManagement, 'GridColumnsManagement');

GridColumnsManagement.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "yarn proptypes"  |
  // ----------------------------------------------------------------------
  /**
   * If `true`, the column search field will be focused automatically.
   * If `false`, the first column switch input will be focused automatically.
   * This helps to avoid input keyboard panel to popup automatically on touch devices.
   * @default true
   */
  autoFocusSearchField: PropTypes.bool,
  /**
   * If `true`, the `Reset` button will not be disabled
   * @default false
   */
  disableResetButton: PropTypes.bool,
  /**
   * If `true`, the `Show/Hide all` toggle checkbox will not be displayed.
   * @default false
   */
  disableShowHideToggle: PropTypes.bool,
  /**
   * Returns the list of togglable columns.
   * If used, only those columns will be displayed in the panel
   * which are passed as the return value of the function.
   * @param {GridColDef[]} columns The `ColDef` list of all columns.
   * @returns {GridColDef['field'][]} The list of togglable columns' field names.
   */
  getTogglableColumns: PropTypes.func,
  searchPredicate: PropTypes.func,
  sort: PropTypes.oneOf(['asc', 'desc']),
  /**
   * Changes the behavior of the `Show/Hide All` toggle when the search field is used:
   * - `all`: Will toggle all columns.
   * - `filteredOnly`: Will only toggle columns that match the search criteria.
   * @default 'all'
   */
  toggleAllMode: PropTypes.oneOf(['all', 'filteredOnly']),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

const GridColumnsManagementBody = styled('div', {
  name: 'MuiDataGrid',
  slot: 'ColumnsManagement',
  overridesResolver: (props, styles) => styles.columnsManagement,
})<{ ownerState: OwnerState }>(({ theme }) => ({
  padding: theme.spacing(0, 3, 1.5),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  flex: '1 1',
  maxHeight: 400,
  alignItems: 'flex-start',
}));

const GridColumnsManagementHeader = styled('div', {
  name: 'MuiDataGrid',
  slot: 'ColumnsManagementHeader',
  overridesResolver: (props, styles) => styles.columnsManagementHeader,
})<{ ownerState: OwnerState }>(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
}));

const GridColumnsManagementFooter = styled('div', {
  name: 'MuiDataGrid',
  slot: 'ColumnsManagementFooter',
  overridesResolver: (props, styles) => styles.columnsManagementFooter,
})<{ ownerState: OwnerState }>(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 0.5, 3),
  display: 'flex',
  justifyContent: 'space-between',
}));

const GridColumnsManagementEmptyText = styled('div')<{ ownerState: OwnerState }>(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  color: theme.palette.grey[500],
  fontSize: theme.typography.body1.fontSize,
  fontFamily: theme.typography.fontFamily,
}));

const defaultSearchPredicate: NonNullable<GridColumnsManagementProps['searchPredicate']> = (
  column: GridColDef,
  searchValue: string
) => (column.headerName || column.field).toLowerCase().indexOf(searchValue) > -1;
