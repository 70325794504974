import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { StatusMessageDTO, StatusMessageTextDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { clientLanguageState } from './telcobill';

const getStatusMessageQuery = selector<StatusMessageDTO>({
  key: 'getStatusMessageDTO',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getStatusMessage();
    return dataOrThrow(response);
  },
});

export const useStatusMessage = () => {
  return useRecoilValue(getStatusMessageQuery);
};

export const useRefreshStatusMessage = () => {
  return useRecoilRefresher_UNSTABLE(getStatusMessageQuery);
};

const getStatusMessageTextQuery = selector<StatusMessageTextDTO>({
  key: 'getStatusMessageText',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getStatusMessageText();
    return dataOrThrow(response);
  },
});

export function useStatusMessageText() {
  return useRecoilValue(getStatusMessageTextQuery);
}
