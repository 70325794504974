import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { Stack, Typography } from '@mui/material';
import SubmitButton from '../../components/formik/SubmitButton';
import { useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { displayName } from '../../util';
import useDocumentTitle from '../../useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageCard } from '../../components/Container';
import StyledLink from '../../components/Link';
import FormikTextInputField from '../../components/formik/FormikTextInputField';

interface FormData {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const api = useApi();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  useDocumentTitle(t('application.shortName') + ' - ' + t('login.title'));
  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        const response = await api.login(values.username, values.password);
        if (response.data?.status === 'OK') {
          if (response.data.admin) {
            window.location.href = `/${language}/tb/admin/support`;
          } else {
            window.location.href = `/${language}/tb/user/overview`;
          }
        } else {
          setErrorMessage(
            response.error?.message === 'wrongCredentials'
              ? t('error.login.unauthorized')
              : t('error.login.serverError')
          );
        }
      } catch (error) {
        setErrorMessage(t('error.login.serverError'));
      }
    },
    [api, language, t]
  );
  const onForgottenPasswordClick = useCallback(() => {
    navigate(`/${language}/forgottenPassword`);
  }, [language, navigate]);

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard data-cy="login">
        <Stack direction="column" padding={4}>
          <Stack alignItems={'center'}>
            <Typography variant="h1">{t('application.title') + ' ' + t('login.title')}</Typography>
          </Stack>
          <Formik initialValues={{ username: '', password: '' }} onSubmit={handleSubmit}>
            <Stack direction="column" marginTop={4}>
              <Form>
                <Stack direction="column" gap={3}>
                  <FormikTextInputField
                    placeholder={t('login.username')}
                    fieldName="username"
                    fieldCypressId="username"
                    type="text"
                    autoFocus={true}
                  />
                  <FormikTextInputField
                    placeholder={t('login.password')}
                    fieldName="password"
                    fieldCypressId="password"
                    type="password"
                  />
                  <Stack alignItems={'end'}>
                    <StyledLink onClick={onForgottenPasswordClick}>{t('login.buttonLabel.forgotten')}</StyledLink>
                  </Stack>
                </Stack>
              </Form>
              {errorMessage && (
                <Stack marginTop={4}>
                  <Typography color={theme => theme.palette.error.main} data-cy="errorMessage">
                    {errorMessage}
                  </Typography>
                </Stack>
              )}
              <Stack direction="column" alignItems={'center'} gap={3} marginTop={3} data-cy="action">
                <SubmitButton id="submit">{t('login.buttonLabel.login')}</SubmitButton>
              </Stack>
            </Stack>
          </Formik>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(Login, 'Login');

export default Login;
