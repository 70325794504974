import React, { Suspense } from 'react';
import { Stack, Typography } from '@mui/material';
import Spinner from './components/Spinner';
import { displayName } from './util';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const SimpleLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <Stack direction="column" flexGrow={1}>
      <Stack direction="row" height={theme => theme.spacing(9)} alignItems="center">
        <Typography variant="h1">{title}</Typography>
      </Stack>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </Stack>
  );
};

displayName(SimpleLayout, 'SimpleLayout');

export default SimpleLayout;
