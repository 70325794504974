import { atom, selector, SetterOrUpdater, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ApiResponse } from '../api';
import { InvoicePeriodDTO, InvoicePeriodsDTO, YearsDTO } from '../dto';
import { apiClient } from './apiClient';
import { drilldownState } from './drilldown';
import { dataOrThrow } from './util';
import { accountState } from './user';
import { clientLanguageState } from './telcobill';

export function ascPeriodComparator(a: InvoicePeriodDTO, b: InvoicePeriodDTO): number {
  if (a.startDate < b.startDate) {
    return -1;
  } else if (a.startDate > b.startDate) {
    return 1;
  } else {
    return 0;
  }
}

export function descPeriodComparator(a: InvoicePeriodDTO, b: InvoicePeriodDTO): number {
  if (a.startDate < b.startDate) {
    return 1;
  } else if (a.startDate > b.startDate) {
    return -1;
  } else {
    return 0;
  }
}

const invoicePeriodsQuery = selector<InvoicePeriodsDTO>({
  key: 'invoicePeriods',
  get: async ({ get }) => {
    const account = get(accountState);
    const lang = get(clientLanguageState);
    const invoicePeriods = await get(apiClient(lang)).getInvoicePeriods(account.name);
    return dataOrThrow(invoicePeriods);
  },
});

const allInvoicePeriodsQuery = selector<InvoicePeriodsDTO>({
  key: 'allInvoicePeriods',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const invoicePeriods = await get(apiClient(lang)).getAllInvoicePeriods();
    return dataOrThrow(invoicePeriods);
  },
});

export const availableInvoicePeriodsQuery = selector<InvoicePeriodDTO[]>({
  key: 'availableInvoicePeriods',
  get: ({ get }) => {
    const periods = get(invoicePeriodsQuery);
    const year = get(invoiceYearState);
    return year === 'all' ? periods.invoicePeriods : periods.invoicePeriods.slice().filter(p => p.id.startsWith(year));
  },
});

export const allAvailableInvoicePeriodsQuery = selector<InvoicePeriodDTO[]>({
  key: 'allAvailableInvoicePeriods',
  get: ({ get }) => {
    const periods = get(allInvoicePeriodsQuery);
    const year = get(invoiceYearState);
    return year === 'all' ? periods.invoicePeriods : periods.invoicePeriods.slice().filter(p => p.id.startsWith(year));
  },
});

const defaultNewestInvoicePeriodQuery = selector<InvoicePeriodDTO>({
  key: 'defaultInvoicePeriod',
  get: ({ get }) => {
    const available = get(availableInvoicePeriodsQuery);
    return available.length > 0 ? available.slice().sort(descPeriodComparator)[0] : allInvoicePeriodsItem;
  },
});

export const useAvailableUnorderedInvoicePeriods = () => useRecoilValue(availableInvoicePeriodsQuery);

export const useAllUnorderedInvoicePeriods = () => useRecoilValue(allAvailableInvoicePeriodsQuery);

const invoiceYearsQuery = selector<ApiResponse<YearsDTO>>({
  key: 'invoiceYears',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getYears();
  },
});

export const useInvoiceYears = () => useRecoilValue(invoiceYearsQuery);

export const allInvoicePeriodsItem = { id: 'all', invoiceNos: [], startDate: '', endDate: '' };

export const newestInvoicePeriodState = atom<InvoicePeriodDTO>({
  key: 'invoicePeriod',
  default: defaultNewestInvoicePeriodQuery,
});

export const useNewestInvoicePeriod = (): [InvoicePeriodDTO, SetterOrUpdater<InvoicePeriodDTO>] => {
  const [newestInvoicePeriod, setNewestInvoicePeriod] = useRecoilState(newestInvoicePeriodState);
  const resetDrilldown = useResetRecoilState(drilldownState);
  const setter: SetterOrUpdater<InvoicePeriodDTO> = (
    valueOrUpdater: ((currVal: InvoicePeriodDTO) => InvoicePeriodDTO) | InvoicePeriodDTO
  ) => {
    setNewestInvoicePeriod(valueOrUpdater);
    resetDrilldown();
  };
  return [newestInvoicePeriod, setter];
};

const invoiceYearState = atom<string>({
  key: 'invoiceYear',
  default: 'all',
});

export const useInvoiceYear = (): [string, SetterOrUpdater<string>] => {
  const [invoiceYear, setInvoiceYear] = useRecoilState(invoiceYearState);
  const resetInvoicePeriod = useResetRecoilState(newestInvoicePeriodState);
  const resetDrilldown = useResetRecoilState(drilldownState);
  const setter: SetterOrUpdater<string> = (valueOrUpdater: ((currVal: string) => string) | string) => {
    setInvoiceYear(valueOrUpdater);
    resetInvoicePeriod();
    resetDrilldown();
  };
  return [invoiceYear, setter];
};

export const useLatestInvoicePeriod = (): InvoicePeriodDTO => {
  const invoicePeriods = useRecoilValue(invoicePeriodsQuery);
  return invoicePeriods.invoicePeriods.length > 0
    ? invoicePeriods.invoicePeriods[0]
    : { id: '', invoiceNos: [], startDate: '1970-01-01', endDate: '1970-01-31' };
};
