import React, { useCallback } from 'react';
import { useApi, useBlacklist, useRefreshBlacklist } from '../../globalState';
import { CustomerDTO } from '../../dto';
import TransferList, { Change } from '../TransferList';
import { checkForApiError, displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';

const CustomerBlacklist: React.FC = () => {
  const { assigned, available } = useBlacklist();
  const { t } = useTranslation();
  const api = useApi();
  const setEncounteredError = useSetEncounteredError();
  const refreshBlacklist = useRefreshBlacklist();

  const handleChange = useCallback(
    async ({ added, removed }: Change<CustomerDTO>) => {
      try {
        if (added.length > 0) {
          const response = await api.addBlacklistCustomer({ ids: added.map(c => c.id) });
          checkForApiError(response);
        } else {
          const response = await api.removeBlacklistCustomer({ ids: removed.map(c => c.id) });
          checkForApiError(response);
        }
        refreshBlacklist();
      } catch (err) {
        setEncounteredError(
          t('admin.administrators.blackList.error', {
            action: added.length > 0 ? t('action.assign') : t('action.remove'),
          }),
          toError(err),
          { api: added.length > 0 ? 'addBlacklistCustomer' : 'removeBlacklistCustomer' }
        );
      }
    },
    [api, refreshBlacklist, setEncounteredError, t]
  );

  return (
    <TransferList
      columns={[{ field: 'name', headerName: t('admin.administrators.blackList.customer') }]}
      availableTitle={t('admin.administrators.blackList.availableTitle')}
      availableItems={available}
      assignedTitle={t('admin.administrators.blackList.assignedTitle')}
      assignedItems={assigned}
      onChange={handleChange}
      marginTop={4}
    />
  );
};

displayName(CustomerBlacklist, 'CustomerBlacklist');

export default CustomerBlacklist;
