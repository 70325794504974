import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import Dialog from '../Dialog';
import { ActionButton, CloseButton, IconButton } from '../Button';
import { displayName } from '../../util';
import { DeleteOutlined } from '@mui/icons-material';
import { getI18n, useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import { SuspendedSpinner } from '../Spinner';

const nonHtmlProps = ['isDragActive', 'isDragAccept', 'isDragReject'];

export enum FileType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  PDF = 'PDF',
  EXCEL = 'Excel',
  CSV = 'CSV',
}

const fileTypesArray = [
  {
    key: 'Image',
    value: ['image/jpeg', 'image/png', 'image/webp'],
  },
  {
    key: 'Video',
    value: ['video/mp4', 'video/quicktime'],
  },
  {
    key: 'PDF',
    value: ['application/pdf'],
  },
  {
    key: 'Excel',
    value: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
  },
  {
    key: 'CSV',
    value: ['text/csv'],
  },
];

export const acceptedExtensions = (fileTypes: FileType[]): string => {
  const fileTypeList: string[] = fileTypes.flatMap((fileType: string) => {
    const matchingObject = fileTypesArray.find(obj => obj.key === fileType);
    return matchingObject ? matchingObject.value : [];
  });
  return fileTypeList.length === 0 ? '' : fileTypeList.length === 1 ? fileTypeList[0] : fileTypeList.join(', ');
};

function unfoldFileType(fileType: FileType): string[] {
  switch (fileType) {
    case 'Excel':
      return ['XLS-', 'XLSX-'];
    default:
      return [fileType.toString() + '-'];
  }
}

export function supportedFileTypesToText(supportedFileTypes?: FileType[]): string {
  const i18n = getI18n();

  if (supportedFileTypes === undefined) {
    throw new Error('No file type provided');
  } else {
    const unfoldedFileTypes: string[] = supportedFileTypes.flatMap(it => unfoldFileType(it));
    let message = '';
    for (let i = 0; i < unfoldedFileTypes.length; i++) {
      message += unfoldedFileTypes[i];
      if (i === unfoldedFileTypes.length - 2) {
        message += ` ${i18n?.t('text.or')} `;
      } else if (i !== unfoldedFileTypes.length - 1) {
        message += ', ';
      }
    }
    return message;
  }
}

const Dropzone = styled('div', { shouldForwardProp: prop => !nonHtmlProps.includes(prop.toString()) })<{
  isDragActive: boolean;
}>`
  background: ${({ theme }) => theme.palette.grey['200']};
  height: ${({ theme }) => theme.spacing(10)};
  border-radius: 4px;
  border-width: 2px;
  border-color: ${({ theme, isDragActive }) => (isDragActive ? theme.palette.primary.main : 'rgba(0, 0, 0, 0)')};
  border-style: dashed;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    width: ${({ theme }) => theme.spacing(8)};
    height: ${({ theme }) => theme.spacing(8)};
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  position: relative;
`;

const AcceptedFiles = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

interface Props {
  title: string;
  text?: React.ReactNode;
  note?: React.ReactNode;
  accept?: FileType[];
  onClose: () => void;
  upload: (file: File) => Promise<unknown>;
  onDropAccepted?: (files: File[]) => void;
}

const UploadDialog: React.FC<Props> = ({ title, text, note, accept = [], onClose, upload, onDropAccepted }) => {
  const { t } = useTranslation();
  const setEncounteredError = useSetEncounteredError();
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const acceptedExtensions = useMemo((): Accept => {
    return Object.fromEntries(
      accept.flatMap((fileType: string) => {
        const matchingObject = fileTypesArray.find(obj => obj.key === fileType);
        return matchingObject ? matchingObject.value.map(value => [value, []]) : [];
      })
    );
  }, [accept]);
  const handleOnDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    setRejectedFiles(fileRejections.length !== 0);
  }, []);
  const handleOnDropAccepted = useCallback(
    (files: File[], event: DropEvent) => {
      try {
        if (onDropAccepted) {
          onDropAccepted(files);
        }
        setFiles(files);
        setDisabled(false);
      } catch (err) {
        setEncounteredError(t('uploadDialog.validate'), toError(err), {}, false);
        setDisabled(true);
      }
    },
    [setEncounteredError, t, onDropAccepted]
  );
  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept: acceptedExtensions,
    onDrop: handleOnDrop,
    onDropAccepted: handleOnDropAccepted,
    maxFiles: 1,
  });

  const onUpload = useCallback(async () => {
    setUploading(true);
    await upload(files[0]);
    onClose();
  }, [upload, files, onClose]);
  const inputProps = getInputProps();
  inputProps.multiple = undefined;

  return (
    <Dialog header={<Typography variant="h2">{title}</Typography>} width="480px">
      <Stack direction="column" marginTop={4} gap={4}>
        <Dropzone {...getRootProps({ isDragAccept, isDragReject, isDragActive })}>
          <input {...inputProps} />
          <UploadIcon />
          <AcceptedFiles>
            {files.map((f, i) => (
              <Card key={i} style={{ cursor: 'default' }} onClick={e => e.stopPropagation()}>
                <Stack direction="row" paddingX={3} paddingY={2} justifyContent="space-between" alignItems="center">
                  <Typography>{f.name}</Typography>
                  <IconButton onClick={() => setFiles(files.filter(file => file !== f))}>
                    <DeleteOutlined fontSize="small" />
                  </IconButton>
                </Stack>
              </Card>
            ))}
          </AcceptedFiles>
        </Dropzone>
        {rejectedFiles && <Typography sx={{ fontWeight: 'bold' }}>{t('uploadDialog.rejectionMessage')}</Typography>}
        {text}
        {note}
        <SuspendedSpinner suspended={uploading} />
        <Stack direction="row" justifyContent="space-between">
          <CloseButton onClose={onClose} />
          <ActionButton onClick={onUpload} disabled={uploading || disabled || files.length === 0}>
            {t('dialog.upload')}
          </ActionButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

displayName(UploadDialog, 'UploadDialog');

export default UploadDialog;
