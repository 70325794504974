import React, { useCallback } from 'react';
import { Box, Checkbox, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import WizardStepLayout from '../WizardStepLayout';
import FormItem from '../FormItem';
import { displayName } from '../../util';
import { useFormikContext } from 'formik';
import { CustomReportData, SendEmailButton } from './CustomReportWizard';
import Text from '../Text';
import { useTranslation } from 'react-i18next';
import { useQueryErrorMessage } from '../../globalState/reports';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormikTextInput from '../formik/FormikTextInput';
import EmailStatusTable from './EmailStatusTable';
import { ReportRecipientDTO } from '../../dto';

interface Props {
  sendEmail: boolean;
  onSendEmailChange: (sendEmail: boolean) => void;
  isBatchMail: boolean;
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
  batchField?: string;
  recipients?: ReportRecipientDTO[];
}

const CustomReportWizardStep1: React.FC<Props> = ({
  sendEmail,
  onSendEmailChange,
  isBatchMail,
  disabledSendEmail,
  triggerSendEmail,
  batchField,
  recipients,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<CustomReportData>();
  const queryErrorMessage = useQueryErrorMessage();

  const handleSendEmailChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFieldValue('dispatchType', checked ? 'SINGLE' : 'NONE');
      onSendEmailChange(checked);
    },
    [onSendEmailChange, setFieldValue]
  );

  return (
    <WizardStepLayout title={t('sidebar.customReport.step1.title')}>
      <Stack direction="column" gap={4}>
        <FormikTextInput
          label={t('sidebar.customReport.step1.nameLabel')}
          fieldName={'reportName'}
          textAlignEnd={true}
          boldLabel={true}
          textWidth={10}
          inputWidth={12}
          gap={4}
        />
        <FormItem label={t('sidebar.customReport.step1.dispatchLabel')}>
          <Checkbox checked={sendEmail} onChange={handleSendEmailChange} sx={{ padding: 0 }} />
          <Text>{t('sidebar.customReport.step1.dispatchText')}</Text>
        </FormItem>
        {queryErrorMessage && (
          <FormItem>
            <QueryErrorInfo queryErrorMessage={queryErrorMessage} />
          </FormItem>
        )}
        {sendEmail && (
          <FormItem>
            <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
          </FormItem>
        )}
        {sendEmail && recipients && recipients.length !== 0 && (
          <>
            <Text weight={'700'}>{t('sidebar.customReport.step1.informationLabel')}</Text>
            <EmailStatusTable isBatchMail={isBatchMail} batchField={batchField} recipients={recipients} />
          </>
        )}
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep1, 'CustomReportWizardStep1');

export default CustomReportWizardStep1;

interface QueryErrorInfoProps {
  queryErrorMessage: string;
}
const QueryErrorInfo: React.FC<QueryErrorInfoProps> = ({ queryErrorMessage }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const tooltipText = (
    <Typography
      sx={{
        whiteSpace: 'pre-line',
        color: 'white',
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px',
        lineHeight: '16px',
      }}
    >
      {t('sidebar.customReport.queryError.tooltip', { errorMessages: queryErrorMessage })}
    </Typography>
  );

  return (
    <Stack direction={'row'} alignItems={'center'} gap={3}>
      <WarningAmberIcon sx={{ fill: 'orange', fontSize: '32px' }} />
      <Text wrapText={true} width={'27'}>
        {t('sidebar.customReport.queryError.text')}
      </Text>
      <Box>
        <Tooltip title={tooltipText}>
          <InfoOutlinedIcon sx={{ fill: theme.palette.info.main, fontSize: '32px' }} />
        </Tooltip>
      </Box>
    </Stack>
  );
};
