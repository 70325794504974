import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { noop } from 'lodash';
import { displayName } from '../util';

const shouldForwardProp = (prop: string) => prop !== 'width' && prop !== 'height' && prop !== 'grow';

export const StyledTextField = styled(TextField, { shouldForwardProp } as never)<{
  width?: number | string;
  height?: number | string;
  grow: boolean;
  hasError?: boolean;
}>`
  box-sizing: border-box;
  ${({ theme, width }) =>
    width === undefined ? '' : `width: ${typeof width === 'number' ? theme.spacing(width) : width};`}
  ${({ theme, height }) =>
    height === undefined
      ? ''
      : `height: ${typeof height === 'number' ? theme.spacing(height) : height}; max-height: ${typeof height === 'number' ? theme.spacing(height) : height};`}
  ${({ grow }) => (grow ? `flex-grow: 1;` : '')}
  .MuiOutlinedInput-root {
    padding: 0;
    ${({ hasError }) => (hasError ? `margin-bottom: 10px` : '')}
  }
  .MuiInputBase-input {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 28px;
    font-size: 11px;
    white-space: nowrap;
    margin-left: 0;
  }
`;

interface Props {
  type?: 'text' | 'password';
  placeholder?: string;
  width?: number | string;
  height?: number | string;
  grow?: boolean;
  multiline?: boolean;
  rows?: number;
  value?: string;
  onChange?: (value: string) => void;
}

const TextInput: React.FC<Props> = ({
  type = 'text',
  placeholder,
  width,
  height,
  grow = false,
  multiline,
  rows,
  value,
  onChange = noop,
}) => {
  return (
    <StyledTextField
      type={type}
      placeholder={placeholder}
      width={width}
      height={height}
      grow={grow}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={(e: { target: { value: string | number } }) => onChange(e.target.value)}
    />
  );
};

displayName(TextInput, 'TextInput');

export default TextInput;
