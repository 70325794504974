import React from 'react';
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AdditionalProfileMenuItemType, AnalysisComponentType, EntityDTO, UserViewType, ViewDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { DEFAULT_LANGUAGE } from './variables';
import { kebabCase } from 'lodash';

export const clientLanguageState = atom<string>({
  key: 'clientLanguageState',
  default: DEFAULT_LANGUAGE,
});

export const useClientLanguageState = () => useRecoilState(clientLanguageState);

const mutationState = atom<boolean>({
  key: 'mutationState',
  default: false,
});

export const useMutationState = () => useRecoilValue(mutationState);

type MutationFunction = () => Promise<unknown>;
type RefreshFunction = () => void;
type ErrorHandler = (error: unknown) => void;
type MutationTracker = (
  mutation: MutationFunction,
  errorHandler?: ErrorHandler,
  refresher?: RefreshFunction
) => Promise<void>;

export const useMutationTracker = (): MutationTracker => {
  const [, setMutating] = useRecoilState(mutationState);
  return async (mutation, errorHandler, refresher) => {
    try {
      setMutating(true);
      await mutation();
    } catch (e) {
      if (errorHandler) {
        errorHandler(e);
      } else {
        throw e;
      }
    } finally {
      setMutating(false);
      if (refresher) {
        refresher();
      }
    }
  };
};

const entities = selector<EntityDTO[]>({
  key: 'entities',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return dataOrThrow(await get(apiClient(lang)).getEntities()).entities;
  },
});

export const useEntities = () => useRecoilValue(entities);

export interface TelcobillView {
  path: string;
  matcher: RegExp;
  type: UserViewType;
  name: string;
  label: string;
  entity?: string;
  components: AnalysisComponentType[];
}

const getPath = (v: ViewDTO): string => {
  switch (v.type) {
    case 'OVERVIEW':
      return '/:lng/tb/user/overview';
    case 'BILL_DRILLDOWN':
      return '/:lng/tb/user/bills';
    case 'DATA_ANALYSIS':
      return `/:lng/tb/user/analysis/${kebabCase(v.entity)}/:type`;
    case 'COST_CENTER_CONFIGURATION':
      return '/:lng/tb/user/costcenters';
    case 'TAG_CONFIGURATION':
      return '/:lng/tb/user/tags';
  }
};

const getMatcher = (v: ViewDTO): RegExp => {
  switch (v.type) {
    case 'OVERVIEW':
      return RegExp('^/([a-z]{2})/tb/user/overview$');
    case 'BILL_DRILLDOWN':
      return RegExp('^/[a-z]{2}/tb/user/bills$');
    case 'DATA_ANALYSIS':
      return RegExp(`^/[a-z]{2}/tb/user/analysis/${kebabCase(v.entity)}/[a-z]*$`);
    case 'COST_CENTER_CONFIGURATION':
      return RegExp('^/[a-z]{2}/tb/user/costcenters$');
    case 'TAG_CONFIGURATION':
      return RegExp('^/[a-z]{2}/tb/user/tags$');
  }
};

export const viewsState = selector<TelcobillView[]>({
  key: 'views',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = dataOrThrow(await get(apiClient(lang)).getViews());
    return response.views
      .filter(v => v.enabled)
      .map(v => ({
        path: getPath(v),
        matcher: getMatcher(v),
        type: v.type,
        name: v.name,
        label: v.label,
        entity: v.entity,
        components: v.components,
      }));
  },
});

export const useViews = () => useRecoilValue(viewsState);

export interface AdminView {
  label: string;
  absolutPath: string;
  relativePath: string;
  matcher: RegExp;
  element: React.ReactNode;
}

const adminViews = atom<AdminView[]>({
  key: 'adminViews',
  default: [],
});

export const useAdminViews = () => useRecoilValue(adminViews);

export const useSetAdminViews = () => useSetRecoilState(adminViews);

const additionalProfileMenuItems = atom<AdditionalProfileMenuItemType[]>({
  key: 'additionalProfileMenuItems',
  default: [],
});

export const useAdditionalProfileMenuItems = () => useRecoilValue(additionalProfileMenuItems);

export const useSetAdditionalProfileMenuItems = () => useSetRecoilState(additionalProfileMenuItems);
