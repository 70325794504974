import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Spinner from './components/Spinner';
import LoadingOverlay from './components/LoadingOverlay';
import './i18n';
import { LocalizationProvider, ThemeProvider } from './Mui';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './pages/NotAvailable';
import { StyledContainer } from './components/Toast';

const container = document.getElementById('app') as Element;
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <ThemeProvider>
      <LocalizationProvider>
        <Suspense fallback={<Spinner />}>
          <StyledContainer />
          <LoadingOverlay />
          <Router>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <DndProvider backend={HTML5Backend}>
                <title>Cost Manager</title>
                <App />
              </DndProvider>
            </ErrorBoundary>
          </Router>
        </Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  </RecoilRoot>
);
