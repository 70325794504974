import { atom, selector, selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { ApiResponse } from '../api';
import { fieldsQuery } from './analysis';
import { apiClient } from './apiClient';
import { useLoadableApiValue } from './util';
import { fromPairs, get as _get } from 'lodash';
import { accountState } from './user';
import { useCallback } from 'react';
import { availableInvoicePeriodsQuery, newestInvoicePeriodState } from './invoice';
import { clientLanguageState } from './telcobill';
import { currentPageState } from './pagination';
import { DRILLDOWN_TABLE_PAGE_SIZE } from './variables';
import {
  ConditionElementDTO,
  DrilldownFieldMappingDTO,
  DrilldownLevelDTO,
  DrilldownLevelsDTO,
  DrilldownPathDTO,
  FieldDTO,
  QueryMetaInformationDTO,
  QueryObjectDTO,
  QueryResultDTO,
} from '../dto';

const accountCondition = (entity: string, field: string, accountIds: string[]): ConditionElementDTO => ({
  type: 'combined',
  logicalOperator: 'OR_OPERATOR',
  conditionElements: accountIds.map(accountId => ({
    type: 'field',
    field: {
      entityName: entity,
      name: field,
    },
    value: accountId,
    conditionComparator: 'EQUAL_COMPARATOR',
  })),
});

const rawValue = (element: DrilldownPathElement, fieldName: string): unknown =>
  _get(element.selection, `__raw.${fieldName}`);

const multiValueCondition = (entity: string, field: string, values: unknown[]): ConditionElementDTO => ({
  type: 'combined',
  logicalOperator: 'OR_OPERATOR',
  conditionElements: values.map(value => ({
    type: 'field',
    field: {
      entityName: entity,
      name: field,
    },
    value,
    conditionComparator: 'EQUAL_COMPARATOR',
  })),
});

const drilldownCondition = (entity: string, field: string, value: unknown): ConditionElementDTO =>
  Array.isArray(value)
    ? multiValueCondition(entity, field as string, value)
    : {
        type: 'field',
        field: {
          entityName: entity,
          name: field,
        },
        value,
        conditionComparator: 'EQUAL_COMPARATOR',
      };

const drilldownConditions = (element: DrilldownPathElement): ConditionElementDTO[] =>
  element.path?.mapping
    .map(m => [m.to, rawValue(element, m.from)])
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => drilldownCondition(element.level.entityName as string, field as string, value)) || [];

const accountFieldQuery = selectorFamily<string, string>({
  key: 'accountField',
  get:
    entity =>
    ({ get }) =>
      get(fieldsQuery({ entity, property: 'AccountField' })).fields[0].name,
});

export const useAccountField = (entity: string) => useRecoilValue(accountFieldQuery(entity));

const invoiceNoFieldQuery = selectorFamily<string, string>({
  key: 'invoiceNoField',
  get:
    entity =>
    ({ get }) =>
      get(fieldsQuery({ entity, property: 'InvoiceNoField' })).fields[0].name,
});

export const useInvoiceNoField = (entity: string) => useRecoilValue(invoiceNoFieldQuery(entity));

const downloadFieldsQuery = selectorFamily<FieldDTO[], string>({
  key: 'downloadFields',
  get:
    entity =>
    ({ get }) =>
      get(fieldsQuery({ entity, property: 'download' }))
        .fields.slice()
        .sort((a, b) => (a.downloadPosition ?? 999) - (b.downloadPosition ?? 999)),
});

export const useDownloadFields = (entity: string) => useRecoilValue(downloadFieldsQuery(entity));

const drilldownTableMetaInfoState = selector<QueryMetaInformationDTO>({
  key: 'drilldownTableMetaInfoState',
  get: ({ get }) => {
    const currentPage = get(currentPageState('DrilldownView'));
    return {
      beginIndex: currentPage * DRILLDOWN_TABLE_PAGE_SIZE,
      endIndex: (currentPage + 1) * DRILLDOWN_TABLE_PAGE_SIZE,
    };
  },
});

const drilldownQueryObject = selector<QueryObjectDTO>({
  key: 'drilldownQueryObject',
  get: ({ get }) => {
    const drilldown = get(drilldownState);
    const drilldownElement = drilldown[drilldown.length - 1];
    const entity = drilldownElement.level.entityName as string;
    const accountField = get(accountFieldQuery(entity));
    const account = get(accountState);
    let invoiceCondition: ConditionElementDTO[] = [];
    if (drilldownElement.level.top) {
      const invoiceNoField = get(invoiceNoFieldQuery(entity));
      invoiceCondition = [
        drilldownCondition(
          drilldownElement.level.entityName as string,
          invoiceNoField,
          _get(drilldownElement.selection, `__raw.${invoiceNoField}`)
        ),
      ];
    }
    return {
      drilldownLevel: drilldownElement.level.name,
      drilldownPayload: drilldownElement.level.drilldownOnTotal ? drilldownElement.selection : undefined,
      entityElements: [entity],
      selectElements: drilldownElement.level.fields.map(f => ({
        entityName: f.entityName as string,
        name: f.name,
      })),
      groupElements: drilldownElement.level.fields
        .filter(f => f.group && (!f.groupOnPayload || rawValue(drilldownElement, f.name) !== undefined))
        .map(f => ({
          entityName: f.entityName as string,
          name: f.name,
        })),
      conditionElement: {
        type: 'combined',
        logicalOperator: 'AND_OPERATOR',
        conditionElements: [accountCondition(entity, accountField, [account.name + ''])]
          .concat(invoiceCondition)
          .concat(drilldownConditions(drilldownElement)),
      },
      sortElements: [],
      metaInformation: get(drilldownTableMetaInfoState),
    };
  },
});

const drilldownTableQuery = selector<ApiResponse<QueryResultDTO>>({
  key: 'drilldownTable',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).query(get(drilldownQueryObject));
  },
});

export const useDrilldownTable = () => useLoadableApiValue(drilldownTableQuery);

const levelsQuery = selector<ApiResponse<DrilldownLevelsDTO>>({
  key: 'drilldownLevels',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getDrilldownLevels();
  },
});

export interface DrilldownPathElement {
  level: DrilldownLevelDTO;
  path?: DrilldownPathDTO;
  selection: Record<string, unknown>;
}

const defaultPathElementQuery = selector<DrilldownPathElement[]>({
  key: 'defaultDrilldownPathElement',
  get: ({ get }) => {
    const level = get(levelsQuery).data?.levels.find(l => l.top);
    if (level) {
      const invoice = get(newestInvoicePeriodState);
      const invoiceField = get(invoiceNoFieldQuery(level.entityName as string));
      const selection: Record<string, unknown> = {};
      selection[invoiceField] =
        invoice.invoiceNos.length === 0
          ? get(availableInvoicePeriodsQuery)
              .map(p => p.invoiceNos.map(n => Number(n)))
              .flatMap(x => x)
          : invoice.invoiceNos.map(n => Number(n));
      selection['__raw'] = selection;
      return [{ level, selection }];
    }
    return [];
  },
});

export const drilldownState = atom<DrilldownPathElement[]>({
  key: 'drilldown',
  default: defaultPathElementQuery,
});

interface DrilldownResult {
  elements: DrilldownPathElement[];
  current: DrilldownPathElement;
  goDown: (levelName: string, record?: Record<string, unknown>) => void;
  goUp: (level: string) => void;
}

const mapSelection = (mapping: DrilldownFieldMappingDTO[], selection: Record<string, unknown>) => {
  const mappedSelection = fromPairs(
    mapping.map(m => [m.from, selection[m.from]]).filter(([, value]) => value !== undefined)
  );
  mappedSelection['__raw'] = fromPairs(
    mapping.map(m => [m.from, _get(selection, `__raw.${m.from}`)]).filter(([, value]) => value !== undefined)
  );
  return mappedSelection;
};

export const useDrilldown = (): DrilldownResult => {
  const [drilldown, setDrilldown] = useRecoilState(drilldownState);
  const levels = useRecoilValue(levelsQuery);
  const current = drilldown[drilldown.length - 1];
  const goDown = useCallback(
    (levelName: string, record?: Record<string, unknown>) => {
      const level = levels.data?.levels.find(l => l.name === levelName);
      const path = current.level.paths.find(p => p.level === levelName);
      if (!!level && !!path) {
        const selection = mapSelection(path.mapping, record ?? current.selection);
        setDrilldown(d => d.concat({ level, path, selection }));
      }
    },
    [current?.level.paths, current.selection, levels.data?.levels, setDrilldown]
  );
  const goUp = useCallback(
    (level: string) => {
      setDrilldown(d => {
        const index = d.findIndex(el => el.level.name === level);
        return index >= 0 ? d.slice(0, index + 1) : d;
      });
    },
    [setDrilldown]
  );
  return {
    elements: drilldown,
    current,
    goDown,
    goUp,
  };
};
