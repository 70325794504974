import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { FilterState } from '../../globalState';
import Text from '../Text';
import TextInput from '../TextInput';
import { displayName } from '../../util';

interface Props {
  filterState: FilterState;
  onChange: (state: FilterState) => void;
}

const value = (newValue: string): number | undefined => {
  const parsed = Number(newValue);
  return Number.isNaN(parsed) ? undefined : parsed;
};

const RangeFilter: React.FC<Props> = ({ filterState, onChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const defaultValue = filterState.field.filterDefaultValue || '';
  const defaultValueParts = defaultValue.split(' ');
  const defaultFrom = defaultValueParts[0];
  const defaultTo = defaultValueParts.length > 1 ? defaultValueParts[1] : '';
  const localValueFrom = (filterState.rangeFrom ?? defaultFrom) + '';
  const localValueTo = (filterState.rangeTo ?? defaultTo) + '';
  const handleChange = (field: 'from' | 'to', newValue: string) => {
    onChange({
      ...filterState,
      rangeFrom: field === 'from' ? value(newValue) : filterState.rangeFrom,
      rangeTo: field === 'to' ? value(newValue) : filterState.rangeTo,
    });
  };
  useEffect(() => {
    // Treat default values same as editing
    onChange({
      ...filterState,
      rangeFrom: value(localValueFrom),
      rangeTo: value(localValueTo),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.field.name]);
  return (
    <Stack direction="column" gap={3}>
      <Stack direction="row" alignItems="center" gap={3}>
        <Text width={6}>{t('sidebar.filter.from')}</Text>
        <TextInput
          width={8}
          height={theme.custom.additionalFilters.customHeight}
          value={localValueFrom}
          onChange={v => handleChange('from', v)}
        />
        <Text>{filterState.field.unitLabel || ''}</Text>
      </Stack>
      <Stack direction="row" alignItems="center" gap={3}>
        <Text width={6}>{t('sidebar.filter.to')}</Text>
        <TextInput
          width={8}
          height={theme.custom.additionalFilters.customHeight}
          value={localValueTo}
          onChange={v => handleChange('to', v)}
        />
        <Text>{filterState.field.unitLabel || ''}</Text>
      </Stack>
    </Stack>
  );
};

displayName(RangeFilter, 'RangeFilter');

export default RangeFilter;
