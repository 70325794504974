import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterPanel from './FilterPanel';
import AdditionalFilter from './AdditionalFilter';
import { useApplyFilters, useFilterIds, useResetFilter, useSetCurrentPageToZero } from '../../globalState';
import { IconButton } from '../Button';
import { displayName, useEntity } from '../../util';
import { AnalysisFilterComponentProps } from './AnalysisFilterComponent';

const AdditionalFilters: React.FC<AnalysisFilterComponentProps> = () => {
  const entity = useEntity();
  const resetFilter = useResetFilter();
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const applyFilters = useApplyFilters();
  const [filterIds, setFilterIds, nextId] = useFilterIds();
  const { t } = useTranslation();

  const deleteFilter = (filterId: number) => {
    setAnalysisPageToZero();
    resetFilter(filterId);
    const newFilterIds = filterIds.slice();
    newFilterIds.splice(filterIds.indexOf(filterId), 1);
    setFilterIds(newFilterIds.length === 0 ? [1] : newFilterIds);
    applyFilters();
  };
  const handleAdd = () => {
    setAnalysisPageToZero();
    setFilterIds(filterIds.concat(nextId));
  };

  return (
    <FilterPanel name={`additional-${entity}`} title={t('sidebar.filter.additional.title')} paddingBottom="8px">
      <Stack width="100%" spacing={3}>
        <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem />}>
          {filterIds.map(id => (
            <React.Fragment key={id}>
              <AdditionalFilter filterId={id} onDelete={() => deleteFilter(id)} />
            </React.Fragment>
          ))}
        </Stack>
        <Stack direction="row" spacing={2}>
          <IconButton sx={{ width: '20px' }} onClick={handleAdd}>
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
          <Typography>{t('sidebar.filter.additional.createLabel')}</Typography>
        </Stack>
      </Stack>
    </FilterPanel>
  );
};

displayName(AdditionalFilters, 'AdditionalFilters');

export default AdditionalFilters;
