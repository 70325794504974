import React from 'react';
import { Backdrop } from '@mui/material';
import Spinner from './Spinner';
import { useMutationState } from '../globalState';
import { displayName } from '../util';

const LoadingOverlay: React.FC = () => {
  const mutating = useMutationState();
  return mutating ? (
    <Backdrop open={true}>
      <Spinner />
    </Backdrop>
  ) : null;
};

displayName(LoadingOverlay, 'LoadingOverlay');

export default LoadingOverlay;
