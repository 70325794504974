import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useOutlet } from 'react-router';
import { HealthChecker } from '../components/HealthChecker';
import { displayName, useToErrorBoundary } from '../util';
import InformationDialog from '../components/dialog/InformationDialog';
import { toError } from '../components';
import { useTranslation } from 'react-i18next';
import { useSetSuccessMessage, useSuccessMessage } from '../components/Success';
import { dataOrThrow, useApi, useSetAdditionalProfileMenuItems } from '../globalState';
import { ExpirationChecker } from '../components/ExpirationChecker';
import { useEncounteredError } from '../components/Error';
import { ErrorInformation } from '../components/ErrorInformation';
import NotFound from './NotFound';

const TelcoBill: React.FC = () => {
  const api = useApi();
  const setAdditionalProfileMenuItems = useSetAdditionalProfileMenuItems();
  const encounteredError = useEncounteredError();
  const successMessage = useSuccessMessage();
  const setSuccessMessage = useSetSuccessMessage();
  const toErrorBoundary = useToErrorBoundary();
  const outlet = useOutlet();
  const { t } = useTranslation();
  const [checkProfileMenu, setCheckProfileMenu] = useState(true);

  useEffect(() => {
    if (checkProfileMenu) {
      (async () => {
        try {
          const additionalProfileMenuItemsDTO = dataOrThrow(await api.getAdditionalProfileMenuItems());
          setAdditionalProfileMenuItems(additionalProfileMenuItemsDTO.additionalProfileMenuItems);
        } catch (err) {
          toErrorBoundary(
            new Error(`Error occurred while getting additional profile menu items because of:\n${toError(err)}`)
          );
        } finally {
          setCheckProfileMenu(false);
        }
      })();
    }
  }, [api, checkProfileMenu, setAdditionalProfileMenuItems, toErrorBoundary]);
  const handleSuccessDialogClose = useCallback(() => {
    setSuccessMessage(undefined);
  }, [setSuccessMessage]);

  if (outlet === null) {
    return <NotFound />;
  } else {
    return (
      <>
        <HealthChecker />
        <ExpirationChecker />
        {encounteredError !== undefined && <ErrorInformation encounteredError={encounteredError} />}
        {successMessage !== undefined && (
          <InformationDialog
            title={''}
            onClick={handleSuccessDialogClose}
            buttonLabel={t('dialog.ok')}
            body={successMessage}
          />
        )}
        <Outlet />
      </>
    );
  }
};

displayName(TelcoBill, 'TelcoBill');

export default TelcoBill;
