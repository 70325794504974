import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const successMessage = atom<string | undefined>({
  key: 'successMessage',
  default: undefined,
});

export const useSuccessMessage = () => useRecoilValue(successMessage);

export const useSetSuccessMessage = () => useSetRecoilState(successMessage);
