import { styled } from '@mui/material/styles';
import { Card, Stack } from '@mui/material';
import React from 'react';
import { CardProps } from '@mui/material/Card/Card';

export const RoundGreyStack = styled(Stack)`
  background: ${({ theme }) => theme.palette.grey['200']};
  border-radius: 4px;
`;

export const CentralizedDiv = styled('div')`
  margin: auto;
`;

const StyledCard = styled(Card)`
  width: 400px;
`;

export const PageCard: React.FC<CardProps> = props => {
  return <StyledCard elevation={1} {...props} />;
};
