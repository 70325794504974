import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import LogoutIcon from '@mui/icons-material/Logout';
import { DEFAULT_LOGOUT_PAGE, SUPPORTED_LANGUAGES, useAdditionalProfileMenuItems, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { IconButton } from '../Button';
import { displayName } from '../../util';
import { AccountCircleOutlined } from '@mui/icons-material';
import ChangePasswordOverlay from './ChangePasswordOverlay';
import { useSetEncounteredError } from '../Error';
import { SuspendedSpinner } from '../Spinner';
import { CONTACT_PAGE } from '../../env';

interface Props {
  basepath?: string;
}

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverGrey.main} !important;
    color: ${({ theme }) => theme.palette.hover.main} !important;
    fill: ${({ theme }) => theme.palette.hover.main} !important;
  }
  &:hover > * > * {
    color: ${({ theme }) => theme.palette.hover.main} !important;
    fill: ${({ theme }) => theme.palette.hover.main} !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0 !important;
`;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    padding: 0 !important;
  }
  .MuiMenuItem-root,
  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.text.primary};
  }
`;

const ProfileMenu: React.FC<Props> = ({ basepath = '' }) => {
  const api = useApi();
  const navigate = useNavigate();
  const additionalProfileMenuItems = useAdditionalProfileMenuItems();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const { pathname } = useLocation();
  const { lng } = useParams();
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [suspended, setSuspended] = useState(false);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleLanguageClick = (lang: string) => {
    handleMenuClose();
    const path = pathname.substring(`${basepath}/${lng}`.length);
    navigate(`${basepath}/${lang}${path}`);
  };
  const handleLogout = async () => {
    const response = await api.logout(language, false);
    const newUrl = response?.data?.redirect ?? DEFAULT_LOGOUT_PAGE.replace(':lng', language);
    window.location.replace(newUrl);
    handleMenuClose();
  };
  const showChangePassword = useMemo(
    () => additionalProfileMenuItems.find(it => it === 'CHANGE_PASSWORD') !== undefined,
    [additionalProfileMenuItems]
  );
  const handleChangePasswordOpen = useCallback(() => {
    handleMenuClose();
    setChangePasswordOpen(true);
  }, []);
  const handleChangePasswordClose = useCallback(() => setChangePasswordOpen(false), []);
  const handleManualDownload = async () => {
    function handleManualDownloadFailure() {
      setEncounteredError(t('menu.manual.error'), new Error('ManualDownloadError'), {
        api: 'downloadManual',
      });
    }
    function handleManualeDownloadWaitingState(isWaiting: boolean) {
      setSuspended(isWaiting);
    }
    await api.downloadManual(handleManualDownloadFailure, handleManualeDownloadWaitingState);
    handleMenuClose();
  };
  const handleContact = useCallback(() => {
    window.open(CONTACT_PAGE, '_blank');
  }, []);

  return (
    <Stack direction={'row'} gap={5}>
      <IconButton onClick={e => setMenuAnchor(e.currentTarget as never)} style={{ width: '36px', height: '36px' }}>
        <AccountCircleOutlined style={{ width: '28px', height: '28px' }} />
      </IconButton>
      <StyledMenu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
        {SUPPORTED_LANGUAGES.map((lang, i) =>
          lang.value === language ? (
            <StyledMenuItem key={i}>
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              {lang.label}
            </StyledMenuItem>
          ) : (
            <StyledMenuItem key={i} onClick={() => handleLanguageClick(lang.value)}>
              <ListItemText inset={true}>{lang.label}</ListItemText>
            </StyledMenuItem>
          )
        )}
        <StyledDivider />
        <StyledMenuItem onClick={handleMenuClose}>
          <ListItemText inset={true} onClick={handleManualDownload}>
            {t('menu.manual.label')}
          </ListItemText>
          <SuspendedSpinner suspended={suspended} />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleMenuClose}>
          <ListItemText inset={true} onClick={handleContact}>
            {t('menu.contact')}
          </ListItemText>
        </StyledMenuItem>
        <StyledDivider />
        {showChangePassword && (
          <StyledMenuItem onClick={handleChangePasswordOpen}>
            <ListItemText inset={true}>{t('changePassword.title')}</ListItemText>
          </StyledMenuItem>
        )}
        {additionalProfileMenuItems.length !== 0 && <StyledDivider />}
        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('menu.logout')}
        </StyledMenuItem>
      </StyledMenu>
      {showChangePassword && (
        <ChangePasswordOverlay open={changePasswordOpen} handleClose={handleChangePasswordClose} />
      )}
    </Stack>
  );
};

displayName(ProfileMenu, 'ProfileMenu');

export default ProfileMenu;
