import React, { useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Dialog from '../Dialog';
import { ActionButton } from '../Button';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { SuspendedSpinner } from '../Spinner';

interface Props {
  title: string;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
  customButtonText?: string;
  customBody?: React.ReactElement;
}

const ConfirmationDialog: React.FC<Props> = ({ title, text, onConfirm, onCancel, customButtonText, customBody }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [suspended, setSuspended] = useState(false);

  const buttonText = customButtonText !== undefined ? customButtonText : t('dialog.delete');
  const handleConfirm = useCallback(() => {
    setDisabled(true);
    setSuspended(true);
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog header={<Typography variant="h2">{title}</Typography>} width="600px">
      <Stack direction="column" marginTop={4} gap={4}>
        {customBody ? customBody : <Typography>{text}</Typography>}
        <SuspendedSpinner suspended={suspended} />
        <Stack direction="row" justifyContent="space-between" gap={4}>
          <ActionButton variant="outlined" onClick={onCancel} disabled={disabled}>
            {t('dialog.cancel')}
          </ActionButton>
          <ActionButton onClick={handleConfirm} disabled={disabled}>
            {buttonText}
          </ActionButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

displayName(ConfirmationDialog, 'ConfirmationDialog');

export default ConfirmationDialog;
