import React from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../useDocumentTitle';
import { Stack, Typography } from '@mui/material';
import { displayName } from '../util';
import { PageCard } from '../components/Container';

const Logout: React.FC = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('application.shortName') + ' - ' + t('logout.title'));

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard>
        <Stack direction="column" padding={4}>
          <Typography variant={'body1'}>{t('logout.text')}</Typography>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(Logout, 'Logout');

export default Logout;
