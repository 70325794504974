import { Button, ButtonProps, IconButton } from '@mui/material';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { displayName } from '../util';
import { useTranslation } from 'react-i18next';

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => !['width'].includes(prop.toString()),
})<{
  width?: number;
}>`
  height: 32px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  text-transform: none;
  ${({ theme, width }) => (width === undefined ? '' : `width: ${theme.spacing(width)};`)}
  ${({ theme, color }) => (color === 'secondary' ? `background: ${theme.palette.grey['300']};` : '')}
  ${({ theme, color }) => (color === 'secondary' ? `color: ${theme.palette.text.primary};` : '')}
  &:hover {
    ${({ variant, theme }) => (variant === 'contained' ? `background-color: ${theme.palette.hover.main};` : '')}
    ${({ variant, theme }) => (variant === 'outlined' ? `color: ${theme.palette.hover.main};` : '')}
    ${({ variant, theme }) => (variant === 'outlined' ? `border-color: ${theme.palette.hover.main};` : '')}
  }
  &:active {
    ${({ variant, theme }) => (variant === 'contained' ? `background-color: ${theme.palette.pressed.main};` : '')}
    ${({ variant, theme }) => (variant === 'outlined' ? `color: ${theme.palette.pressed.main};` : '')}
    ${({ variant, theme }) => (variant === 'outlined' ? `border-color: ${theme.palette.pressed.main};` : '')}
  }
`;

displayName(StyledButton, 'Button');

export default StyledButton;

interface Props extends ButtonProps {
  width?: number;
  children?: React.ReactNode;
}

const ActionButton: React.FC<Props> = props => {
  return <StyledButton variant="contained" disableElevation={true} {...props} />;
};

displayName(ActionButton, 'ActionButton');

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => (prop as string) !== 'customSize',
})<{
  customSize?: number | string;
}>`
  padding: 0;
  .MuiSvgIcon-root {
    fill: ${({ theme, color }) => (color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main)};
  }
  &:disabled {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.action.disabled};
    }
  }
  ${({ theme, customSize }) =>
    customSize === undefined
      ? ''
      : `width: ${typeof customSize === 'string' ? customSize : theme.spacing(customSize)};`}
  ${({ theme, customSize }) =>
    customSize === undefined
      ? ''
      : `height: ${typeof customSize === 'string' ? customSize : theme.spacing(customSize)};`}
`;

displayName(StyledIconButton, 'IconButton');

interface UploadButtonProps extends Props {
  mimeType: string;
  onUpload: (file: File) => void;
}

const StyledInput = styled('input')`
  display: none;
`;

const UploadButton: React.FC<UploadButtonProps> = ({ mimeType, onUpload, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);
  const id = `upload-button-${Math.floor(Math.random() * 100000)}`;
  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length === 1) {
        const file = e.target.files[0];
        onUpload(file);
        if (ref.current) {
          ref.current.value = '';
        }
      }
    },
    [onUpload]
  );
  return (
    <label htmlFor={id}>
      <StyledInput ref={ref} accept={mimeType} id={id} multiple={false} type="file" onChange={handleFileChange} />
      <ActionButton variant="outlined" disableElevation={true} component="span" {...rest} />
    </label>
  );
};

displayName(UploadButton, 'UploadButton');

export { ActionButton, StyledIconButton as IconButton, UploadButton };

interface CloseButtonProps {
  onClose: () => void;
  disabled?: boolean;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClose, disabled }) => {
  const { t } = useTranslation();

  return (
    <ActionButton variant="outlined" onClick={onClose} disabled={disabled}>
      {t('dialog.cancel')}
    </ActionButton>
  );
};
