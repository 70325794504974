import { InputLabel, InputLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLabel = styled(InputLabel)`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const Label: React.FC<InputLabelProps> = props => {
  return <StyledLabel variant="standard" {...props} />;
};

export default Label;
