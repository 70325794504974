import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { ToastIcon, ToastOptions } from 'react-toastify/dist/types';
import { Bounce, Id, toast, ToastContainer } from 'react-toastify';
import { TOAST_DURATION } from '../globalState';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const StyledContainer = styled(ToastContainer)`
  .Toastify__progress-bar--error {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
  .Toastify__progress-bar--warning {
    background-color: ${({ theme }) => theme.palette.warning.main};
  }
  .Toastify__progress-bar--success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
  .Toastify__progress-bar--info {
    background-color: ${({ theme }) => theme.palette.info.main};
  }
  .Toastify__toast-icon {
    margin-right: 23px;
  }
`;

const Message = (toastText: string) => {
  return (
    <div className="msg-container">
      <Typography className="msg-description" variant={'body1'}>
        {toastText}
      </Typography>
    </div>
  );
};

type ToastType = 'error' | 'warning' | 'info' | 'success';

const ErrorIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack>
      <InfoOutlinedIcon sx={{ fill: theme.palette.error.main, fontSize: '32px' }} />
    </Stack>
  );
};

const WarningIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack>
      <InfoOutlinedIcon sx={{ fill: theme.palette.warning.main, fontSize: '32px' }} />
    </Stack>
  );
};

const InfoIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack>
      <InfoOutlinedIcon sx={{ fill: theme.palette.info.main, fontSize: '32px' }} />
    </Stack>
  );
};

const SuccessIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack>
      <InfoOutlinedIcon sx={{ fill: theme.palette.success.main, fontSize: '32px' }} />
    </Stack>
  );
};

function createCustomIcon(toastType: ToastType): ToastIcon {
  switch (toastType) {
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon />;
    case 'success':
      return <SuccessIcon />;
    default:
      return <></>;
  }
}

function createToastOptions(autoClose: boolean, toastType: ToastType): ToastOptions {
  const closeOption = autoClose ? TOAST_DURATION : false;

  return {
    autoClose: closeOption,
    hideProgressBar: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    transition: Bounce,
    icon: createCustomIcon(toastType),
  };
}

export const customToaster = (toastText: string, autoClose: boolean): Id =>
  toast(Message(toastText), createToastOptions(autoClose, 'error'));

customToaster.info = (toastText: string, autoClose: boolean): Id =>
  toast.info(Message(toastText), createToastOptions(autoClose, 'info'));
customToaster.error = (toastText: string, autoClose: boolean): Id =>
  toast.error(Message(toastText), createToastOptions(autoClose, 'error'));
customToaster.success = (toastText: string, autoClose: boolean): Id =>
  toast.success(Message(toastText), createToastOptions(autoClose, 'success'));
customToaster.warning = (toastText: string, autoClose: boolean): Id =>
  toast.warning(Message(toastText), createToastOptions(autoClose, 'warning'));
