import React, { useCallback, useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';
import { Stack, Typography } from '@mui/material';
import DraggableListItem from './DraggableListItem';
import CustomDragLayer from './CustomDragLayer';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

interface Props<T> {
  items: T[];
  onChange?: (items: T[]) => void;
  emptyMessage?: string;
  width?: number | string;
}

export interface ListItemBase {
  id: string;
  label: string;
}

const DraggableList = <T extends ListItemBase>({
  items,
  onChange = noop,
  emptyMessage,
  width,
}: Props<T>): React.ReactElement | null => {
  const [localItems, setLocalItems] = useState(items);
  const [innerWidth, setInnerWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const findItem = useCallback(
    (id: string) => {
      const item = localItems.filter(it => it.id === id)[0] as {
        id: string;
        label: string;
      };
      return {
        item,
        index: localItems.indexOf(item as never),
      };
    },
    [localItems]
  );
  const handleDelete = useCallback(
    (id: string) => {
      const copy = items.slice();
      copy.splice(findItem(id).index, 1);
      onChange(copy);
    },
    [findItem, items, onChange]
  );
  const handleMove = useCallback(
    (id: string, atIndex: number) => {
      const { item, index } = findItem(id);
      setLocalItems(
        update(localItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, item as never],
          ],
        })
      );
    },
    [findItem, localItems, setLocalItems]
  );
  const handleCommit = useCallback(() => onChange(localItems), [onChange, localItems]);
  const [, drop] = useDrop(() => ({ accept: 'ListItem' }));
  useEffect(() => {
    drop(ref);
    if (ref.current) {
      setInnerWidth(ref.current.clientWidth - 16);
    }
  }, [setInnerWidth, drop]);
  useEffect(() => setLocalItems(items), [setLocalItems, items]);

  return (
    <Stack
      ref={ref}
      direction="column"
      gap={2}
      padding={3}
      bgcolor={theme => theme.palette.grey[200]}
      borderRadius="4px"
      width={typeof width === 'number' ? theme => theme.spacing(width) : width}
    >
      {localItems.map(item => (
        <DraggableListItem
          key={item.id}
          id={item.id}
          label={item.label}
          findItem={findItem}
          onDelete={handleDelete}
          onMove={handleMove}
          onCommit={handleCommit}
        />
      ))}
      {localItems.length === 0 && <Typography>{emptyMessage ?? 'No items selected'}</Typography>}
      <CustomDragLayer width={innerWidth} findItem={findItem} />
    </Stack>
  );
};

export default DraggableList;
