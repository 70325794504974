import React, { useMemo } from 'react';
import { CostCenterDTO } from '../../dto';
import { createTrie, TreeNode, TreeView } from '../treeView';
import { noop } from 'lodash';
import { Stack } from '@mui/material';
import { displayName } from '../../util';

const wrap = (cc: CostCenterDTO, showNonVirtualOnly: boolean | undefined): TreeNode<CostCenterDTO> => ({
  id: cc.costCenterId + '',
  label: cc.name,
  value: cc,
  children: cc.costCenters.filter(i => !(showNonVirtualOnly && i.virtual)).map(i => wrap(i, showNonVirtualOnly)),
});

export type ProcessStateType = 'ongoing' | 'success' | 'failure';

export interface ProcessState {
  processState: ProcessStateType;
  error?: Error;
}

interface Props {
  root: CostCenterDTO;
  selectedCostCenter: CostCenterDTO;
  onSelectionChange: (selected: CostCenterDTO) => void;
  enableDnd?: boolean;
  onCostCenterMove?: (source: CostCenterDTO, target: CostCenterDTO) => void;
  showNonVirtualOnly?: boolean;
}

const CostCenterTree: React.FC<Props> = ({
  root,
  selectedCostCenter,
  onSelectionChange,
  enableDnd = false,
  onCostCenterMove = noop,
  showNonVirtualOnly,
}) => {
  const rootNode = useMemo(() => wrap(root, showNonVirtualOnly), [root, showNonVirtualOnly]);
  const trie = useMemo(() => {
    return createTrie(rootNode);
  }, [rootNode]);

  return (
    <>
      <Stack direction="column" width="100%" spacing={3}>
        <TreeView
          root={rootNode}
          treeKey={'costCenter'}
          selectedNode={wrap(selectedCostCenter, showNonVirtualOnly)}
          onSelectionChange={selected => onSelectionChange(selected.value)}
          enableDnd={enableDnd}
          onDrop={onCostCenterMove}
          trie={trie}
        />
      </Stack>
    </>
  );
};

displayName(CostCenterTree, 'CostCenterTree');

export default CostCenterTree;
