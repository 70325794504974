import React, { useMemo, useRef } from 'react';
import { DefaultRawDatum, PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { Stack, Typography, useTheme } from '@mui/material';
import { ChartItemDTO } from '../../dto';
import Text from '../Text';
import { get } from 'lodash';
import { displayName } from '../../util';
import { ChartDownloadComponent, DownloadContainer } from './ChartDownloadComponent';
import SimpleCard from 'components/SimpleCard';
import { CHART_COLOR_SCHEME } from '../../globalState/variables';

type FormatterType = (value: number) => string;

const Tooltip: React.FC<PieTooltipProps<DefaultRawDatum>> = ({ datum }) => {
  return (
    <SimpleCard>
      <Stack direction="column" padding={3}>
        <Text>{datum.data.id}</Text>
        <Stack direction="row" gap={4}>
          <Text>{get(datum.data, 'labels.valueAxis')}</Text>
          <Text weight="500">{datum.formattedValue}</Text>
        </Stack>
      </Stack>
    </SimpleCard>
  );
};

interface Props {
  data: ChartItemDTO[];
  valueAxisLabel: string;
  valueFormatter?: FormatterType;
  title?: string;
}

const PieChart: React.FC<Props> = ({ data, valueFormatter, title }) => {
  const theme = useTheme();

  const chart = useRef<HTMLDivElement | null>(null);
  const pieData = useMemo(
    () => data.map(item => ({ id: item.category, value: item.value, labels: get(item, 'labels') })),
    [data]
  );

  return (
    <DownloadContainer ref={chart}>
      <ChartDownloadComponent chart={chart} />
      {title && (
        <Typography variant={'h1'} sx={{ textAlign: 'center', marginTop: '15px' }}>
          {title}
        </Typography>
      )}
      <ResponsivePie
        data={pieData}
        colors={CHART_COLOR_SCHEME}
        padAngle={1}
        cornerRadius={4}
        innerRadius={0.3}
        borderColor={theme.palette.primary.main}
        borderWidth={1}
        valueFormat={valueFormatter}
        margin={{ top: 50, right: 1, bottom: 100, left: 1 }}
        theme={{
          text: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
          },
          axis: {
            legend: {
              text: {
                fontWeight: 500,
              },
            },
          },
        }}
        activeOuterRadiusOffset={15}
        enableArcLinkLabels={false}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: -500,
            itemWidth: 58,
            itemHeight: 24,
            itemsSpacing: 0,
            symbolSize: 11,
            itemDirection: 'left-to-right',
          },
        ]}
        tooltip={Tooltip}
      />
    </DownloadContainer>
  );
};

displayName(PieChart, 'PieChart');

export default PieChart;
