import React from 'react';
import { Tooltip } from '@mui/material';
import { displayName } from '../util';

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
}

const NonInteractiveTooltip: React.FC<Props> = ({ title, children }) => {
  return (
    <Tooltip title={title} disableInteractive={true} enterDelay={300} enterNextDelay={300}>
      {children}
    </Tooltip>
  );
};

displayName(NonInteractiveTooltip, 'NonInteractiveTooltip');

export default NonInteractiveTooltip;
