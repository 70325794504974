import React from 'react';
import { Box, CircularProgress, Stack, Tooltip, useTheme } from '@mui/material';
import { displayName } from '../util';
import { useTranslation } from 'react-i18next';

interface SpinnerProps {
  size?: number;
}

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>((props: SpinnerProps, ref) => {
  const theme = useTheme();
  const { size } = props;

  return (
    <Stack ref={ref} justifyContent="center" alignItems="center" position={'static'} height="100%">
      <CircularProgress size={size ? theme.spacing(size) : undefined} />
    </Stack>
  );
});

displayName(Spinner, 'Spinner');

export default Spinner;

interface SuspendedSpinnerProps {
  className?: string;
  suspended: boolean;
}
export const SuspendedSpinner: React.FC<SuspendedSpinnerProps> = ({ className, suspended }) => {
  return suspended ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} marginTop={4} className={className}>
      <CircularProgress />
    </Box>
  ) : (
    <></>
  );
};

interface DownloadSpinnerProps {
  size: number;
  suspended: boolean;
}
export const DownloadSpinner: React.FC<DownloadSpinnerProps> = ({ size, suspended }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: suspended ? 'flex' : 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip title={t('tooltip.downloadInProgress')}>
        <CircularProgress size={size} />
      </Tooltip>
    </Box>
  );
};
