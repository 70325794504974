import * as XLSX from 'xlsx';
import download from 'downloadjs';

export const downloadXLSX = (columns: string[], data: Record<string, string>[], filename: string) => {
  const wb = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(data, { header: columns, skipHeader: true });
  XLSX.utils.book_append_sheet(wb, sheet);
  const file = XLSX.write(wb, { type: 'buffer', bookType: 'xlsx' });
  download(file, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};

export const uploadXLSX = async <T extends Record<string, unknown>>(file: File, keys: (keyof T)[]): Promise<T[]> => {
  const data = await file.arrayBuffer();
  const wb = XLSX.read(data);
  return XLSX.utils.sheet_to_json<T>(wb.Sheets[wb.SheetNames[0]], { header: keys as string[] }).map(r => ({ ...r }));
};
