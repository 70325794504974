import React, { useContext, useState } from 'react';
import { Stack, ToggleButtonGroup } from '@mui/material';
import SidebarLayout from '../../SidebarLayout';
import HeaderItem from '../../components/HeaderItem';
import CostCenterTreeEditor from '../../components/costcenter/CostCenterTreeEditor';
import CostCenterDefinition from '../../components/costcenter/CostCenterDefinition';
import CostCenterAccounts from '../../components/costcenter/CostCenterAccounts';
import CostCenterSubscriptions from '../../components/costcenter/CostCenterSubscriptions';
import CostCenterUsers from '../../components/costcenter/CostCenterUsers';
import ToggleButton from '../../components/ToggleButton';
import { CostCenterDTO } from '../../dto';
import { useCostCenters, useUser } from '../../globalState';
import { displayName, useView } from '../../util';
import { useTranslation } from 'react-i18next';
import SimpleCard from '../../components/SimpleCard';
import useDocumentTitle from '../../useDocumentTitle';

interface CostCenterContextProps {
  selectedCostCenter: CostCenterDTO;
  setSelectedCostCenter: (costCenter: CostCenterDTO) => void;
}

const CostCenterContext = React.createContext<CostCenterContextProps>({} as never);

export const useCostCenterContext = () => useContext(CostCenterContext);

const CostCenters: React.FC = () => {
  const rootCostCenter = useCostCenters();
  const user = useUser();
  const view = useView();
  const { t } = useTranslation();
  const [selectedCostCenter, setSelectedCostCenter] = useState(rootCostCenter);
  const [costCenterView, setCostCenterView] = useState('definition');

  useDocumentTitle(t('application.shortName') + ' - ' + view.label);
  const handleViewChange = (_: unknown, view: string) => {
    if (!!view) {
      setCostCenterView(view);
    }
  };

  return (
    <CostCenterContext.Provider value={{ selectedCostCenter, setSelectedCostCenter }}>
      <SidebarLayout title={view.label} sidebarContent={<CostCenterTreeEditor showNonVirtualOnly={true} />}>
        <SimpleCard>
          <Stack direction="row" alignItems="center" height={theme => theme.spacing(7)} gap={7} paddingX={4}>
            <HeaderItem label={t('costCenters.header.label')} value={selectedCostCenter.name} />
            <HeaderItem label={t('costCenters.header.toggle.user')} value={`${user.firstName} ${user.lastName}`} />
            <ToggleButtonGroup color="primary" exclusive={true} value={costCenterView} onChange={handleViewChange}>
              <ToggleButton value="definition">{t('costCenters.header.toggle.definition')}</ToggleButton>
              <ToggleButton value="accounts">{t('costCenters.header.toggle.accounts')}</ToggleButton>
              <ToggleButton value="subscriptions">{t('costCenters.header.toggle.subscriptions')}</ToggleButton>
              <ToggleButton value="users">{t('costCenters.header.toggle.users')}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </SimpleCard>
        <Stack marginTop={4}>
          {costCenterView === 'definition' && <CostCenterDefinition />}
          {costCenterView === 'accounts' && <CostCenterAccounts />}
          {costCenterView === 'subscriptions' && <CostCenterSubscriptions rootCostCenter={rootCostCenter} />}
          {costCenterView === 'users' && <CostCenterUsers />}
        </Stack>
      </SidebarLayout>
    </CostCenterContext.Provider>
  );
};

displayName(CostCenters, 'CostCenters');

export default CostCenters;
