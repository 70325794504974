import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Stack, useTheme } from '@mui/material';
import TextInput from '../TextInput';
import { FilterState } from '../../globalState';
import { displayName } from '../../util';

interface Props {
  filterState: FilterState;
  onChange: (state: FilterState) => void;
  startsWith: boolean;
}

const TextFilter: React.FC<Props> = ({ filterState, onChange, startsWith }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack direction="column">
      <TextInput
        placeholder={t('sidebar.filter.additional.placeholder')}
        width={12}
        height={theme.custom.additionalFilters.customHeight}
        value={filterState.text ?? ''}
        onChange={text => onChange({ ...filterState, text })}
      />
      {startsWith && (
        <FormControlLabel
          control={
            <Checkbox
              checked={filterState.startsWith ?? false}
              onChange={(_, startsWith) => onChange({ ...filterState, startsWith })}
            />
          }
          label={t('sidebar.filter.additional.startsWith') as string}
        />
      )}
    </Stack>
  );
};

displayName(TextFilter, 'TextFilter');

export default TextFilter;
