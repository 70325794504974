import React, { useCallback } from 'react';
import WizardStepLayout from '../WizardStepLayout';
import RichTextEditor from '../RichTextEditor';
import { useFormikContext } from 'formik';
import { CustomReportData, SendEmailButton } from './CustomReportWizard';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

interface Props {
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
}

const CustomReportWizardStep3: React.FC<Props> = ({ disabledSendEmail, triggerSendEmail }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CustomReportData>();
  const handleChange = useCallback((html: string) => setFieldValue('customText', html), [setFieldValue]);
  return (
    <WizardStepLayout title={t('sidebar.customReport.step3.title')}>
      <RichTextEditor
        height={12}
        placeholder={t('sidebar.customReport.step3.title')}
        initialValue={values.customText}
        onChange={handleChange}
      />
      <Stack marginTop={4} alignItems={'start'}>
        <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep3, 'CustomReportWizardStep3');

export default CustomReportWizardStep3;
