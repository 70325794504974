/* eslint-disable prettier/prettier */
import { Dictionary, fromPairs, keyBy, range } from 'lodash';
import { GridColumnVisibilityModel, GridSortDirection } from '@mui/x-data-grid-pro';
import { clientLanguageState, TelcobillView, useViews } from './telcobill';
import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { ApiResponse } from '../api';
import {
  ConditionElementDTO,
  CustomReportDTO,
  FieldDTO,
  MailTemplateDTO,
  OverviewReportDTO,
  QueryFieldDTO,
  QueryObjectDTO,
  SystemReportDTO,
} from '../dto';
import { apiClient } from './apiClient';
import { useEntity } from '../util';
import { dataOrThrow } from './util';
import { currentPageState, useSetCurrentPageToZero } from './pagination';
import {
  analysisTableAllFieldQuery,
  analysisTableFieldState,
  analysisTableSortState,
  appliedFiltersState,
  determineNewAnalysisTableFields,
  fieldsQuery,
  filterIdsState,
  filterPanelState,
  filterState,
  FilterState,
  GroupIndexType,
  persistFilterStateInBrowserHistory,
  serializeFilter,
  tableGroupState,
  useAllAnalysisTableFields,
  useRefreshCurrentAggregationAnalysisTableFields,
  useSetDataGridUpdate,
  useVisibleGroupingsAfterReportSelectionCallback,
} from './analysis';

const emptyCustomReport = { id: 0, name: '--' } as CustomReportDTO;
const emptySystemReport = { id: 0, name: '--' } as SystemReportDTO;

const customReportsQuery = selectorFamily<CustomReportDTO[], string>({
  key: 'customReports',
  get:
    entity =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      const reports = dataOrThrow(await get(apiClient(lang)).getCustomReports(entity)).reports;
      return reports.length > 0 ? reports : [emptyCustomReport];
    },
});

export const useDefaultReport = () => {
  const reports = useCustomReports();
  const defaultReport = reports.filter(it => it.costCenterId === null);
  if (defaultReport.length === 0) {
    throw new Error('The default report was not found');
  } else {
    //entity 3 level reports have multiple default reports with no costCenterId, currently the first one is the default
    //report
    return defaultReport[0];
  }
};

export const useCustomReports = () => {
  const entity = useEntity();
  return useRecoilValue(customReportsQuery(entity));
};

export const useResetAndRefreshCustomReports = () => {
  const entity = useEntity();
  return useRecoilCallback(
    ({ refresh, reset }) =>
      () => {
        refresh(customReportsQuery(entity));
        reset(selectedCustomReport(entity));
      },
    [entity]
  );
};

export const useRefreshCustomReports = () => {
  const entity = useEntity();
  return useRecoilCallback(
    ({ refresh }) =>
      () => {
        refresh(customReportsQuery(entity));
      },
    [entity]
  );
};

const systemReportsQuery = selectorFamily<SystemReportDTO[], string>({
  key: 'systemReports',
  get:
    entity =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      const reports = dataOrThrow(await get(apiClient(lang)).getSystemReports(entity)).reports;
      return reports.length > 0 ? reports : [emptySystemReport];
    },
});

export const useSystemReports = () => {
  const entity = useEntity();
  return useRecoilValue(systemReportsQuery(entity));
};

const defaultSelectedSystemReport = selectorFamily<SystemReportDTO, string>({
  key: 'defaultSelectedSystemReport',
  get:
    entity =>
    ({ get }) =>
      get(systemReportsQuery(entity))[0],
});

const selectedSystemReport = atomFamily<SystemReportDTO, string>({
  key: 'selectedSystemReport',
  default: defaultSelectedSystemReport,
});

export const useSelectedSystemReport = () => {
  const entity = useEntity();
  return useRecoilState(selectedSystemReport(entity));
};

const defaultSelectedCustomReport = selectorFamily<CustomReportDTO, string>({
  key: 'defaultSelectedCustomReport',
  get:
    entity =>
    ({ get }) =>
      get(customReportsQuery(entity))[0],
});

const selectedCustomReport = atomFamily<CustomReportDTO, string>({
  key: 'selectedCustomReport',
  default: defaultSelectedCustomReport,
});

export const useSelectedCustomReport = () => {
  const entity = useEntity();
  return useRecoilState(selectedCustomReport(entity));
};

export const useGetSelectedCustomReport = () => {
  const entity = useEntity();
  return useRecoilValue(selectedCustomReport(entity));
};

export const useSetSelectedCustomReport = () => {
  const entity = useEntity();
  const setSelectedCustomReport = useSetRecoilState(selectedCustomReport(entity));

  return (report: CustomReportDTO) => setSelectedCustomReport(report);
};

const mailTemplateQuery = selectorFamily<ApiResponse<MailTemplateDTO>, string>({
  key: 'mailTemplate',
  get:
    name =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).getMailTemplate(name);
    },
});

export const useMailTemplate = (name: string) => dataOrThrow(useRecoilValue(mailTemplateQuery(name)));

const leafConditions = (cond: ConditionElementDTO): ConditionElementDTO[] => {
  if (cond.type === 'combined' && cond.logicalOperator === 'AND_OPERATOR') {
    return cond.conditionElements ? cond.conditionElements.flatMap(leafConditions) : [];
  }
  return [cond];
};

const conditionToFilter =
  (fieldsByName: Dictionary<FieldDTO>) =>
  (cond: ConditionElementDTO): FilterState[] => {
    if (cond.type === 'field' && cond.field) {
      const field = fieldsByName[cond.field.name];
      const value = (cond.value ?? '') + '';
      if (!field || !value) {
        return [];
      }
      if (field.filterType === 'choice' || field.filterType === 'multiSelectFilter') {
        return [
          {
            field,
            choice: [value],
          },
        ];
      }
      return [
        {
          field,
          text: value,
        },
      ];
    }
    if (cond.type === 'range' && cond.field) {
      const field = fieldsByName[cond.field.name];
      if (!field) {
        return [];
      }
      return [
        {
          field,
          rangeFrom: Number.isNaN(Number(cond.fromValue))
            ? undefined
            : field.conversionFactor
              ? Number(cond.fromValue) / field.conversionFactor
              : Number(cond.fromValue),
          rangeTo: Number.isNaN(Number(cond.toValue))
            ? undefined
            : field.conversionFactor
              ? Number(cond.toValue) / field.conversionFactor
              : Number(cond.toValue),
        },
      ];
    }
    if (cond.type === 'map' && cond.field) {
      const field = fieldsByName[cond.field.name];
      if (!field) {
        return [];
      }
      return [
        {
          field,
          choice: cond.values ? cond.values.map(v => v + '') : [],
        },
      ];
    }
    if (
      cond.type === 'combined' &&
      cond.conditionElements &&
      cond.conditionElements.length > 0 &&
      cond.conditionElements[0].field
    ) {
      const field = fieldsByName[cond.conditionElements[0].field.name];
      if (!field) {
        return [];
      }
      if (field.filterType === 'textField') {
        return cond.conditionElements.map(c => ({
          field,
          text: c.value + '',
        }));
      }
      return [
        {
          field,
          choice: cond.conditionElements.map(c => c.value + ''),
        },
      ];
    }
    return [];
  };

export const filtersFromQuery = (qo: QueryObjectDTO, fields: FieldDTO[]): FilterState[] => {
  const fieldsByName = keyBy(fields, 'name');
  return leafConditions(qo.conditionElement).flatMap(conditionToFilter(fieldsByName));
};

export const useApplyReport = () => {
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const setCustomReport = useSetSelectedCustomReport();
  const setQueryErrorMessage = useSetQueryErrorMessage();
  const applyReportQuery = useApplyReportQuery();
  const setVisibleGroupings = useVisibleGroupingsAfterReportSelectionCallback();
  const allFields = useAllAnalysisTableFields();
  const setDataGridUpdate = useSetDataGridUpdate();
  const refreshFields = useRefreshCurrentAggregationAnalysisTableFields();

  return async (selectedReport: CustomReportDTO, queryObject: QueryObjectDTO) => {
    const isGrouping = queryObject.groupElements !== undefined && queryObject.groupElements.length > 0;
    setVisibleGroupings.callback(queryObject.groupElements ? Math.max(queryObject.groupElements?.length, 1) : 1);

    const groupingElements = queryObject.groupElements;
    let visibleSelectElements = queryObject.selectElements;
    let otherAggregationSelectElements = allFields.filter(it => it.aggregated !== isGrouping);
    const allColumnSet = new Set(allFields.map(it => it.name));
    //When the user has created a grouping, the grouped columns should also appear, even though they are a non-grouped
    //field
    let additionalVisibleNonAggregateElements: QueryFieldDTO[] = [];
    if (isGrouping && groupingElements) {
      const newVisibleSelect = new Set(groupingElements.map(it => it.name));
      //To prevent duplicate assignment to the fields that are used in the grouping, they are filtered out
      visibleSelectElements = visibleSelectElements.filter(it => !newVisibleSelect.has(it.name));
      otherAggregationSelectElements = otherAggregationSelectElements.filter(it => !newVisibleSelect.has(it.name));
      groupingElements.forEach(it => allColumnSet.delete(it.name));

      additionalVisibleNonAggregateElements = groupingElements;
    }
    //The invisible columns of the current aggregation type have to be determined
    const visibleColumnsNameArray = visibleSelectElements.map(it => it.name);
    visibleColumnsNameArray.forEach(it => allColumnSet.delete(it));
    otherAggregationSelectElements.forEach(it => allColumnSet.delete(it.name));
    const invisibleSelectElements = Array.from(allColumnSet);
    //Initialize the three column visibility models. The order in which the different types are stored does not matter
    const visibleAdditionalCurrentAggregationColumns = additionalVisibleNonAggregateElements.map(it => [it.name, true]);
    const visibleCurrentAggregationColumns = visibleSelectElements.map(it => [it.name, true]);
    const invisibleCurrentAggregationColumns = invisibleSelectElements.map(it => [it, false]);
    const otherAggregationColumns = otherAggregationSelectElements.map(it => [it.name, it.visible]);

    const columnVisibilityModel = fromPairs([
      ...visibleAdditionalCurrentAggregationColumns,
      ...visibleCurrentAggregationColumns,
      ...invisibleCurrentAggregationColumns,
      ...otherAggregationColumns,
    ]);

    //the columnOrderModel should always contain all possible columns because the column model determines which columns can
    // show up. And by convention, it should start with the non-aggregate fields and be followed by the aggregated fields
    //That means, when the user wants to store a report with a mixed aggregate and non-aggregate field order, it will
    //not be stored in that manner.
    let columnOrderModel: string[];
    if (isGrouping) {
      columnOrderModel = [
        ...otherAggregationSelectElements.map(it => it.name),
        ...additionalVisibleNonAggregateElements.map(it => it.name),
        ...visibleSelectElements.map(it => it.name),
        ...invisibleSelectElements,
      ];
    } else {
      columnOrderModel = [
        ...additionalVisibleNonAggregateElements.map(it => it.name),
        ...visibleSelectElements.map(it => it.name),
        ...invisibleSelectElements,
        ...otherAggregationSelectElements.map(it => it.name),
      ];
    }

    //For the column order, the order in which things are stored matters.
    setCurrentPageToZero();
    setCustomReport(selectedReport);
    setQueryErrorMessage(selectedReport.errorMessage);
    await applyReportQuery(queryObject, columnVisibilityModel, columnOrderModel);
    setDataGridUpdate({ columnVisibilityModel: columnVisibilityModel, columnOrderModel: columnOrderModel });
    refreshFields();
  };
};

export const useApplyReportQuery = () => {
  const transaction = useRecoilTransaction_UNSTABLE(
    ({ set, reset }) =>
      (
        queryObject: QueryObjectDTO,
        filterFields: FieldDTO[],
        allAnalysisTableFields: FieldDTO[],
        columnVisibilityModel: GridColumnVisibilityModel,
        columnOrderModel: string[]
      ) => {
        const entity = queryObject.entityElements[0];

        // Reset sort, filter and group
        reset(analysisTableSortState(entity));
        reset(appliedFiltersState(entity));
        reset(filterIdsState(entity));
        reset(tableGroupState({ entity, index: '1' }));
        reset(tableGroupState({ entity, index: '2' }));
        reset(tableGroupState({ entity, index: '3' }));
        // Set filters
        const filters = filtersFromQuery(queryObject, filterFields);
        set(appliedFiltersState(entity), filters);
        set(filterIdsState(entity), range(1, filters.length + 1));
        filters.forEach((f, i) => set(filterState({ entity, filterId: i + 1 }), f));
        persistFilterStateInBrowserHistory(filters.map(serializeFilter));
        if (filters.length > 0) {
          set(filterPanelState(`additional-${entity}`), true);
        }
        // Set group
        if (queryObject.groupElements && queryObject.groupElements.length > 0) {
          const index: GroupIndexType[] = ['1', '2', '3'];
          for (let i = 0; i < 3 && i < queryObject.groupElements.length; i++) {
            set(tableGroupState({ entity, index: index[i] }), queryObject.groupElements[i].name);
          }
        }
        // Set sort
        if (queryObject.sortElements && queryObject.sortElements.length > 0) {
          const sortDirection: GridSortDirection =
            queryObject.sortElements[0].sortOperator === 'ASC_SORT' ? 'asc' : 'desc';
          set(analysisTableSortState(entity), [{ field: queryObject.sortElements[0].field.name, sort: sortDirection }]);
        }
        //Set select
        const newAnalysisTableFields = determineNewAnalysisTableFields(
          allAnalysisTableFields,
          columnVisibilityModel,
          columnOrderModel
        );
        set(analysisTableFieldState(entity), newAnalysisTableFields);
      },
    []
  );
  return useRecoilCallback(
    ({ snapshot }) =>
      async (
        queryObject: QueryObjectDTO,
        columnVisibilityModel: GridColumnVisibilityModel,
        columnOrderModel: string[]
      ) => {
        const entity = queryObject.entityElements[0];
        const filterFields = (await snapshot.getPromise(fieldsQuery({ entity, property: 'filter' }))).fields ?? [];
        const allAnalysisTableFields = await snapshot.getPromise(analysisTableAllFieldQuery(entity));
        transaction(queryObject, filterFields, allAnalysisTableFields, columnVisibilityModel, columnOrderModel);
      },
    [transaction]
  );
};

export const reportQueryErrorMessage = atom<string | null>({
  key: 'reportQueryErrorMessage',
  default: null,
});

export const useSetQueryErrorMessage = () => useSetRecoilState(reportQueryErrorMessage);

export const useQueryErrorMessage = () => useRecoilValue(reportQueryErrorMessage);

export const useApplyOverviewReport = () => {
  const views = useViews();
  const applyReportQuery = useApplyReportQuery();
  const setVisibleGroupings = useVisibleGroupingsAfterReportSelectionCallback();
  const setDataGridUpdate = useSetDataGridUpdate();

  return useRecoilCallback(
    ({ set, refresh, snapshot }) =>
      async (overviewReport: OverviewReportDTO) => {
        const entity = overviewReport.entityName;
        const view = views.find(view => view.entity === entity) as TelcobillView;
        const selectedReport = overviewReport.report;
        const allFields = await snapshot.getPromise(analysisTableAllFieldQuery(entity));

        const isGrouping =
          selectedReport.queryObject.groupElements !== undefined && selectedReport.queryObject.groupElements.length > 0;

        const groupingElements = selectedReport.queryObject.groupElements;
        let visibleSelectElements = selectedReport.queryObject.selectElements;
        let otherAggregationSelectElements = allFields.filter(it => it.aggregated !== isGrouping);
        const allColumnSet = new Set(allFields.map(it => it.name));
        //When the user has created a grouping, the grouped columns should also appear, even though they are a non-grouped
        //field
        let additionalVisibleNonAggregateElements: QueryFieldDTO[] = [];
        if (isGrouping && groupingElements) {
          const newVisibleSelect = new Set(groupingElements.map(it => it.name));
          //To prevent duplicate assignment to the fields that are used in the grouping, they are filtered out
          visibleSelectElements = visibleSelectElements.filter(it => !newVisibleSelect.has(it.name));
          otherAggregationSelectElements = otherAggregationSelectElements.filter(it => !newVisibleSelect.has(it.name));
          groupingElements.forEach(it => allColumnSet.delete(it.name));

          additionalVisibleNonAggregateElements = groupingElements;
        }
        //The invisible columns of the current aggregation type have to be determined
        const visibleColumnsNameArray = visibleSelectElements.map(it => it.name);
        visibleColumnsNameArray.forEach(it => allColumnSet.delete(it));
        otherAggregationSelectElements.forEach(it => allColumnSet.delete(it.name));
        const invisibleSelectElements = Array.from(allColumnSet);
        //Initialize the three column visibility models. The order in which the different types are stored does not matter
        const visibleAdditionalCurrentAggregationColumns = additionalVisibleNonAggregateElements.map(it => [
          it.name,
          true,
        ]);
        const visibleCurrentAggregationColumns = visibleSelectElements.map(it => [it.name, true]);
        const invisibleCurrentAggregationColumns = invisibleSelectElements.map(it => [it, false]);
        const otherAggregationColumns = otherAggregationSelectElements.map(it => [it.name, it.visible]);

        const columnVisibilityModel: GridColumnVisibilityModel = fromPairs([
          ...visibleAdditionalCurrentAggregationColumns,
          ...visibleCurrentAggregationColumns,
          ...invisibleCurrentAggregationColumns,
          ...otherAggregationColumns,
        ]);

        //the columnOrderModel should always contain all possible columns because the column model determines which columns can
        // show up. And by convention, it should start with the non-aggregate fields and be followed by the aggregated fields
        //That means, when the user wants to store a report with a mixed aggregate and non-aggregate field order, it will
        //not be stored in that manner.
        let columnOrderModel: string[];
        if (isGrouping) {
          columnOrderModel = [
            ...otherAggregationSelectElements.map(it => it.name),
            ...additionalVisibleNonAggregateElements.map(it => it.name),
            ...visibleSelectElements.map(it => it.name),
            ...invisibleSelectElements,
          ];
        } else {
          columnOrderModel = [
            ...additionalVisibleNonAggregateElements.map(it => it.name),
            ...visibleSelectElements.map(it => it.name),
            ...invisibleSelectElements,
            ...otherAggregationSelectElements.map(it => it.name),
          ];
        }

        set(currentPageState(view.name), 0);
        set(selectedCustomReport(entity), selectedReport);
        set(reportQueryErrorMessage, selectedReport.errorMessage);
        await applyReportQuery(selectedReport.queryObject, columnVisibilityModel, columnOrderModel);
        setDataGridUpdate({ columnVisibilityModel: columnVisibilityModel, columnOrderModel: columnOrderModel });
        setVisibleGroupings.callback(
          selectedReport.queryObject.groupElements ? Math.max(selectedReport.queryObject.groupElements?.length, 1) : 1
        );
        // refresh(analysisTableCurrentAggregationFieldQuery(entity));
      },
    [applyReportQuery, setDataGridUpdate, setVisibleGroupings, views]
  );
};
