import React from 'react';
import { Stack, Typography } from '@mui/material';
import { displayName } from '../util';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const WizardStepLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <Stack direction="column">
      <Typography variant="h2">{title}</Typography>
      <Stack direction="column" marginY={5}>
        {children}
      </Stack>
    </Stack>
  );
};

displayName(WizardStepLayout, 'WizardStepLayout');

export default WizardStepLayout;
