import React, { SyntheticEvent, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Accordion as MuiAccordion } from '@mui/material';
import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';
import { useAccordionContext } from './Accordion';
import { displayName } from '../util';

interface Props {
  value: string;
  title: string;
  children?: React.ReactNode;
}

const StyledAccordion = styled(MuiAccordion)`
  overflow: clip;
  .MuiAccordionSummary-root {
    background: ${({ theme }) => theme.palette.grey['200']};
  }
`;

const AccordionItem: React.FC<Props> = ({ value: itemValue, title, children }) => {
  const { value, onChange } = useAccordionContext();
  const handleChange = useCallback(
    (_: SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        onChange(itemValue);
      }
    },
    [itemValue, onChange]
  );
  return (
    <StyledAccordion disableGutters={true} elevation={0} expanded={value === itemValue} onChange={handleChange}>
      <AccordionSummary title={title} />
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

displayName(AccordionItem, 'AccordionItem');

export default AccordionItem;
