import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import ContentCard from '../ContentCard';
import { ActionButton, IconButton } from '../Button';
import DataGrid from '../DataGrid';
import { useApi, useRefreshTagGroups, useTagGroups } from '../../globalState';
import { GridColDef } from '@mui/x-data-grid-pro';
import { checkForApiError, displayName, formatTimestamp, throwApiError } from '../../util';
import { CreateTagGroupDTO, TagFileType, TagGroupDTO } from 'dto';
import TagDefinitionDialog, { FormData } from './TagDefinitionDialog';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import UploadDialog, { FileType, supportedFileTypesToText } from '../dialog/UploadDialog';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { toError, useResetEncounteredError, useSetEncounteredError } from '../Error';
import { DownloadSpinner } from '../Spinner';

function validateFoundFileType(foundFileType: string): TagFileType {
  switch (foundFileType) {
    case 'XLS':
      return 'XLS';
    case 'XLSX':
      return 'XLSX';
    case 'CSV':
      return 'CSV';
    default:
      throw new Error(`File type '${foundFileType}' is not supported`);
  }
}

const TagDefinition: React.FC = () => {
  const api = useApi();
  const refreshTagGroups = useRefreshTagGroups();
  const resetEncounteredError = useResetEncounteredError();
  const setEncounteredError = useSetEncounteredError();
  const { t } = useTranslation();
  const { fields } = useTagGroups();
  const [editing, setEditing] = useState<{ open: boolean; tag?: TagGroupDTO }>({ open: false });
  const [uploading, setUploading] = useState<{
    open: boolean;
    tag?: TagGroupDTO;
    ext?: FileType[];
    format?: TagFileType;
  }>({ open: false });
  const [confirmDeleting, setConfirmDeleting] = useState<TagGroupDTO>();
  const [confirmEditing, setConfirmEditing] = useState(false);
  const [editedDTO, setEditedDTO] = useState<CreateTagGroupDTO>();
  const [suspendedTagDownload, setSuspendedTagDownload] = useState(false);
  const [suspendedAllTagDownload, setSuspendedAllTagDownload] = useState(false);
  const [downloadedRowId, setDownloadedRowId] = useState(-1);

  const handleTagDownloadWaitingState = useCallback((isWaiting: boolean) => {
    setSuspendedTagDownload(isWaiting);
  }, []);
  const handleAllTagDownloadWaitingState = useCallback((isWaiting: boolean) => {
    setSuspendedAllTagDownload(isWaiting);
  }, []);
  const handleAdd = useCallback(() => setEditing({ open: true }), [setEditing]);
  const handleDownloadAll = useCallback(() => {
    function handleDownloadAllTagFailure() {
      setEncounteredError(t('tags.definition.error.downloadAll'), new Error('AllTagDownloadFailure'), {
        api: 'downloadAllTagValues',
      });
    }
    api.downloadAllTagValues(handleDownloadAllTagFailure, handleAllTagDownloadWaitingState);
  }, [api, handleAllTagDownloadWaitingState, setEncounteredError, t]);

  const handleDownloadTag = useCallback(
    (rowId: number) => {
      const downloadFormat = 'XLSX';
      function handleDownloadTagFailure() {
        setEncounteredError(t('tags.definition.error.download'), new Error('TagDownloadFailure'), {
          tagId: rowId,
          format: downloadFormat,
        });
      }
      setDownloadedRowId(rowId);
      api.downloadTagValues(rowId, downloadFormat, handleDownloadTagFailure, handleTagDownloadWaitingState);
    },
    [api, handleTagDownloadWaitingState, setEncounteredError, t]
  );

  const handleEditClose = useCallback(() => setEditing({ open: false }), [setEditing]);
  const handleEditingSave = useCallback(
    async (data: FormData) => {
      const dto: CreateTagGroupDTO = { name: data.name, baseFields: data.baseFields.map(f => f.id) };
      if (editing.tag) {
        const noChanges =
          isEqual(
            editing.tag.baseFields.map(it => it.name),
            dto.baseFields
          ) && isEqual(editing.tag.name, dto.name);
        if (noChanges) {
          setEditedDTO(undefined);
          setEditing({ open: false });
        } else {
          setEditedDTO(dto);
          setConfirmEditing(true);
          setEditing({ open: false, tag: editing.tag });
        }
      } else {
        try {
          const response = await api.createTagGroup(dto);
          checkForApiError(response);
          refreshTagGroups();
        } catch (err) {
          setEncounteredError(
            t('tags.definition.error.action', {
              action: t(`action.create`),
            }),
            toError(err),
            {}
          );
        } finally {
          setEditing({ open: false });
        }
      }
    },
    [api, editing.tag, refreshTagGroups, setEncounteredError, t]
  );
  const handleDeleteCancel = useCallback(() => setConfirmDeleting(undefined), []);

  const handleDeleteConfirm = useCallback(async () => {
    if (confirmDeleting) {
      try {
        const response = await api.deleteTagGroup(confirmDeleting.id);
        if (!response.error) {
          resetEncounteredError();
          refreshTagGroups();
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(
          t('tags.definition.error.action', {
            action: t(`action.delete`),
          }),
          toError(err),
          { api: 'deleteTagGroup', id: confirmDeleting.id }
        );
      } finally {
        setConfirmDeleting(undefined);
      }
    }
  }, [api, confirmDeleting, refreshTagGroups, resetEncounteredError, setEncounteredError, t]);
  const handleEditConfirm = useCallback(async () => {
    if (editedDTO && editing.tag) {
      try {
        const response = await api.updateTagGroup(editing.tag.id, editedDTO);
        if (!response.error) {
          resetEncounteredError();
          refreshTagGroups();
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(
          t('tags.definition.error.action', {
            action: t(`action.edit`),
          }),
          toError(err),
          { api: 'updateTagGroup', tagGroupId: editing.tag.id }
        );
      } finally {
        setEditedDTO(undefined);
        setConfirmEditing(false);
        setEditing({ open: false });
      }
    }
  }, [api, editedDTO, editing.tag, refreshTagGroups, resetEncounteredError, setEncounteredError, t]);
  const handleEditCancel = useCallback(() => {
    setEditedDTO(undefined);
    setConfirmEditing(false);
    setEditing({ open: false });
  }, []);
  const handleUploadClose = useCallback(() => setUploading({ open: false }), []);
  const upload = useCallback(
    async (file: File) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await api.uploadTagValues(uploading.tag!.id, file, uploading.format!);
        checkForApiError(response);
        refreshTagGroups();
      } catch (err) {
        setEncounteredError(
          t('tags.definition.error.action', {
            action: t(`action.upload`),
          }),
          toError(err),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          { api: 'uploadTagValues', tagGroupId: uploading.tag!.id, format: uploading.format! }
        );
      }
    },
    [api, refreshTagGroups, setEncounteredError, t, uploading.format, uploading.tag]
  );
  const validateFileType = useCallback(
    (files: File[]) => {
      const file = files[0];
      const foundFileType = file.name.substr(file.name.lastIndexOf('.') + 1).toUpperCase();
      const validatedFileType = validateFoundFileType(foundFileType);
      setUploading({ ...uploading, format: validatedFileType });
    },
    [uploading]
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('tags.definition.table.columnHeader.name'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'baseFields',
        headerName: t('tags.definition.table.columnHeader.baseFields'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'lastImport',
        headerName: t('tags.definition.table.columnHeader.lastImport'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'lastUpdate',
        headerName: t('tags.definition.table.columnHeader.lastUpdate'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: '__ACTIONS__',
        headerName: t('tags.definition.table.columnHeader.actions'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        width: 160,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={3}>
            <Tooltip title={t('tags.definition.table.tooltip.import')}>
              <IconButton
                disabled={!row.value.uploadAllowed}
                onClick={() => setUploading({ open: true, tag: row.value, ext: [FileType.CSV, FileType.EXCEL] })}
              >
                <UploadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tags.definition.table.tooltip.export')}>
              <IconButton
                onClick={() => handleDownloadTag(row.value.id)}
                disabled={suspendedTagDownload || suspendedAllTagDownload}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tags.definition.table.tooltip.edit')}>
              <IconButton
                onClick={() => {
                  setEditing({ open: true, tag: row.value });
                }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tags.definition.table.tooltip.delete')}>
              <IconButton onClick={() => setConfirmDeleting(row.value)}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <DownloadSpinner size={20} suspended={suspendedTagDownload && downloadedRowId === row.value.id} />
          </Stack>
        ),
      },
    ];
  }, [t, suspendedTagDownload, suspendedAllTagDownload, downloadedRowId, handleDownloadTag]);
  const rows = useMemo(() => {
    return fields.map((f, i) => ({
      id: i + '',
      name: f.name,
      baseFields: f.baseFields.map(bf => bf.label).join(', '),
      lastImport: formatTimestamp(f.lastImport),
      lastUpdate: formatTimestamp(f.lastUpdate),
      value: f,
    }));
  }, [fields]);

  return (
    <ContentCard title={t('tags.header.toggle.field')} minHeight={12} padding={0} flexGrow={1}>
      <Stack direction="row" gap={3} marginBlock={4}>
        <ActionButton variant="outlined" startIcon={<AddIcon />} onClick={handleAdd}>
          {t('tags.definition.buttons.import')}
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadAll}
          disabled={suspendedTagDownload || suspendedAllTagDownload}
        >
          {t('tags.definition.buttons.export')}
        </ActionButton>
        <DownloadSpinner size={25} suspended={suspendedAllTagDownload} />
      </Stack>
      <DataGrid
        columns={columns}
        rows={rows}
        hideFooter={true}
        columnHeaderHeight={36}
        rowHeight={30}
        disableRowSelectionOnClick={true}
        disableColumnReorder={true}
        customMarginTop={0}
      />
      {editing.open && (
        <TagDefinitionDialog
          initialValue={editing.tag ? { name: editing.tag.name, baseFields: editing.tag.baseFields } : undefined}
          onClose={handleEditClose}
          onSave={handleEditingSave}
        />
      )}
      {confirmDeleting && (
        <ConfirmationDialog
          title={t('tags.definition.dialog.confirmDeleting.title')}
          text={t('tags.definition.dialog.confirmDeleting.text', { name: confirmDeleting.name })}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {confirmEditing && editedDTO && (
        <ConfirmationDialog
          title={t('tags.definition.dialog.confirmEditing.title')}
          text={t('tags.definition.dialog.confirmEditing.text', { name: editedDTO.name })}
          onCancel={handleEditCancel}
          onConfirm={handleEditConfirm}
          customButtonText={t('dialog.save')}
        />
      )}
      {uploading.open && (
        <UploadDialog
          title={t('tags.definition.dialog.upload.title')}
          text={
            <Typography>
              {t('tags.definition.dialog.upload.text', { format: supportedFileTypesToText(uploading.ext) })}
            </Typography>
          }
          note={
            <Typography component="div">
              <Box fontWeight="bold" display="inline">
                {t('tags.definition.dialog.upload.note.emphasis')}
              </Box>{' '}
              {t('tags.definition.dialog.upload.note.body')}
            </Typography>
          }
          onClose={handleUploadClose}
          accept={uploading.ext}
          upload={upload}
          onDropAccepted={validateFileType}
        />
      )}
    </ContentCard>
  );
};

displayName(TagDefinition, 'TagDefinition');

export default TagDefinition;
