import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import ContentCard from '../../components/ContentCard';
import DataGrid from '../../components/DataGrid';
import RunAnalysisButton from '../../components/RunAnalysisButton';
import Spinner from '../../components/Spinner';
import SimpleLayout from '../../SimpleLayout';
import { JOB_REFRESH_INTERVAL, useApi, useJobs, useRefreshJobs } from '../../globalState';
import { checkForApiError, displayName, formatTimestamp } from '../../util';
import { JobDTO } from '../../dto';
import { toError, useSetEncounteredError } from '../../components/Error';
import useDocumentTitle from '../../useDocumentTitle';
import { ErrorOverlay } from '../../components/Overlay';

interface RowType extends JobDTO, GridValidRowModel {
  id: string;
}

const Jobs: React.FC = () => {
  const { data, loading, error } = useJobs();
  const { t } = useTranslation();
  const refreshJobs = useRefreshJobs();
  const api = useApi();
  const [executionPending, setExecutionPending] = useState<string>();
  const setEncounteredError = useSetEncounteredError();

  useDocumentTitle(t('application.shortName') + ' - ' + t('admin.jobs.table.title'));
  useEffect(() => {
    const timer = setInterval(refreshJobs, JOB_REFRESH_INTERVAL);
    return () => clearTimeout(timer);
  }, [refreshJobs]);
  const rows = useMemo(() => {
    const jobs = data?.jobs ?? [];
    return jobs.map((job, i) => ({ id: `${i + 1}`, ...job }));
  }, [data]);
  const columns: GridColDef<RowType>[] = useMemo(() => {
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: ({ row }) =>
          executionPending === row.jobName ? (
            <Spinner size={4} />
          ) : (
            <RunAnalysisButton
              onClick={async () => {
                try {
                  setExecutionPending(row.jobName);
                  const response = await api.executeJob(row.jobName, row.jobGroup);
                  checkForApiError(response);
                } catch (err) {
                  setEncounteredError(t('admin.jobs.error', { action: t('action.execute') }), toError(err), {
                    api: 'executeJob',
                    jobName: row.jobName,
                    jobGroup: row.jobGroup,
                  });
                } finally {
                  setExecutionPending(undefined);
                }
              }}
              disabled={!row.enabled}
            />
          ),
        renderHeader: () => (loading ? <Spinner size={4} /> : null),
      },
      {
        field: 'jobName',
        headerName: t('admin.jobs.table.name'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'running',
        headerName: t('admin.jobs.table.running'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: ({ value }) => (!!value ? t('admin.jobs.table.values.yes') : t('admin.jobs.table.values.no')),
      },
      {
        field: 'lastExecution',
        headerName: t('admin.jobs.table.lastExecution'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: ({ value }) => formatTimestamp(value),
      },
      {
        field: 'nextExecution',
        headerName: t('admin.jobs.table.nextExecution'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: ({ value }) => formatTimestamp(value),
      },
    ];
  }, [api, executionPending, loading, setEncounteredError, t]);

  return (
    <SimpleLayout title={t('admin.jobs.table.title')}>
      <ContentCard flexGrow={1} padding={0}>
        {error ? (
          <ErrorOverlay error={error} />
        ) : (
          <DataGrid
            columns={columns as never}
            rows={rows}
            hideFooter={true}
            columnHeaderHeight={36}
            rowHeight={30}
            disableRowSelectionOnClick={true}
          />
        )}
      </ContentCard>
    </SimpleLayout>
  );
};

displayName(Jobs, 'Jobs');

export default Jobs;
