import React, { Suspense } from 'react';
import { Stack, Typography } from '@mui/material';
import Spinner from './components/Spinner';
import { displayName } from './util';
import { SIDEBAR_WIDTH } from './globalState/variables';

interface Props {
  title: string;
  sidebarContent: React.ReactElement;
  children?: React.ReactNode;
}

const SidebarLayout: React.FC<Props> = ({ title, sidebarContent, children }) => {
  return (
    <Stack direction="row" flexGrow={1}>
      <Stack direction="column" gap={4} width={`${SIDEBAR_WIDTH}px`} marginTop={9} flexShrink={0}>
        <Suspense fallback={<Spinner />}>{sidebarContent}</Suspense>
      </Stack>
      <Stack direction="column" marginLeft={4} flexGrow={1} width={`calc(99.5% - ${SIDEBAR_WIDTH}px)`}>
        <Stack direction="row" alignItems="center" height={theme => theme.spacing(9)} flexShrink={0}>
          <Typography variant="h1">{title}</Typography>
        </Stack>
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </Stack>
    </Stack>
  );
};

displayName(SidebarLayout, 'SidebarLayout');

export default SidebarLayout;
