import React, { useEffect } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { AnalysisViewType, useAnalysisTab } from '../../globalState';
import { displayName, useEntity } from '../../util';
import ToggleButton from '../ToggleButton';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';

export const useUpdateTabState = (targetTab: AnalysisViewType) => {
  const [tab, setTab] = useAnalysisTab(useEntity());
  useEffect(() => {
    if (tab !== targetTab) {
      setTab(targetTab);
    }
  }, [tab, setTab, targetTab]);
};

interface Props {
  analysisTab: string;
  handleToggleChanged: (_: React.MouseEvent, value: string) => void;
}

const AnalysisToggle: React.FC<Props> = ({ analysisTab, handleToggleChanged }) => {
  const { t } = useTranslation();

  return (
    <HeaderItem label={t('analysis.header.view')}>
      <ToggleButtonGroup color="primary" exclusive={true} value={analysisTab} onChange={handleToggleChanged}>
        <ToggleButton value="table">{t('analysis.header.table')}</ToggleButton>
        <ToggleButton value="chart">{t('analysis.header.chart')}</ToggleButton>
      </ToggleButtonGroup>
    </HeaderItem>
  );
};

displayName(AnalysisToggle, 'AnalysisToggle');

export default AnalysisToggle;
