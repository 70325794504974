import { selector, useRecoilValue } from 'recoil';
import { SelectItemDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { clientLanguageState } from './telcobill';

const privilegeLevelsQuery = selector<SelectItemDTO[]>({
  key: 'privilegeLevels',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const response = await get(apiClient(lang)).getPrivilegeLevels();
    return dataOrThrow(response).items;
  },
});

export const usePrivilegeLevels = () => useRecoilValue(privilegeLevelsQuery);
