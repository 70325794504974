import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';
import { useView } from '../util';

export const currentPageState = atomFamily<number, string>({
  key: 'currentPageState',
  default: 0,
});

export const useCurrentPageState = () => {
  const view = useView();
  return useRecoilState(currentPageState(view.name));
};

export const useSetCurrentPageToZero = () => {
  const view = useView();
  const setAnalysisTablePageState = useSetRecoilState(currentPageState(view.name));

  return () => setAnalysisTablePageState(0);
};
