import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { StyledTextField } from '../TextInput';
import { displayName } from '../../util';
import { Stack } from '@mui/material';
import ErrorText from './ErrorText';
import { ValidationError } from '../Error';

interface Props {
  type?: 'text' | 'password';
  fieldName: string;
  fieldCypressId?: string;
  width?: number | string;
  height?: number | string;
  grow?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  autocomplete?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
}

const FormikTextInputField: React.FC<Props> = ({
  type = 'text',
  fieldName,
  fieldCypressId,
  width,
  height,
  multiline,
  rows,
  grow = false,
  disabled,
  autocomplete = false,
  autoFocus = false,
  placeholder,
}) => {
  const ctx = useFormikContext();
  const [field, meta, helpers] = useField(fieldName);
  const ref = useRef<HTMLInputElement>(null);
  const validationError = meta.error as unknown as ValidationError;

  useEffect(() => {
    ctx.registerField(fieldName, {
      validate: (value: unknown) => {
        if (ref.current && value !== ref.current.value) {
          helpers.setValue(ref.current.value, false);
        }
      },
    });
    return () => ctx.unregisterField(fieldName);
  }, [ctx, helpers, fieldName]);

  return (
    <Stack data-cy={fieldCypressId}>
      <StyledTextField
        inputRef={ref}
        defaultValue={field.value}
        type={type}
        onBlur={(e: { target: { value: string | number } }) => helpers.setValue(e.target.value, !!meta.error)}
        width={width}
        height={height}
        grow={grow}
        multiline={multiline}
        rows={rows}
        error={!!meta.error}
        disabled={disabled}
        autoComplete={autocomplete ? 'on' : 'off'}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {!!meta.error && validationError.message && <ErrorText text={validationError.message} />}
    </Stack>
  );
};

displayName(FormikTextInputField, 'FormikTextInputField');

export default FormikTextInputField;
