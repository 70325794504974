import React, { MutableRefObject, useEffect } from 'react';
import { toJpeg, toPng } from 'html-to-image';
import download from 'downloadjs';
import { ChartExportFormatType } from '../../dto';
import { useSetChartDownloadCallback } from '../../globalState/analysis';
import { styled } from '@mui/material/styles';

interface Props {
  chart: MutableRefObject<HTMLDivElement | null>;
}
export const ChartDownloadComponent: React.FC<Props> = ({ chart }) => {
  const setChartDownloadCallback = useSetChartDownloadCallback();

  useEffect(() => {
    const downloadChart = async (format: ChartExportFormatType, setWaitingState: (isWaiting: boolean) => void) => {
      if (!chart.current) {
        return;
      }
      setWaitingState(true);
      chart.current.style.backgroundColor = '#FFFFFF';
      if (format === 'png') {
        const dataUrl = await toPng(chart.current);
        download(dataUrl, 'chart.png');
      } else if (format === 'jpg') {
        const dataUrl = await toJpeg(chart.current);
        download(dataUrl, 'chart.jpg');
      }
      chart.current.style.backgroundColor = 'rgba(0, 0, 0, 0)';
      setWaitingState(false);
    };
    setChartDownloadCallback({ callback: downloadChart });
    return () => undefined;
  }, [chart, setChartDownloadCallback]);

  return null;
};

export const DownloadContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& > * > * > * > * > * > text': {
    fontSize: `${theme.typography.body1.fontSize} !important`,
  },
  '& > * > * > * > * > * > * > text': {
    fontSize: `${theme.typography.body1.fontSize} !important`,
  },
}));
