import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import Text from '../Text';
import { useApi, useCostCenters, useMutationTracker, useRefreshCostCenters } from '../../globalState';
import CostCenterTree from '../costcenter/CostCenterTree';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import { CostCenterDTO } from '../../dto';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import SimpleCard from '../SimpleCard';

interface CostCenterTreeEditorProps {
  showNonVirtualOnly?: boolean;
}

const CostCenterTreeEditor: React.FC<CostCenterTreeEditorProps> = ({ showNonVirtualOnly }) => {
  const api = useApi();
  const root = useCostCenters();
  const refreshCostCenters = useRefreshCostCenters();
  const setEncounteredError = useSetEncounteredError();
  const { selectedCostCenter, setSelectedCostCenter } = useCostCenterContext();
  const trackMutation = useMutationTracker();
  const { t } = useTranslation();

  const handleCostCenterMove = useCallback(
    (source: CostCenterDTO, target: CostCenterDTO) =>
      trackMutation(
        () => api.attachCostCenter({ ccId: source.costCenterId, parentId: target.costCenterId }),
        (error: unknown) =>
          setEncounteredError(t('sidebar.costCenters.error.attaching'), toError(error), { api: 'attachCostCenter' }),
        refreshCostCenters
      ),
    [trackMutation, refreshCostCenters, api, setEncounteredError, t]
  );

  return (
    <SimpleCard>
      <Stack direction="column" paddingX={3} paddingTop={2} paddingBottom={3} gap={3}>
        <Text weight="700">{t('sidebar.costCenters.title')}</Text>
        <CostCenterTree
          root={root}
          selectedCostCenter={selectedCostCenter}
          onSelectionChange={setSelectedCostCenter}
          enableDnd={true}
          onCostCenterMove={handleCostCenterMove}
          showNonVirtualOnly={showNonVirtualOnly}
        />
      </Stack>
    </SimpleCard>
  );
};

displayName(CostCenterTreeEditor, 'CostCenterTreeEditor');

export default CostCenterTreeEditor;
