import { selector, useRecoilRefresher_UNSTABLE } from 'recoil';
import { ApiResponse } from '../api';
import { JobsDTO } from '../dto';
import { apiClient } from './apiClient';
import { useLoadableApiValue } from './util';
import { clientLanguageState } from './telcobill';

const jobsQuery = selector<ApiResponse<JobsDTO>>({
  key: 'jobs',
  get: ({ get }) => {
    const lang = get(clientLanguageState);
    return get(apiClient(lang)).getJobs();
  },
});

export const useJobs = () => useLoadableApiValue(jobsQuery);

export const useRefreshJobs = () => useRecoilRefresher_UNSTABLE(jobsQuery);
