import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { fromPairs } from 'lodash';
import BarChart from '../chart/BarChart';
import {
  useApplicationStatisticsChart,
  useApplicationStatisticsChartConfig,
  useApplicationStatisticsSeries,
} from '../../globalState';
import { displayName } from '../../util';

const ApplicationStatisticsChart: React.FC = () => {
  const seriesOptions = useApplicationStatisticsSeries();
  const [config] = useApplicationStatisticsChartConfig();
  const series = useApplicationStatisticsChart();

  const seriesLabelByValue = useMemo(() => fromPairs(seriesOptions.map(o => [o.value, o.label])), [seriesOptions]);
  const valueAxisLabel = useMemo(() => seriesLabelByValue[config.series], [config.series, seriesLabelByValue]);
  const labels = useMemo(() => ({ valueAxis: valueAxisLabel }), [valueAxisLabel]);
  const dataWithLabels = useMemo(() => series.map(item => ({ ...item, labels })), [series, labels]);

  return (
    <Stack height="10px" flexGrow={1}>
      <BarChart
        layout="vertical"
        data={dataWithLabels}
        valueAxisLabel={valueAxisLabel}
        tableMargin={{ top: 16, right: 32, bottom: 32, left: 96 }}
      />
    </Stack>
  );
};

displayName(ApplicationStatisticsChart, 'ApplicationStatisticsChart');

export default ApplicationStatisticsChart;
