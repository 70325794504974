import React from 'react';
import { styled } from '@mui/material/styles';
import { noop } from 'lodash';
import { alpha, Card, Stack } from '@mui/material';
import { IconButton } from '../Button';
import Text from '../Text';
import { displayName } from '../../util';
import { DeleteOutlined } from '@mui/icons-material';

interface Props {
  label: string;
  hidden?: boolean;
  onDelete?: () => void;
}

const StyledCard = styled(Card, {
  shouldForwardProp: prop => (prop as string) !== 'highlighted' && (prop as string) !== 'hidden',
})<{
  highlighted: boolean;
  hidden: boolean;
}>`
  ${({ theme, highlighted }) => (highlighted ? `background: ${alpha(theme.palette.success.main, 0.2)};` : '')}
  ${({ hidden }) => (hidden ? 'visibility: collapse;' : '')}
  cursor: move;
`;

const ListItem: React.FC<Props> = ({ label, hidden = false, onDelete = noop }) => {
  return (
    <StyledCard highlighted={false} hidden={hidden}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" paddingX={3} paddingY={2}>
        <Text>{label}</Text>
        <IconButton onClick={onDelete}>
          <DeleteOutlined fontSize="small" />
        </IconButton>
      </Stack>
    </StyledCard>
  );
};

displayName(ListItem, 'ListItem');

export default ListItem;
