import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CardActions, CardContent, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { displayName, formatInvoicePeriod } from '../../util';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import SimpleCard from 'components/SimpleCard';
import {
  allInvoicePeriodsItem,
  descPeriodComparator,
  useAvailableUnorderedInvoicePeriods,
  useNewestInvoicePeriod,
  useSetCurrentPageToZero,
} from '../../globalState';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  gap: ${({ theme }) => theme.spacing(2)};
  button.MuiToggleButtonGroup-grouped {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 ${({ theme }) => theme.spacing(2)};
    border: 0 !important;
    border-radius: 4px !important;
    text-transform: none;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 400;
  }
  button.MuiToggleButtonGroup-grouped:hover {
    background-color: ${({ theme }) => theme.palette.hoverGrey.main};
    color: ${({ theme }) => theme.palette.hover.main};
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
`;

const InvoicePeriodFilter: React.FC = () => {
  const periods = useAvailableUnorderedInvoicePeriods();
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const [period, setPeriod] = useNewestInvoicePeriod();
  const { t } = useTranslation();

  const periodById = useMemo(() => {
    const periodById = keyBy(periods, 'id');
    periodById['all'] = allInvoicePeriodsItem;
    return periodById;
  }, [periods]);

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontWeight: 700 }}>{t('sidebar.filter.invoicePeriodFilter.title')}</Typography>
      </CardContent>
      <CardActions>
        <StyledToggleButtonGroup
          fullWidth={true}
          color="primary"
          exclusive={true}
          orientation="vertical"
          value={period.id}
          onChange={(_, id: string | number) => {
            setCurrentPageToZero();
            if (!!id) {
              setPeriod(periodById[id]);
            }
          }}
        >
          <ToggleButton value="all">
            {t('sidebar.filter.all')} <StyledChevronRightIcon />
          </ToggleButton>
          {periods
            .slice()
            .sort(descPeriodComparator)
            .map((p, i) => (
              <ToggleButton key={i} value={p.id}>
                {formatInvoicePeriod(p)} <StyledChevronRightIcon />
              </ToggleButton>
            ))}
        </StyledToggleButtonGroup>
      </CardActions>
    </React.Fragment>
  );

  return (
    <Box sx={{ minWidth: 275 }}>
      <SimpleCard>{card}</SimpleCard>
    </Box>
  );
};

displayName(InvoicePeriodFilter, 'InvoicePeriodFilter');

export default InvoicePeriodFilter;
