import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '../Button';
import { DEFAULT_USER_START_PAGE, useApi } from '../../globalState';
import { useLanguage } from '../../i18n';
import { SupportUserDTO } from '../../dto';
import SupportUserDialog from './SupportUserDialog';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { checkForApiError, displayName, throwApiError } from '../../util';
import SyncIcon from '@mui/icons-material/Sync';
import { toError, useSetEncounteredError } from '../Error';
import NonInteractiveTooltip from '../Tooltip';
import { DownloadSpinner } from '../Spinner';
import SupportDataGrid from './SupportDataGrid';
import { ApiError } from '../../api';

interface Props {
  users: SupportUserDTO[];
  refresh: () => void;
  error: ApiError | undefined;
}

const SupportUsers: React.FC<Props> = ({ users, refresh, error }) => {
  const api = useApi();
  const language = useLanguage();
  const setEncounteredError = useSetEncounteredError();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [editing, setEditing] = useState<{ open: boolean; user?: SupportUserDTO }>({ open: false });
  const [deleting, setDeleting] = useState<{ open: boolean; user?: SupportUserDTO }>({ open: false });
  const [suspendedLogin, setSuspendedLogin] = useState(false);
  const [suspendedSync, setSuspendedSync] = useState(false);
  const [actionRow, setActionRow] = useState('-1');

  const handleEditClose = useCallback(
    (dirty: boolean) => {
      setEditing({ open: false });
      if (dirty) {
        refresh();
      }
    },
    [refresh]
  );
  const handleDeleteCancel = useCallback(() => setDeleting({ open: false }), []);
  const handleDeleteConfirm = useCallback(async () => {
    const userId = deleting.user?.id;
    if (userId === undefined) {
      throw Error('User is undefined');
    }
    try {
      const response = await api.deleteUser(userId);
      checkForApiError(response);
      refresh();
    } catch (err) {
      setEncounteredError(
        t('admin.support.error.dialog', {
          action: t(`action.delete`),
        }),
        toError(err),
        { deleteId: userId }
      );
    } finally {
      setDeleting({ open: false });
    }
  }, [api, deleting.user?.id, refresh, setEncounteredError, t]);
  const columns: GridColDef<SupportUserDTO & { rowId: string }>[] = useMemo(() => {
    const loginAsUser = async (user: SupportUserDTO, rowId: string) => {
      setSuspendedLogin(true);
      setActionRow(rowId);
      try {
        const response = await api.loginAs('USER', '' + user.id);
        if (response.data?.status === 'OK') {
          window.location.href = DEFAULT_USER_START_PAGE.replace(':lng', language);
        } else {
          throwApiError(response.error);
        }
      } catch (err) {
        setEncounteredError(t('admin.support.error.login'), toError(err), { loginId: user.id });
      } finally {
        setSuspendedLogin(false);
      }
    };
    const triggerCRMSync = async (user: SupportUserDTO, rowId: string) => {
      setSuspendedSync(true);
      setActionRow(rowId);
      try {
        const response = await api.triggerCrmSync({ loginName: user.name, referer: 'sbanew' });
        checkForApiError(response);
      } catch (err) {
        setEncounteredError(t('admin.support.error.crmSync'), toError(err), { crmSyncUser: user.name });
      } finally {
        setSuspendedSync(false);
      }
    };

    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 120,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={3}>
            <NonInteractiveTooltip title={t('admin.support.tooltip.login')}>
              <IconButton onClick={() => loginAsUser(row, row.rowId)} disabled={suspendedLogin || !row.active}>
                <ChevronRightIcon />
              </IconButton>
            </NonInteractiveTooltip>
            {row.masterUser && (
              <NonInteractiveTooltip title={t('admin.support.tooltip.triggerCRMSync')}>
                <IconButton customSize="24px" onClick={() => triggerCRMSync(row, row.rowId)} disabled={suspendedSync}>
                  <SyncIcon fontSize="small" />
                </IconButton>
              </NonInteractiveTooltip>
            )}
            <DownloadSpinner size={20} suspended={(suspendedSync || suspendedLogin) && actionRow === row.rowId} />
          </Stack>
        ),
      },
      {
        field: 'name',
        headerName: t('admin.support.user.header.name'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'activeLabel',
        headerName: t('admin.support.user.header.active'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'fullName',
        headerName: t('admin.support.user.header.fullName'),
        valueGetter: params => `${params.row['lastName']} ${params.row['firstName']}`,
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 240,
      },
      {
        field: 'mail',
        headerName: t('admin.support.user.header.mail'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 240,
      },
      {
        field: 'masterUser',
        headerName: t('admin.support.user.header.masterUser'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
        valueFormatter: ({ value }) => (!!value ? t('dialog.yes') : t('dialog.no')),
      },
      {
        field: 'levelLabel',
        headerName: t('admin.support.user.header.levelLabel'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'customer',
        headerName: t('admin.support.user.header.customer'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 120,
      },
      {
        field: 'costCenters',
        headerName: t('admin.support.user.header.costCenters'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 180,
      },
      {
        field: 'lastLogin',
        headerName: t('admin.support.user.header.lastLogin'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        width: 160,
      },
    ];
  }, [actionRow, api, language, setEncounteredError, suspendedLogin, suspendedSync, t]);
  const rows = useMemo(() => {
    return users.map((user, i) => ({ ...user, rowId: `${i + 1}` }));
  }, [users]);

  return (
    <>
      <SupportDataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        error={error}
        title={t('admin.support.user.title')}
      />
      {editing.open && <SupportUserDialog user={editing.user} onClose={handleEditClose} />}
      {deleting.open && (
        <ConfirmationDialog
          title={t('admin.support.user.deletingDialog.title')}
          text={t('admin.support.user.deletingDialog.text', { name: deleting.user?.name })}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};

displayName(SupportUsers, 'SupportUsers');

export default SupportUsers;
