import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { displayName } from '../util';

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  children?: React.ReactNode;
}

interface AccordionContextProps {
  value: string;
  onChange: (value: string) => void;
}

const AccordionContext = React.createContext<AccordionContextProps>({} as never);

export const useAccordionContext = () => useContext(AccordionContext);

const Accordion: React.FC<Props> = ({ className, value, onChange, children }) => {
  return (
    <Stack className={className} direction="column" gap={2}>
      <AccordionContext.Provider value={{ value, onChange }}>{children}</AccordionContext.Provider>
    </Stack>
  );
};

displayName(Accordion, 'Accordion');

export default Accordion;
