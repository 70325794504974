import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.hover.main};
    text-decoration: underline;
  }
`;

export default StyledLink;
