import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Text from './Text';
import { displayName } from '../util';

interface Props {
  label: string;
  value?: string;
  showTooltip?: boolean;
  children?: React.ReactNode;
}

const Value = styled(Text)`
  max-width: ${({ theme }) => theme.spacing(11)};
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const HeaderItem: React.FC<Props> = ({ label, value, children }) => {
  if (value !== undefined) {
    return (
      <Stack direction="column">
        <Text weight="700">{label}</Text>
        <Value sx={{ maxWidth: 'none' }}>{value}</Value>
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <Text weight="700">{label}</Text>
      {children}
    </Stack>
  );
};

displayName(HeaderItem, 'HeaderItem');

export default HeaderItem;
