import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../Dialog';
import { MenuItem, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { ActionButton } from '../Button';
import { displayName } from '../../util';
import { DrilldownActionDTO, QueryObjectDTO, TableExportFormatType } from '../../dto';
import DefaultSelect from '../Select';
import { useAccountField, useApi, useDownloadFields, useInvoiceNoField } from '../../globalState';
import { useSetEncounteredError } from '../Error';
import { keyBy } from 'lodash';
import { SuspendedSpinner } from '../Spinner';
import Text from '../Text';
import { useDrilldownDownload } from '../../globalState/analysis';

interface Props {
  actions: DrilldownActionDTO[];
  onCancel: () => void;
  sourceEntity: string;
  row: Record<string, unknown>;
}

const DrilldownDataExportDialog: React.FC<Props> = ({ actions, onCancel, sourceEntity, row }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = useState<string>(actions[0].title ?? '');
  const [dataFormat, setDataFormat] = useState<TableExportFormatType>('xlsx');
  const [disabled, setDisabled] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [isDataAction, setIsDataAction] = useState(actions[0].type === 'DATA_DOWNLOAD');

  const actionByTitle = keyBy(actions, 'title');
  const handleDownloadWaitingState = useCallback((isWaiting: boolean) => {
    setSuspended(isWaiting);
    setDisabled(isWaiting);
  }, []);
  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      const actionTitle = e.target.value as string;
      const action = actionByTitle[actionTitle];
      if (action.type === 'DATA_DOWNLOAD') {
        setIsDataAction(true);
      } else {
        setIsDataAction(false);
      }
      setSelectedAction(actionTitle);
    },
    [actionByTitle]
  );
  const textWidth = theme.spacing(7);
  const selectWidth = '100%';
  const elementGap = 3;

  return (
    <Dialog header={<Typography variant="h2">{t('bills.exportDialog.title')}</Typography>} width="600px">
      <Stack direction="column" marginTop={4} gap={4}>
        <Stack direction="row" gap={elementGap}>
          <Stack alignItems="start" justifyContent={'center'} width={textWidth}>
            <Text weight={'700'}>{t('bills.exportDialog.labels.data')}</Text>
          </Stack>
          <Stack width={selectWidth}>
            <DefaultSelect value={selectedAction} onChange={handleSelectChange}>
              {actions.map((f, i) => (
                <MenuItem key={i} value={f.title}>
                  {f.title}
                </MenuItem>
              ))}
            </DefaultSelect>
          </Stack>
        </Stack>
        <Stack direction="row" gap={elementGap}>
          <Stack alignItems="start" justifyContent={'center'} width={textWidth}>
            <Text weight={'700'}>{t('bills.exportDialog.labels.format')}</Text>
          </Stack>
          <Stack width={selectWidth}>
            {isDataAction ? (
              <DefaultSelect value={dataFormat} onChange={e => setDataFormat(e.target.value as TableExportFormatType)}>
                <MenuItem value="xlsx">XLSX</MenuItem>
                <MenuItem value="csv">CSV</MenuItem>
              </DefaultSelect>
            ) : (
              <DefaultSelect value={'pdf'}>
                <MenuItem value="pdf">PDF</MenuItem>
              </DefaultSelect>
            )}
          </Stack>
        </Stack>
        <SuspendedSpinner suspended={suspended} />
        <Stack direction="row" justifyContent="space-between" gap={4}>
          <ActionButton variant="outlined" onClick={onCancel} disabled={disabled}>
            {t('dialog.cancel')}
          </ActionButton>
          {isDataAction ? (
            <DataExportButton
              action={actionByTitle[selectedAction]}
              fileType={dataFormat}
              handleDownloadWaitingState={handleDownloadWaitingState}
              row={row}
              sourceEntity={sourceEntity}
              onCancel={onCancel}
            />
          ) : (
            <FileExportButton
              handleDownloadWaitingState={handleDownloadWaitingState}
              onCancel={onCancel}
              row={row}
              sourceEntity={sourceEntity}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

displayName(DrilldownDataExportDialog, 'DrilldownDataExportDialog');

export default DrilldownDataExportDialog;

interface DataExportButtonProps {
  action: DrilldownActionDTO;
  fileType: TableExportFormatType;
  sourceEntity: string;
  row: Record<string, unknown>;
  handleDownloadWaitingState: (isWaiting: boolean) => void;
  onCancel: () => void;
}

const DataExportButton: React.FC<DataExportButtonProps> = ({
  action,
  fileType,
  sourceEntity,
  row,
  handleDownloadWaitingState,
  onCancel,
}) => {
  const sourceAccountField = useAccountField(sourceEntity);
  const sourceInvoiceNoField = useInvoiceNoField(sourceEntity);
  const downloadFields = useDownloadFields(action.entity as string);
  const downloadAccountField = useAccountField(action.entity as string);
  const downloadInvoiceNoField = useInvoiceNoField(action.entity as string);
  const downloadData = useDrilldownDownload();
  const setEncounteredError = useSetEncounteredError();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const finalHandleDownloadWaitingState = useCallback(
    (isWaiting: boolean) => {
      setDisabled(isWaiting);
      handleDownloadWaitingState(isWaiting);
    },
    [handleDownloadWaitingState]
  );
  const handleDataDownload = useCallback(() => {
    const qo: QueryObjectDTO = {
      entityElements: [action.entity as string],
      selectElements: downloadFields.map(f => ({
        entityName: action.entity as string,
        name: f.name,
      })),
      conditionElement: {
        type: 'combined',
        logicalOperator: 'AND_OPERATOR',
        conditionElements: [
          {
            type: 'field',
            conditionComparator: 'EQUAL_COMPARATOR',
            field: { entityName: action.entity as string, name: downloadAccountField },
            value: (row['__raw'] as Record<string, unknown>)[sourceAccountField],
          },
          {
            type: 'field',
            conditionComparator: 'EQUAL_COMPARATOR',
            field: { entityName: action.entity as string, name: downloadInvoiceNoField },
            value: (row['__raw'] as Record<string, unknown>)[sourceInvoiceNoField],
          },
        ],
      },
    };
    const filename = `${replaceFields(action.name, row)}.${fileType}`;
    function handleDataDownloadFailure(error?: Error) {
      onCancel();
      setEncounteredError(
        error?.name === 'ThresholdExceededException' ? error.message : t('bills.error.download'),
        error ?? new Error('DataDownloadError'),
        {
          api: 'downloadTable - drilldown',
          filename: filename,
        }
      );
    }
    downloadData(qo, fileType, finalHandleDownloadWaitingState, handleDataDownloadFailure, filename).catch(
      console.error
    );
  }, [
    action.entity,
    action.name,
    downloadAccountField,
    downloadData,
    downloadFields,
    downloadInvoiceNoField,
    fileType,
    finalHandleDownloadWaitingState,
    onCancel,
    row,
    setEncounteredError,
    sourceAccountField,
    sourceInvoiceNoField,
    t,
  ]);

  return (
    <ActionButton onClick={handleDataDownload} disabled={disabled}>
      {t('dialog.download')}
    </ActionButton>
  );
};

interface FileExportButtonProps {
  sourceEntity: string;
  row: Record<string, unknown>;
  handleDownloadWaitingState: (isWaiting: boolean) => void;
  onCancel: () => void;
}

const FileExportButton: React.FC<FileExportButtonProps> = ({
  sourceEntity,
  row,
  handleDownloadWaitingState,
  onCancel,
}) => {
  const api = useApi();
  const sourceAccountField = useAccountField(sourceEntity);
  const sourceInvoiceNoField = useInvoiceNoField(sourceEntity);
  const setEncounteredError = useSetEncounteredError();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const finalHandleDownloadWaitingState = useCallback(
    (isWaiting: boolean) => {
      setDisabled(isWaiting);
      handleDownloadWaitingState(isWaiting);
    },
    [handleDownloadWaitingState]
  );
  const handleFileDownload = useCallback(() => {
    const accountId = (row['__raw'] as Record<string, unknown>)[sourceAccountField] as number;
    const invoiceNo = (row['__raw'] as Record<string, unknown>)[sourceInvoiceNoField] as string;

    function handleFileDownloadFailure() {
      onCancel();
      setEncounteredError(t('bills.error.download'), new Error('FileDownloadError'), {
        api: 'downloadInvoice - drilldown',
        accountId: accountId,
        invoiceNo: invoiceNo,
      });
    }
    api.downloadInvoice(sourceEntity, accountId, invoiceNo, handleFileDownloadFailure, finalHandleDownloadWaitingState);
  }, [
    api,
    finalHandleDownloadWaitingState,
    onCancel,
    row,
    setEncounteredError,
    sourceAccountField,
    sourceEntity,
    sourceInvoiceNoField,
    t,
  ]);

  return (
    <ActionButton onClick={handleFileDownload} disabled={disabled}>
      {t('dialog.download')}
    </ActionButton>
  );
};

const FIELD_PATTERN = /\$F\{(.*?)\}/;
const HIDDEN_PATTERN = /\$H\{(.*?)\}/;

export const replaceFields = (s: string | undefined, row: Record<string, unknown>): string => {
  if (!s) {
    return '';
  }
  let result = s;
  while (true) {
    const match = FIELD_PATTERN.exec(result);
    if (!match) {
      break;
    }
    result = result.replace(match[0], `${row[match[1]]}`);
  }
  while (true) {
    const match = HIDDEN_PATTERN.exec(result);
    if (!match) {
      break;
    }
    result = result.replace(match[0], `${(row['__raw'] as Record<string, unknown>)[match[1]]}`);
  }
  return result;
};
