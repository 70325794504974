import React from 'react';
import { Dialog as MuiDialog, Stack } from '@mui/material';
import { displayName } from '../util';

interface Props {
  className?: string;
  header?: React.ReactElement;
  width?: string;
  height?: string;
  children?: React.ReactNode;
}

const Dialog: React.FC<Props> = ({ className, width, height, header, children }) => {
  return (
    <MuiDialog className={className} open={true} maxWidth={false}>
      <Stack
        direction="column"
        width={width}
        height={height !== undefined ? height : '100%'}
        margin={4}
        boxSizing="border-box"
      >
        <Stack direction="row" justifyContent="space-between">
          {header || <div />}
        </Stack>
        {children}
      </Stack>
    </MuiDialog>
  );
};

displayName(Dialog, 'Dialog');

export default Dialog;
