import React, { useState } from 'react';
import { Stack, ToggleButtonGroup, Typography } from '@mui/material';
import ToggleButton from '../../components/ToggleButton';
import TagDefinition from '../../components/tags/TagDefinition';
import TagValues from '../../components/tags/TagValues';
import { displayName, useView } from '../../util';
import { useTranslation } from 'react-i18next';
import SimpleCard from 'components/SimpleCard';
import useDocumentTitle from 'useDocumentTitle';

const Tags: React.FC = () => {
  const { t } = useTranslation();
  const view = useView();
  const [tagView, setTagView] = useState('definition');

  useDocumentTitle(t('application.shortName') + ' - ' + view.label);
  const handleViewChange = (_: unknown, view: string) => {
    if (!!view) {
      setTagView(view);
    }
  };

  return (
    <Stack flexGrow={1}>
      <Stack direction="row" height={theme => theme.spacing(9)} alignItems="center">
        <Typography variant="h1">{view.label}</Typography>
      </Stack>
      <SimpleCard>
        <Stack padding={3} gap={3}>
          <Typography fontWeight={700}>{t('tags.header.title')}</Typography>
          <ToggleButtonGroup color="primary" exclusive={true} value={tagView} onChange={handleViewChange}>
            <ToggleButton value="definition">{t('tags.header.toggle.field')}</ToggleButton>
            <ToggleButton value="values">{t('tags.header.toggle.value')}</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </SimpleCard>
      <Stack marginTop={4} flexGrow={1}>
        {tagView === 'definition' && <TagDefinition />}
        {tagView === 'values' && <TagValues />}
      </Stack>
    </Stack>
  );
};

displayName(Tags, 'Tags');

export default Tags;
