import React, { useCallback, useState } from 'react';
import { useApi, useCostCenterSubscriptions, useRefreshAllCostCenters } from '../../globalState';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import TransferList, { Change, ColumnDef } from '../TransferList';
import { CostCenterDTO, CostCenterSubscriptionDTO, SubscriptionAssignmentDTO } from '../../dto';
import { checkForApiError, displayName, throwApiError } from '../../util';
import { Box, Stack, Typography } from '@mui/material';
import { ActionButton } from '../Button';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import UploadDialog, { FileType, supportedFileTypesToText } from '../dialog/UploadDialog';
import ApplySubscriptionAssignmentDialog from '../dialog/ApplySubscriptionAssignmentDialog';
import { toError, useSetEncounteredError } from '../Error';
import { DownloadSpinner } from '../Spinner';

interface Props {
  rootCostCenter: CostCenterDTO;
}

const CostCenterSubscriptions: React.FC<Props> = ({ rootCostCenter }) => {
  const api = useApi();
  const { t } = useTranslation();
  const { selectedCostCenter } = useCostCenterContext();
  const { assignedSubscriptions, availableSubscriptions } = useCostCenterSubscriptions(selectedCostCenter.costCenterId);
  const refreshAllCostCenters = useRefreshAllCostCenters();
  const setEncounteredError = useSetEncounteredError();
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File>(new File([], ''));
  const defaultResult: SubscriptionAssignmentDTO = {
    addedAssignmentsCostCenterIds: [],
    addedAssignmentsSubscriptionIds: [[]],
    addedAssignmentsSubscriptions: [[]],
    removedAssignmentsCostCenterIds: [],
    removedAssignmentsSubscriptionIds: [[]],
    removedAssignmentsSubscriptions: [[]],
    errorMessageList: [],
  };
  const [subscriptionAssignmentResult, setSubscriptionAssignmentResult] =
    useState<SubscriptionAssignmentDTO>(defaultResult);
  const [suspended, setSuspended] = useState(false);
  const [applying, setApplying] = useState<boolean>(false);

  const handleSubscriptionExportWaitingState = useCallback((isWaiting: boolean) => {
    setSuspended(isWaiting);
  }, []);
  const columns: ColumnDef[] = [
    {
      field: 'subscription',
      headerName: t('costCenters.header.toggle.subscriptions'),
    },
    {
      field: 'account',
      headerName: t('costCenters.header.toggle.accounts'),
    },
    {
      field: 'name',
      headerName: t('costCenters.header.name'),
    },
  ];
  const supportedUploadFileTypes: FileType[] = [FileType.EXCEL];
  const handleUploadClose = useCallback(() => setUploading(false), []);
  const upload = useCallback(
    async (file: File) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      try {
        const newSubscriptionAssignmentResponse = await api.uploadAssignments(file);
        const subscriptionAssignmentResultDTO = newSubscriptionAssignmentResponse.data;
        if (subscriptionAssignmentResultDTO !== undefined && subscriptionAssignmentResultDTO !== null) {
          setSubscriptionAssignmentResult(subscriptionAssignmentResultDTO);
          setFile(file);
          setApplying(true);
        } else {
          throwApiError(newSubscriptionAssignmentResponse.error);
        }
      } catch (err) {
        setEncounteredError(
          t('costCenters.subscriptions.informationDialog.fileUpload.failure', {
            fileName: file.name,
          }),
          toError(err),
          { api: 'uploadAssignments', fileName: file.name }
        );
      }
    },
    [api, setEncounteredError, t]
  );
  const handleApplySubscriptionAssignment = useCallback(() => setApplying(false), []);

  const updateCostCenter = async ({ added, removed }: Change<CostCenterSubscriptionDTO>) => {
    try {
      if (added.length > 0) {
        const response = await api.assignSubscriptionsToCostCenter(
          selectedCostCenter.costCenterId,
          added.map(s => s.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      } else if (removed.length > 0) {
        const response = await api.removeSubscriptionsFromCostCenter(
          selectedCostCenter.costCenterId,
          removed.map(s => s.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      }
    } catch (err) {
      setEncounteredError(
        added.length > 0 ? t('costCenters.subscriptions.error.add') : t('costCenters.subscriptions.error.remove'),
        toError(err),
        {
          api: added.length > 0 ? 'assignSubscriptionsToCostCenter' : 'removeSubscriptionsFromCostCenter',
          id: selectedCostCenter.costCenterId,
        }
      );
    }
  };
  const handleAllAssignmentDownload = () => {
    function handleAllAssignmentDownloadFailure() {
      setEncounteredError(t('costCenters.subscriptions.error.download'), new Error('AllAssignmentDownloadError'), {
        api: 'downloadAssignments',
      });
    }
    api.downloadAssignments(handleAllAssignmentDownloadFailure, handleSubscriptionExportWaitingState);
  };

  return (
    <>
      <Stack direction="row" gap={3}>
        <ActionButton variant="outlined" startIcon={<UploadIcon />} onClick={() => setUploading(true)}>
          {t('costCenters.subscriptions.importExport.upload')}
        </ActionButton>
        <ActionButton
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={handleAllAssignmentDownload}
          disabled={suspended}
        >
          {t('costCenters.subscriptions.importExport.download')}
        </ActionButton>
        <DownloadSpinner size={25} suspended={suspended} />
      </Stack>
      <TransferList
        marginTop={4}
        columns={columns}
        availableTitle={t('costCenters.subscriptions.availableTitle', {
          selectedCostCenterName: selectedCostCenter.name,
        })}
        availableItems={availableSubscriptions}
        assignedTitle={t('costCenters.assignedTitle', {
          selectedCostCenterName: selectedCostCenter.name,
        })}
        assignedItems={assignedSubscriptions}
        onChange={updateCostCenter}
      />
      {uploading && (
        <UploadDialog
          title={t('costCenters.subscriptions.uploadDialog.title')}
          text={
            <Typography>
              {t('costCenters.subscriptions.uploadDialog.text', {
                format: supportedFileTypesToText(supportedUploadFileTypes),
              })}
            </Typography>
          }
          note={
            <Typography component="div">
              <Box fontWeight="bold" display="inline">
                {t('costCenters.subscriptions.uploadDialog.note.emphasis')}
              </Box>{' '}
              {t('costCenters.subscriptions.uploadDialog.note.body')}
            </Typography>
          }
          onClose={handleUploadClose}
          accept={supportedUploadFileTypes}
          upload={upload}
        />
      )}
      {applying && (
        <ApplySubscriptionAssignmentDialog
          root={rootCostCenter}
          subscriptionAssignmentResultDTO={subscriptionAssignmentResult}
          currentFileName={file.name}
          onClose={handleApplySubscriptionAssignment}
          upload={upload}
        />
      )}
    </>
  );
};

displayName(CostCenterSubscriptions, 'CostCenterSubscriptions');

export default CostCenterSubscriptions;
