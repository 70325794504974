import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { RecoilValueReadOnly, useRecoilValueLoadable } from 'recoil';
import { ApiError, ApiResponse } from '../api';
import { useCreateError } from '../components/Error';

export const dataOrThrow = <T>(response: ApiResponse<T>): T => {
  if (response.data) {
    return response.data;
  }
  throw response.error;
};

interface LoadableResponse<T> extends ApiResponse<T> {
  loading: boolean;
  data?: T;
  error?: ApiError;
}

export const useLoadableApiValue = <T>(value: RecoilValueReadOnly<ApiResponse<T>>): LoadableResponse<T> => {
  const valueRef = useRef<T>();
  const errorRef = useRef<ApiError>();
  const navigate = useNavigate();
  const createError = useCreateError();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dep = {};
  useEffect(() => {
    if (dep && errorRef.current?.message === 'Unauthorized') {
      throw new Error('Unauthorized');
    }
  }, [navigate, dep, createError]);
  try {
    const loadable = useRecoilValueLoadable(value);
    if (loadable.state === 'hasError') {
      throw loadable.contents;
    } else if (loadable.state === 'loading') {
      return {
        loading: true,
        data: valueRef.current,
        error: undefined,
      };
    }
    valueRef.current = loadable.contents.data;
    return { loading: false, ...loadable.contents };
  } catch (e) {
    const error = e as ApiError;
    errorRef.current = error;
    return { loading: false, data: undefined, error };
  }
};
