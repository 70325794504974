import React, { useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { fromPairs } from 'lodash';
import Text from '../Text';
import Dialog from '../Dialog';
import SubmitButton from '../formik/SubmitButton';
import { BaseFieldDTO, BaseValueDTO } from '../../dto';
import BaseValuesCombo from './BaseValuesCombo';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { CloseButton } from '../Button';
import { ValidationError } from '../Error';
import FormikTextInput from '../formik/FormikTextInput';
import { SuspendedSpinner } from '../Spinner';
import { useGetFieldTypeNameMap } from '../../globalState';

interface BaseMapping {
  field: BaseFieldDTO;
  value?: BaseValueDTO;
}

interface FormData extends Record<string, string> {
  value: string;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

interface Props {
  tagGroupId: number;
  fieldName: string;
  baseMappings: BaseMapping[];
  alreadyCreatedTags: BaseValueDTO[][];
  value: string | undefined;
  isNew: boolean;
  onCancel: () => void;
  onSave: (baseValues: Record<string, string>, value: string) => Promise<void>;
}

const TagValuesDialog: React.FC<Props> = ({
  tagGroupId,
  fieldName,
  baseMappings,
  alreadyCreatedTags,
  value,
  isNew,
  onCancel,
  onSave,
}) => {
  const fieldTypeNameMap = useGetFieldTypeNameMap();
  const { t } = useTranslation();
  const [errorAmount, setErrorAmount] = useState(0);
  const [allBaseValuesMap, setAllBaseValuesMap] = useState<Map<string, BaseValueDTO[]>>(new Map());
  const [suspended, setSuspended] = useState(false);
  const [warningTextMap] = useState<Map<string, string | undefined>>(new Map());

  const validate = (formData: FormData): ValidationErrors => {
    const allFormDataFields = Object.keys(formData);
    const currentSelectedFields = allFormDataFields.slice(1, allFormDataFields.length);

    const allFormDataValues = Object.values(formData);
    const currentSelectedValues = allFormDataValues.slice(1, allFormDataValues.length);
    const combinedCurrentSelectedValues = currentSelectedValues.toString();

    const errors: ValidationErrors = {};
    let errorCount = 0;

    if (isNew) {
      //check for whether the fields even exist for which a tag should be created
      currentSelectedFields.forEach(currentSelectedField => {
        const currentSelectedRawValue = formData[currentSelectedField];

        const allPossibleValues = allBaseValuesMap.get(currentSelectedField);
        if (allPossibleValues) {
          let valueDoesNotExist = true;
          allPossibleValues.forEach(possibleValue => {
            if (
              //the currentSelectedRawValue equals the raw value if it comes from the autocomplete and it equals the value if it
              //is typed in manually. However, the raw value is stored in a tag.
              possibleValue.rawValue.toString() === currentSelectedRawValue.toString()
            ) {
              valueDoesNotExist = false;
            }
          });
          let fieldTypeName = undefined;
          if (fieldTypeNameMap.has(currentSelectedField)) {
            fieldTypeName = fieldTypeNameMap.get(currentSelectedField);
          }
          if (valueDoesNotExist) {
            //A user should be able to also create tags based on baseValues that currently do not exist.
            //A non-existing value for a string baseField could eventually be matched, because (presumably)
            //the value and the raw-value of a string baseValue are the same.
            //A non-existing value for a non-string baseField can never be matched, because any new base values
            //will have a non-string raw-value.
            if (fieldTypeName !== undefined && fieldTypeName === 'String') {
              warningTextMap.set(currentSelectedField, t(`tags.values.dialog.editing.error.nonExisting`));
            } else {
              errors[currentSelectedField] = {
                message: t(`tags.values.dialog.editing.error.invalid`),
              };
              warningTextMap.set(currentSelectedField, undefined);
              errorCount += 1;
            }
          }
        }
      });
      //check for whether a tag already exists for the selected fields
      alreadyCreatedTags.forEach(existingTag => {
        //because a new field combination could match an existing field combination based on a mix of manually typed fields (values)
        //and fields selected from the autocomplete (raw values), all possible combinations have to be checked
        const allPossibleCombinations: Set<string> = determineAllPossibleCombinations(existingTag);
        if (allPossibleCombinations.has(combinedCurrentSelectedValues)) {
          const errorMessage =
            baseMappings.length === 1
              ? t('tags.values.dialog.editing.error.duplicateField.single')
              : t('tags.values.dialog.editing.error.duplicateField.plural');
          baseMappings.forEach(baseValue => {
            errors[baseValue.field.name] = { message: errorMessage };
            errorCount += 1;
          });
        }
      });
    }

    baseMappings.forEach(baseValue => {
      if (!formData[baseValue.field.name] || formData[baseValue.field.name].trim().length === 0) {
        errors[baseValue.field.name] = { message: t('tags.values.dialog.editing.error.emptyBaseValue') };
        errorCount += 1;
      }
    });
    if (!formData.value || formData.value.trim().length === 0) {
      errors.value = { message: t('tags.values.dialog.editing.error.emptyTag') };
      errorCount += 1;
    }

    setErrorAmount(errorCount);
    return errors;
  };
  const handleSave = useCallback(
    async (formData: FormData) => {
      const { value, ...baseValues } = formData;
      setSuspended(true);
      await onSave(baseValues, value);
      setSuspended(false);
    },
    [onSave]
  );

  return (
    <Dialog width="800px" header={<Typography variant="h2">{t('tags.values.dialog.editing.title')}</Typography>}>
      <Formik
        initialValues={
          fromPairs(
            [['value', value ?? '']].concat(
              baseMappings.map(baseMapping => [baseMapping.field.name, baseMapping.value?.rawValue ?? ''])
            )
          ) as FormData
        }
        validate={validate}
        onSubmit={handleSave}
      >
        <>
          <Stack direction="column" gap={4} marginTop={4}>
            <Text weight="700">{t('tags.values.dialog.editing.baseValues')}</Text>
            {baseMappings.map((b, i) => (
              <Stack key={i} direction="row">
                <Text width={12}>{b.field.label}</Text>
                {isNew ? (
                  <BaseValuesCombo
                    tagGroupId={tagGroupId}
                    name={b.field.name}
                    baseField={b.field.name}
                    width={12}
                    setAllBaseValuesMap={setAllBaseValuesMap}
                    allBaseValuesMap={allBaseValuesMap}
                    warningText={warningTextMap.get(b.field.name)}
                  />
                ) : (
                  <Text>{b.value?.value}</Text>
                )}
              </Stack>
            ))}
          </Stack>
          <Stack direction="column" gap={4} marginTop={4}>
            <Text weight="700">{t('tags.values.dialog.editing.assignedValues')}</Text>
            <FormikTextInput label={fieldName} fieldName="value" inputWidth={12} textWidth={12} gap={0} />
          </Stack>
          <SuspendedSpinner suspended={suspended} />
          <Stack direction="row" justifyContent="space-between" marginTop={3}>
            <CloseButton onClose={onCancel} />
            <SubmitButton additionalDisabledCondition={errorAmount > 0}>{t('dialog.save')}</SubmitButton>
          </Stack>
        </>
      </Formik>
    </Dialog>
  );
};

displayName(TagValuesDialog, 'TagValuesDialog');

export default TagValuesDialog;

function determineAllPossibleCombinations(existingTag: BaseValueDTO[]): Set<string> {
  const allCombinations: string[] = getAllCombinations('', 0, existingTag);
  return new Set(allCombinations);
}

function getAllCombinations(previousString: string, index: number, existingTag: BaseValueDTO[]): string[] {
  const beginning = previousString.length === 0 ? '' : previousString + ',';
  const newString1 = beginning + existingTag[index].value;
  const newString2 = beginning + existingTag[index].rawValue;

  if (index === existingTag.length - 1) {
    return [newString1, newString2];
  } else {
    const result1 = getAllCombinations(newString1, index + 1, existingTag);
    const result2 = getAllCombinations(newString2, index + 1, existingTag);
    return [...result1, ...result2];
  }
}
