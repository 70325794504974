import React from 'react';
import DefaultSelect from '../Select';
import { FilterState, useFieldValues } from '../../globalState';
import { MenuItem, SelectChangeEvent, useTheme } from '@mui/material';
import { displayName } from '../../util';
import Checkbox from '../Checkbox';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const PlaceholderText = styled('span')`
  color: rgba(0, 0, 0, 0.38);
`;

interface Props {
  filterState: FilterState;
  onChange: (state: FilterState) => void;
}

const MultiSelectFilter: React.FC<Props> = ({ filterState, onChange }) => {
  const fieldValues = useFieldValues(filterState.field.name);
  const theme = useTheme();
  const { t } = useTranslation();

  const items =
    filterState.field.filterChoice ?? fieldValues.values.map(v => ({ value: v.raw + '', label: v.formatted }));
  const idToLabelDic = keyBy(items, 'value');
  const selectedItems = filterState.choice !== undefined ? ['', ...filterState.choice] : [''];

  const selectedItemsSet = new Set(selectedItems);
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const newValue = (e.target.value as string[]).filter(it => it !== '');
    onChange({
      ...filterState,
      choice: newValue,
    });
  };

  return (
    <DefaultSelect
      width={12}
      multiple={true}
      value={selectedItems}
      onChange={handleChange}
      disabled={items.length === 0}
      renderValue={(selectedItems: unknown) => {
        const selectItemsStringList = selectedItems as string[];
        if (selectItemsStringList.length === 1) {
          if (items.length === 0) {
            return <PlaceholderText>{t('sidebar.filter.additional.noResult')}</PlaceholderText>;
          } else {
            return <PlaceholderText>{t('sidebar.filter.additional.placeholder')}</PlaceholderText>;
          }
        } else {
          return (
            <span>
              {selectItemsStringList
                .filter(it => it !== '' && it in idToLabelDic)
                .map(it => idToLabelDic[it].label)
                .join(', ')}
            </span>
          );
        }
      }}
      customHeight={theme.custom.additionalFilters.customHeight}
    >
      {items.map((v, i) => (
        <MenuItem key={i} value={v.value}>
          <Checkbox
            checked={selectedItemsSet.has(v.value)}
            sx={{ margin: 0, paddingTop: '4px', paddingBottom: '4px', paddingLeft: '0', paddingRight: '4px' }}
          />
          {v.label}
        </MenuItem>
      ))}
    </DefaultSelect>
  );
};

displayName(MultiSelectFilter, 'MultiSelectFilter');

export default MultiSelectFilter;
