import React, { useMemo, useRef } from 'react';
import { ChartItemDTO } from '../../dto';
import { ResponsiveLine } from '@nivo/line';
import { Typography, useTheme } from '@mui/material';
import { displayName } from '../../util';
import { DownloadContainer, ChartDownloadComponent } from './ChartDownloadComponent';

type FormatterType = (value: number) => string;

interface Props {
  data: ChartItemDTO[];
  valueAxisLabel: string;
  fill: boolean;
  valueFormatter?: FormatterType;
  title?: string;
}

const LineChart: React.FC<Props> = ({ data, valueAxisLabel, fill, valueFormatter, title }) => {
  const theme = useTheme();

  const chart = useRef<HTMLDivElement | null>(null);
  const lineData = useMemo(() => ({ id: '1', data: data.map(item => ({ x: item.category, y: item.value })) }), [data]);
  return (
    <DownloadContainer ref={chart}>
      <ChartDownloadComponent chart={chart} />
      {title && (
        <Typography variant={'h1'} sx={{ textAlign: 'center', marginTop: '15px' }}>
          {title}
        </Typography>
      )}
      <ResponsiveLine
        data={[lineData]}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        colors={[theme.palette.primary.main]}
        margin={{ top: 16, right: 32, bottom: 77, left: 96 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: valueAxisLabel,
          legendPosition: 'middle',
          legendOffset: -80,
          format: valueFormatter,
        }}
        theme={{
          text: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
          },
          axis: {
            legend: {
              text: {
                fontWeight: 500,
              },
            },
          },
        }}
        defs={[
          {
            id: 'gradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: theme.palette.grey[100] },
              { offset: 100, color: theme.palette.primary.main },
            ],
          },
        ]}
        fill={[
          {
            match: {},
            id: 'gradient',
          },
        ]}
        enableArea={fill}
        areaOpacity={1}
      />
    </DownloadContainer>
  );
};

displayName(LineChart, 'LineChart');

export default LineChart;
