import React from 'react';
import { Box, CardActions, CardContent, Typography } from '@mui/material';
import { displayName } from '../../util';
import SimpleCard from 'components/SimpleCard';

interface Props {
  name: string;
  title: string;
  children?: React.ReactNode;
  paddingBottom?: string;
}

const FilterPanel: React.FC<Props> = ({ title, children, paddingBottom }) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <SimpleCard>
        <CardContent>
          <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
        </CardContent>
        <CardActions sx={{ paddingBottom: paddingBottom }}>{children}</CardActions>
      </SimpleCard>
    </Box>
  );
};

displayName(FilterPanel, 'FilterPanel');

export default FilterPanel;
