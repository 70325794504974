import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { displayName } from '../../util';

interface Props {
  label: string;
  name: string;
  value?: string;
}
//To use this component, the formikState has to include a value of the same name that is of type string[]
//This component is checked, if in the formikState, the value of this checkbox is in this list
const FormikCheckbox: React.FC<Props> = ({ label, name, value }) => {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();

  const selectedValues = value ? ((values[name] ?? []) as string[]) : [];
  const checked = value ? selectedValues.includes(value) : !!values[name];
  const handleChange = (_: React.ChangeEvent, checked: boolean) => {
    if (value) {
      setFieldValue(name, checked ? selectedValues.concat(value) : selectedValues.filter(v => v !== value));
    } else {
      setFieldValue(name, checked);
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox name={name} value={value} checked={checked} onChange={handleChange} />}
      label={label}
    />
  );
};

displayName(FormikCheckbox, 'FormikCheckbox');

export default FormikCheckbox;
