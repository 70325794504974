import { ApiResponse } from '../api';
import { CostCenterAccountsDTO, CostCenterDTO, CostCenterSubscriptionsDTO, CostCenterUsersDTO } from '../dto';
import { clientLanguageState } from './telcobill';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import {
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

export const costCenterQuery = selectorFamily<ApiResponse<CostCenterDTO>, number>({
  key: 'costCenter',
  get:
    ccId =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).getCostCenter(ccId);
    },
});

export const useGetCostCenter = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (ccId: number) => {
        const response = await snapshot.getPromise(costCenterQuery(ccId));
        return dataOrThrow(response);
      },
    []
  );
};

export const costCentersQuery = selector<ApiResponse<CostCenterDTO>>({
  key: 'costCenters',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getCostCenters();
  },
});

export const useCostCenters = () => dataOrThrow(useRecoilValue(costCentersQuery));

export const useRefreshCostCenters = () => useRecoilRefresher_UNSTABLE(costCentersQuery);

export const costCenterFetchDateQuery = selector<Date>({
  key: 'costCenterFetchDate',
  get: ({ get }) => {
    return new Date();
  },
});

export const useRefreshAllCostCenters = () => useRecoilRefresher_UNSTABLE(costCenterFetchDateQuery);

const costCenterAccountsQuery = selectorFamily<ApiResponse<CostCenterAccountsDTO>, number>({
  key: 'costCenterAccounts',
  get:
    ccId =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const lastFetchDate = get(costCenterFetchDateQuery);
      //the above line is here just so that all calls to the costCenterAccountsQuery can be refreshed simultaneously
      //by refreshing the costCenterFetchDateQuery
      return await get(apiClient(lang)).getCostCenterAccounts(ccId);
    },
});

export const useCostCenterAccounts = (ccId: number) => dataOrThrow(useRecoilValue(costCenterAccountsQuery(ccId)));

const costCenterSubscriptionsQuery = selectorFamily<ApiResponse<CostCenterSubscriptionsDTO>, number>({
  key: 'costCenterSubscriptions',
  get:
    ccId =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const lastFetchDate = get(costCenterFetchDateQuery);
      //the above line is here just so that all calls to the costCenterAccountsQuery can be refreshed simultaneously
      //by refreshing the costCenterFetchDateQuery
      return await get(apiClient(lang)).getCostCenterSubscriptions(ccId);
    },
});

export const useCostCenterSubscriptions = (ccId: number) =>
  dataOrThrow(useRecoilValue(costCenterSubscriptionsQuery(ccId)));

const costCenterUsersQuery = selectorFamily<ApiResponse<CostCenterUsersDTO>, number>({
  key: 'costCenterUsers',
  get:
    ccId =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const lastFetchDate = get(costCenterFetchDateQuery);
      //the above line is here just so that all calls to the costCenterAccountsQuery can be refreshed simultaneously
      //by refreshing the costCenterFetchDateQuery
      return await get(apiClient(lang)).getCostCenterUsers(ccId);
    },
});

export const useCostCenterUsers = (ccId: number) => dataOrThrow(useRecoilValue(costCenterUsersQuery(ccId)));

export type TreeType = 'costCenter' | 'assignmentUpload';

export const expandedTreeItems = atomFamily<string[], TreeType>({
  key: 'expandedTreeItems',
  default: [],
});

export const useExpandedTreeItems = (treeKey: TreeType) => useRecoilValue(expandedTreeItems(treeKey));

export const useSetExpandedTreeItems = (treeKey: TreeType) => useSetRecoilState(expandedTreeItems(treeKey));
