function App() {
  return (
    <div className="App" style={{ height: '100%' }}>
      <h3>Mail Proxy for testing, captures all emails</h3>
      <iframe src="http://localhost:8084/" width="100%" height="100%" title={'mail'}></iframe>
    </div>
  );
}

export default App;
