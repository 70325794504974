import React, { useCallback, useEffect, useState } from 'react';
import { customToaster } from './Toast';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOGOUT_PAGE, LOGOUT_ADVANCED_WARNING_MILLISECONDS, useApi } from '../globalState';
import { noop } from 'lodash';
import { useLanguage } from '../i18n';

export const ExpirationChecker: React.FC = () => {
  const api = useApi();
  const language = useLanguage();
  const { t } = useTranslation();
  const [showingWarning, setShowingWarning] = useState(false);
  const [, setPreviousWarningTimeout] = useState<NodeJS.Timeout>();
  const [, setPreviousLogOutTimeout] = useState<NodeJS.Timeout>();

  const showLogoutWarning = useCallback(() => {
    if (!showingWarning) {
      setShowingWarning(true);
      customToaster.warning(t('backgroundWorker.expirationWarning'), true);
      setShowingWarning(false);
    }
  }, [showingWarning, t]);

  const handleLogout = useCallback(async () => {
    const response = await api.logout(language, true);
    const newUrl = response?.data?.redirect ?? DEFAULT_LOGOUT_PAGE.replace(':lng', language);
    window.location.replace(newUrl);
  }, [api, language]);

  const handleExpirationEvent = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent;
      const expirationDate = new Date(customEvent.detail);
      const now = new Date();
      //we subtract 2 seconds from the cookie expiration date so that any request made after being logged out does not
      //accidentally create a new session
      const timeUntilLogout = expirationDate.getTime() - now.getTime() - 2000;
      const timeUntilLogoutWarning = timeUntilLogout - LOGOUT_ADVANCED_WARNING_MILLISECONDS;

      if (timeUntilLogoutWarning >= LOGOUT_ADVANCED_WARNING_MILLISECONDS) {
        const logoutWarningTimer = setTimeout(() => showLogoutWarning(), timeUntilLogoutWarning);
        setPreviousWarningTimeout(previousLogOutTimeout => {
          clearTimeout(previousLogOutTimeout);
          return logoutWarningTimer;
        });

        const logoutTimer = setTimeout(() => handleLogout(), timeUntilLogout);
        setPreviousLogOutTimeout(previousLogOutTimeout => {
          clearTimeout(previousLogOutTimeout);
          return logoutTimer;
        });

        return () => {
          clearTimeout(logoutWarningTimer);
          clearTimeout(logoutTimer);
        };
      }
      return () => noop();
    },
    [handleLogout, showLogoutWarning]
  );

  useEffect(() => {
    document.addEventListener('expirationEvent', handleExpirationEvent);
    return () => document.removeEventListener('expirationEvent', handleExpirationEvent);
  }, [handleExpirationEvent]);

  return <></>;
};
