/* eslint-disable prettier/prettier */
import React, { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { alpha, Collapse, Stack, Typography } from '@mui/material';
import CollapsedIcon from '@mui/icons-material/ChevronRight';
import ExpandedIcon from '@mui/icons-material/ExpandMore';
import { ConnectableElement, useDrag, useDrop } from 'react-dnd';
import { TreeNode, useTreeContext } from './treeutil';
import TreeItemLabel from './TreeItemLabel';
import { useTranslation } from 'react-i18next';
import { ChildrenLabels } from './ChildrenLabel';

const Container = styled(Stack, {
  shouldForwardProp: prop => (prop as string) !== 'selected' && (prop as string) !== 'isDropOver',
})<{
  selected: boolean;
  isDropOver: boolean;
}>`
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  ${({ theme, selected, isDropOver }) =>
    isDropOver
      ? `background: ${alpha(theme.palette.success.main, 0.2)};`
      : selected
      ? `background: ${alpha(theme.palette.primary.main, 0.08)};`
      : ''}
  &:hover {
    background: ${({ theme, selected }) =>
      selected ? alpha(theme.palette.primary.main, 0.12) : 'rgba(0, 0, 0, 0.04)'};
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    padding: 0 4px;
  }
`;

const StyledStack = styled(Stack)`
  &:hover > * > * {
    color: ${({ theme }) => theme.palette.hover.main} !important;
  }
`;

const isChild = (source: TreeNode, target: TreeNode): boolean => {
  for (const child of source.children) {
    if (child.id === target.id || isChild(child, target)) {
      return true;
    }
  }
  return false;
};

interface Props {
  node: TreeNode;
  children?: React.ReactNode;
}

const TreeItem: React.FC<Props> = ({ node, children }) => {
  const { selectedNode, onSelectionChange, expanded, toggleExpanded, enableDnd, onDrop, filter, filterNoResult } =
    useTreeContext();
  const { t } = useTranslation();
  const [, drag] = useDrag(
    () => ({
      type: 'TreeNode',
      item: node,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [node]
  );
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'TreeNode',
      drop: (droppedNode: TreeNode) => {
        if (droppedNode.id !== node.id) {
          onDrop(droppedNode.value, node.value);
        }
      },
      canDrop: source =>
        source.id !== node.id && !node.children.map(c => c.id).includes(source.id) && !isChild(source, node),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [node]
  );

  const handleIconClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      toggleExpanded(node.id);
    },
    [toggleExpanded, node.id]
  );
  const hasAdditions = node.additions === undefined ? false : node.additions.length > 0;
  const hasChildren = node.children.length > 0 || hasAdditions;
  const highlight = useMemo(() => {
    const trimmedFilter = filter.trim().toLocaleLowerCase();
    if (!trimmedFilter) {
      return;
    }
    let match = null;
    try {
      const searchExpression = '^' + trimmedFilter;
      match = node.label.toLocaleLowerCase().match(new RegExp(searchExpression));
    } catch (e) {}
    return typeof match?.index === 'number' ? { index: match.index, length: trimmedFilter.length } : undefined;
  }, [filter, node.label]);
  const isExpanded = expanded.includes(node.id);

  return (
    <>
      {filterNoResult ? (
        <Typography>{t('sidebar.costCenters.noResult')}</Typography>
      ) : (
        <StyledStack direction="column" width="100%" onClick={handleIconClick}>
          <Container
            direction="row"
            alignItems="center"
            selected={selectedNode.id === node.id}
            isDropOver={isOver && canDrop}
            gap="4px"
            ref={enableDnd ? (node: ConnectableElement) => drag(drop(node)) : undefined}
            onClick={() => onSelectionChange(node)}
          >
            <Stack width="26px">
              {hasChildren && isExpanded && <ExpandedIcon />}
              {hasChildren && !isExpanded && <CollapsedIcon />}
            </Stack>
            <TreeItemLabel
              label={node.label}
              highlight={highlight}
              stylingNotHighlighted={node.stylingNotHighlighted}
            />
          </Container>
          <Stack marginLeft={4}>
            <Collapse in={isExpanded}>
              <ChildrenLabels additions={node.additions} children={children} />
            </Collapse>
          </Stack>
        </StyledStack>
      )}
    </>
  );
};

export default TreeItem;
