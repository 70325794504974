import React, { ProviderProps, useContext } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type ChangeType = 'added' | 'removed';

export interface Addition {
  id: string;
}

export interface SubscriptionChange extends Addition {
  changeType: ChangeType;
}

export function instanceOfSubscriptionChange(object: Addition): object is SubscriptionChange {
  return 'changeType' in object;
}

export interface TreeNode<T = unknown> {
  id: string;
  label: string;
  value: T;
  children: TreeNode<T>[];
  stylingNotHighlighted?: SxProps<Theme>;
  additions?: Addition[];
}

interface TreeContextProps<T> {
  selectedNode: TreeNode<T>;
  onSelectionChange: (node: TreeNode<T>) => void;
  expanded: string[];
  toggleExpanded: (nodeId: string) => void;
  enableDnd: boolean;
  onDrop: (source: T, target: T) => void;
  filter: string;
  filterNoResult: boolean;
}

const createContext = <T>() => React.createContext<TreeContextProps<T>>({} as never);

const TreeContext = createContext();

export const useTreeContext = () => useContext(TreeContext);

export const TreeContextProvider: <T>(props: ProviderProps<TreeContextProps<T>>) => React.ReactElement =
  TreeContext.Provider as never;

const createChildToParentMap = (
  root: TreeNode | undefined,
  childLabelToParentIdMap: Map<string, Set<string>>,
  parentIdList: string[]
) => {
  if (root === undefined) {
    return;
  }
  const currentLabel = root.label;
  const currentId = root.id;
  let currentValue = childLabelToParentIdMap.get(currentLabel);
  if (currentValue === undefined) {
    currentValue = new Set<string>();
  }
  parentIdList.map(it => currentValue?.add(it));
  childLabelToParentIdMap.set(currentLabel, currentValue);
  parentIdList.push(currentId);
  root.children.map(it => createChildToParentMap(it, childLabelToParentIdMap, parentIdList));
  root.additions !== undefined &&
    root.additions.map(it => createAdditionToParentMap(it, childLabelToParentIdMap, parentIdList));
};

const createAdditionToParentMap = (
  root: Addition | undefined,
  childLabelToParentIdMap: Map<string, Set<string>>,
  parentIdList: string[]
) => {
  if (root === undefined) {
    return;
  }
  const currentId = root.id;
  let currentValue = childLabelToParentIdMap.get(currentId);
  if (currentValue === undefined) {
    currentValue = new Set<string>();
  }
  parentIdList.map(it => currentValue?.add(it));
  childLabelToParentIdMap.set(currentId, currentValue);
};

const convertMapToTrie = (childToParentMap: Map<string, Set<string>>): Map<string, Set<string>> => {
  const trie = new Map<string, Set<string>>();

  childToParentMap.forEach((value: Set<string>, key: string) => {
    let currentKey = '';
    for (let i = 0; i < key.length; i++) {
      const addition = key.charAt(i);
      currentKey += addition;
      trie.set(currentKey, value);
    }
  });

  return trie;
};

export function createTrie(rootNode: TreeNode): Map<string, Set<string>> {
  const childToParentMap = new Map<string, Set<string>>();
  createChildToParentMap(rootNode, childToParentMap, []);
  return convertMapToTrie(childToParentMap);
}
