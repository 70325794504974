import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { ApiResponse } from '../api';
import { AccountDTO, AccountsDTO, UserDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { clientLanguageState } from './telcobill';

const userQuery = selector<ApiResponse<UserDTO>>({
  key: 'user',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getUser();
  },
});

export const useUser = () => dataOrThrow(useRecoilValue(userQuery));

const accountsQuery = selector<ApiResponse<AccountsDTO>>({
  key: 'accounts',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getAccounts();
  },
});

export const useAccounts = () => useRecoilValue(accountsQuery);

const defaultAccountQuery = selector<AccountDTO>({
  key: 'defaultAccount',
  get: ({ get }) => {
    const accounts = get(accountsQuery).data?.accounts || [];
    return accounts.length > 0 ? accounts[0] : ({} as AccountDTO);
  },
});

export const accountState = atom<AccountDTO>({
  key: 'account',
  default: defaultAccountQuery,
});

export const useAccount = () => useRecoilState(accountState);
