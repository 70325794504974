import React, { useCallback, useState } from 'react';
import { Stack, ToggleButtonGroup } from '@mui/material';
import ToggleButton from '../../components/ToggleButton';
import AdministratorTable from '../../components/administrators/AdministratorTable';
import SimpleLayout from '../../SimpleLayout';
import CustomerBlacklist from '../../components/administrators/CustomerBlacklist';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import SimpleCard from '../../components/SimpleCard';
import useDocumentTitle from '../../useDocumentTitle';

type ViewType = 'administrators' | 'blacklist';

const Administrators: React.FC = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<ViewType>('administrators');

  useDocumentTitle(t('application.shortName') + ' - ' + t('admin.administrators.title'));
  const handleViewChange = useCallback((_: unknown, view: ViewType) => {
    if (!!view) {
      setView(view);
    }
  }, []);

  return (
    <SimpleLayout title={t('admin.administrators.title')}>
      <Stack direction="column" flexGrow={1}>
        <SimpleCard>
          <Stack padding={3}>
            <ToggleButtonGroup color="primary" exclusive={true} value={view} onChange={handleViewChange}>
              <ToggleButton value="administrators">
                {t('admin.administrators.header.toggle.administrators')}
              </ToggleButton>
              <ToggleButton value="blacklist">{t('admin.administrators.header.toggle.blacklist')}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </SimpleCard>
        {view === 'administrators' && <AdministratorTable />}
        {view === 'blacklist' && <CustomerBlacklist />}
      </Stack>
    </SimpleLayout>
  );
};

displayName(Administrators, 'Administrators');

export default Administrators;
