import React, { useCallback } from 'react';
import CostCenterTree from '../costcenter/CostCenterTree';
import { useAnalysisCostCenter, useCostCenters, useSetCurrentPageToZero } from '../../globalState';
import FilterPanel from './FilterPanel';
import { displayName } from '../../util';
import { AnalysisFilterComponentProps } from './AnalysisFilterComponent';
import { useTranslation } from 'react-i18next';
import { CostCenterDTO } from '../../dto';

const CostCenterFilter: React.FC<AnalysisFilterComponentProps> = () => {
  const root = useCostCenters();
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const { t } = useTranslation();
  const [selectedCostCenter, setSelectedCostCenter] = useAnalysisCostCenter();

  const handleSelectionChange = useCallback(
    (selected: CostCenterDTO) => {
      if (selected !== selectedCostCenter) {
        setAnalysisPageToZero();
      }
      setSelectedCostCenter(selected);
    },
    [selectedCostCenter, setAnalysisPageToZero, setSelectedCostCenter]
  );

  return (
    <FilterPanel name="costcenter" title={t('sidebar.costCenters.title')}>
      <CostCenterTree root={root} selectedCostCenter={selectedCostCenter} onSelectionChange={handleSelectionChange} />
    </FilterPanel>
  );
};

displayName(CostCenterFilter, 'CostCenterFilter');

export default CostCenterFilter;
