import React, { useCallback, useMemo } from 'react';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import Text from '../Text';
import DefaultSelect from '../Select';
import { toMenuItem } from './util';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import {
  useApplicationStatisticsChartConfig,
  useApplicationStatisticsGrouping,
  useApplicationStatisticsSeries,
} from '../../globalState';

const ApplicationStatisticsHeader: React.FC = () => {
  const [values, setValues] = useApplicationStatisticsChartConfig();
  const series = useApplicationStatisticsSeries();
  const grouping = useApplicationStatisticsGrouping();
  const { t } = useTranslation();

  const seriesOptions = useMemo(() => series.map(toMenuItem), [series]);
  const groupingOptions = useMemo(() => grouping.map(toMenuItem), [grouping]);
  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const { name, value } = e.target;
      setValues(v => ({ ...v, [name]: value }));
    },
    [setValues]
  );

  return (
    <Stack direction="row" gap={5} marginLeft={4} marginBottom={4}>
      <Stack direction="row" alignItems="center">
        <Text weight="500" width={9}>
          {t('admin.statistics.application.header.data')}
        </Text>
        <DefaultSelect name="series" value={values.series} onChange={handleSelectChange} width={12}>
          {seriesOptions}
        </DefaultSelect>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Text weight="500" width={9}>
          {t('admin.statistics.application.header.grouping')}
        </Text>
        <DefaultSelect name="grouping" value={values.grouping} onChange={handleSelectChange} width={11}>
          {groupingOptions}
        </DefaultSelect>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Text weight="500" width={9}>
          {t('admin.statistics.application.header.aggregation.label')}
        </Text>
        <DefaultSelect name="aggregation" value={values.aggregation} onChange={handleSelectChange} width={9}>
          <MenuItem value="Min">{t('admin.statistics.application.header.aggregation.min')}</MenuItem>
          <MenuItem value="Avg">{t('admin.statistics.application.header.aggregation.avg')}</MenuItem>
          <MenuItem value="Max">{t('admin.statistics.application.header.aggregation.max')}</MenuItem>
        </DefaultSelect>
      </Stack>
    </Stack>
  );
};

displayName(ApplicationStatisticsHeader, 'ApplicationStatisticsHeader');

export default ApplicationStatisticsHeader;
