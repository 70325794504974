import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarLayout from '../../SidebarLayout';
import InvoiceFilter from '../../components/filter/InvoiceFilter';
import InvoicePeriodFilter from '../../components/filter/InvoicePeriodFilter';
import DrilldownHeader from '../../components/drilldown/DrilldownHeader';
import DrilldownTable from '../../components/drilldown/DrilldownTable';
import { displayName, useView } from '../../util';
import useDocumentTitle from '../../useDocumentTitle';

const Bills: React.FC = () => {
  const view = useView();
  const { t } = useTranslation();

  useDocumentTitle(t('application.shortName') + ' - ' + view.label);
  return (
    <SidebarLayout
      title={view.label}
      sidebarContent={
        <>
          <InvoiceFilter />
          <InvoicePeriodFilter />
        </>
      }
    >
      <DrilldownHeader />
      <DrilldownTable />
    </SidebarLayout>
  );
};

displayName(Bills, 'Bills');

export default Bills;
