import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mui/material';

interface Props {
  id: string;
  onMove: (id: string, to: number) => void;
  findItem: (id: string) => { index: number };
  onCommit: () => void;
  children?: React.ReactNode;
}

interface Item {
  id: string;
  originalIndex: number;
}

const DraggableListItem: React.FC<Props> = ({ id, children, onMove, findItem, onCommit }) => {
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ListItem',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (didDrop) {
          onCommit();
        } else {
          onMove(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, onMove, onCommit]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'ListItem',
      hover: ({ id: draggedId }: Item) => {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          onMove(draggedId, overIndex);
        }
      },
    }),
    [findItem, onMove]
  );
  return (
    <Box
      ref={((node: React.RefObject<HTMLDivElement>) => drag(drop(node))) as never}
      flex={{ shrink: 0 }}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      {children}
    </Box>
  );
};

DraggableListItem.displayName = 'DraggableListItem';

export default DraggableListItem;
