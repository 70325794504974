import { atom, selector, useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil';
import { TagGroupsDTO, TagsDTO } from '../dto';
import { apiClient } from './apiClient';
import { dataOrThrow } from './util';
import { clientLanguageState } from './telcobill';

const tagGroupsQuery = selector<TagGroupsDTO>({
  key: 'tagGroups',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const tagGroups = await get(apiClient(lang)).getTagGroups();
    return dataOrThrow(tagGroups);
  },
});

export const useTagGroups = () => useRecoilValue(tagGroupsQuery);

const selectedTagGroupIdState = atom<number | undefined>({
  key: 'selectedTagGroupIdState',
  default: undefined,
});

export const useSetSelectedTagGroupId = () => useSetRecoilState(selectedTagGroupIdState);

const tagQuery = selector<TagsDTO | undefined>({
  key: 'tags',
  get: async ({ get }) => {
    const lang = get(clientLanguageState);
    const selectedTagGroupId = get(selectedTagGroupIdState);
    if (selectedTagGroupId === undefined) {
      return undefined;
    }
    const tagGroups = await get(apiClient(lang)).getTagsOfTagGroup(selectedTagGroupId);
    return dataOrThrow(tagGroups);
  },
});

export const useTags = () => useRecoilValue(tagQuery)?.tags;

export const useRefreshTagGroups = () => useRecoilRefresher_UNSTABLE(tagGroupsQuery);

export const useRefreshTags = () => useRecoilRefresher_UNSTABLE(tagQuery);

//The fieldTypes of a baseValue of a tag are received in a BaseValuesCombo component but needed in the parent component
//TagValuesDialog. Therefore, they are stored in this atom.
export const fieldTypeNameMap = atom<Map<string, string>>({
  key: 'baseValueFieldTypeName',
  default: new Map<string, string>(),
});

export const useGetFieldTypeNameMap = () => useRecoilValue(fieldTypeNameMap);

export const useSetBaseValueFieldTypeName = (baseField: string) => {
  const setFieldTypeNameMap = useSetRecoilState(fieldTypeNameMap);
  const currentMap = useGetFieldTypeNameMap();

  return (fieldType: string) => {
    const updatedMap = currentMap.set(baseField, fieldType);
    setFieldTypeNameMap(updatedMap);
  };
};
