import React from 'react';
import { Stack } from '@mui/material';
import { useAnalysisSummaryFields } from '../../globalState';
import Text from '../Text';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { RoundGreyStack } from '../Container';

interface Props {
  className?: string;
  data: Record<string, unknown>;
}

const AnalysisSummary: React.FC<Props> = ({ className, data }) => {
  const { t } = useTranslation();
  const summaryFields = useAnalysisSummaryFields();

  return (
    <RoundGreyStack className={className} direction="row" gap={5} padding={3} height="38px">
      <Stack direction="column" flexGrow={1}>
        <Text weight="700">{t('analysis.table.summary.total')}</Text>
        <Text weight="700">{t('analysis.table.summary.information')}</Text>
      </Stack>
      {summaryFields.fields.map((f, i) => (
        <Stack key={i} direction="column">
          <Text weight="700">{f.label}</Text>
          <Text>{(data[f.name] ?? '-') + ''}</Text>
        </Stack>
      ))}
    </RoundGreyStack>
  );
};

displayName(AnalysisSummary, 'AnalysisSummary');

export default AnalysisSummary;
