import React, { useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ListItem from './ListItem';
import { displayName } from '../../util';

interface Props {
  id: string;
  label: string;
  onMove: (id: string, to: number) => void;
  findItem: (id: string) => { index: number };
  onDelete: (id: string) => void;
  onCommit: () => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

const DraggableListItem: React.FC<Props> = ({ id, label, onMove, findItem, onDelete, onCommit }) => {
  const handleDelete = useCallback(() => onDelete(id), [id, onDelete]);
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'ListItem',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (didDrop) {
          onCommit();
        } else {
          onMove(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, onMove, onCommit]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'ListItem',
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          onMove(draggedId, overIndex);
        }
      },
    }),
    [findItem, onMove]
  );
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Stack ref={node => drag(drop(node))}>
      <ListItem label={label} hidden={isDragging} onDelete={handleDelete} />
    </Stack>
  );
};

displayName(DraggableListItem, 'DraggableListItem');

export default DraggableListItem;
