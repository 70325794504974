import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { deDE, LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLanguage } from './i18n';
import { displayName } from './util';

LicenseInfo.setLicenseKey(
  'f7ab92ddc8f35fa23ceb533e89b455c9Tz04NDM4NSxFPTE3Mzk2Mjk2NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

declare module '@mui/material/styles' {
  interface Theme {
    table: {
      row: {
        height: string;
        even: string;
        odd: string;
      };
    };
    custom: {
      additionalFilters: {
        customHeight: number;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    table?: {
      row?: {
        height?: string;
        even?: string;
        odd?: string;
      };
    };
    custom: {
      additionalFilters: {
        customHeight: number;
      };
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    hover: Palette['primary'];
    hoverGrey: Palette['primary'];
    pressed: Palette['primary'];
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    hover: PaletteOptions['primary'];
    hoverGrey: PaletteOptions['primary'];
    pressed: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
  }
}

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#DA291C',
      },
      hover: {
        main: '#8a281d',
      },
      hoverGrey: {
        main: '#f1f1f1',
      },
      pressed: {
        main: '#b94260',
      },
      disabled: {
        main: '#e4e3df',
      },
      secondary: {
        main: '#757575',
      },
      grey: {
        '100': '#F7F6F5',
        '200': '#E6E3DF',
        '300': '#B3AEAA',
        '400': '#86807C',
        '500': '#5F5955',
        '600': '#3D3935',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
      },
      error: {
        main: '#BF0760',
      },
      success: {
        main: '#8FD200',
      },
      warning: {
        main: '#FFC805',
      },
      info: {
        main: '#2E6199', //Dark Blue Haze
      },
      background: {
        paper: '#FAFAFA',
      },
      text: {
        primary: '#3D3935',
      },
    },
    table: {
      row: {
        height: '47px',
        even: 'white',
        odd: 'white',
      },
    },
    custom: {
      additionalFilters: {
        customHeight: 5,
      },
    },
    typography: {
      fontFamily: 'Avenir Next, Tahoma',
      h1: {
        fontSize: '34px',
        lineHeight: '42px',
        fontWeight: 'bold',
        color: '#3D3935',
      },
      h2: {
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 'bold',
        color: '#3D3935',
      },
      button: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '500',
      },
      body1: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 'normal',
        color: '#3D3935',
      },
    },
    spacing: (s: number) => (Number.isInteger(s) ? [0, 2, 4, 8, 16, 32, 48, 64, 80, 96, 128, 192, 240][s] : 16 * s),
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            height: '32px',
            fontSize: '14px',
            boxSizing: 'border-box',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '4px 8px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            zIndex: 999,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            lineHeight: '24px',
            padding: '6px 8px',
            fontWeight: 700,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '8px 16px',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 16px 16px 16px ',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '25px',
          },
        },
      },
    },
  },
  deDE
);

interface ThemeProviderProps {
  children?: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

displayName(ThemeProvider, 'ThemeProvider');

interface LocalizationProviderProps {
  children?: React.ReactNode;
}

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({ children }) => {
  const language = useLanguage();

  return (
    <MuiLocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      {children}
    </MuiLocalizationProvider>
  );
};
