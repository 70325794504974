import { Stack, Typography } from '@mui/material';
import { ActionButton } from './Button';
import React, { useEffect, useState } from 'react';
import { StyledTextArea } from './TextArea';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../api';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IS_PRODUCTION } from '../env';
import { useApi } from '../globalState';

function isErrorWithMessage(error: unknown): error is Error {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string' &&
    'name' in error &&
    typeof (error as Record<string, unknown>).name === 'string'
  );
}

export function createDetailedError(input: unknown): Error | undefined {
  if (
    typeof input === 'object' &&
    input !== null &&
    'message' in input &&
    typeof (input as Record<string, unknown>).message === 'string' &&
    'details' in input &&
    typeof (input as Record<string, unknown>).details === 'string'
  ) {
    const newError = new Error(input.message as string);
    newError.name = input.details as string;
    return newError;
  } else {
    return undefined;
  }
}

function isApiError(error: unknown): error is ApiError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

export function isEnhancedError(error: unknown): error is EnhancedError {
  return typeof error === 'object' && error !== null && 'error' in error && 'occurrence' in error;
}

function extractEmbeddedErrors(apiErrors: ApiError[], level: string): string {
  let combinedString = '';

  for (let i = 0; i < apiErrors.length; i++) {
    const additionalError = apiErrors[i];
    const updatedLevel = `${level}.${i + 1}`;
    combinedString += `\n${updatedLevel}: ${additionalError.message}`;
    if (additionalError.errors !== undefined && additionalError.errors !== null) {
      combinedString += extractEmbeddedErrors(additionalError.errors, updatedLevel);
    }
  }
  return combinedString;
}

function apiErrorToError(apiError: ApiError): Error {
  let message = apiError.message;
  if (apiError.errors !== undefined && apiError.errors !== null) {
    message += extractEmbeddedErrors(apiError.errors, '0');
  }
  return new Error(message);
}

export function toError(maybeError: unknown): Error {
  if (isErrorWithMessage(maybeError)) return maybeError;
  if (isApiError(maybeError)) return apiErrorToError(maybeError);
  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export type ActionType = 'create' | 'duplicate' | 'delete' | 'edit' | 'upload';

interface AdditionalUserInformation {
  id: number;
  name: string;
}

export interface AdditionalErrorInformation {
  user?: AdditionalUserInformation;
}

export const additionalErrorInformationState = atom<AdditionalErrorInformation>({
  key: 'additionalErrorInformationState',
  default: {},
});

export const useAdditionalErrorInformation = () => useRecoilValue(additionalErrorInformationState);

export const useSetAdditionalUserErrorInformation = () => {
  const [additionalErrorInformation, setAdditionalErrorInformation] = useRecoilState(additionalErrorInformationState);
  return (user: AdditionalUserInformation) => {
    setAdditionalErrorInformation({ ...additionalErrorInformation, user: user });
  };
};

export interface EncounteredError {
  message: string;
  logError: boolean;
  error?: EnhancedError;
}

export interface EnhancedError {
  error: Error;
  occurrence: Date;
  additionalErrorInformation?: AdditionalErrorInformation;
  context?: ErrorContext;
}

export const useCreateError = () => {
  const additionalErrorInformation = useAdditionalErrorInformation();

  return (err: unknown, context?: ErrorContext): EnhancedError => {
    return {
      error: toError(err),
      occurrence: new Date(),
      additionalErrorInformation: additionalErrorInformation,
      context: context,
    };
  };
};

export interface InformationText {
  message: string;
  status: 'error' | 'success';
}

interface Props {
  message: string;
  enhancedError?: EnhancedError;
}

export const ErrorInformationBody: React.FC<Props> = ({ message, enhancedError }) => {
  const { t } = useTranslation();
  const logError = useLogError();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const handleLogError = async () => {
      if (enhancedError) {
        await logError(enhancedError);
      }
    };
    handleLogError().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhancedError]);

  const errorMessageList: string[] = [];
  let contextMessageList: string[] = [];
  if (enhancedError && enhancedError.error) {
    if (enhancedError.error.message) {
      errorMessageList.push(enhancedError.error.message);
    }
    if ('details' in enhancedError) {
      errorMessageList.push(enhancedError['details'] as string);
    }
    if (enhancedError.context) {
      contextMessageList = Object.entries(enhancedError.context).map(it => `${it[0]}: ${it[1]}`);
    }
  }

  return (
    <>
      <Typography>{message}</Typography>
      {!IS_PRODUCTION && enhancedError !== undefined && Object.keys(enhancedError).length > 0 && (
        <>
          <Stack direction="row" justifyContent="start" marginTop={4}>
            <ActionButton
              onClick={() => setShowErrorMessage(!showErrorMessage)}
              endIcon={showErrorMessage ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
              variant={'outlined'}
            >
              {t('errorInformationDialog.buttonLabel')}
            </ActionButton>
          </Stack>
          {showErrorMessage && (
            <Stack marginTop={4}>
              {enhancedError.error && enhancedError.error.name && (
                <Typography>
                  {t('errorInformationDialog.errorType', { errorType: enhancedError.error.name })}
                </Typography>
              )}
              {enhancedError.occurrence && (
                <Typography>
                  {t('errorInformationDialog.occurrence', { occurrence: enhancedError.occurrence.toISOString() })}
                </Typography>
              )}
              {enhancedError.additionalErrorInformation?.user && (
                <Typography>
                  {t('errorInformationDialog.account', {
                    account: enhancedError.additionalErrorInformation?.user.name,
                  })}
                </Typography>
              )}
              {contextMessageList.length > 0 &&
                contextMessageList.map((contextInfo, i) => <Typography key={i}>{contextInfo}</Typography>)}
              {errorMessageList.map((errorMessage, i) => (
                <StyledTextArea readOnly={true} rows={10} value={errorMessage} key={i} />
              ))}
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export interface ErrorContext {
  [key: string]: string | number;
}

const encounteredError = atom<EncounteredError | undefined>({
  key: 'encounteredError',
  default: undefined,
});

export const useResetEncounteredError = () => {
  const store = useSetRecoilState(encounteredError);
  return () => {
    store(undefined);
  };
};
export const useEncounteredError = () => useRecoilValue(encounteredError);

export const useSetEncounteredError = () => {
  const store = useSetRecoilState(encounteredError);
  const createError = useCreateError();
  return (message: string, err: unknown, context?: ErrorContext, logError?: boolean) => {
    const finalLogError = logError !== undefined ? logError : true;
    const res = createError(err, context);
    store({ message: message, error: res, logError: finalLogError });
  };
};

export const useLogError = () => {
  const api = useApi();
  const createError = useCreateError();

  return async (encounteredError: EnhancedError) => {
    let enhancedError = encounteredError;
    if (!isEnhancedError(enhancedError)) {
      enhancedError = createError(enhancedError);
    }
    await api.logError({
      error: { name: enhancedError.error.name, message: enhancedError.error.message, stack: enhancedError.error.stack },
      occurrence: enhancedError.occurrence,
      additionalErrorInformation: enhancedError.additionalErrorInformation,
      context: enhancedError.context,
    });
  };
};

const heartbeatFailure = atom<boolean>({
  key: 'heartbeatFailure',
  default: false,
});

export const useHeartbeatFailure = () => useRecoilValue(heartbeatFailure);

export const useSetHeartbeatFailure = () => useSetRecoilState(heartbeatFailure);

export interface ValidationError {
  message?: string;
}
