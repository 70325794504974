import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Breadcrumbs, Button, Card, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import { DrilldownPathElement, useDrilldown, useSetCurrentPageToZero } from '../../globalState';
import { displayName } from '../../util';
import { ActionButton } from '../Button';

const StyledButton = styled(Button)`
  text-transform: none;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0 ${({ theme }) => theme.spacing(0)};
  &:hover > * > * {
    color: ${({ theme }) => theme.palette.hover.main};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverGrey.main};
  }
`;

const nbsp = '\u00A0';

const DrilldownHeader: React.FC = () => {
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const { elements, goUp } = useDrilldown();
  const { t } = useTranslation();

  const value = (elements: DrilldownPathElement[], i: number): string => {
    if (elements.length > i + 1) {
      const path = elements[i + 1].path;
      if (path) {
        return (elements[i + 1].selection[path.select] ?? t('bills.header.all')) + '';
      }
    }
    return nbsp;
  };

  return (
    <Box>
      <Card variant="elevation" elevation={0} sx={{ padding: '10px 16px 10px 16px' }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Breadcrumbs separator={<NavigateNextIcon />}>
            {elements.map((el, i) => (
              <StyledButton
                key={i}
                disabled={i === elements.length - 1}
                onClick={() => {
                  setCurrentPageToZero();
                  goUp(el.level.name);
                }}
                sx={{ paddingX: '12px', borderRadius: 1 }}
              >
                <Stack direction="column" alignItems="start">
                  <Text weight="700">{el.level.title}</Text>
                  <Text>{value(elements, i)}&nbsp;</Text>
                </Stack>
              </StyledButton>
            ))}
          </Breadcrumbs>
          {elements.length >= 2 && (
            <ActionButton
              variant={'outlined'}
              onClick={() => {
                setCurrentPageToZero();
                goUp(elements[elements.length - 2].level.name);
              }}
            >
              {t('dialog.back')}
            </ActionButton>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

displayName(DrilldownHeader, 'DrilldownHeader');

export default DrilldownHeader;
