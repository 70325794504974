import React, { useMemo } from 'react';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { keyBy } from 'lodash';
import DefaultSelect from '../Select';
import { IconButton } from '../Button';
import TextFilter from './TextFilter';
import RangeFilter from './RangeFilter';
import ChoiceFilter from './ChoiceFilter';
import { displayName } from '../../util';
import { DeleteOutlined } from '@mui/icons-material';
import MultiSelectFilter from './MultiSelectFilter';
import {
  FilterState,
  useAnalysisFilterFields,
  useApplyFilters,
  useFilter,
  useSetCurrentPageToZero,
} from '../../globalState';

interface Props {
  filterId: number;
  onDelete: () => void;
}

const AdditionalFilter: React.FC<Props> = ({ filterId, onDelete }) => {
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const filterFields = useAnalysisFilterFields();
  const applyFilters = useApplyFilters();
  const [filterState, setFilterState] = useFilter(filterId);

  const sortedFilterFields = useMemo(
    () => filterFields.fields.slice().sort((a, b) => a.label.localeCompare(b.label)),
    [filterFields.fields]
  );
  const fieldsByName = useMemo(() => keyBy(filterFields.fields, 'name'), [filterFields.fields]);
  const currentField = fieldsByName[filterState.field.name];
  const filterType = currentField?.filterType;

  const handleSelectionChange = (e: SelectChangeEvent<unknown>) => {
    const selectedFilterField = fieldsByName[e.target.value as string];
    const selectedFilter = { field: selectedFilterField ?? { name: '--' } };
    if (filterState !== selectedFilter) {
      setAnalysisPageToZero();
    }
    setFilterState(selectedFilter);
  };
  const handleFilterChange = (state: FilterState) => {
    if (JSON.stringify(filterState) !== JSON.stringify(state)) {
      setAnalysisPageToZero();
      setFilterState(state);
      applyFilters();
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Stack direction="row" justifyContent="space-between">
        <DefaultSelect width={12} value={filterState.field.name} onChange={e => handleSelectionChange(e)}>
          <MenuItem value="--">--</MenuItem>
          {sortedFilterFields.map((f, i) => (
            <MenuItem key={i} value={f.name}>
              {f.label}
            </MenuItem>
          ))}
        </DefaultSelect>
        <IconButton onClick={onDelete} customSize={5} disabled={filterId === 1 && filterState.field.name === '--'}>
          <DeleteOutlined fontSize="small" sx={{ paddingLeft: '8px' }} />
        </IconButton>
      </Stack>
      {(filterType === 'textField' || filterType === 'textFieldStartsWith') && (
        <TextFilter
          filterState={filterState}
          onChange={handleFilterChange}
          startsWith={filterType === 'textFieldStartsWith'}
        />
      )}
      {filterType === 'range' && <RangeFilter filterState={filterState} onChange={handleFilterChange} />}
      {filterType === 'choice' && <ChoiceFilter filterState={filterState} onChange={handleFilterChange} />}
      {filterType === 'multiSelectFilter' && (
        <MultiSelectFilter filterState={filterState} onChange={handleFilterChange} />
      )}
    </Stack>
  );
};

displayName(AdditionalFilter, 'AdditionalFilter');

export default AdditionalFilter;
