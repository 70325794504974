import React from 'react';
import Dialog from '../Dialog';
import { Stack, Typography } from '@mui/material';
import { ActionButton } from '../Button';
import { displayName } from '../../util';

interface Props {
  title: string;
  body: React.ReactElement | string;
  buttonLabel: string;
  onClick: () => void;
}

const InformationDialog: React.FC<Props> = ({ title, body, buttonLabel, onClick }) => {
  let bodyElement = body;
  if (typeof body === 'string') {
    bodyElement = <Typography variant="body1">{body}</Typography>;
  }
  let header = <></>;
  let marginTop = undefined;
  if (title.length !== 0) {
    header = <Typography variant="h2">{title}</Typography>;
    marginTop = 4;
  }

  return (
    <Dialog header={header} width="480px">
      <Stack direction="column" marginTop={marginTop}>
        {bodyElement}
        <Stack direction="row" justifyContent="right" marginTop={4}>
          <ActionButton onClick={onClick}>{buttonLabel}</ActionButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

displayName(InformationDialog, 'InformationDialog');

export default InformationDialog;
