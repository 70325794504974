import React from 'react';
import { Stack, Typography } from '@mui/material';
import { displayName } from '../../util';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface HighlightProps {
  index: number;
  length: number;
}

interface Props {
  label: string;
  highlight?: HighlightProps;
  stylingNotHighlighted?: SxProps<Theme>;
}

const TreeItemLabel: React.FC<Props> = ({ label, highlight, stylingNotHighlighted }) => {
  if (!highlight) {
    return <Typography sx={stylingNotHighlighted}>{label}</Typography>;
  }
  const { index, length } = highlight;
  return (
    <Stack direction="row">
      <Typography whiteSpace="pre">{label.substring(0, index)}</Typography>
      <Stack bgcolor="#FFFF00">
        <Typography whiteSpace="pre">{label.substring(index, index + length)}</Typography>
      </Stack>
      <Typography whiteSpace="pre">{label.substring(index + length)}</Typography>
    </Stack>
  );
};

displayName(TreeItemLabel, 'TreeItemLabel');

export default TreeItemLabel;
