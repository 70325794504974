import React from 'react';
import { Pagination } from '@mui/material';
import { displayName } from '../../util';

interface ClientSidePaginationProps {
  currentPage: number;
  pageCount: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  alignRight?: boolean;
}

export const ClientSidePagination: React.FC<ClientSidePaginationProps> = ({
  currentPage,
  pageCount,
  onChange,
  alignRight,
}) => {
  return (
    <Pagination
      variant="outlined"
      color="primary"
      size="small"
      count={pageCount}
      page={currentPage + 1}
      onChange={onChange}
      sx={{
        '& > ul': {
          justifyContent: alignRight === true ? 'right' : undefined,
        },
      }}
    />
  );
};

displayName(ClientSidePagination, 'ClientSidePagination');

export default ClientSidePagination;
