import React, { useCallback } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import Dialog from '../Dialog';
import { checkForApiError, displayName, isValidEmailAddress } from '../../util';
import Text from '../Text';
import FormikTextInputField from '../formik/FormikTextInputField';
import SubmitButton from '../formik/SubmitButton';
import { useApi, usePrivilegeLevels } from '../../globalState';
import { SupportUserDTO } from '../../dto';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError, ValidationError } from '../Error';
import FormikSelect from '../formik/FormikSelect';
import { CloseButton } from '../Button';
import FormikTextInput from '../formik/FormikTextInput';

interface FormData {
  name: string;
  mail: string;
  firstName?: string;
  lastName?: string;
  level: string;
}

interface Props {
  user?: SupportUserDTO;
  onClose: (dirty: boolean) => void;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

const SupportUserDialog: React.FC<Props> = ({ user, onClose }) => {
  const api = useApi();
  const privilegeLevels = usePrivilegeLevels();
  const { t } = useTranslation();
  const setEncounteredError = useSetEncounteredError();

  const validate = useCallback(
    (values: FormData): ValidationErrors => {
      const errors: ValidationErrors = {};
      if (!values.name || values.name.trim().length === 0) {
        errors.name = { message: t('admin.support.user.editingDialog.validation.emptyField') };
      }
      if (!values.mail || values.mail.trim().length === 0) {
        errors.mail = { message: t('admin.support.user.editingDialog.validation.emptyField') };
      } else if (!isValidEmailAddress(values.mail)) {
        errors.mail = { message: t('admin.support.user.editingDialog.validation.invalidEmail') };
      }
      return errors;
    },
    [t]
  );
  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        if (user) {
          const updateReponse = await api.updateUser(user.id, {
            mail: values.mail,
            firstName: values.firstName,
            lastName: values.lastName,
            level: values.level,
          });
          checkForApiError(updateReponse);
        } else {
          const creationResponse = await api.createUser(values);
          checkForApiError(creationResponse);
        }
      } catch (err) {
        setEncounteredError(
          t('admin.support.user.error', {
            action: user ? t(`action.edit`) : t(`action.create`),
          }),
          toError(err),
          { api: user ? `updateUser` : `createUser`, id: user ? user.id : 'createUser' }
        );
      } finally {
        onClose(true);
      }
    },
    [api, onClose, setEncounteredError, t, user]
  );
  const handleClose = useCallback(() => onClose(false), [onClose]);
  return (
    <Dialog
      width="800px"
      header={
        <Typography variant="h2">
          {user ? t('admin.support.user.editingDialog.title.edit') : t('admin.support.user.editingDialog.title.add')}
        </Typography>
      }
    >
      <Formik
        initialValues={
          user
            ? {
                name: user.name,
                firstName: user.firstName,
                lastName: user.lastName,
                mail: user.mail,
                level: user.level,
              }
            : {
                name: '',
                firstName: '',
                lastName: '',
                mail: '',
                level: privilegeLevels[0].value,
              }
        }
        validate={validate}
        onSubmit={handleSubmit}
      >
        <Stack direction="column" gap={5} justifyContent="space-between" height={'100%'}>
          <Stack direction="row" justifyContent="space-between" gap={5} marginTop={5}>
            <Stack direction="column" flexBasis="10px" flexGrow={1} gap={4}>
              <FormikTextInput label={t('admin.support.user.editingDialog.email')} fieldName={'mail'} inputWidth={12} />
              <FormikTextInput
                label={t('admin.support.user.editingDialog.lastName')}
                fieldName={'lastName'}
                inputWidth={12}
              />
              <FormikTextInput
                label={t('admin.support.user.editingDialog.name')}
                fieldName={'firstName'}
                textWidth={10}
              />
            </Stack>
            <Stack direction="column" flexBasis="10px" flexGrow={1} gap={4}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text>{t('admin.support.user.editingDialog.login')}</Text>
                {user ? <Text width={12}>{user.name}</Text> : <FormikTextInputField fieldName="name" width={12} />}
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text>{t('admin.support.user.editingDialog.level')}</Text>
                <FormikSelect name="level" width={12}>
                  {privilegeLevels.map((l, i) => (
                    <MenuItem key={i} value={l.value}>
                      {l.label}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginTop={4}>
            <CloseButton onClose={handleClose} />
            <SubmitButton>{t('dialog.save')}</SubmitButton>
          </Stack>
        </Stack>
      </Formik>
    </Dialog>
  );
};

displayName(SupportUserDialog, 'SupportUserDialog');

export default SupportUserDialog;
