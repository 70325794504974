import React, { useRef } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { AxisProps } from '@nivo/axes';
import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import Text from '../Text';
import { ChartItemDTO } from '../../dto';
import { flatMap, get, isEmpty, isEqual } from 'lodash';
import { displayName } from '../../util';
import { ChartDownloadComponent, DownloadContainer } from './ChartDownloadComponent';
import { Box } from '@nivo/core';
import SimpleCard from '../SimpleCard';
import { CHART_COLOR_SCHEME } from '../../globalState/variables';

type FormatterType = (value: number) => string;

interface TooltipProps {
  categoryArray: string[];
  isGroupedArray: boolean;
  categoryGrouping?: string;
  groupNameToLabel?: Map<string, string>;
}
const Tooltip: React.FC<BarTooltipProps<BarDatum> & TooltipProps> = ({
  data,
  value,
  label,
  categoryArray,
  isGroupedArray,
  categoryGrouping,
  groupNameToLabel,
}) => {
  const formatter = get(data, 'formatters.valueAxis') as unknown as FormatterType;
  const formattedValue = formatter ? formatter(value) : value;
  let newLabel = get(data, 'labels.valueAxis');

  if (isGroupedArray) {
    let splitLabel = label.split(' - ')[0];
    for (const category of categoryArray) {
      if (label.includes(category)) {
        const categorySplitLabel = label.split(category);
        const cleandedLabel = categorySplitLabel[0];
        splitLabel = cleandedLabel.slice(0, cleandedLabel.length - 3);
        break;
      }
    }
    newLabel = splitLabel;
    if (categoryGrouping) {
      newLabel = categoryGrouping + ' ' + splitLabel + ': ';
      if (groupNameToLabel) {
        const categoryGroupingLabel = groupNameToLabel.get(categoryGrouping);
        if (categoryGroupingLabel) {
          newLabel = categoryGroupingLabel + ' ' + splitLabel + ': ';
        }
      }
    }
  }

  return (
    <SimpleCard>
      <Stack direction="column" padding={3}>
        <Text>{data['category']}</Text>
        <Stack direction="row" gap={4}>
          <Text>{newLabel}</Text>
          <Text weight="500">{formattedValue}</Text>
        </Stack>
      </Stack>
    </SimpleCard>
  );
};

interface Grouping {
  groupingName: string;
  value: number;
}

function groupResultsByCategory(input: ChartItemDTO[]): BarDatum[] {
  const categoryToGroupingsMap = new Map<string, Grouping[]>();
  input.forEach(it => {
    let groupings: Grouping[] = [];
    if (categoryToGroupingsMap.has(it.category)) {
      const previousGroupings = categoryToGroupingsMap.get(it.category);
      if (previousGroupings !== undefined) {
        groupings = previousGroupings;
      }
    }
    if (it.grouping !== undefined) {
      const groupingName = it.grouping;
      const value = it.value;
      groupings.push({ groupingName, value });
      categoryToGroupingsMap.set(it.category, groupings);
    }
  });
  const result: BarDatum[] = [];
  const categories = Array.from(categoryToGroupingsMap.keys());
  for (const category of categories) {
    const groupings = categoryToGroupingsMap.get(category);
    const newDataPoint: BarDatum = {};
    if (groupings !== undefined) {
      newDataPoint['category'] = category;
      //this assumes, that there is only one type of value formatter that applies to all the data
      newDataPoint['formatters'] = get(input[0], 'formatters') as never;
      groupings.forEach(it => {
        newDataPoint[it.groupingName] = it.value;
      });
      result.push(newDataPoint);
    }
  }
  return result;
}

type accessibleProperties = 'grouping' | 'category';

function getAllUniqueElements(input: ChartItemDTO[], property: accessibleProperties): string[] {
  const keySet = new Set<string>();
  input.forEach(it => {
    if (property === 'grouping' && it.grouping !== undefined) {
      keySet.add(it.grouping);
    } else if (property === 'category') {
      keySet.add(it.category);
    }
  });
  return Array.from(keySet.keys());
}

function getAmountNonEmptyGroupings(input: ChartItemDTO[]): number {
  const keySet = new Set<string>();
  input.forEach(it => {
    if (it.grouping !== undefined) {
      if (it.value !== 0) {
        keySet.add(it.grouping);
      }
    }
  });
  return Array.from(keySet.keys()).length;
}

interface Props {
  data: ChartItemDTO[];
  valueAxisLabel: string;
  layout: 'vertical' | 'horizontal';
  tableMargin: Box;
  valueFormatter?: FormatterType;
  title?: string;
  groupNameToLabel?: Map<string, string>;
  categoryGrouping?: string;
}

const BarChart: React.FC<Props> = ({
  data,
  valueAxisLabel,
  layout,
  tableMargin,
  valueFormatter,
  title,
  categoryGrouping,
  groupNameToLabel,
}) => {
  const theme = useTheme();
  const chart = useRef<HTMLDivElement | null>(null);

  let chartInput: ChartItemDTO[] | BarDatum[] = data;
  let keys: string[] = ['value'];
  const categoryArray = getAllUniqueElements(data, 'category');
  const groupingArray = getAllUniqueElements(data, 'grouping');
  const isGroupedArray = !isEmpty(groupingArray)
    ? !isEqual(categoryArray, groupingArray) && getAmountNonEmptyGroupings(data) > 1
    : false;
  if (isGroupedArray) {
    chartInput = groupResultsByCategory(data);
    keys = groupingArray;
  }

  const horizontalCategoryAxis: AxisProps = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 32,
    renderTick: tick => <YAxisTicks x={tick.x} y={tick.y} text={tick.value as string} />,
  };
  const categoryAxis: AxisProps = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 32,
  };
  const valueAxis: AxisProps = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: valueAxisLabel,
    legendPosition: 'middle',
    legendOffset: layout === 'vertical' ? -80 : 40,
    format: valueFormatter,
  };
  const itemCount = chartInput.length;

  return (
    <DownloadContainer ref={chart}>
      <ChartDownloadComponent chart={chart} />
      {title && (
        <Typography variant={'h1'} sx={{ textAlign: 'center', marginTop: '15px' }}>
          {title}
        </Typography>
      )}
      <ResponsiveBar
        layout={layout}
        theme={{
          text: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
          },
          axis: {
            legend: {
              text: {
                fontWeight: 500,
              },
            },
          },
        }}
        data={chartInput as never}
        keys={keys}
        indexBy="category"
        valueScale={{ type: 'linear' }}
        valueFormat={valueFormatter}
        indexScale={{ type: 'band', round: true }}
        margin={tableMargin}
        padding={itemCount === 1 ? 0.7 : 0.4}
        colors={isGroupedArray ? CHART_COLOR_SCHEME : theme.palette.primary.main}
        borderColor={theme.palette.primary.main}
        borderWidth={0}
        enableLabel={false}
        axisBottom={layout === 'vertical' ? categoryAxis : valueAxis}
        axisLeft={layout === 'vertical' ? valueAxis : horizontalCategoryAxis}
        enableGridX={layout === 'horizontal'}
        enableGridY={layout === 'vertical'}
        tooltip={x => (
          <Tooltip
            categoryArray={categoryArray}
            isGroupedArray={isGroupedArray}
            categoryGrouping={categoryGrouping}
            groupNameToLabel={groupNameToLabel}
            {...x}
          />
        )}
      />
    </DownloadContainer>
  );
};

displayName(BarChart, 'BarChart');

export default BarChart;

interface YAxisTicksProps {
  x: number;
  y: number;
  text: string;
}

const YAxisTicks: React.FC<YAxisTicksProps> = ({ x, y, text }) => {
  const theme = useTheme();

  const splitOnSpace = text.split(' ');
  const combinedString = combineString(splitOnSpace);
  const cleanedString = flatMap(combinedString.map(it => cleanString(it)));
  const hypenatedString = cleanedString.map((string, i) => {
    if (i !== cleanedString.length - 1 && string[string.length - 1] !== '-') {
      return string + '-';
    } else {
      return string;
    }
  });
  const yOffset = hypenatedString.length > 1 ? hypenatedString.length * 5 : 0;

  return (
    <>
      {hypenatedString.map((stringPart, i) => (
        <text
          key={i}
          x={x - 95}
          y={y - yOffset + 15 * i}
          style={{
            fontSize: theme.typography.body1.fontSize,
            fill: theme.typography.body1.color,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {stringPart}
        </text>
      ))}
    </>
  );
};

function combineString(stringList: string[]): string[] {
  let i = 0;
  const res: string[] = [];
  while (i < stringList.length) {
    const curString = stringList[i];
    const j = i + 1;
    if (j < stringList.length) {
      const nextString = stringList[j];
      if (curString.length + nextString.length <= 10) {
        res.push(curString + ' ' + nextString);
      } else {
        res.push(curString);
        res.push(nextString);
      }
      i = i + 2;
    } else {
      res.push(curString);
      i++;
    }
  }
  return res;
}

function cleanString(inputString: string): string[] {
  const splitString = inputString.split(/(.{10})/).filter(O => O);
  if (splitString.length > 1 && splitString[splitString.length - 1].length < 3) {
    const toAdd = splitString.pop();
    if (toAdd) {
      const prevString = splitString[splitString.length - 1];
      splitString[splitString.length - 1] = prevString + toAdd;
    }
  }
  return splitString;
}
