import React from 'react';
import { IconButton } from './Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { displayName } from '../util';

interface Props {
  className?: string;
  size?: number;
  onClick: () => void;
  disabled?: boolean;
}

const RunAnalysisButton: React.FC<Props> = ({ className, size, onClick, disabled }) => {
  return (
    <IconButton className={className} customSize={size} onClick={onClick} disabled={disabled}>
      <ChevronRightIcon />
    </IconButton>
  );
};

displayName(RunAnalysisButton, 'RunAnalysisButton');

export default RunAnalysisButton;
