import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dialog from '../Dialog';
import { ActionButton } from '../Button';
import { displayName, throwApiError } from '../../util';
import { useApi } from '../../globalState';
import ErrorMessageTree from '../costcenter/ErrorMessageTree';
import { noop } from 'lodash';
import { createTrie, SubscriptionChange, TreeNode, TreeView } from '../treeView';
import { toError, useSetEncounteredError } from '../Error';
import { useSetSuccessMessage } from '../Success';
import { acceptedExtensions, FileType } from './UploadDialog';
import { SuspendedSpinner } from '../Spinner';
import {
  CostCenterDTO,
  SubscriptionAssignmentDTO,
  SubscriptionAssignmentResult,
  unpackSubscriptionAssignmentDTO,
} from '../../dto';

function determineChange(
  cc: CostCenterDTO,
  addedAssignments: Map<number, string[]>,
  removedAssignments: Map<number, string[]>
): boolean {
  const addedSubscriptions = addedAssignments.get(cc.costCenterId)?.map(function (x): SubscriptionChange {
    return { id: x, changeType: 'added' };
  });
  const removedSubscriptions = removedAssignments.get(cc.costCenterId)?.map(function (x): SubscriptionChange {
    return { id: x, changeType: 'removed' };
  });
  const hasChanged = addedSubscriptions !== undefined || removedSubscriptions !== undefined;

  const childrenChanges = cc.costCenters.map(function (x) {
    return determineChange(x, addedAssignments, removedAssignments);
  });
  return hasChanged || childrenChanges.some(x => x);
}

function wrapAsTreeNode(
  cc: CostCenterDTO,
  subscriptionAssignmentResult: SubscriptionAssignmentResult
): TreeNode<CostCenterDTO> {
  const addedAssignments: Map<number, string[]> = subscriptionAssignmentResult.addedSubscriptionAssignments;
  const removedAssignments: Map<number, string[]> = subscriptionAssignmentResult.removedSubscriptionAssignments;

  const addedSubscriptions = addedAssignments.get(cc.costCenterId)?.map(function (x): SubscriptionChange {
    return { id: x, changeType: 'added' };
  });
  const finalAddedSubscriptions = addedSubscriptions === undefined ? [] : addedSubscriptions;

  const removedSubscriptions = removedAssignments.get(cc.costCenterId)?.map(function (x): SubscriptionChange {
    return { id: x, changeType: 'removed' };
  });
  const finalRemovedSubscriptions = removedSubscriptions === undefined ? [] : removedSubscriptions;
  const changedSubscription = [...finalAddedSubscriptions, ...finalRemovedSubscriptions];
  const stylingNotHighlighted = determineChange(cc, addedAssignments, removedAssignments) ? { fontWeight: 'bold' } : {};

  return {
    id: cc.costCenterId + '',
    label: cc.name,
    value: cc,
    children: cc.costCenters.map(function (x) {
      return wrapAsTreeNode(x, subscriptionAssignmentResult);
    }),
    stylingNotHighlighted: stylingNotHighlighted,
    additions: changedSubscription,
  };
}

interface Props {
  root: CostCenterDTO;
  subscriptionAssignmentResultDTO: SubscriptionAssignmentDTO;
  currentFileName: string;
  onClose: () => void;
  upload: (file: File) => Promise<unknown>;
}

const ApplySubscriptionAssignmentDialog: React.FC<Props> = ({
  root,
  subscriptionAssignmentResultDTO,
  currentFileName,
  onClose,
  upload,
}) => {
  const api = useApi();
  const { t } = useTranslation();
  const setEncounteredError = useSetEncounteredError();
  const setSuccessMessage = useSetSuccessMessage();
  const [suspended, setSuspended] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const subscriptionAssignmentResults = unpackSubscriptionAssignmentDTO(subscriptionAssignmentResultDTO);
  const rootNode = useMemo(
    () => wrapAsTreeNode(root, subscriptionAssignmentResults),
    [root, subscriptionAssignmentResults]
  );

  const apply = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await api.applyAssignments(subscriptionAssignmentResultDTO);
  }, [api, subscriptionAssignmentResultDTO]);
  const onApply = useCallback(async () => {
    setSuspended(true);
    try {
      const applicationResponse = await apply();
      onClose();
      if (applicationResponse.error !== undefined && applicationResponse.error !== null) {
        throwApiError(applicationResponse.error);
      } else {
        setSuccessMessage(
          t('costCenters.subscriptions.informationDialog.assignmentImport.success', { fileName: currentFileName })
        );
      }
    } catch (err) {
      onClose();
      setEncounteredError(
        t('costCenters.subscriptions.informationDialog.assignmentImport.failure', {
          fileName: currentFileName,
        }),
        toError(err),
        { api: 'applyAssignments' }
      );
    }
  }, [apply, currentFileName, onClose, setEncounteredError, setSuccessMessage, t]);
  const onUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFiles = event.target.files;
      if (newFiles !== null) {
        setSuspended(true);
        upload(newFiles[0]);
        setSuspended(false);
      }
    },
    [upload]
  );
  const onUploadClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const numberFailedImports = subscriptionAssignmentResultDTO.errorMessageList.length;
  const importDisabled = numberFailedImports !== 0;
  const importMessage = importDisabled
    ? t('costCenters.subscriptions.assignmentDialog.importMessage.failure', { fileName: currentFileName })
    : t('costCenters.subscriptions.assignmentDialog.importMessage.success', { fileName: currentFileName });
  const disabledImportRowsMessage = t('costCenters.subscriptions.assignmentDialog.disabledImportRowsMessage', {
    numberRows: numberFailedImports,
  });
  const trie = useMemo(() => {
    return createTrie(rootNode);
  }, [rootNode]);

  return (
    <Dialog
      header={<Typography variant="h2">{t('costCenters.subscriptions.assignmentDialog.title')}</Typography>}
      width="480px"
    >
      <Stack direction="column">
        {importDisabled && (
          <Stack marginTop={4}>
            <ErrorMessageTree errorMessages={subscriptionAssignmentResults.invalidAssignmentsErrorMessages} />
            <Typography marginTop={3}>{disabledImportRowsMessage}</Typography>
          </Stack>
        )}
        <Stack gap={4} marginTop={4}>
          <Typography fontWeight={700}>{t('costCenters.subscriptions.assignmentDialog.differences')}</Typography>
          <TreeView
            root={rootNode}
            treeKey={'assignmentUpload'}
            onSelectionChange={noop}
            trie={trie}
            selectedNode={rootNode}
          />
        </Stack>
        <Stack marginTop={4}>
          <Typography>{importMessage}</Typography>
          <input
            type={'file'}
            accept={acceptedExtensions([FileType.EXCEL])}
            ref={inputRef}
            onChange={onUpload}
            style={{ display: 'none' }}
          />
        </Stack>
        <SuspendedSpinner suspended={suspended} />
        <Stack direction="row" justifyContent={'space-between'} marginTop={4}>
          <ActionButton variant={'outlined'} onClick={onClose} disabled={suspended}>
            {t('dialog.cancel')}
          </ActionButton>
          <Stack direction="row" gap={'4px'}>
            <ActionButton onClick={onUploadClick} disabled={suspended}>
              {t('dialog.upload')}
            </ActionButton>
            <ActionButton onClick={onApply} disabled={importDisabled || suspended}>
              {t('dialog.apply')}
            </ActionButton>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

displayName(ApplySubscriptionAssignmentDialog, 'ConfirmSubscriptionAssignmentDialog');

export default ApplySubscriptionAssignmentDialog;
